<template>
	<div>
		<b-row>
			<b-col col="12">
				<b-overlay
						:show="isBusy"
						rounded
						opacity="0.6"
						spinner-small
						spinner-variant="primary"
						class="d-inline-block w-100"
				>
					<b-card class="mt-3">
						<center>Updating Data...</center>
					</b-card>
				</b-overlay>
			</b-col>
		</b-row>
	</div>
</template>
<style lang="scss" scoped>
	@import '../../assets/scss/index-page.scss';
</style>
<script>
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import IncomingDocumentList from '@/models/IncomingDocument/IncomingDocumentList';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import RoleDetail from '@/models/Core/RoleDetail';

import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import AppInputTextarea from '@/components/AppInputTextarea';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';
import SenderStatusFilter from '@/filters/SenderStatus/SenderStatusFilter';
import SenderStatus from '@/models/SenderStatus/SenderStatus';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import SifatSuratFilter from '@/filters/SifatSurat/SifatSuratFilter';
import SifatSurat from '@/models/SifatSurat/SifatSurat';
import DocumentTypeFilter from '@/filters/DocumentType/DocumentTypeFilter';
import DocumentType from '@/models/DocumentType/DocumentType';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import AppLabelFileupload from '@/components/AppLabelFileupload';
import AttachmentFilter from '@/filters/Core/AttachmentFilter';
import Attachment from '@/models/Core/Attachment';
import DistributionTypeFilter from '@/filters/DistributionType/DistributionTypeFilter';
import DistributionType from '@/models/DistributionType/DistributionType';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
import IncomingDocumentStatusFilter from '@/filters/IncomingDocumentStatus/IncomingDocumentStatusFilter';
import IncomingDocumentStatus from '@/models/IncomingDocumentStatus/IncomingDocumentStatus';
import PopupDownload from '@/components/PopupDownload';
import PopupPDF from '@/components/PopupPDF';
import PopupDataEditNotification from '@/components/PopupDataEditNotification';
import Upload from './Upload';
import PopupDownloadDocument from './PopupDownloadDocument';
import PopupDestinationUsers from './PopupDestinationUsers';
import { mapGetters } from 'vuex';

export default {
	components : {PopupDestinationUsers,PopupDownloadDocument,IncomingDocument, IncomingDocumentList, IncomingDocumentFilter, AppInputTextbox,AppInputTextarea,AppInputComboboxAutocomplete,AppInputDatepicker,AppInputDatepickerRange,AppInputTextarea,AppInputDatetimepicker,AppInputDatetimepickerRange,PopupDownload,PopupPDF,Upload,PopupDataEditNotification,AppLabelFileupload},
	data() {
		return {
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fields: [
				{"no" : { "class" : "th-number"}}, 
				{"documentCode": {}, "class" : "th-document-code", "label" : "Kode Risalah", key: 'documentCode', sortable: true}, 
				{"createdAt": {}, "class" : "th-created-at", "label" : "Tanggal", key: 'createdAt', sortable: true}, 
				{"senderName": {}, "class" : "th-sender-name", "label" : "Pengirim", key: 'senderName', sortable: true}, 
				{"destinationUser": {}, "class" : "th-destination-user", "label" : "Ditujukan Kepada", key: 'destinationUser', sortable: false}, 
				{"documentType": {}, "class" : "th-document-type", "label" : "Jenis Surat/Barang", key: 'documentType', sortable: true}, 
				//{"documentNo": {}, "class" : "th-document-no", "label" : "Nomor Surat", key: 'documentNo', sortable: true}, 
				{"description": {}, "class" : "th-description", "label" : "Ringkasan Surat", key: 'description', sortable: true}, 
				{"documentAttachment": {}, "class" : "th-document-attachment", "label" : "File Dokumen", key: 'documentAttachment', sortable: true}, 
				{"distributionType": {}, "class" : "th-distribution-type", "label" : "Bentuk Distribusi", key: 'distributionType', sortable: false},
				{"documentStatus": {}, "class" : "th-document-status", "label" : "Status", key: 'documentStatus', sortable: true},
				{"actions": { "class" : "th-actions", "label" : ""}},
			],
			isBusy:false,
			isDataLoading: false,
			isDownloadDataBusy: false,
			isMultiplePrintBusy: false,
			showPopupUpload: false,
			showPopupDownload: false,
			isShowPopupPDF: false,
			pdfUrl: "",
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			filter: null,
			processId: "",
			popupDownloadLabel: "",
			hasLoadedData: false,
			showFilter: false,
			isShowFilter: false,
			isCreateBusy: false,
			btnEditData: [],
			currentDataId: null,
			incomingDocuments: [],
			incomingDocumentsPaging: {},
			documentLocations : [],
			senderStatuss : [],
			userProfiles : [],
			sifatSurats : [],
			documentTypes : [],
			distributionTypes : [],
			incomingDocumentStatuss : [],
			isShowPopupDocument: false,
			parent:{},
			isSendEmailBusy: false,
			createdAtFrom: "",
			createdAtTo: "",
			allowUpdate: false,
			isShowPopupDestinationUser: false,
		}
	},
	methods : {
		showPopupDocument(parent) {
			
			this.allowUpdate = false;
			if (this.userId == parent.createdBy) {
				this.allowUpdate = true;
			}
			
			this.parent=parent;
			this.isShowPopupDocument = true;
		},
		showPopupDestinationUser(parent) {
			this.parent=parent;
			this.isShowPopupDestinationUser = true;
		},
		getIncomingDocuments : async function() {
			this.isDataLoading = true;
			this.sortingBy = Array();
			if (this.sortBy != null) {
				this.sortingBy[this.sortBy] = this.sortDesc;
			}
			IncomingDocument.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions,'documentLocation,senderStatus,userProfile,destinationUser,copyUser,sifatSurat,documentType,distributionType,incomingDocumentStatus,copyDocumentStatus,documentStatus,documentAttachment,incomingDocumentDestinationUser,incomingDocumentCopyUser')
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
					this.incomingDocumentsPaging = result.pagination
				}
				this.isDataLoading = false;
			})
			.catch(error => {
				this.isDataLoading = false;
			});
		},
		getIncomingDocumentsWithoutLoading : async function() {
			this.sortingBy = Array();
			if (this.sortBy != null) {
				this.sortingBy[this.sortBy] = this.sortDesc;
			}
			IncomingDocument.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'documentLocation,senderStatus,userProfile,destinationUser,copyUser,sifatSurat,documentType,distributionType,incomingDocumentStatus,copyDocumentStatus,documentStatus,documentAttachment,incomingDocumentDestinationUser,incomingDocumentCopyUser')
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
					this.incomingDocumentsPaging = result.pagination
				}
			})
			.catch(error => {});
		},
		getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentLocation.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		getSenderStatuss : async function(input) {
			let filter = new SenderStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			SenderStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.senderStatuss = result.data
				}
			})
			.catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		getSifatSurats : async function(input) {
			let filter = new SifatSuratFilter();
			if (input != undefined) {
				filter.name = input;
			}
			SifatSurat.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.sifatSurats = result.data
				}
			})
			.catch(error => {});
		},
		getDocumentTypes : async function(input) {
			let filter = new DocumentTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentTypes = result.data
				}
			})
			.catch(error => {});
		},
		getDistributionTypes : async function(input) {
			let filter = new DistributionTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DistributionType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.distributionTypes = result.data
				}
			})
			.catch(error => {});
		},
		getIncomingDocumentStatuss : async function(input) {
			let filter = new IncomingDocumentStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			IncomingDocumentStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocumentStatuss = result.data
				}
			})
			.catch(error => {});
		},
		resetFilter() {
			this.filter = new IncomingDocumentFilter();
		},
		showPopupUploadData() {
			this.showPopupUpload = true;
		},
		uploadData(modalEvent) {
			this.showPopupUpload = false;
		},
		resetModal() {
			this.showPopupUpload = false;
			this.showPopupDownload = false;
			this.isShowPopupPDF = false;
		},
		resetModalUpload() {
			this.isShowPopupDocument = false;
			this.showPopupUpload = false;
			this.isShowPopupDestinationUser = false;
			this.getIncomingDocuments();
		},
		toggleIsPrint(id) {
			if (this.checkAllData.includes(id)) {
				this.$store.dispatch("removeCheckData", id);
			} else {
				this.$store.dispatch("addCheckData", id);
			}
		},
		toggleIsReminder(model) {
			if (model.reminderDate == null || model.reminderDate == undefined) {
				this.$store.dispatch('addErrorMessageGlobal', "Untuk menyalakan reminder, silahkan set Reminder Date terlebih dahulu.");  
			} else {
				this.$http.get(process.env.VUE_APP_API_URL + 'incomingdocument/toggleisreminder/' + model.id).then(result => {
					this.getIncomingDocumentsWithoutLoading();
				});
			}
		},
		showPopupPrint(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di cetak";
			this.processId = id;
		},
		showPopupDownloadData(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di unduh";
			this.processId = id;
		},
		showPopupPDF(url) {
			this.isShowPopupPDF = true;
			this.pdfUrl = url;
		},
		printData() {
			let data = this.$store.state.dataIds;
			if (data.length > 0) {
				this.isMultiplePrintBusy = true;
				IncomingDocument.multiPagePrint(this).then(result => {
					if (result) {
						this.showPopupPrint(result.id);
					}
					this.isMultiplePrintBusy = false;
				}).catch(error => { this.isMultiplePrintBusy = false; });
			}
		},
		printSingleData(id) {
			IncomingDocument.singleDataPagePrint(this, id).then(result => {
				if (result) {
					this.showPopupPDF(result.download);
				}
			});
		},
		downloadData() {
			if (this.sortBy != null) {
					this.sortingBy[this.sortBy] = this.sortDesc;
			}
			this.isDownloadDataBusy = true;
			IncomingDocument.downloadData(this, this.filter, this.sortingBy).then(result => {
				if (result) {
					this.showPopupDownloadData(result.id);
				}
				this.isDownloadDataBusy = false;
			}).catch(error => { this.isDownloadDataBusy = false; })
		},
		deleteData(id) {
			IncomingDocument.deleteData(this, id).then(result => {
				if (result)
				{
					this.$store.dispatch('addSuccessMessageGlobal', "Data Berhasil dihapus");
					this.getIncomingDocuments();
				}
			})
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768) {
				this.isShowFilter = true;
			} else {
				this.isShowFilter = this.showFilter;
			}
		},
		createData() {
			this.isCreateBusy = true;
			IncomingDocument.createData(this).then(result => {
				if (result != null) {
					if (result.id != null && result.id != "") {
						this.$router.push({ name: 'incomingdocumentedit', params : { id: result.id } });
					}
				}
				this.isCreateBusy = false;
			}).catch(error => {
				this.isCreateBusy = false;
			})
		},
		sendData() {
			let data = this.$store.state.dataIds;
			if (data.length > 0) {
				this.isSendEmailBusy = true;
				IncomingDocument.sendEmail(this).then(result => {
					this.getIncomingDocuments();
					this.isSendEmailBusy = false;
				}).catch(error => { this.isSendEmailBusy = false; });
			}
		},
		setBetweenDateFilter(filterName, from, to) {
			this.filter[filterName + "From"] = from;
			this.filter[filterName + "To"] = to;
			this.getIncomingDocumentsWithoutLoading();
		}
	},
	beforeMount() {
		this.$store.dispatch("removeCheckAllData");
		this.filter = new IncomingDocumentFilter();
	},
	mounted(){
		let id = this.$route.params.id;
		if (id != null && id != undefined && id != "") {
			this.isBusy = true;

			var this_ = this;
			this.$http.get(process.env.VUE_APP_API_URL + 'incomingdocument/updatereceiveddestination/' + id).then(result => {
				this.$store.dispatch('addSuccessMessageGlobal', "Data Berhasil diperbaharui");
				this.$router.push({ name: 'receiptdocumentindex' });
			}).catch(function(error){
            	this_.$store.dispatch('addErrorMessageGlobal', error);
				this_.$router.push({ name: 'receiptdocumentindex' });
            	throw error;
        	});
		} else {
			this.$router.push({ name: 'receiptdocumentindex' });
		}
	},
	watch: {
		sortBy: {
			handler: function() {
				this.getIncomingDocuments();
			}
		},
		sortDesc: {
			handler: function() {
				this.getIncomingDocuments();
			}
		},
		currentPage: {
			handler: function() {
				this.getIncomingDocuments();
			}
		},
		pageSizeOptions: {
			handler: function() {
				this.getIncomingDocuments();
			}
		},
		selectAll(newValue) {
			if (newValue) {
				IncomingDocument.checkAllData(this, this.filter, this.sortingBy);
			} else {
				this.$store.dispatch("removeCheckAllData");
			}
		},
		'$route'(to, from) {
			if (to != null) {
				if (to.name == "incomingdocumentindex") {
					this.getIncomingDocumentsWithoutLoading();
				}
			}
		},
	},
	computed: {
		checkAllData() {
			return this.$store.state.dataIds;
		},
		userRole() {
			return RoleDetail.query().where("functionInfoId", "incoming_document").first();
		},
		...mapGetters({
			isSuperUser: 'isSuperUser',
			user: 'user',
			userId: 'userId',
		}),
	},
	beforeDestroy() {
		this.$store.dispatch("removeCheckAllData");
	},
}
</script>
