import BaseModelAPI from '../BaseModelAPI';

export default class OutgoingDocumentList extends BaseModelAPI {

	static entity = 'outgoingdocumentlist'
	static entityName = 'outgoingdocument';

	constructor() {
		this.pageIndex = 0;
		this.pageSize = 0;
		this.pageCount = 0;
		this.dataCount = 0;
	}
}
