<template>
	<PopupRouterView label="Contract Document Input"  entityName="contractdocument" :documentId="mainRecordId"  @saveMethod="saveAction" @hideMethod="onHidden">
		<b-card>
			<b-col cols="12" lg="6">
				<app-input-combobox-autocomplete :options="incomingDocuments" optionsLabel="documentCode" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Surat Masuk" id="input-" size="sm" :model.sync="model.incomingDocumentId"  :selected="model.incomingDocument != null ? model.incomingDocument.documentCode : ''" @input="getIncomingDocuments" />
				<app-input-combobox-autocomplete :options="outgoingDocuments" optionsLabel="documentCode" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Surat Keluar" id="input-" size="sm" :model.sync="model.outgoingDocumentId"  :selected="model.outgoingDocument != null ? model.outgoingDocument.documentCode : ''" @input="getOutgoingDocuments" />
				<app-input-datepicker  id="fieldset-contract-date" label-cols-sm="4" label-cols-lg="3" label="Masa Berlaku Kontrak" size="sm" :model.sync="model.contractDate"  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"  />
				<app-input-combobox-autocomplete :options="contractStatuss" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Status" id="input-" size="sm" :model.sync="model.contractStatusId"  :selected="model.contractStatus != null ? model.contractStatus.name : ''" @input="getContractStatuss" />
			</b-col>
			<b-col>
				<span class="float-right" v-if="modelId != null && modelId != undefined && modelId != ''">
					<b-button variant="success" class="float-right ml-2" @click="downloadData" size="sm"><b-icon-download class="mr-1"></b-icon-download> Download</b-button>
				</span>
			</b-col>
		</b-card>
		<PopupConfirmationDetail :openPopup="isShowPopupConfirmation" :model="model" @saveActionMethod="saveAction" @resetMethod="resetModal" />
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</PopupRouterView>
</template>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import ContractDocument from '@/models/ContractDocument/ContractDocument';
import ContractDocumentList from '@/models/ContractDocument/ContractDocumentList';
import ContractDocumentFilter from '@/filters/ContractDocument/ContractDocumentFilter';

import PopupConfirmationDetail from './PopupConfirmationDetail';

import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import ContractStatus from '@/models/ContractStatus/ContractStatus';
import ContractStatusFilter from '@/filters/ContractStatus/ContractStatusFilter';
import PopupRouterView from '@/components/PopupRouterView';
export default {
	components : {AppInputComboboxAutocomplete,AppInputDatepicker,AppInputDatepickerRange,PopupRouterView,PopupConfirmationDetail},
	mixins : [validationMixin],
	validations: {
		model: {
		}
	},
	data() {
		return {
			errorMessage : {
			},
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			modelId: null,
			model: {},
			filterContractDocument : null,
			currentContractDocumentPage : 1,
			contractDocumentPageSize: 10,
			sortByContractDocument: '',
			sortDescContractDocument: '',
			incomingDocuments: [],
			outgoingDocuments: [],
			contractStatuss: [],
			isShowPopupConfirmation : false,
			isDataCommited : false,
			mainRecordId : '',
		}
	},
	methods : {
		getContractDocument : async function(id) {
			this.isBusy = true;
			ContractDocument.getData(this, id).then(result => { this.model = result; this.mainRecordId = String(result.mainRecordId); this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		downloadData() {
			this.isDownloadContractDocument = true;
			this.filterContractDocument.id = this.modelId;
			ContractDocument.downloadDataDetail(this, this.filterContractDocument).then(result => {
				this.isDownloadContractDocument = false;
			}).catch(error => { this.isDownloadContractDocument = false; })
		},
		getIncomingDocuments : async function(input) {
			let filter = new IncomingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			IncomingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		getOutgoingDocuments : async function(input) {
			let filter = new OutgoingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			OutgoingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.outgoingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		getContractStatuss : async function(input) {
			let filter = new ContractStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			ContractStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.contractStatuss = result.data
				}
			})
			.catch(error => {});
		},
		validateState(name) {
			if (!this.$v.model[name]) return null;
			const { $dirty, $error } = this.$v.model[name];
			return $dirty ? !$error : null;
		},
		getErrorMessage(message, name) {
			if (!this.$v.model[name]) return "";
			if (this.$v.model[name].required != undefined && !this.$v.model[name].required) {
				if (message.required) return message.required;
			}
		},
		saveActionConfirmation(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isShowPopupConfirmation = true;
			}
		},
		saveAction(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isBusy = true;
				let id = this.modelId;
				let model = this.model;

				if (id != '' && id != null && id != undefined) {
					ContractDocument.updateData(this, model, id).then(result => {
						this.isDataCommited = true;
						this.$router.push({ name: 'contractdocumentindex' });
						this.isBusy = false;
					}).catch(error => { this.isBusy = false; });
			} else {
				ContractDocument.insertData(this, model).then(result => {
					this.$router.push({ name: 'contractdocumentindex' });
					this.isBusy = false;
				}).catch(error => { this.isBusy = false; });
			}
			}
		},
		onHidden() {
			this.getContractDocuments();
		},
		resetModal(modalType) {
			this.showPopupCopyData = false; 
			this.isShowPopupConfirmation = false;
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
	},
	beforeMount() {
		this.filterContractDocument = new ContractDocumentFilter();
		this.getIncomingDocuments();
		this.getOutgoingDocuments();
		this.getContractStatuss();
	},
	async mounted(){
		let id = this.$route.params.id;
		let copyData = this.$route.params.copydata;
		if (id != '' && id != null && id != undefined) {
			if (copyData != null && copyData != undefined) {
				this.getContractDocument(id);
			} else {
				this.modelId = id;
				this.getContractDocument(id);
			}
		}
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	computed: {
	},
	watch : {
	},
	beforeDestroy() {
	},
}
</script>
