<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true @ok="addButtonAction" title="Add Data Investigator Detail">
		<b-card>
			<b-col cols="12" lg="6">
				<app-input-combobox-autocomplete :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="User Profile" id="input-" size="sm" :model.sync="model.userProfileId"   :selected="model.userProfile != null ? model.userProfile.fullName : ''" @input="getUserProfiles" />
			</b-col>
		</b-card>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<style lang="scss" scoped>
	::v-deep  .modal-dialog {
		width: 70% !important; 
	}
</style> 
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import InvestigatorDetail from '@/models/ArchivedDocument/InvestigatorDetail';
import InvestigatorDetailFilter from '@/filters/ArchivedDocument/InvestigatorDetailFilter';

import ArchivedDocument from '@/models/ArchivedDocument/ArchivedDocument';
import ArchivedDocumentFilter from '@/filters/ArchivedDocument/ArchivedDocumentFilter';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import PopupRouterView from '@/components/PopupRouterView';
export default {
	props: ['modelId', 'openPopup','addButtonMethod', 'resetMethod'],
	components : {AppInputComboboxAutocomplete,PopupRouterView,},
	mixins : [validationMixin],
	validations: {
		model: {
		}
	},
	data() {
		return {
			model : {},
			errorMessage : {
			},
			userProfiles : [],
			isBusy: false,
		}
	},
	methods : {
		getInvestigatorDetail : async function(id) {
			this.isBusy = true;
			InvestigatorDetail.getData(this, id).then(result => { this.model = result; this.isBusy = false }).catch(error => { this.isBusy = false; });
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		validateState(name) {
			const { $dirty, $error } = this.$v.model[name];
			return $dirty ? !$error : null;
		},
		getErrorMessage(message, name) {
			if (this.$v.model[name].required != undefined && !this.$v.model[name].required) {
				if (message.required) return message.required;
			}
		},
		addButtonAction(modalEvent) {
			modalEvent.preventDefault();

			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.$emit("addButtonMethod", modalEvent, this.model); 
			}
		},
		resetModal() {
			this.$emit('resetMethod');
		},
	},
	beforeMount() {
		this.getUserProfiles();
	},
	async mounted(){
	},
	watch : {
		modelId(newValue) {
			if (newValue != null && newValue != undefined && newValue != '') {
				this.getInvestigatorDetail(newValue);
			} else {
				this.model = {};
			}
		},
		openPopup(newValue) {
			if (newValue) {
				this.getUserProfiles();
			} else {
				this.model = {};
			}
		}
	},
	computed: {
		isShowPopup : {
			get: function () {
				if (this.openPopup) return true;
				else return false;
			},
			set: function (newValue) {}
		},
	}
}
</script>
