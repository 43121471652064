<template>
	<div>
		<b-card class="mt-3">
			<b-row>
				<b-col cols="12" md="6" lg="6">
					<b-form-group id="fieldset-send-to-all" label-cols-sm="4" label-cols-lg="3" label="Send to All" label-for="filter-send-to-all">
						<b-form-checkbox :id="'checkbox-send-to-all'" switch v-model="model.value" :name="'checkbox-send-to-all'" :value="'true'" :unchecked-value="'false'" @change="updateData" ></b-form-checkbox>
					</b-form-group>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>
<style lang="scss" scoped>
	@import '../../assets/scss/index-page.scss';
</style>
<script>
import ReceiptDocument from '@/models/ReceiptDocument/ReceiptDocument';
import { mapGetters } from 'vuex';

export default {
	components : {},
	data() {
		return {
			model: {}
		}
	},
	methods : {
		getData : async function() {
			return this.$http.get(process.env.VUE_APP_API_URL + `showemailtoall`)
				.then(response => {
					if (response.data) {
						let responseData = response.data;
						if ("data" in responseData) {
							responseData = responseData.data;
						}
						this.model = responseData;
					}
				})
				.catch(function(error){
					this.$store.dispatch('addErrorMessageGlobal', error);
					throw error;
				});
		},
		updateData : async function() {
			return this.$http.put(process.env.VUE_APP_API_URL + `toggleemailtoall`, this.model)
				.then(response => {
					if (response.data) {
						if (response.data.data) {
							this.model = response.data.data;
							return response.data.data;
						}
						this.model = response.data.data;
						return response.data;
					}
				})
				.catch(function(error){
					this.$store.dispatch('addErrorMessageGlobal', error);
					throw error;
				});
		},
	},
	beforeMount() {
		this.$store.dispatch("removeCheckAllData");
	},
	mounted(){
		let breadcrumb =[
			"Settings"
		];
		this.getData();
		this.$store.dispatch('setBreadCrumb', breadcrumb);
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
		});
	},
	watch: {
		
	},
	computed: {
		...mapGetters({
			isSuperUser: 'isSuperUser',
			user: 'user',
			userId: 'userId',
		}),
	},
	beforeDestroy() {
		this.$store.dispatch("removeCheckAllData");
	},
}
</script>
