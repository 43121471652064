<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true @ok="saveButtonAction" title="Save Confirmation - Reminder">
<!-- APP_DETAIL_HEADER -->
		<b-card>
			<b-col cols="12" lg="6">
					<b-form-group id="fieldset-incoming-document" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" label-for="filter-incoming-document">
						{{ model != null ? ( model.incomingDocument != null ? model.incomingDocument.documentCode : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-reminder-date" label-cols-sm="4" label-cols-lg="3" label="Reminder Date" label-for="filter-reminder-date">
						{{ (model != null ? model.reminderDate : "") | moment("DD-MMM-YYYY HH:mm") }}
					</b-form-group>
					<b-form-group id="fieldset-reminder-status" label-cols-sm="4" label-cols-lg="3" label="Reminder Status" label-for="filter-reminder-status">
						{{ model != null ? ( model.reminderStatus != null ? model.reminderStatus.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-created-by" label-cols-sm="4" label-cols-lg="3" label="Created By" label-for="filter-created-by">
						{{ model != null ? model.createdBy : "" }}
					</b-form-group>
					<b-form-group id="fieldset-updated-by" label-cols-sm="4" label-cols-lg="3" label="Updated By" label-for="filter-updated-by">
						{{ model != null ? model.updatedBy : "" }}
					</b-form-group>
			</b-col>
		</b-card>
<!-- END_APP_DETAIL_HEADER -->
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import Reminder from '@/models/Reminder/Reminder';
import ReminderList from '@/models/Reminder/ReminderList';
import ReminderFilter from '@/filters/Reminder/ReminderFilter';

import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
/*END_APP_DETAIL_IMPORT*/
export default {
	/*APP_DETAIL_PROP_DEFINITION*/
	props : ["openPopup", "model", "saveActionMethod", "resetMethod"],
	/*END_APP_DETAIL_PROP_DEFINITION*/
	components : {
	/*APP_DETAIL_PROP_DEFINITION*/
	PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,
	/*APP_DETAIL_COMPONENTS_DEFINITION*/
},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			/*APP_DETAIL_PAGINATION*/
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterReminder : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentReminderPage : 1,
			reminderPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByReminder: '',
			sortDescReminder: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			/*END_APP_DETAIL_OBJ_VARIABLE*/
		}
	},
	methods : {
		/*APP_DETAIL_GETReminder*/
		getReminder : async function(id) {
			this.isBusy = true;
			Reminder.getData(this, id).then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETReminder*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'remindercopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_SAVEBUTTONACTION*/
		saveButtonAction(modalEvent) {
			this.$emit("saveActionMethod", modalEvent);
		},
		/*END_APP_DETAIL_SAVEBUTTONACTION*/
		/*APP_DETAIL_RESETMODAL*/
		resetModal() {
			this.$emit('resetMethod'); 
		},
		/*END_APP_DETAIL_RESETMODAL*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterReminder = new ReminderFilter();
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	watch : {
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
		isShowPopup: {
			get: function(){
				if (this.openPopup) return true; 
				else return false; 
			},
			set: function(newValue){ }
		},
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
