<template>
	<PopupRouterView label="User Profile Input"  entityName="userprofile" :documentId="mainRecordId"  @saveMethod="saveAction" @hideMethod="onHidden">
		<b-row>
				<b-col cols="12" lg="6">
					<b-card>
						<app-input-textbox id="fieldset-full-name" label-cols-sm="4" label-cols-lg="3" label="Name" label-for="input-full-name" size="sm" :model.sync="model.fullName"   />
						<app-input-datepicker  id="fieldset-birth-date" label-cols-sm="4" label-cols-lg="3" label="Birth Date" size="sm" :model.sync="model.birthDate"  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"  />
						<app-input-textbox id="fieldset-email" label-cols-sm="4" label-cols-lg="3" label="Email" label-for="input-email" size="sm" :model.sync="model.email"   />
						<app-input-textbox id="fieldset-phone-number" label-cols-sm="4" label-cols-lg="3" label="Phone Number" label-for="input-phone-number" size="sm" :model.sync="model.phoneNumber"   />
						<app-input-textarea id="fieldset-address" label-cols-sm="4" label-cols-lg="3" label="Address" label-for="input-address" size="sm" :model.sync="model.address"   />
						<app-input-combobox-autocomplete v-if="false" :options="coreUsers" optionsLabel="firstName" optionsKey="userName" label-cols-sm="4" label-cols-lg="3" label="Core User" id="input-" size="sm" :model.sync="model.coreUserId"  :selected="model.coreUser != null ? model.coreUser.firstName : ''" @input="getCoreUsers" />
					</b-card>
					<b-card class="mt-3">
						<app-input-textbox id="fieldset-nip" label-cols-sm="4" label-cols-lg="3" label="Nip" label-for="input-nip" size="sm" :model.sync="model.nip"   />
						<app-input-textbox id="fieldset-job-description" label-cols-sm="4" label-cols-lg="3" label="Job" label-for="input-job-description" size="sm" :model.sync="model.jobDescription"   />
						<app-input-datepicker  id="fieldset-join-date" label-cols-sm="4" label-cols-lg="3" label="Join Date" size="sm" :model.sync="model.joinDate"  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"  />
						<app-input-textbox id="fieldset-npwp-number" label-cols-sm="4" label-cols-lg="3" label="Npwp Number" label-for="input-npwp-number" size="sm" :model.sync="model.npwpNumber"   />
						<app-input-combobox-autocomplete :options="branchs" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Kantor" id="input-" size="sm" :model.sync="model.branchId"  :selected="model.branch != null ? model.branch.name : ''" @input="getBranchs" />
						<app-input-combobox-autocomplete :options="directorates" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Direktorat" id="input-" size="sm" :model.sync="model.directorateId"  :selected="model.directorate != null ? model.directorate.name : ''" @input="getDirectorates" />
						<app-input-combobox-autocomplete :options="employeeLevels" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Employee Level" id="input-" size="sm" :model.sync="model.employeeLevelId"  :selected="model.employeeLevel != null ? model.employeeLevel.name : ''" @input="getEmployeeLevels" />
						<app-input-combobox-autocomplete :options="employeeStatuss" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Employee Status" id="input-" size="sm" :model.sync="model.employeeStatusId"  :selected="model.employeeStatus != null ? model.employeeStatus.name : ''" @input="getEmployeeStatuss" />
					</b-card>
					
				</b-col>
				<b-col cols="12" lg="6">
					<b-card>
						<app-input-combobox-autocomplete :options="educationLevels" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Education Level" id="input-" size="sm" :model.sync="model.educationLevelId"  :selected="model.educationLevel != null ? model.educationLevel.name : ''" @input="getEducationLevels" />
						<app-input-combobox-autocomplete :options="bloodTypes" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Blood Type" id="input-" size="sm" :model.sync="model.bloodTypeId"  :selected="model.bloodType != null ? model.bloodType.name : ''" @input="getBloodTypes" />
						<app-input-combobox-autocomplete :options="maritalStatuss" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Marital Status" id="input-" size="sm" :model.sync="model.maritalStatusId"  :selected="model.maritalStatus != null ? model.maritalStatus.name : ''" @input="getMaritalStatuss" />
						<app-input-textbox id="fieldset-kids-count" label-cols-sm="4" label-cols-lg="3" label="Kids Count" label-for="input-kids-count" size="sm" :model.sync="model.kidsCount"   />
						<app-input-textbox id="fieldset-real-email" label-cols-sm="4" label-cols-lg="3" label="Real Email" label-for="input-real-email" size="sm" :model.sync="model.realEmail" v-if="false"  />
					</b-card>
				</b-col>
		</b-row>
		<PopupConfirmationDetail :openPopup="isShowPopupConfirmation" :model="model" @saveActionMethod="saveAction" @resetMethod="resetModal" />
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</PopupRouterView>
</template>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileList from '@/models/UserProfile/UserProfileList';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';

import PopupConfirmationDetail from './PopupConfirmationDetail';

import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import CoreUser from '@/models/Core/CoreUser';
import CoreUserFilter from '@/filters/Core/CoreUserFilter';
import Branch from '@/models/Branch/Branch';
import BranchFilter from '@/filters/Branch/BranchFilter';
import Directorate from '@/models/Directorate/Directorate';
import DirectorateFilter from '@/filters/Directorate/DirectorateFilter';
import EmployeeLevel from '@/models/EmployeeLevel/EmployeeLevel';
import EmployeeLevelFilter from '@/filters/EmployeeLevel/EmployeeLevelFilter';
import EmployeeStatus from '@/models/EmployeeStatus/EmployeeStatus';
import EmployeeStatusFilter from '@/filters/EmployeeStatus/EmployeeStatusFilter';
import EducationLevel from '@/models/EducationLevel/EducationLevel';
import EducationLevelFilter from '@/filters/EducationLevel/EducationLevelFilter';
import BloodType from '@/models/BloodType/BloodType';
import BloodTypeFilter from '@/filters/BloodType/BloodTypeFilter';
import MaritalStatus from '@/models/MaritalStatus/MaritalStatus';
import MaritalStatusFilter from '@/filters/MaritalStatus/MaritalStatusFilter';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import AppInputTextarea from '@/components/AppInputTextarea';
import PopupRouterView from '@/components/PopupRouterView';
export default {
	components : {AppInputTextbox,AppInputComboboxAutocomplete,AppInputDatepicker,AppInputDatepickerRange,AppInputTextarea,PopupRouterView,PopupConfirmationDetail},
	mixins : [validationMixin],
	validations: {
		model: {
		}
	},
	data() {
		return {
			errorMessage : {
			},
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			modelId: null,
			model: {},
			filterUserProfile : null,
			currentUserProfilePage : 1,
			userProfilePageSize: 10,
			sortByUserProfile: '',
			sortDescUserProfile: '',
			coreUsers: [],
			branchs: [],
			directorates: [],
			employeeLevels: [],
			employeeStatuss: [],
			educationLevels: [],
			bloodTypes: [],
			maritalStatuss: [],
			isShowPopupConfirmation : false,
			isDataCommited : false,
			mainRecordId : '',
		}
	},
	methods : {
		getUserProfile : async function(id) {
			this.isBusy = true;
			UserProfile.getData(this, id, 'coreUser,branch,directorate,employeeLevel,employeeStatus,educationLevel,bloodType,maritalStatus').then(result => { this.model = result; this.mainRecordId = String(result.mainRecordId); this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		downloadData() {
			this.isDownloadUserProfile = true;
			this.filterUserProfile.id = this.modelId;
			UserProfile.downloadDataDetail(this, this.filterUserProfile).then(result => {
				this.isDownloadUserProfile = false;
			}).catch(error => { this.isDownloadUserProfile = false; })
		},
		getCoreUsers : async function(input) {
			let filter = new CoreUserFilter();
			if (input != undefined) {
				filter.firstName = input;
			}
			CoreUser.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.coreUsers = result.data
				}
			})
			.catch(error => {});
		},
		getBranchs : async function(input) {
			let filter = new BranchFilter();
			if (input != undefined) {
				filter.name = input;
			}
			Branch.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.branchs = result.data
				}
			})
			.catch(error => {});
		},
		getDirectorates : async function(input) {
			let filter = new DirectorateFilter();
			if (input != undefined) {
				filter.name = input;
			}
			Directorate.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.directorates = result.data
				}
			})
			.catch(error => {});
		},
		getEmployeeLevels : async function(input) {
			let filter = new EmployeeLevelFilter();
			if (input != undefined) {
				filter.name = input;
			}
			EmployeeLevel.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.employeeLevels = result.data
				}
			})
			.catch(error => {});
		},
		getEmployeeStatuss : async function(input) {
			let filter = new EmployeeStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			EmployeeStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.employeeStatuss = result.data
				}
			})
			.catch(error => {});
		},
		getEducationLevels : async function(input) {
			let filter = new EducationLevelFilter();
			if (input != undefined) {
				filter.name = input;
			}
			EducationLevel.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.educationLevels = result.data
				}
			})
			.catch(error => {});
		},
		getBloodTypes : async function(input) {
			let filter = new BloodTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			BloodType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.bloodTypes = result.data
				}
			})
			.catch(error => {});
		},
		getMaritalStatuss : async function(input) {
			let filter = new MaritalStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			MaritalStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.maritalStatuss = result.data
				}
			})
			.catch(error => {});
		},
		validateState(name) {
			if (!this.$v.model[name]) return null;
			const { $dirty, $error } = this.$v.model[name];
			return $dirty ? !$error : null;
		},
		getErrorMessage(message, name) {
			if (!this.$v.model[name]) return "";
			if (this.$v.model[name].required != undefined && !this.$v.model[name].required) {
				if (message.required) return message.required;
			}
		},
		saveActionConfirmation(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isShowPopupConfirmation = true;
			}
		},
		saveAction(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isBusy = true;
				let id = this.modelId;
				let model = this.model;

				if (id != '' && id != null && id != undefined) {
					UserProfile.updateData(this, model, id).then(result => {
						this.isDataCommited = true;
						this.$router.push({ name: 'userprofileindex' });
						this.isBusy = false;
					}).catch(error => { this.isBusy = false; });
			} else {
				UserProfile.insertData(this, model).then(result => {
					this.$router.push({ name: 'userprofileindex' });
					this.isBusy = false;
				}).catch(error => { this.isBusy = false; });
			}
			}
		},
		onHidden() {
			this.getUserProfiles();
		},
		resetModal(modalType) {
			this.showPopupCopyData = false; 
			this.isShowPopupConfirmation = false;
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
	},
	beforeMount() {
		this.filterUserProfile = new UserProfileFilter();
		this.getCoreUsers();
		this.getBranchs();
		this.getDirectorates();
		this.getEmployeeLevels();
		this.getEmployeeStatuss();
		this.getEducationLevels();
		this.getBloodTypes();
		this.getMaritalStatuss();
	},
	async mounted(){
		let id = this.$route.params.id;
		let copyData = this.$route.params.copydata;
		if (id != '' && id != null && id != undefined) {
			if (copyData != null && copyData != undefined) {
				this.getUserProfile(id);
			} else {
				this.modelId = id;
				this.getUserProfile(id);
			}
		}
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	computed: {
	},
	watch : {
	},
	beforeDestroy() {
	},
}
</script>
