<template>
	<PopupRouterViewDetail label="Outgoing Document Detail" @copyDataMethod="copyDataAction">
<!-- APP_DETAIL_HEADER -->
		<b-row>
			<b-col cols="12" lg="6">
				<b-card class="mb-3">
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" label-for="filter-document-code">
						{{ model != null ? model.documentCode : "" }}
					</b-form-group>
					<b-form-group id="fieldset-created-at" label-cols-sm="4" label-cols-lg="3" label="Tanggal & Waktu" label-for="filter-document-location">
							{{ model != null ? ( model.createdAt != null ? model.createdAt : "") : "" | moment("DD-MM-YYYY HH:mm:ss") }}
						</b-form-group>
					<b-form-group id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Lokasi Dokumen" label-for="filter-document-location">
						{{ model != null ? ( model.documentLocation != null ? model.documentLocation.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-no" label-cols-sm="4" label-cols-lg="3" label="Nama Pemohon" label-for="filter-document-no">
						{{ model != null ? (model.createdBy != null ? model.createdBy.fullName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-no" v-if="model != null ? model.documentNo != null && model.documentNo != '' ? true : true : true" label-cols-sm="4" label-cols-lg="3" label="Nomor Surat" label-for="filter-document-no">
						{{ model != null ? model.documentNo : "" }}
					</b-form-group>
				</b-card>
				<b-card class="mt-3">
					<span v-if="model.isSendToAll">
						Semua Karyawan
					</span>
					<span v-else>
						<b-form-group id="fieldset-destination-user" label-cols-sm="4" label-cols-lg="3" label="Ditujukan Kepada" label-for="filter-destination-user">
							<b-badge variant="secondary" v-for="item in model.outgoingDocumentDestinationUser" :key="item.id" class="mr-2" style="font-size:13px; font-weight: 400;background-color:#bbb">
								{{ item.destinationUser ? item.destinationUser.fullName : "-" }}
							</b-badge>
						</b-form-group>
					</span>
				</b-card>
				<b-card class="mt-3 mb-3">
					<b-form-group id="fieldset-copy-user" label-cols-sm="4" label-cols-lg="3" label="Ditembuskan Kepada" label-for="filter-copy-user">
						<b-badge variant="secondary" v-for="item in model.outgoingDocumentCopyUser" :key="item.id" class="mr-2" style="font-size:13px; font-weight: 400;background-color:#bbb">
							{{ item.copyUser ? item.copyUser.fullName : "-" }}
						</b-badge>
					</b-form-group>
				</b-card>
				<div v-if="isSuperUser">
					<b-card class="mb-3">
						<b-form-group id="fieldset-document-date" v-if="model != null ? model.documentDate != null && model.documentDate != '' ? true : true : true" label-cols-sm="4" label-cols-lg="3" label="Tanggal Surat" label-for="filter-document-date">
							{{ (model != null ? model.documentDate : "") | moment("DD-MMM-YYYY") }}
						</b-form-group>
						<b-form-group id="fieldset-description" label-cols-sm="4" label-cols-lg="3" label="Perihal/Ringkasan Surat" label-for="filter-description">
							{{ model != null ? model.description : "" }}
						</b-form-group>
					</b-card>
					<b-card class="mb-3">
						<b-form-group id="fieldset-contract-date" label-cols-sm="4" label-cols-lg="3" label="Masa Berlaku Kontrak" label-for="filter-contract-date">
							{{ (model != null ? model.contractDateFrom : "") | moment("DD-MMM-YYYY") }}{{ (model.contractDateFrom != null && model.contractDateFrom != "") && (model.contractDateTo != null && model.contractDateTo != "") ? " - " : "" }}{{ (model != null ? model.contractDateTo : "") | moment("DD-MMM-YYYY") }}
						</b-form-group>
						<b-form-group id="fieldset-contract-description" label-cols-sm="4" label-cols-lg="3" label="Catatan Atas Kontrak" label-for="filter-contract-description">
							{{ model != null ? model.contractDescription : "" }}
						</b-form-group>
					</b-card>
					<b-card class="mb-3">
						<b-form-group v-if="model.isDraft" id="fieldset-draft-attachment" label-cols-sm="4" label-cols-lg="3" label="Draft" label-for="filter-draft-attachment">
							{{ model != null ? ( model.draftAttachment != null ? model.draftAttachment.originalFilename : "") : "" }}
						</b-form-group>
						<b-form-group v-if="model.isLampiran" id="fieldset-lampiran-attachment" label-cols-sm="4" label-cols-lg="3" label="File Dokumen" label-for="filter-lampiran-attachment">
							<template v-slot:label>File Dokumen</template>
							<div>
								<b-input-group :prepend="model.documentAttachmentCount + ' Attachment(s)'">
									<b-input-group-append>
										<b-button variant="light" class="btn-upload" style="border: 1px #ddd solid" size="sm" @click="showPopupFile"><b-icon-paperclip /></b-button>
									</b-input-group-append>
								</b-input-group>
							</div>
						</b-form-group>
					</b-card>
				</div>
			</b-col>
			<b-col cols="12" lg="6">
				<div v-if="!isSuperUser">
					<b-card class="mb-3">
						<b-form-group id="fieldset-document-date" v-if="model != null ? model.documentDate != null && model.documentDate != '' ? true : true : true" label-cols-sm="4" label-cols-lg="3" label="Tanggal Surat" label-for="filter-document-date">
							{{ (model != null ? model.documentDate : "") | moment("DD-MMM-YYYY") }}
						</b-form-group>
						<b-form-group id="fieldset-description" label-cols-sm="4" label-cols-lg="3" label="Perihal/Ringkasan Surat" label-for="filter-description">
							{{ model != null ? model.description : "" }}
						</b-form-group>
					</b-card>
					<b-card class="mb-3">
						<b-form-group id="fieldset-contract-date" label-cols-sm="4" label-cols-lg="3" label="Masa Berlaku Kontrak" label-for="filter-contract-date">
							{{ (model != null ? model.contractDateFrom : "") | moment("DD-MMM-YYYY") }}{{ (model.contractDateFrom != null && model.contractDateFrom != "") && (model.contractDateTo != null && model.contractDateTo != "") ? " - " : "" }}{{ (model != null ? model.contractDateTo : "") | moment("DD-MMM-YYYY") }}
						</b-form-group>
						<b-form-group id="fieldset-contract-description" label-cols-sm="4" label-cols-lg="3" label="Catatan Atas Kontrak" label-for="filter-contract-description">
							{{ model != null ? model.contractDescription : "" }}
						</b-form-group>
					</b-card>
					<b-card class="mb-3">
						<b-form-group v-if="model.isDraft" id="fieldset-draft-attachment" label-cols-sm="4" label-cols-lg="3" label="Draft" label-for="filter-draft-attachment">
							{{ model != null ? ( model.draftAttachment != null ? model.draftAttachment.originalFilename : "") : "" }}
						</b-form-group>
						<b-form-group v-if="model.isLampiran" id="fieldset-lampiran-attachment" label-cols-sm="4" label-cols-lg="3" label="Lampiran" label-for="filter-lampiran-attachment">
							<template v-slot:label>File Dokumen</template>
							<div>
								<b-input-group :prepend="model.documentAttachmentCount + ' Attachment(s)'">
									<b-input-group-append>
										<b-button variant="light" class="btn-upload" style="border: 1px #ddd solid" size="sm" @click="showPopupFile"><b-icon-paperclip /></b-button>
									</b-input-group-append>
								</b-input-group>
							</div>
						</b-form-group>
					</b-card>
				</div>
				<div v-if="isSuperUser">
					<b-card class="mb-3">
						<b-form-group id="fieldset-sifat-surat" label-cols-sm="4" label-cols-lg="3" label="Sifat Surat" label-for="filter-sifat-surat">
							{{ model.sifatSurat != null ? model.sifatSurat.name : '' }}
						</b-form-group>
						<b-form-group id="fieldset-sifat-surat" label-cols-sm="4" label-cols-lg="3" label="Jenis Surat" label-for="filter-sifat-surat">
							{{ model.documentType != null ? model.documentType.name : '' }}
						</b-form-group>
					</b-card>
					<b-card class="mb-3">
						<b-form-group id="fieldset-destination-user" label-cols-sm="4" label-cols-lg="3" label="Pemeriksa Surat" label-for="filter-destination-user">
							<b-badge variant="secondary" v-for="item in model.outgoingDocumentCheckerUser" :key="item.id" class="mr-2" style="font-size:13px; font-weight: 400;background-color:#bbb">
								{{ item.userProfile ? item.userProfile.fullName : "-" }}
							</b-badge>
						</b-form-group>
					</b-card>
					<b-card class="mb-3">
						<b-form-group id="fieldset-destination-user" label-cols-sm="4" label-cols-lg="3" label="Penandatangan Surat" label-for="filter-destination-user">
							<b-badge variant="secondary" v-for="item in model.outgoingDocumentSignerUser" :key="item.id" class="mr-2" style="font-size:13px; font-weight: 400;background-color:#bbb">
								{{ item.userProfile ? item.userProfile.fullName : "-" }}
							</b-badge>
						</b-form-group>
					</b-card>
					<b-card class="mb-3">
						<b-form-group id="fieldset-is-reminder" label-cols-sm="4" label-cols-lg="3" label="Pengesahan Surat" label-for="input-distribution-type">
							<template v-slot:label>Pengesahan Surat<span class="required"></span></template>
							<span>{{ model != null ? ( model.isTandaTanganAsli != null && model.isTandaTanganAsli  ? "Tanda Tangan Asli" : "") : "" }}</span>
							<span class="ml-2">{{ model != null ? ( model.isParafAsli != null && model.isParafAsli  ? "Paraf Asli" : "") : "" }}</span>
							<span class="ml-2">{{ model != null ? ( model.isStempel != null && model.isStempel  ? "Stempel" : "") : "" }}</span>
							<span class="ml-2">{{ model != null ? ( model.isTandaTanganDigital != null && model.isTandaTanganDigital  ? "Tanda Tangan Scan/Digital" : "") : "" }}</span>
							<span class="ml-2">{{ model != null ? ( model.isParafDigital != null && model.isParafDigital  ? "Paraf Scan/Digital" : "") : "" }}</span>
							<span class="ml-2">{{ model != null ? ( model.isMaterai != null && model.isMaterai  ? "Materai" : "") : "" }}</span>
						</b-form-group>
					</b-card>
					<b-card class="mb-3">
						<b-form-group id="fieldset-is-reminder" label-cols-sm="4" label-cols-lg="3" label="Bentuk Distribusi" label-for="input-distribution-type">
							<template v-slot:label>Bentuk Distribusi<span class="required"></span></template>
							<span>{{ model != null ? ( model.isFisik != null && model.isFisik  ? "Fisik" : "") : "" }}</span>
							<span class="ml-2">{{ model != null ? ( model.isFile != null && model.isFile  ? "File" : "") : "" }}</span>
						</b-form-group>
					</b-card>
					<b-card class="mb-3">
						<b-form-group id="fieldset-is-reminder" label-cols-sm="4" label-cols-lg="3" label="Reminder Arsip" label-for="input-distribution-type">
							<span>{{ model != null ? ( model.isReminder != null && model.isReminder  ? "Yes" : "No") : "No" }}</span>
						</b-form-group>
						<b-form-group id="fieldset-sifat-surat" v-if="model.isReminder" label-cols-sm="4" label-cols-lg="3" label="Tanggal Reminder" label-for="filter-sifat-surat">
							{{ (model != null ? model.reminderDateFrom : "") | moment("DD-MMM-YYYY") }}{{ (model.reminderDateFrom != null && model.reminderDateFrom != "") && (model.reminderDateTo != null && model.reminderDateTo != "") ? " - " : "" }}{{ (model != null ? model.reminderDateTo : "") | moment("DD-MMM-YYYY") }}
						</b-form-group>
						<b-form-group v-if="model.isReminder" id="fieldset-sifat-surat" label-cols-sm="4" label-cols-lg="3" label="Keterangan" label-for="filter-sifat-surat">
							{{ model.reminderRemarks }}
						</b-form-group>
					</b-card>
				</div>
				<b-card class="mb-3">
					<b-form-group id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Catatan" label-for="filter-remarks">
						{{ model != null ? model.remarks : "" }}
					</b-form-group>
				</b-card>
			</b-col>
		</b-row>
<!-- END_APP_DETAIL_HEADER -->
		<b-overlay :show="isBusy" no-wrap></b-overlay>
		<template v-slot:modal-left-button>
			<span v-if="!isSuperUser">
				<click-confirm v-if="model.outgoingDocumentStatusId == 'SAVED'">
					<b-button size="sm" variant="primary" @click="sendDocument">Kirim</b-button>
				</click-confirm>
			</span>
			<span v-else>
				<click-confirm v-if="model.outgoingDocumentStatusId == 'SUBMISSION' && model.isDataComplete">
					<b-button size="sm" variant="primary" @click="submitDocument">Kirim</b-button>
				</click-confirm>
				<click-confirm v-if="model.outgoingDocumentStatusId == 'APPROVED'">
					<b-button size="sm" variant="primary" @click="distributeDocument">Kirim</b-button>
				</click-confirm>
			</span>
			<span v-if="model.outgoingDocumentStatusId != 'SAVED'">
				<b-button class="ml-2" size="sm" variant="secondary" @click="showApprovalLog">Show Approval Log</b-button>
			</span>
		</template>
		<PopupApprovalHistory label="Approval Log" :approvalInstanceId="model.id" :openPopup="isShowApprovalLog" @resetModalMethod="resetApprovalLog" />
		<PopupDownloadDocument :openPopup="isShowPopupDocument" :parent="model" @resetMethod="resetModalUpload" />
	</PopupRouterViewDetail>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import OutgoingDocumentList from '@/models/OutgoingDocument/OutgoingDocumentList';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';

import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
import PopupApprovalHistory from '@/components/PopupApprovalHistory';
import { mapGetters } from 'vuex';
import PopupDownloadDocument from './PopupDownloadDocument';

/*END_APP_DETAIL_IMPORT*/
export default {
	components : {
	/*APP_DETAIL_PROP_DEFINITION*/
	PopupDownloadDocument,PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,PopupApprovalHistory
	/*APP_DETAIL_COMPONENTS_DEFINITION*/
},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			isShowApprovalLog: false,
			selected:'',
			model: {},
			/*APP_DETAIL_PAGINATION*/
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterOutgoingDocument : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentOutgoingDocumentPage : 1,
			outgoingDocumentPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByOutgoingDocument: '',
			sortDescOutgoingDocument: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			/*END_APP_DETAIL_OBJ_VARIABLE*/
			isShowPopupDocument: false,
		}
	},
	methods : {
		showPopupFile() {
			this.isShowPopupDocument = true;
		},
		resetModalUpload() {
			this.getOutgoingDocumentAttachment();
			this.isShowPopupDocument = false;
		},
		getOutgoingDocumentAttachment : async function() {
			//this.isBusy = true;
			let id = this.modelId;
			OutgoingDocument.getData(this, id, '').then(result => { 
				this.model.documentAttachmentCount = result.documentAttachmentCount;
				//this.isBusy = false; 
			}).catch(error => { this.isBusy = false; });
		},
		/*APP_DETAIL_GETOutgoingDocument*/
		getOutgoingDocument : async function(id) {
			this.isBusy = true;
			OutgoingDocument.getData(this, id, 'documentLocation,applicant,destinationStatus,draftAttachment,lampiranAttachment,outgoingDocumentStatus,sifatSurat,createdByUserProfile,documentType,outgoingDocumentCheckerUser,outgoingDocumentSignerUser,outgoingDocumentDestinationUser,outgoingDocumentCopyUser,createdBy').then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETOutgoingDocument*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'outgoingdocumentcopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
		sendDocument() {
			this.isBusy = true;
			this.$http.get(process.env.VUE_APP_API_URL + "outgoingdocument/senddocument/" + this.modelId).then(result => {
				this.isBusy = false;
				this.getOutgoingDocument(this.modelId);
				this.$store.dispatch('addSuccessMessageGlobal', "Dokumen Berhasil dikirim");
			})
			.catch(error => {
				this.$store.dispatch('addErrorMessageGlobal', error);
				this.isBusy = false;
				throw error;
			});
		},
		submitDocument() {
			this.isBusy = true;
			this.$http.get(process.env.VUE_APP_API_URL + "outgoingdocument/submitdocument/" + this.modelId).then(result => {
				this.isBusy = false;
				this.getOutgoingDocument(this.modelId);
				this.$store.dispatch('addSuccessMessageGlobal', "Dokumen Berhasil dikirim");
			})
			.catch(error => {
				this.$store.dispatch('addErrorMessageGlobal', error);
				this.isBusy = false;
				throw error;
			});
		},
		distributeDocument() {
			this.isBusy = true;
			this.$http.get(process.env.VUE_APP_API_URL + "outgoingdocument/distributedocument/" + this.modelId).then(result => {
				this.isBusy = false;
				this.getOutgoingDocument(this.modelId);
				this.$store.dispatch('addSuccessMessageGlobal', "Dokumen Berhasil dikirim");
			})
			.catch(error => {
				this.$store.dispatch('addErrorMessageGlobal', error);
				this.isBusy = false;
				throw error;
			});
		},
		showApprovalLog() {
			this.isShowApprovalLog = true;
		},
		resetApprovalLog() {
			this.isShowApprovalLog = false;
			console.log(this.isShowApprovalLog)
		}
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterOutgoingDocument = new OutgoingDocumentFilter();
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	/*APP_DETAIL_MOUNTED*/
	mounted(){
		let id = this.$route.params.id;
		this.modelId = id;
		this.getOutgoingDocument(id);
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	/*END_APP_DETAIL_MOUNTED*/
	watch : {
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
		...mapGetters({
			isSuperUser: 'isSuperUser',
			user: 'user',
			userId: 'userId',
		}),
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
