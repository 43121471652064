<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true id="popup-upload" @ok="uploadButtonAction" :okTitle="okTitle" title="Upload Data">
		<b-card>
			<b-row>
				<b-col cols="12">
					<app-input-fileupload label="File" size="sm" :model.sync="fileupload" />
				</b-col>
			</b-row>
			<b-row v-if="isUploadSuccess">
				<b-col cols="12">
					<b-card no-body>
						<b-tabs card>
							<b-tab title="Document Attachment" active>
								<b-row class="mb-2">
									<b-col>
										<span class="float-right">
											<b-form-select v-model="documentAttachmentPageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
										</span>
									</b-col>
								</b-row>
								<b-row>
								<b-col>
								<div class="table-corner">
									<b-table id="my-table" stacked="md" head-variant="light" :no-local-sorting="true" :items="documentAttachments" :fields="fieldsDocumentAttachment" :busy="isDataLoading" :sort-by.sync="sortByDocumentAttachment" :sort-desc.sync="sortDescDocumentAttachment" small responsive>
									<template v-slot:head(actions)>
										<b-button @click="isShowDocumentAttachmentFilter = !isShowDocumentAttachmentFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
									</template>
									<template #top-row v-if="isShowDocumentAttachmentFilter">
										<b-th></b-th>
										<b-th stacked-heading="Name">
											<b-form-input id="input-name-table" v-model="filterDocumentAttachment.name" size="sm"></b-form-input>
										</b-th>
										<b-th>
										</b-th>
									</template>
									<template v-slot:table-busys>
										<div class="text-center my-2">
									<b-spinner class="align-middle text-primary"></b-spinner>
										<strong class="ml-2">Loading..</strong>
										</div>
									</template>
									<template v-slot:cell(no)="row">
										{{ ((row.index + 1) + ( documentAttachmentPageSize * (currentDocumentAttachmentPage - 1))) }}
									</template>
									</b-table>
									</div>
								</b-col>
								<b-col cols="12">
									<b-pagination
									class="float-right btn-paging"
									v-model="currentDocumentAttachmentPage"
									:total-rows="totalDocumentAttachmentRow"
									:per-page="documentAttachmentPageSize"
									aria-controls="my-table"
									></b-pagination>
								</b-col>
								</b-row>
							</b-tab>
							<b-tab title="Document Attachment Detail" active>
								<b-row class="mb-2">
									<b-col>
										<span class="float-right">
											<b-form-select v-model="documentAttachmentDetailPageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
										</span>
									</b-col>
								</b-row>
								<b-row>
								<b-col>
								<div class="table-corner">
									<b-table id="my-table" stacked="md" head-variant="light" :no-local-sorting="true" :items="documentAttachmentDetails" :fields="fieldsDocumentAttachmentDetail" :busy="isDataLoading" :sort-by.sync="sortByDocumentAttachmentDetail" :sort-desc.sync="sortDescDocumentAttachmentDetail" small responsive>
									<template v-slot:head(actions)>
										<b-button @click="isShowDocumentAttachmentDetailFilter = !isShowDocumentAttachmentDetailFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
									</template>
									<template #top-row v-if="isShowDocumentAttachmentDetailFilter">
										<b-th></b-th>
										<b-th>
										</b-th>
										<b-th stacked-heading="File Name">
											<b-form-input id="input-file-name-table" v-model="filterDocumentAttachmentDetail.fileName" size="sm"></b-form-input>
										</b-th>
										<b-th>
										</b-th>
										<b-th>
										</b-th>
									</template>
									<template v-slot:table-busys>
										<div class="text-center my-2">
									<b-spinner class="align-middle text-primary"></b-spinner>
										<strong class="ml-2">Loading..</strong>
										</div>
									</template>
									<template v-slot:cell(no)="row">
										{{ ((row.index + 1) + ( documentAttachmentDetailPageSize * (currentDocumentAttachmentDetailPage - 1))) }}
									</template>
									<template v-slot:cell(documentAttachment)="row">
										{{ row.item.documentAttachment != null ? row.item.documentAttachment.name : "" }}
									</template>
									<template v-slot:cell(attachment)="row">
										{{ row.item.attachment != null ? row.item.attachment.originalFileName : "" }}
									</template>
									</b-table>
									</div>
								</b-col>
								<b-col cols="12">
									<b-pagination
									class="float-right btn-paging"
									v-model="currentDocumentAttachmentDetailPage"
									:total-rows="totalDocumentAttachmentDetailRow"
									:per-page="documentAttachmentDetailPageSize"
									aria-controls="my-table"
									></b-pagination>
								</b-col>
								</b-row>
							</b-tab>
						</b-tabs>
					</b-card>
				</b-col>
			</b-row>
		</b-card>
		<template #modal-footer="{ ok, cancel }">
			<b-button v-if="isUploadSuccess" size="sm" variant="primary" @click="downloadLog()">
				Download Log
			</b-button>
			<b-button size="sm" variant="success" class="float-right" @click="ok()">
				{{ okTitle }}
			</b-button>
			<b-button size="sm" variant="secondary" class="float-right" @click="cancel()">
				Cancel
			</b-button>
		</template>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<style lang="scss" scoped>
.card {
	border: 1px solid rgba(0, 0, 0, 0.125) !important;
}
::v-deep .th-message {
	color: red;
}
::v-deep .modal-footer {
	display: block;
}
</style>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate"; 
import { required, minLength } from "vuelidate/lib/validators"; 
import { saveAs } from 'file-saver';

import DocumentAttachment from '@/models/DocumentAttachment/DocumentAttachment';
import DocumentAttachmentList from '@/models/DocumentAttachment/DocumentAttachmentList';
import DocumentAttachmentFilter from '@/filters/DocumentAttachment/DocumentAttachmentFilter';

import DocumentAttachmentDetail from '@/models/DocumentAttachment/DocumentAttachmentDetail';
import DocumentAttachmentDetailFilter from '@/filters/DocumentAttachment/DocumentAttachmentDetailFilter';
import Attachment from '@/models/Core/Attachment';
import AttachmentFilter from '@/filters/Core/AttachmentFilter';
import AppInputFileupload from '@/components/AppInputFileupload';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
export default {
	props: ['model', 'openPopup','uploadButtonMethod', 'parentId'],
	components : {AppInputFileupload,AppInputDatetimepicker,AppInputDatetimepickerRange,},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fieldsDocumentAttachment: [
				{"no" : { "class" : "th-number"}}, 
				{"name": { "class" : "th-name", "label" : "Name"}, key: 'name', sortable: true}, 
				{"createdBy": { "class" : "th-created-by", "label" : "Created By"}, key: 'createdBy', sortable: true}, 
				{"updatedBy": { "class" : "th-updated-by", "label" : "Updated By"}, key: 'updatedBy', sortable: true}, 
				{"class" : "th-status", "label" : "Status", key: 'uploadValidationStatus', sortable: true},
				{"class" : "th-message", "label" : "Message", key: 'uploadValidationMessage', sortable: true},
				{"actions": { "class" : "th-actions", "label" : "" }}
			],
			fieldsDocumentAttachmentDetail: [
				{"no" : { "class" : "th-number"}}, 
				{"documentAttachment": { "class" : "th-document-attachment", "label" : "DocumentAttachment"}, key: 'documentAttachment', sortable: true}, 
				{"fileName": { "class" : "th-file-name", "label" : "File Name"}, key: 'fileName', sortable: true}, 
				{"attachment": { "class" : "th-attachment", "label" : "Attachment"}, key: 'attachment', sortable: true}, 
				{"createdBy": { "class" : "th-created-by", "label" : "Created By"}, key: 'createdBy', sortable: true}, 
				{"updatedBy": { "class" : "th-updated-by", "label" : "Updated By"}, key: 'updatedBy', sortable: true}, 
				{"class" : "th-status", "label" : "Status", key: 'uploadValidationStatus', sortable: true},
				{"class" : "th-message", "label" : "Message", key: 'uploadValidationMessage', sortable: true},
				{"actions": { "class" : "th-actions", "label" : "" }}
			],
			busy:false,
			isDataLoading: false,
			isBusy : false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			documentAttachments : [],
			documentAttachmentId : '',
			totalDocumentAttachmentRow : 0,
			isShowDocumentAttachmentFilter: false,
			filterDocumentAttachment : null,
			currentDocumentAttachmentPage : 1,
			documentAttachmentPageSize: 10,
			sortByDocumentAttachment: '',
			sortDescDocumentAttachment: '',
			filterDocumentAttachmentDetail : null,
			currentDocumentAttachmentDetailPage : 1,
			documentAttachmentDetailPageSize: 10,
			sortByDocumentAttachmentDetail: '',
			sortDescDocumentAttachmentDetail: '',
			sortingByDocumentAttachmentDetail: [],
			documentAttachmentDetails : [],
			documentAttachmentDetailId : '',
			totalDocumentAttachmentDetailRow : 0,
			isShowDocumentAttachmentDetailFilter: false,
			documentAttachments: [],
			processId: "",
			isUploadSuccess: false,
		}
	},
	methods : {
		resetModal() {
			this.datas = Array();
			this.$emit('resetMethod');
		},
		uploadButtonAction(modalEvent) {
			modalEvent.preventDefault();
			this.isBusy = true;
			if (this.isUploadSuccess) {
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'documentattachment/confirmUpload',
					[],
					).then(async function(response){
						if (response != null) {
						if (response.data != null) {
							_this.resetModal();
							_this.datas = Array();
							_this.fileupload = null;
							_this.isUploadSuccess = false;
						}
						}
						_this.isBusy = false;
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
			} else {
				if (this.fileupload != null) {
				let data = new FormData();
				data.append('file', this.fileupload);
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'documentattachment/upload',
					data,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
					).then(async function(response){
						_this.isBusy = false;
						_this.isUploadSuccess = true;
						_this.getDocumentAttachments();
						_this.getDocumentAttachmentDetails();
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
				}
			}
		},
		getDocumentAttachments : async function() {
			let filter = {};
			Object.assign(filter, this.filterDocumentAttachment);
			filter.draftFromUpload = "1";
			filter.draftMode = "1";
			this.sortingByDocumentAttachment = Array();
			if (this.sortByDocumentAttachment != null) {
				this.sortingByDocumentAttachment[this.sortByDocumentAttachment] = this.sortDescDocumentAttachment;
			}
			let currentPage = (this.currentDocumentAttachmentPage - 1);
			DocumentAttachment.getList(this, filter, this.sortingByDocumentAttachment, currentPage , this.documentAttachmentPageSize).then(result => {
				this.documentAttachments = result.data;
				this.totalDocumentAttachmentRow = result.dataCount;
			}).catch(error => {});
		},
		getDocumentAttachmentDetails : async function() {
			let filter = {};
			Object.assign(filter, this.filterDocumentAttachmentDetail);
			filter.draftFromUpload = "1";
			filter.draftMode = "1";
			this.sortingByDocumentAttachmentDetail = Array();
			if (this.sortByDocumentAttachmentDetail != null) {
				this.sortingByDocumentAttachmentDetail[this.sortByDocumentAttachmentDetail] = this.sortDescDocumentAttachmentDetail;
			}
			let currentPage = (this.currentDocumentAttachmentDetailPage - 1);
			DocumentAttachmentDetail.getList(this, filter, this.sortingByDocumentAttachmentDetail, currentPage , this.documentAttachmentDetailPageSize).then(result => {
				this.documentAttachmentDetails = result.data;
				this.totalDocumentAttachmentDetailRow = result.dataCount;
			}).catch(error => {});
		},
		getAttachments : async function(input) {
			let filter = new AttachmentFilter();
			if (input != undefined) {
				filter.originalFileName = input;
			}
			Attachment.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.attachments = result.data
				}
			})
			.catch(error => {});
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
				this.isShowDocumentAttachmentDetailFilter = true;
			}
		},
		downloadLog() {
			let _this = this;
			this.$http.post(process.env.VUE_APP_API_URL + 'documentattachment/downloadlog', [],
			{ headers: { 'Content-Type': 'application/json' }}
			).then(async function(response){
				await saveAs(process.env.VUE_APP_API_URL + 'documentattachment/downloadlog', "DocumentAttachment.xslx");
				_this.isBusy = false;
			})
			.catch(error => {
				_this.$store.dispatch('addErrorMessageGlobal', error);
				_this.isBusy = false;
			});
		}
	},
	beforeMount() {
	},
	mounted(){
	},
	watch: {
		sortByDocumentAttachment : {
			handler: function() {
				this.getDocumentAttachments();
			}
		},
		sortDescDocumentAttachment: {
			handler: function() {
				this.getDocumentAttachments();
			}
		},
		currentDocumentAttachmentPage: {
			handler: function() {
				this.getDocumentAttachments();
			}
		},
		documentAttachmentPageSize : {
			handler: function() {
				this.getDocumentAttachments();
			}
		},
		filterDocumentAttachment: {
			handler: function() {
				this.getDocumentAttachments();
			},
			deep: true,
		},
		sortByDocumentAttachmentDetail : {
			handler: function() {
				this.getDocumentAttachmentDetails();
			}
		},
		sortDescDocumentAttachmentDetail: {
			handler: function() {
				this.getDocumentAttachmentDetails();
			}
		},
		currentDocumentAttachmentDetailPage: {
			handler: function() {
				this.getDocumentAttachmentDetails();
			}
		},
		documentAttachmentDetailPageSize : {
			handler: function() {
				this.getDocumentAttachmentDetails();
			}
		},
		filterDocumentAttachmentDetail: {
			handler: function() {
				this.getDocumentAttachmentDetails();
			},
			deep: true,
		},
		fileupload(newValue, oldValue) {
			this.datas = Array();
			this.isUploadSuccess = false;
		}
	},
	computed: {
		okTitle: {
			get: function() {
				if (this.isUploadSuccess) {
					return "Proses";
				}
				return "Simpan";
				}
		},
		isShowPopup : {
			get: function () {
				if (this.openPopup) {
					this.filterDocumentAttachment = new DocumentAttachmentFilter();
					this.filterDocumentAttachmentDetail = new DocumentAttachmentDetailFilter();
					return true;
				}
				else return false;
			},
			set: function (newValue) {}
		},
	}
}
</script>
