import DocumentAttachmentDetail from '@/models/DocumentAttachment/DocumentAttachmentDetail';

export default class DocumentAttachmentDetailFilter {

	constructor() {
	/*APP_FILTER_DEFINITION*/
		this.id = '';
		this.documentAttachmentId  = '';
		this.documentAttachmentName = '';
		this.fileName = '';
		this.attachmentId  = '';
		this.attachmentOriginalFileName = '';
		this.createdBy = '';
		this.updatedBy = '';
		this.draftFromUpload = '';
		this.draftMode = '';
	/*END_APP_FILTER_DEFINITION*/
		this.exactType = {
	/*APP_APP_FILTER_CRITERIA*/
			id : '0',
			documentAttachmentId : '0',
			documentAttachmentName : '0',
			fileName : '0',
			attachmentId : '0',
			attachmentOriginalFileName : '0',
			createdBy : '0',
			updatedBy : '0',
	/*END_APP_FILTER_CRITERIA*/
		};
	}

}