import ArchivedDocument from '@/models/ArchivedDocument/ArchivedDocument';

export default class ArchivedDocumentFilter {

	constructor() {
	/*APP_FILTER_DEFINITION*/
		this.id = '';
		this.noBeritaAcara = '';
		this.archivedDateFromFrom = '';
		this.archivedDateFromTo = '';
		this.archivedDateToFrom = '';
		this.archivedDateToTo = '';
		this.documentLocationId  = '';
		this.documentLocationName = '';
		this.pICId  = '';
		this.pICFullName = '';
		this.approvedById  = '';
		this.approvedByFullName = '';
		this.removalMethodId  = '';
		this.removalMethodName = '';
		this.remarksMultiple = '';
		this.incomingDocumentId  = '';
		this.incomingDocumentDocumentCode = '';
		this.outgoingDocumentId  = '';
		this.outgoingDocumentDocumentCode = '';
		this.draftFromUpload = '';
		this.draftMode = '';
	/*END_APP_FILTER_DEFINITION*/
		this.exactType = {
	/*APP_APP_FILTER_CRITERIA*/
			id : '0',
			noBeritaAcara : '0',
			archivedDateFromFrom : '0',
			archivedDateFromTo : '0',
			archivedDateToFrom : '0',
			archivedDateToTo : '0',
			documentLocationId : '0',
			documentLocationName : '0',
			pICId : '0',
			pICFullName : '0',
			approvedById : '0',
			approvedByFullName : '0',
			removalMethodId : '0',
			removalMethodName : '0',
			remarksMultiple : '0',
			incomingDocumentId : '0',
			incomingDocumentDocumentCode : '0',
			outgoingDocumentId : '0',
			outgoingDocumentDocumentCode : '0',
	/*END_APP_FILTER_CRITERIA*/
		};
	}

}