<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true @ok="saveButtonAction" title="Save Confirmation - Function Info">
<!-- APP_DETAIL_HEADER -->
		<b-card>
			<b-col cols="12" lg="6">
					<b-form-group id="fieldset-name" label-cols-sm="4" label-cols-lg="3" label="Name" label-for="filter-name">
						{{ model != null ? model.name : "" }}
					</b-form-group>
					<b-form-group id="fieldset-uri" label-cols-sm="4" label-cols-lg="3" label="URL" label-for="filter-uri">
						{{ model != null ? model.uri : "" }}
					</b-form-group>
					<b-form-group id="fieldset-icon-name" label-cols-sm="4" label-cols-lg="3" label="Icon Name" label-for="filter-icon-name">
						<!-- <b-icon icon="" aria-hidden="{{parentName.Icon}}"></b-icon> -->
						<b-icon :icon="model != null ? model.iconName : ''" aria-hidden="false"></b-icon>
						- {{ model != null ? model.iconName : "" }}
					</b-form-group>
					<b-form-group id="fieldset-is-enabled" label-cols-sm="4" label-cols-lg="3" label="Is Enabled" label-for="filter-is-enabled">
						{{ model != null ? model.isEnabled : "False" }}
					</b-form-group>
					<b-form-group id="fieldset-module-info" label-cols-sm="4" label-cols-lg="3" label="Module Info" label-for="filter-module-info">
						{{ model != null ?  model.moduleName : "" }}
					</b-form-group>
			</b-col>
		</b-card>
<!-- END_APP_DETAIL_HEADER -->
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import FunctionInfo from '@/models/FunctionInfo/FunctionInfo';
import FunctionInfoList from '@/models/FunctionInfo/FunctionInfoList';
import FunctionInfoFilter from '@/filters/FunctionInfo/FunctionInfoFilter';

import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
/*END_APP_DETAIL_IMPORT*/
export default {
	/*APP_DETAIL_PROP_DEFINITION*/
	props : ["openPopup", "model", "saveActionMethod", "resetMethod"],
	/*END_APP_DETAIL_PROP_DEFINITION*/
	components : {
	/*APP_DETAIL_PROP_DEFINITION*/
	PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,
	/*APP_DETAIL_COMPONENTS_DEFINITION*/
},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			/*APP_DETAIL_PAGINATION*/
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterFunctionInfo : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentFunctionInfoPage : 1,
			functionInfoPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByFunctionInfo: '',
			sortDescFunctionInfo: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			/*END_APP_DETAIL_OBJ_VARIABLE*/
		}
	},
	methods : {
		/*APP_DETAIL_GETFunctionInfo*/
		getFunctionInfo : async function(id) {
			this.isBusy = true;
			FunctionInfo.getData(this, id).then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETFunctionInfo*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'functioninfocopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_SAVEBUTTONACTION*/
		saveButtonAction(modalEvent) {
			this.$emit("saveActionMethod", modalEvent);
		},
		/*END_APP_DETAIL_SAVEBUTTONACTION*/
		/*APP_DETAIL_RESETMODAL*/
		resetModal() {
			this.$emit('resetMethod'); 
		},
		/*END_APP_DETAIL_RESETMODAL*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterFunctionInfo = new FunctionInfoFilter();
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	watch : {
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
		isShowPopup: {
			get: function(){
				if (this.openPopup) return true; 
				else return false; 
			},
			set: function(newValue){ }
		},
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
