<template>
	<PopupRouterViewDetail label="User Profile Detail" @copyDataMethod="copyDataAction">
		<b-row>
			<b-col cols="12" lg="6">
				<b-card>
					<b-form-group id="fieldset-full-name" label-cols-sm="4" label-cols-lg="3" label="Name" label-for="filter-full-name">
						{{ model != null ? model.fullName : "" }}
					</b-form-group>
					<b-form-group id="fieldset-email" label-cols-sm="4" label-cols-lg="3" label="Email" label-for="filter-email">
						{{ model != null ? model.email : "" }}
					</b-form-group>
					<b-form-group id="fieldset-phone-number" label-cols-sm="4" label-cols-lg="3" label="Phone Number" label-for="filter-phone-number">
						{{ model != null ? model.phoneNumber : "" }}
					</b-form-group>
					<b-form-group id="fieldset-branch" label-cols-sm="4" label-cols-lg="3" label="Kantor" label-for="filter-branch">
						{{ model != null ? ( model.branch != null ? model.branch.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-division" label-cols-sm="4" label-cols-lg="3" label="Division" label-for="filter-division">
						{{ model != null ? ( model.division != null ? model.division.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-directorate" label-cols-sm="4" label-cols-lg="3" label="Direktorate" label-for="filter-directorate">
						{{ model != null ? ( model.directorateName != null ? model.directorateName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-super-user" label-cols-sm="4" label-cols-lg="3" label="Is Super User" label-for="filter-super-user">
						{{ model != null ? ( model.isSuperUser ? "Yes" : "No") : "No" }}
					</b-form-group>
				</b-card>
			</b-col>
			<b-col cols="12" lg="6">
			</b-col>
		</b-row>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
		<template #modal-left-button>
			<b-overlay
					:show="isToggleSuperUserBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="warning"
					class="d-inline-block"
					>
					<b-button :variant=" model != null ? model.isSuperUser ? 'danger' : 'success' : 'success'" class="float-left ml-2" @click="toggleSuperUser" size="sm">
						<b-icon-plus v-if="!model.isSuperUser" class="mr-1"></b-icon-plus>
						<b-icon-x v-else class="mr-1"></b-icon-x> 
						{{ model != null ? model.isSuperUser ? 'Remove as Super User' : 'Set as Super User' : 'Set as Super User'}}
					</b-button>
			</b-overlay>
		</template>
	</PopupRouterViewDetail>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileList from '@/models/UserProfile/UserProfileList';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';

import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
/*END_APP_DETAIL_IMPORT*/
export default {
	components : {
	/*APP_DETAIL_PROP_DEFINITION*/
	PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,
	/*APP_DETAIL_COMPONENTS_DEFINITION*/
},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			model: {},
			/*APP_DETAIL_PAGINATION*/
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterUserProfile : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentUserProfilePage : 1,
			userProfilePageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByUserProfile: '',
			sortDescUserProfile: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			/*END_APP_DETAIL_OBJ_VARIABLE*/
			isToggleSuperUserBusy: false,
		}
	},
	methods : {
		/*APP_DETAIL_GETUserProfile*/
		getUserProfile : async function(id) {
			this.isBusy = true;
			UserProfile.getData(this, id, 'division,employeePosition,directorate,branch').then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETUserProfile*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'userprofilecopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		toggleSuperUser() {
			this.isToggleSuperUserBusy = true;
			this.$http.get(process.env.VUE_APP_API_URL + 'userprofile/togglesuperuser/' + this.modelId)
			.then(response => {
				console.log(response);
				if (response != null) {
					if (response.data != null && response.data != undefined) {
						if (response.data.data != null && response.data.data != undefined) {
							if (response.data.data.id != null && response.data.data.id != undefined && response.data.data != "")
								this.model = response.data.data;
						}
					}
				}
				
				this.isToggleSuperUserBusy = false;
			}).catch(error => {
				this.isToggleSuperUserBusy = false;
			});
		}
		/*END_APP_DETAIL_DISPLAYFILTER*/
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterUserProfile = new UserProfileFilter();
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	/*APP_DETAIL_MOUNTED*/
	mounted(){
		let id = this.$route.params.id;
		this.modelId = id;
		this.getUserProfile(id);
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	/*END_APP_DETAIL_MOUNTED*/
	watch : {
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
