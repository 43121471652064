<template>
	<div>
		<b-card>
			<b-table-simple
			:bordered="true"
			
			:small="true"
			:no-border-collapse="true"
			>
			<b-tbody>
				<b-tr>
					<b-td>No. Tanda Terima Surat</b-td>
					<b-td colspan="3">{{ model != null ? model.receiptDocumentNo : "" }}</b-td>
				</b-tr>
				<b-tr>
					<b-td>Kode Risalah</b-td>
					<b-td>{{ model != null ? model.documentCode : "" }}</b-td>
					<b-td>Nama Penyerah Surat</b-td>
					<b-td>{{ model != null ? ( model.deliveryUser != null ? model.deliveryUser.fullName : "") : "" }}</b-td>
				</b-tr>
				<b-tr>
					<b-td>Tanggal & Waktu</b-td>
					<b-td>{{ (model != null ? model.documentCreatedDate : "") | moment("DD-MMM-YYYY") }}</b-td>
					<b-td>Jabatan Pegawai</b-td>
					<b-td>{{ model != null ? ( model.deliveryUser != null ? model.deliveryUser.jobDescription : "") : "" }}</b-td>
				</b-tr>
				<b-tr>
					<b-td>Lokasi Surat</b-td>
					<b-td>{{ model != null ? ( model.documentLocation != null ? model.documentLocation.name : "") : "" }}</b-td>
					<b-td>Nama Penerima Surat</b-td>
					<b-td>{{ model != null ? ( model.deliveryUser != null ? model.receiptUser.fullName : "") : "" }}</b-td>
				</b-tr>
				<b-tr>
					<b-td>Nama Pengirim</b-td>
					<b-td>{{ model != null ? model.senderName : "" }}</b-td>
					<b-td>Jabatan Pegawai</b-td>
					<b-td>{{ model != null ? ( model.deliveryUser != null ? model.receiptUser.jobDescription : "") : "" }}</b-td>
				</b-tr>
				<b-tr>
					<b-td>Ditujukan Kepada</b-td>
					<b-td style="white-space: pre-wrap; width: 35% !important">
						<span v-if="model.incomingDocument != null && model.incomingDocument.isSendToAll">
							Semua Karyawan
						</span>
						<span v-else>
							<span v-for="item, index in model.incomingDocumentDestinationUser" :key="item.id" style="white-space: pre-wrap">
								{{ item.destinationUser ? item.destinationUser.fullName : "-" }}<span v-if="index < (model.incomingDocumentDestinationUser.length - 1)">, </span>
							</span>
						</span>
					</b-td>
					<b-td colspan="2" rowspan="2">Catatan :<br />{{ model != null ? model.remarks : "" }}</b-td>
				</b-tr>
				<b-tr>
					<b-td>Tanggal Surat</b-td>
					<b-td>{{ (model != null ? model.documentDate : "") | moment("DD-MMM-YYYY") }}</b-td>
				</b-tr>
				<b-tr>
					<b-td>Jenis Surat / Barang</b-td>
					<b-td>{{ model != null ? ( model.documentType != null ? model.documentType.name : "-") : "-" }}</b-td>
					<b-td rowspan="4" colspan="2">Tanda Tangan Penerima :</b-td>
				</b-tr>
				<b-tr>
					<b-td>Nomor Surat</b-td>
					<b-td>{{ model != null ? model.documentNo : "-" }}</b-td>
				</b-tr>
				<b-tr>
					<b-td colspan="2" rowspan="2">Perihal / Ringkasan Surat:<br />{{ model != null ? (model.documentDescription != null && model.documentDescription != "" ? model.documentDescription : "-") : "-" }}</b-td>
				</b-tr>
			</b-tbody>
			</b-table-simple>
			<b-col cols="12" lg="6" id="detail-section" v-if="false" >
				<b-form-group id="fieldset-job-description" label-cols-sm="4" label-cols-lg="3" label="No. Tanda Terima Surat" label-for="filter-job-description">
					{{ model != null ? model.receiptDocumentNo : "-" }}
				</b-form-group>
				<b-form-group id="fieldset-job-description" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" label-for="filter-job-description">
					{{ model != null ? model.documentCode : "-" }}
				</b-form-group>
				<b-form-group id="fieldset-job-description" label-cols-sm="4" label-cols-lg="3" label="Tanggal & Waktu" label-for="filter-job-description">
					{{ model != null ? model.documentCreatedDate : "-" }}
				</b-form-group>
				<b-form-group id="fieldset-job-description" label-cols-sm="4" label-cols-lg="3" label="Lokasi Surat" label-for="filter-job-description">
					{{ model != null ? (model.documentLocation != null ? model.documentLocation.name : '-') : "-" }}
				</b-form-group>
				<b-form-group id="fieldset-job-description" label-cols-sm="4" label-cols-lg="3" label="Nama Pengirim" label-for="filter-job-description">
					{{ model != null ? model.senderName : "-" }}
				</b-form-group>
				<b-form-group id="fieldset-job-description" label-cols-sm="4" label-cols-lg="3" label="Ditujukan Kepada" label-for="filter-job-description">
					{{ model != null ? (model.destinationUser != null ? model.destinationUser.fullName : '-') : "-" }}
				</b-form-group>
				<b-form-group id="fieldset-job-description" label-cols-sm="4" label-cols-lg="3" label="Tanggal Surat" label-for="filter-job-description">
					{{ model != null ? model.documentDate : "-" }}
				</b-form-group>
				<b-form-group id="fieldset-job-description" label-cols-sm="4" label-cols-lg="3" label="Jenis Surat/Barang" label-for="filter-job-description">
					{{ model != null ? (model.documentType != null ? model.documentType.name : '-') : "-" }}
				</b-form-group>
				<b-form-group id="fieldset-job-description" label-cols-sm="4" label-cols-lg="3" label="Nomor Surat" label-for="filter-job-description">
					{{ model != null ? model.documentNo : "-" }}
				</b-form-group>
				<b-form-group id="fieldset-job-description" label-cols-sm="4" label-cols-lg="3" label="Nama Penyerah Surat" label-for="filter-job-description">
					{{ model != null ? (model.deliveryUser != null ? model.deliveryUser.fullName : '-') : "-" }}
				</b-form-group>
				<b-form-group id="fieldset-job-description" label-cols-sm="4" label-cols-lg="3" label="Jabatan Pegawai" label-for="filter-job-description">
					{{ model != null ? (model.deliveryUser != null ? model.deliveryUser.jobDescription : '-') : "-" }}
				</b-form-group>
				<b-form-group id="fieldset-job-description" label-cols-sm="4" label-cols-lg="3" label="Nama Penerima Surat" label-for="filter-job-description">
					{{ model != null ? (model.receiptUser != null ? model.receiptUser.fullName : '-') : "-" }}
				</b-form-group>
				<b-form-group id="fieldset-job-description" label-cols-sm="4" label-cols-lg="3" label="Jabatan Pegawai" label-for="filter-job-description">
					{{ model != null ? (model.receiptUser != null ? model.receiptUser.jobDescription : '-') : "-" }}
				</b-form-group>
				<app-input-textarea :isEditable="false" id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Catatan" label-for="input-remarks" size="sm" :model.sync="model.remarks"   />
				<app-input-combobox-autocomplete v-if="false" :options="incomingDocuments" optionsLabel="documentCode" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Incoming Document" id="input-" size="sm" :model.sync="model.incomingDocumentId"  :selected="model.incomingDocument != null ? model.incomingDocument.documentCode : ''" @input="getIncomingDocuments" />
				<app-input-textbox v-if="false" id="fieldset-outgoing-document" label-cols-sm="4" label-cols-lg="3" label="OutgoingDocument" label-for="input-outgoing-document" size="sm" :model.sync="model.outgoingDocument"   />
			</b-col>
		</b-card>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</div>
</template>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import ReceiptDocument from '@/models/ReceiptDocument/ReceiptDocument';
import ReceiptDocumentList from '@/models/ReceiptDocument/ReceiptDocumentList';
import ReceiptDocumentFilter from '@/filters/ReceiptDocument/ReceiptDocumentFilter';

import PopupConfirmationDetail from './PopupConfirmationDetail';

import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import DocumentType from '@/models/DocumentType/DocumentType';
import DocumentTypeFilter from '@/filters/DocumentType/DocumentTypeFilter';
import AppInputTextarea from '@/components/AppInputTextarea';
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import PopupRouterView from '@/components/PopupRouterView';

export default {
	components : {AppInputTextbox,AppInputDatepicker,AppInputDatepickerRange,AppInputComboboxAutocomplete,AppInputTextarea,PopupRouterView,PopupConfirmationDetail},
	props : ["incomingDocumentId","mainRecordId"],
	mixins : [validationMixin],
	validations: {
		model: {
		}
	},
	data() {
		return {
			errorMessage : {
			},
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			modelId: null,
			filterReceiptDocument : null,
			currentReceiptDocumentPage : 1,
			receiptDocumentPageSize: 10,
			sortByReceiptDocument: '',
			sortDescReceiptDocument: '',
			documentLocations: [],
			userProfiles: [],
			documentTypes: [],
			incomingDocuments: [],
			isShowPopupConfirmation : false,
			isDataCommited : false,
			model : {},
		}
	},
	methods : {
		getReceiptDocument : async function(id) {
			this.isBusy = true;
			ReceiptDocument.getData(this, id, 'documentLocation,deliveryUser,documentType,destinationUser,receiptUser,incomingDocument,incomingDocumentDestinationUser').then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		downloadData() {
			this.isDownloadReceiptDocument = true;
			this.filterReceiptDocument.id = this.modelId;
			ReceiptDocument.downloadDataDetail(this, this.filterReceiptDocument).then(result => {
				this.isDownloadReceiptDocument = false;
			}).catch(error => { this.isDownloadReceiptDocument = false; })
		},
		getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentLocation.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		getDocumentTypes : async function(input) {
			let filter = new DocumentTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentTypes = result.data
				}
			})
			.catch(error => {});
		},
		getIncomingDocuments : async function(input) {
			let filter = new IncomingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			IncomingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		validateState(name) {
			if (!this.$v.model[name]) return null;
			const { $dirty, $error } = this.$v.model[name];
			return $dirty ? !$error : null;
		},
		getErrorMessage(message, name) {
			if (!this.$v.model[name]) return "";
			if (this.$v.model[name].required != undefined && !this.$v.model[name].required) {
				if (message.required) return message.required;
			}
		},
		saveActionConfirmation(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isShowPopupConfirmation = true;
			}
		},
		saveAction(modalEvent) {
			if (this.mainRecordId == null || this.mainRecordId == '') {
				modalEvent.preventDefault();
				return ReceiptDocument.insertData(this, this.model).then(result => {
					this.isBusy = false;
					this.mainRecordId = result.id;
					this.getReceiptDocument(this.mainRecordId);
					return result;
				}).catch(error => { this.isBusy = false; });
			}
		},
		onHidden() {
			this.getReceiptDocuments();
		},
		resetModal(modalType) {
			this.showPopupCopyData = false; 
			this.isShowPopupConfirmation = false;
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		getIncomingDocument : async function(id) {
			this.isBusy = true;
			IncomingDocument.getData(this, id, 'documentLocation,senderStatus,userProfile,destinationUser,copyUser,sifatSurat,documentType,attachment,distributionType,incomingDocumentStatus,copyDocumentStatus,documentStatus,createdByUserProfile,incomingDocumentDestinationUser').then(result => { this.copyDataFromIncomingDocument(result); this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		copyDataFromIncomingDocument(incomingDocument) {
			this.model.receiptDocumentNo = 'Autonumber';
			this.model.documentCode = incomingDocument.documentCode;
			this.model.documentCreatedDate = incomingDocument.createdAt;
			this.model.documentLocationId = incomingDocument.documentLocation;
			this.model.documentLocation = incomingDocument.documentLocation;
			this.model.senderName = incomingDocument.senderName;
			this.model.destinationUserId = incomingDocument.destinationUserId;
			this.model.destinationUser = incomingDocument.destinationUser;
			this.model.documentDate = incomingDocument.documentDate;
			this.model.documentTypeId = incomingDocument.documentTypeId;
			this.model.documentType = incomingDocument.documentType;
			this.model.documentNo = incomingDocument.documentNo;
			this.model.documentDescription = incomingDocument.description;
			this.model.incomingDocumentId = incomingDocument.id;
			this.model.incomingDocument = incomingDocument;
			this.model.deliveryUserId = incomingDocument.createdByUserProfile.id;
			this.model.deliveryUser = incomingDocument.createdByUserProfile;
		},
		changeReceiptUser(selectedValue){
			if (selectedValue) {
				this.model.receiptUserId = selectedValue.id;
				this.model.receiptUser = selectedValue;
			} else {
				this.model.receiptUserId = "";
				this.model.receiptUser = {};
			}
		},
	},
	beforeMount() {
		
	},
	async mounted(){
		let id = this.mainRecordId;
		if (id != '' && id != null && id != undefined) {
			this.getReceiptDocument(id);
		}
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	computed: {
	},
	watch : {
	},
	beforeDestroy() {
	},
}
</script>
