<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true ok-title="Close" :ok-only="true" title="Destination Users">
		<b-card class="mb-3" header-bg-variant="transparent">
			<b-row class="mb-2">
				<b-col>
					<span class="float-right" v-if="false">
						<b-form-select v-model="documentAttachmentDetailPageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
					</span>
				</b-col>
			</b-row>
			<b-row>
			<b-col>
				<div class="table-corner">
				<b-table id="my-table" stacked="md" :no-local-sorting="true" :items="documentAttachmentDetails" :fields="fieldsDocumentAttachmentDetail" :busy="isDataLoading" :sort-by.sync="sortByDocumentAttachmentDetail" :sort-desc.sync="sortDescDocumentAttachmentDetail" small responsive>
				<!--APP_TABLE_FILTER-->
				<template v-slot:head(actions)>
					<b-button v-if="false" @click="isShowDocumentAttachmentDetailFilter = !isShowDocumentAttachmentDetailFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
				</template>
				<template #top-row v-if="false">
					<b-th></b-th>
					<b-th stacked-heading="File Name">
						<b-form-input id="input-file-name-table" v-model="filterDocumentAttachmentDetail.fileName" size="sm"></b-form-input>
					</b-th>
					<b-th></b-th>
				</template>
				<!--END_APP_TABLE_FILTER-->
				<!--APP_TABLE_DATA-->
				<template v-slot:cell(no)="row">
					{{ ((row.index + 1) + ( documentAttachmentDetailPageSize * (currentDocumentAttachmentDetailPage - 1))) }}
				</template>
				<template v-slot:cell(fullName)="row">
					{{ row.item.destinationUser != null ? row.item.destinationUser.fullName : "" }}
				</template>
				<template v-slot:cell(email)="row">
					{{ row.item.destinationUser != null ? row.item.destinationUser.email : "" }}
				</template>
				<template v-slot:cell(jobDescription)="row">
					{{ row.item.destinationUser != null ? row.item.destinationUser.jobDescription : "" }}
				</template>
				<template v-slot:cell(actions) ></template>
				<!--END_APP_TABLE_DATA-->
				</b-table>
				</div>
			</b-col>
			<b-col cols="12">
				<!--APP_TABLE_PAGINATION-->
				<b-pagination
				v-if="false"
				class="float-right btn-paging"
				v-model="currentDocumentAttachmentDetailPage"
				:total-rows="totalDocumentAttachmentDetailRow"
				:per-page="documentAttachmentDetailPageSize"
				aria-controls="my-table"
				></b-pagination>
				<!--END_APP_TABLE_PAGINATION-->
			</b-col>
			</b-row>
		</b-card>
		<RouterView />
		<OutgoingDocumentAttachmentDetailUpload :openPopup="this.isShowUploadPopup" :modelId="modelId" :parent="parent" @resetMethod="resetUploadModal" />
	</b-modal>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import ReceiptDocumentInputComponent from '@/views/ReceiptDocument/InputComponent';
import ReceiptDocumentDetailComponent from '@/views/ReceiptDocument/DetailComponent';
import OutgoingDocumentList from '@/models/OutgoingDocument/OutgoingDocumentList';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';

import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
import OutgoingDocumentAttachmentDetailUpload from './OutgoingDocumentAttachmentDetailUpload';

import OutgoingDocumentAttachmentDetail from '@/models/OutgoingDocument/OutgoingDocumentAttachmentDetail';
/*END_APP_DETAIL_IMPORT*/
export default {
	/*APP_DETAIL_PROP_DEFINITION*/
	props : ["openPopup", "saveActionMethod", "resetMethod","outgoingDocumentId","parent", "receiptDocumentId","allowUpdate"],
	/*END_APP_DETAIL_PROP_DEFINITION*/
	components : {
		/*APP_DETAIL_PROP_DEFINITION*/
		PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,ReceiptDocumentInputComponent,ReceiptDocumentDetailComponent,OutgoingDocumentAttachmentDetailUpload
		/*APP_DETAIL_COMPONENTS_DEFINITION*/
	},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			/*APP_DETAIL_PAGINATION*/
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterOutgoingDocument : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentOutgoingDocumentPage : 1,
			outgoingDocumentPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByOutgoingDocument: '',
			sortDescOutgoingDocument: '',
			mainRecordId: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			/*END_APP_DETAIL_OBJ_VARIABLE*/
			okTitle : 'Save',
			fieldsDocumentAttachmentDetail: [
				{"no" : {  "class" : "th-number" }},
				{"fullName": {},  "class" : "th-attachment", "label" : "Name", key: 'fullName', sortable: true}, 
				{"email": {},  "class" : "th-fileSize", "label" : "Email", key: 'email', sortable: true}, 
				{"jobDescription": {},  "class" : "th-created-at", "label" : "Job Description", key: 'jobDescription', sortable: true}, 
			],
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			filterDocumentAttachmentDetail : null,
			currentDocumentAttachmentDetailPage : 1,
			documentAttachmentDetailPageSize: 10,
			sortByDocumentAttachmentDetail: '',
			sortDescDocumentAttachmentDetail: '',
			sortingByDocumentAttachmentDetail: [],
			documentAttachmentDetails : [],
			documentAttachmentDetailId : '',
			totalDocumentAttachmentDetailRow : 0,
			isShowDocumentAttachmentDetailFilter: false,
			isShowTableDocumentAttachmentDetail: false,
			modelId:'',
			isShowUploadPopup: false,
		}
	},
	methods : {
		editData(index, id) {
			this.modelId = id;
			this.isShowUploadPopup = true;
		},
		deleteData(id) {
			OutgoingDocumentAttachmentDetail.deleteData(this, id).then(result => {
				if (result)
				{
					this.$store.dispatch('addSuccessMessageGlobal', "Data Berhasil dihapus");
					this.getOutgoingDocumentAttachmentDetails();
				}
			})
		},
		downloadData(currentModel) {
			if (currentModel == null || currentModel == undefined) return; 
			//console.log(currentModel);
			let url = process.env.VUE_APP_API_URL + 'attachment/download/' + currentModel.id;
			let fileName = currentModel.originalFilename;
			this.$http.get(url, {responseType: 'blob'}).then(response => {
				if (response != null) {
					saveAs(response.data, fileName);
				}
			}).catch(error => {
				this.$store.dispatch('addErrorMessageGlobal', error);
				throw error;
			})
		},
		/*APP_DETAIL_GETOutgoingDocument*/
		getOutgoingDocument : async function(id) {
			this.isBusy = true;
			OutgoingDocument.getData(this, id).then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETOutgoingDocument*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'outgoingdocumentcopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_SAVEBUTTONACTION*/
		saveButtonAction(modalEvent) {
			if (this.mainRecordId == null || this.mainRecordId == undefined || this.mainRecordId == '') {
				this.$refs.childComponent.saveAction(modalEvent).then(result => {
					this.mainRecordId = result.id;
					this.$emit('update:model', result);
					this.okTitle = "Print";
				});
			} else {
				modalEvent.preventDefault();
				//window.print();
			}
		},
		/*END_APP_DETAIL_SAVEBUTTONACTION*/
		/*APP_DETAIL_RESETMODAL*/
		resetModal() {
			this.documentAttachmentDetails = [];
			this.$emit('resetMethod'); 
		},
		resetUploadModal() {
			this.getOutgoingDocumentAttachmentDetails();
			this.modelId = "";
			this.isShowUploadPopup = false;
			this.documentAttachmentDetails = [];
		},
		/*END_APP_DETAIL_RESETMODAL*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
		addFileUpload() {
			this.isShowUploadPopup = true;
		},
		getOutgoingDocumentAttachmentDetails : async function() {
			let filter = {};
			Object.assign(filter, this.filterDocumentAttachmentDetail);
			//if (this.modelId == null || this.modelId == undefined || this.modelId == '') return;
			//if (!this.isShowTableDocumentAttachmentDetail) return;
			//filter.documentAttachmentId = this.modelId;
			this.sortingByDocumentAttachmentDetail = Array();
			if (this.sortByDocumentAttachmentDetail != null) {
				this.sortingByDocumentAttachmentDetail[this.sortByDocumentAttachmentDetail] = this.sortDescDocumentAttachmentDetail;
			}
			let currentPage = this.currentDocumentAttachmentDetailPage;
			filter.outgoing_document_id = this.parent.id;
			OutgoingDocumentAttachmentDetail.getList(this, filter, this.sortingByDocumentAttachmentDetail, currentPage , this.documentAttachmentDetailPageSize, 'attachment').then(result => {
				this.documentAttachmentDetails = result.data;
				this.totalDocumentAttachmentDetailRow = result.pagination.total;
			}).catch(error => {});
		},
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterOutgoingDocument = new OutgoingDocumentFilter();
	},
	mounted() {
		//this.getOutgoingDocumentAttachmentDetails();
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	watch : {
		openPopup(newValue, oldValue) {
			this.mainRecordId = '';
			this.documentAttachmentDetails = this.parent.outgoingDocumentDestinationUser;
		},
		sortByDocumentAttachmentDetail : {
			handler: function() {
				this.getOutgoingDocumentAttachmentDetails();
			}
		},
		sortDescDocumentAttachmentDetail: {
			handler: function() {
				this.getOutgoingDocumentAttachmentDetails();
			}
		},
		currentDocumentAttachmentDetailPage: {
			handler: function() {
				this.getOutgoingDocumentAttachmentDetails();
			}
		},
		documentAttachmentDetailPageSize : {
			handler: function() {
				this.getOutgoingDocumentAttachmentDetails();
			}
		},
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
		isShowPopup: {
			get: function(){
				if (this.openPopup) { return true; }
				else { return false; }
			},
			set: function(newValue){ }
		},
		isAllowUpdate: {
			get: function(){
				if (this.allowUpdate != null && this.allowUpdate != undefined) {
					return this.allowUpdate;
				} else {
					true;
				}
			},
			set: function(newValue){ }
		},
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
