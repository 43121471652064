<template>
	<PopupRouterView label="Archived Document Input"  entityName="archiveddocument" :documentId="mainRecordId"  @saveMethod="saveAction" @hideMethod="onHidden">
		<b-card>
			<b-col cols="12" lg="6">
				<app-input-textbox id="fieldset-no-berita-acara" label-cols-sm="4" label-cols-lg="3" label="No. Berita Acara" label-for="input-no-berita-acara" size="sm" :model.sync="model.noBeritaAcara"  @change="updateData" />
				<app-input-datepicker  id="fieldset-archived-date-from" label-cols-sm="4" label-cols-lg="3" label="Periode Arsip Dari" size="sm" :model.sync="model.archivedDateFrom"  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" @change="updateData" />
				<app-input-datepicker  id="fieldset-archived-date-to" label-cols-sm="4" label-cols-lg="3" label="Periode Arsip Sampai" size="sm" :model.sync="model.archivedDateTo"  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" @change="updateData" />
				<app-input-combobox-autocomplete :options="documentLocations" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Lokasi Arsip" id="input-" size="sm" :model.sync="model.documentLocationId"  :selected="model.documentLocation != null ? model.documentLocation.name : ''" @input="getDocumentLocations" @change="updateData"/>
				<app-input-combobox-autocomplete :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Pelaksana" id="input-" size="sm" :model.sync="model.pICId"  :selected="model.pIC != null ? model.pIC.fullName : ''" @input="getUserProfiles" @change="updateData"/>
				<app-input-combobox-autocomplete :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Menyetujui" id="input-" size="sm" :model.sync="model.approvedById"  :selected="model.approvedBy != null ? model.approvedBy.fullName : ''" @input="getUserProfiles" @change="updateData"/>
				<app-input-combobox-autocomplete :options="removalMethods" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Metode Hapus" id="input-" size="sm" :model.sync="model.removalMethodId"  :selected="model.removalMethod != null ? model.removalMethod.name : ''" @input="getRemovalMethods" @change="updateData"/>
				<app-input-textarea id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Catatan" label-for="input-remarks" size="sm" :model.sync="model.remarks"  @change="updateData" />
				<app-input-combobox-autocomplete :options="incomingDocuments" optionsLabel="documentCode" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Surat Masuk" id="input-" size="sm" :model.sync="model.incomingDocumentId"  :selected="model.incomingDocument != null ? model.incomingDocument.documentCode : ''" @input="getIncomingDocuments" @change="updateData"/>
				<app-input-combobox-autocomplete :options="outgoingDocuments" optionsLabel="documentCode" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Surat Keluar" id="input-" size="sm" :model.sync="model.outgoingDocumentId"  :selected="model.outgoingDocument != null ? model.outgoingDocument.documentCode : ''" @input="getOutgoingDocuments" @change="updateData"/>
			</b-col>
			<b-col>
				<span class="float-right" v-if="modelId != null && modelId != undefined && modelId != ''">
					<b-button variant="success" class="float-right ml-2" @click="downloadData" size="sm"><b-icon-download class="mr-1"></b-icon-download> Download</b-button>
				</span>
			</b-col>
		</b-card>
	<b-card class="mt-3" header-bg-variant="transparent">
		<b-row class="mb-2">
			<b-col cols="9"><h5 class="card-title font-weight-bolder text-dark">Investigator Detail</h5></b-col>
			<b-col>
				<span class="float-right">
					<b-form-select v-model="investigatorDetailPageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
				</span>
			</b-col>
		</b-row>
		<b-row>
		<b-col>
			<app-table-input :items="investigatorDetails" :fields="fieldsInvestigatorDetail" :busy="isDataLoading" :sort-by.sync="sortByInvestigatorDetail" :sort-desc.sync="sortDescInvestigatorDetail" small responsive
			@editButtonMethod="editRowDataInvestigatorDetail"
			@deleteButtonMethod="deleteRowDataInvestigatorDetail"
			@showPopupAddMethod="addDataInvestigatorDetail()"
			@showPopupUploadMethod="showPopupUploadInvestigatorDetail()"
			@downloadMethod="downloadInvestigatorDetail()"
			@addFromClipboardMethod="addFromClipboardInvestigatorDetail()"
			@replaceFromClipboardMethod="replaceFromClipboardInvestigatorDetail()"
			:showActionButton="true"
			:showAddButton="true"
			:showAddFromClipboardButton="true"
			:showReplaceFromClipboardButton="true"
			:showUploadButton="true"
			:showDownloadButton = "true"
			:isDownloadBusy = "isDownloadInvestigatorDetail"
			>
			<template v-slot:head(actions)>
				<b-button @click="isShowInvestigatorDetailFilter = !isShowInvestigatorDetailFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
			</template>
			<template #top-row v-if="isShowInvestigatorDetailFilter">
				<b-th></b-th>
				<b-th stacked-heading="User Profile">
					<v-select append-to-body label="fullName" :options="userProfiles" :value="filterInvestigatorDetail.userProfileId" :reduce="item => item.id" v-model="filterInvestigatorDetail.userProfileId" @search="getUserProfiles" :filterable ="true"></v-select>
				</b-th>
				<b-th>
				</b-th>
			</template>
			<template v-slot:cell(no)="row">
				{{ ((row.index + 1) + ( investigatorDetailPageSize * (currentInvestigatorDetailPage - 1))) }}
			</template>
			<template v-slot:table-busys>
				<div class="text-center my-2">
			<b-spinner class="align-middle text-primary"></b-spinner>
				<strong class="ml-2">Loading...</strong>
				</div>
			</template>
			<template v-slot:cell(userProfile)="row">
				<app-input-combobox-autocomplete :options="userProfiles" optionsLabel="fullName" optionsKey="id" id="input-" size="sm" :model.sync="row.item.userProfileId"   @change="saveInputInvestigatorDetail(row.item)" />
			</template>
				<template v-slot:form-add-detail>
				<InvestigatorDetailInput 
					:model="modelInvestigatorDetail"
					:openPopup="isShowPopupInvestigatorDetail"
					@resetMethod="resetModal"
					@addButtonMethod="addRowDataInvestigatorDetail"/>
				</template>
				<template v-slot:upload-detail>
					<InvestigatorDetailUpload
					:openPopup="isShowPopupUploadInvestigatorDetail"
					@resetMethod="resetModal"
					@uploadButtonMethod="uploadDataInvestigatorDetail"
					:parentId="modelId"
				 />
				</template>
			<template v-slot:table-paging>
				<b-pagination
				class="float-right btn-paging"
				v-model="currentInvestigatorDetailPage"
				:total-rows="totalInvestigatorDetailRow"
				:per-page="investigatorDetailPageSize"
				aria-controls="my-table"
				></b-pagination>
			</template>
			</app-table-input>
		</b-col>
		</b-row>
	</b-card>
		<PopupConfirmationDetail :openPopup="isShowPopupConfirmation" :modelId="modelId" @saveActionMethod="saveAction" @resetMethod="resetModal" />
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</PopupRouterView>
</template>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import ArchivedDocument from '@/models/ArchivedDocument/ArchivedDocument';
import ArchivedDocumentList from '@/models/ArchivedDocument/ArchivedDocumentList';
import ArchivedDocumentFilter from '@/filters/ArchivedDocument/ArchivedDocumentFilter';

import PopupConfirmationDetail from './PopupConfirmationDetail';

import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import RemovalMethod from '@/models/RemovalMethod/RemovalMethod';
import RemovalMethodFilter from '@/filters/RemovalMethod/RemovalMethodFilter';
import AppInputTextarea from '@/components/AppInputTextarea';
import InvestigatorDetail from '@/models/ArchivedDocument/InvestigatorDetail';
import InvestigatorDetailFilter from '@/filters/ArchivedDocument/InvestigatorDetailFilter';
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';
import AppTableInput from '@/components/AppTableInput';
import AppTableInputPopup from '@/components/AppTableInputPopup';
import InvestigatorDetailInput from '@/views/ArchivedDocument/InvestigatorDetailInput';
import InvestigatorDetailUpload from '@/views/ArchivedDocument/InvestigatorDetailUpload';
import PopupRouterView from '@/components/PopupRouterView';
export default {
	components : {AppInputTextbox,AppInputDatepicker,AppInputDatepickerRange,AppInputComboboxAutocomplete,AppInputTextarea,InvestigatorDetailInput,InvestigatorDetailUpload,AppTableInput,AppTableInputPopup,PopupRouterView,PopupConfirmationDetail},
	mixins : [validationMixin],
	validations: {
		model: {
		}
	},
	data() {
		return {
			errorMessage : {
			},
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			fieldsInvestigatorDetail: [
				{"no" : { "class" : "th-number"}}, 
				{"userProfile": { "class" : "th-user-profile"}, "label" : "User Profile", key: 'userProfile', sortable: true}, 
				{"actions": { "class" : "th-actions", "label" : ""}}
			],
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			modelId: null,
			model: {},
			modelInvestigatorDetail : {},
			isShowPopupInvestigatorDetail : false,
			isShowPopupUploadInvestigatorDetail : false,
			filterArchivedDocument : null,
			currentArchivedDocumentPage : 1,
			archivedDocumentPageSize: 10,
			sortByArchivedDocument: '',
			sortDescArchivedDocument: '',
			filterInvestigatorDetail : null,
			currentInvestigatorDetailPage : 1,
			investigatorDetailPageSize: 10,
			sortByInvestigatorDetail: '',
			sortDescInvestigatorDetail: '',
			sortingByInvestigatorDetail: [],
			investigatorDetails : [],
			investigatorDetailId : '',
			totalInvestigatorDetailRow : 0,
			isShowInvestigatorDetailFilter: false,
			isDownloadInvestigatorDetail: false,
			userProfiles: [],
			documentLocations: [],
			removalMethods: [],
			incomingDocuments: [],
			outgoingDocuments: [],
			isShowPopupConfirmation : false,
			isDataCommited : false,
			mainRecordId : '',
		}
	},
	methods : {
		getArchivedDocument : async function(id) {
			this.isBusy = true;
			ArchivedDocument.getData(this, id).then(result => { this.model = result; this.mainRecordId = String(result.mainRecordId); this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		downloadData() {
			this.isDownloadArchivedDocument = true;
			this.filterArchivedDocument.id = this.modelId;
			ArchivedDocument.downloadDataDetail(this, this.filterArchivedDocument).then(result => {
				this.isDownloadArchivedDocument = false;
			}).catch(error => { this.isDownloadArchivedDocument = false; })
		},
		getInvestigatorDetails : async function(isAddRow) {
			let filter = {};
			Object.assign(filter, this.filterInvestigatorDetail);
			if (this.modelId == null) return;
			filter.archivedDocumentId = this.modelId;
			filter.draftMode = "1";
			if (this.sortByInvestigatorDetail != null) {
				this.sortingByInvestigatorDetail[this.sortByInvestigatorDetail] = this.sortDescInvestigatorDetail;
			}
			let currentPage = (this.currentInvestigatorDetailPage - 1);
			if (isAddRow) {
				let totalData = this.totalInvestigatorDetailRow + 1;
				let page = Math.ceil(totalData/this.investigatorDetailPageSize);
				currentPage = page - 1;
			}
			InvestigatorDetail.getList(this, filter, this.sortingByInvestigatorDetail, currentPage , this.investigatorDetailPageSize).then(result => {
				this.currentInvestigatorDetailPage = currentPage + 1;
				this.investigatorDetails = result.data;
				this.totalInvestigatorDetailRow = result.dataCount;
			}).catch(error => {});
		},
		getArchivedDocuments : async function(input) {
			let filter = new ArchivedDocumentFilter();
			if (input != undefined) {
				filter.noBeritaAcara = input;
			}
			ArchivedDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.archivedDocuments = result.data
				}
			})
			.catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentLocation.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		getRemovalMethods : async function(input) {
			let filter = new RemovalMethodFilter();
			if (input != undefined) {
				filter.name = input;
			}
			RemovalMethod.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.removalMethods = result.data
				}
			})
			.catch(error => {});
		},
		getIncomingDocuments : async function(input) {
			let filter = new IncomingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			IncomingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		getOutgoingDocuments : async function(input) {
			let filter = new OutgoingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			OutgoingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.outgoingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		validateState(name) {
			if (!this.$v.model[name]) return null;
			const { $dirty, $error } = this.$v.model[name];
			return $dirty ? !$error : null;
		},
		getErrorMessage(message, name) {
			if (!this.$v.model[name]) return "";
			if (this.$v.model[name].required != undefined && !this.$v.model[name].required) {
				if (message.required) return message.required;
			}
		},
		saveActionConfirmation(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isShowPopupConfirmation = true;
			}
		},
		saveAction(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isBusy = true;
				let id = this.modelId;
				let model = this.model;

				if (id != '' && id != null && id != undefined) {
					ArchivedDocument.commitData(this, model, id).then(result => {
								this.isDataCommited = true;
								this.$router.push({ name: 'archiveddocumentindex' });
								this.isBusy = false;
						}).catch(error => { this.isBusy = false; });
					} else {
						ArchivedDocument.insertData(this, model).then(result => {
							this.$router.push({ name: 'archiveddocumentindex' });
							this.isBusy = false;
						}).catch(error => { this.isBusy = false; });
					}
			}
		},
		updateData() {
			let id = this.modelId;
			let model = this.model;

			let promises = Array();

			Promise.all(promises).then((results) => {
				if (id != '' && id != null && id != undefined) {
					ArchivedDocument.updateData(this, model, id);
				}
			});
		},
		onHidden() {
			this.getArchivedDocuments();
		},
		addRowDataInvestigatorDetail(modalEvent, model) {
			if (model != null) {
				if (model.id != null && model.id != '') {
					model.archivedDocumentId =  this.modelId;
					InvestigatorDetail.updateDataDetail(this, model, model.id)
					.then(result => {
						this.getInvestigatorDetails(true);
						this.isShowPopupInvestigatorDetail = false;
					})
				} else {
					model.archivedDocumentId =  this.modelId;
					InvestigatorDetail.createDataDetail(this, model)
					.then(result => {
						this.getInvestigatorDetails(true);
						this.isShowPopupInvestigatorDetail = false;
					})
				}
			}
		},
		async editRowDataInvestigatorDetail(id) {
			this.investigatorDetailId = id;
			this.isShowPopupInvestigatorDetail = true; 
		},
		async deleteRowDataInvestigatorDetail(id) {
			InvestigatorDetail.deleteData(this, id).then(result => { this.getInvestigatorDetails(); });
		},
		async resetDataInvestigatorDetail() {
			this.investigatorDetailId = "";
			this.isShowPopupInvestigatorDetail = true;
		},
		async addDataInvestigatorDetail() {
			InvestigatorDetail.createDataDetail(this, { archivedDocumentId: this.modelId })
			.then(result => {
				this.getInvestigatorDetails(true);
			})
		},
		async addFromClipboardInvestigatorDetail() {
			let userProfiles = new Array();
			navigator.clipboard.readText().then(value => {
				if(value.match(/(?:\r\n|\r|\n)/g)) { value=value.replace(/(?:\r\n|\r|\n)/g, ',') ; }
				let values = value.split(",");
				values.forEach(item => {
					userProfiles.push({ userProfile: item, archivedDocumentId: this.modelId });
				});
				InvestigatorDetail.addFromClipboard(this, userProfiles).then(result => { this.getInvestigatorDetails() });
			});
		},
		async replaceFromClipboardInvestigatorDetail() {
			let userProfiles = new Array();
			navigator.clipboard.readText().then(value => {
				if(value.match(/(?:\r\n|\r|\n)/g)) { value=value.replace(/(?:\r\n|\r|\n)/g, ',') ; }
				let values = value.split(",");
				values.forEach(item => {
					userProfiles.push({ userProfile: item, archivedDocumentId: this.modelId });
				});
				InvestigatorDetail.replaceFromClipboard(this, userProfiles).then(result => { this.getInvestigatorDetails() });
			});   
		},
		showPopupUploadInvestigatorDetail() {
			this.isShowPopupUploadInvestigatorDetail = true;
		},
		uploadDataInvestigatorDetail(modalEvent, newDatas) {
			this.isShowPopupUploadInvestigatorDetail = false; 
		},
		downloadInvestigatorDetail(modalEvent) {
			this.isDownloadInvestigatorDetail = true;
			this.filterInvestigatorDetail.archivedDocumentId = this.modelId;
			InvestigatorDetail.downloadDataDetail(this, this.filterInvestigatorDetail).then(result => {
				this.isDownloadInvestigatorDetail = false;
			}).catch(error => { this.isDownloadInvestigatorDetail = false; })
		},
		saveInputInvestigatorDetail(item) {
			InvestigatorDetail.updateDataDetail(this, item, item.id);
		},
		resetModal(modalType) {
			this.isShowPopupUploadInvestigatorDetail = false; 
			this.isShowPopupInvestigatorDetail = false; 
			if (modalType == 'InvestigatorDetail') {
				this.getInvestigatorDetails();
			}
			this.showPopupCopyData = false; 
			this.isShowPopupConfirmation = false;
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
				this.isShowInvestigatorDetailFilter = true;
			}
		},
	},
	beforeMount() {
		this.filterArchivedDocument = new ArchivedDocumentFilter();
		this.filterInvestigatorDetail = new InvestigatorDetailFilter();
		this.getArchivedDocuments();
		this.getUserProfiles();
		this.getDocumentLocations();
		this.getRemovalMethods();
		this.getIncomingDocuments();
		this.getOutgoingDocuments();
	},
	async mounted(){
		let id = this.$route.params.id;
		let copyData = this.$route.params.copydata;
		if (id != '' && id != null && id != undefined) {
			if (copyData != null && copyData != undefined) {
				this.getArchivedDocument(id);
			} else {
				this.modelId = id;
				this.getArchivedDocument(id);
			}
		}
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	computed: {
	},
	watch : {
		sortByInvestigatorDetail : {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		sortDescInvestigatorDetail: {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		currentInvestigatorDetailPage: {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		investigatorDetailPageSize : {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		filterInvestigatorDetail: {
			handler: function() {
				this.getInvestigatorDetails();
			},
			deep: true,
		},
	},
	beforeDestroy() {
		if (!this.isDataCommited) {
			ArchivedDocument.discardData(this, this.modelId);
		}
	},
}
</script>
