import BaseModelAPI from '../BaseModelAPI';

export default class ReminderStatusList extends BaseModelAPI {

	static entity = 'reminderstatuslist'
	static entityName = 'reminderstatus';

	constructor() {
		this.pageIndex = 0;
		this.pageSize = 0;
		this.pageCount = 0;
		this.dataCount = 0;
	}
}
