import ContractDocument from '@/models/ContractDocument/ContractDocument';

export default class ContractDocumentFilter {

	constructor() {
	/*APP_FILTER_DEFINITION*/
		this.id = '';
		this.incomingDocumentId  = '';
		this.incomingDocumentDocumentCode = '';
		this.outgoingDocumentId  = '';
		this.outgoingDocumentDocumentCode = '';
		this.contractDateFrom = '';
		this.contractDateTo = '';
		this.contractStatusId  = '';
		this.contractStatusName = '';
		this.draftFromUpload = '';
		this.draftMode = '';
	/*END_APP_FILTER_DEFINITION*/
		this.exactType = {
	/*APP_APP_FILTER_CRITERIA*/
			id : '0',
			incomingDocumentId : '0',
			incomingDocumentDocumentCode : '0',
			outgoingDocumentId : '0',
			outgoingDocumentDocumentCode : '0',
			contractDateFrom : '0',
			contractDateTo : '0',
			contractStatusId : '0',
			contractStatusName : '0',
	/*END_APP_FILTER_CRITERIA*/
		};
	}

}