<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" size="sm" @hidden="resetModal" scrollable :no-close-on-backdrop=true id="popup-upload" @ok="uploadButtonAction" :okTitle="okTitle" title="Upload Data">
		<b-card>
			<b-row>
				<b-col cols="12">
					<app-input-fileupload label="File" size="sm" :maxFileSize="5" description="Ukuran maksimum file 5MB." :model.sync="fileupload" />
				</b-col>
			</b-row>
		</b-card>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate"; 
import { required, minLength } from "vuelidate/lib/validators"; 

import IncomingDocumentAttachmentDetail from '@/models/IncomingDocument/IncomingDocumentAttachmentDetail';

import AppInputFileupload from '@/components/AppInputFileupload';
export default {
	props: ['modelId', 'parent', 'openPopup','uploadButtonMethod', 'parentId'],
	components : {AppInputFileupload,},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			busy:false,
			isDataLoading: false,
			isBusy : false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			processId: "",
			model : {},
		}
	},
	methods : {
		resetModal() {
			this.$emit('resetMethod', 'IncomingDocumentAttachmentDetail');
		},
		getIncomingDocumentAttachmentDetail : async function(id) {
			this.isBusy = true;
			IncomingDocumentAttachmentDetail.getData(this, id).then(result => { this.model = result; this.isBusy = false }).catch(error => { this.isBusy = false; });
		},
		uploadButtonAction(modalEvent) {
			modalEvent.preventDefault();
			if (this.fileupload != null) {
				this.isBusy = true;
				let data = new FormData();
				data.append('file', this.fileupload);
				let _this = this;

				let model = this.model;
				model.incomingDocument = this.parent;
				this.$http.post(process.env.VUE_APP_API_URL + 'attachment/upload',
					data,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
					).then(async function(response){
						let this_ =_this;
						let dataAttachment = null;
						if (response.data) {
							dataAttachment = response.data;
							if (response.data.data) {
								dataAttachment = response.data.data;
							}
						}
						if (dataAttachment) {
							if (model.id == "" || model.id == null) {
								model.attachment = dataAttachment;
								IncomingDocumentAttachmentDetail.insertData(_this , model).then(result => {
									if (result) {
										this_.$store.dispatch('addSuccessMessageGlobal', "File Berhasil di Upload.");
										this_.isBusy = false;
										this_.resetModal();
									}
								}).catch(error => { this_.isBusy = false; });
							} else {
								model.attachment = dataAttachment;
								IncomingDocumentAttachmentDetail.updateData(_this , model, model.id).then(result => {
									if (result) {
										this_.$store.dispatch('addSuccessMessageGlobal', "File Berhasil di Upload.");
										this_.isBusy = false;
										this_.resetModal();
									}
								}).catch(error => { this_.isBusy = false; });
							}
						} else {
							_this .isBusy = false;
						}
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
			}
		},
	},
	beforeMount() {
	},
	mounted(){
	},
	computed: {
		okTitle: {
			get: function() {
				if (this.datas != null) {
					if (this.datas.length > 0) {
					return "Proses";
					}
				}
				return "Upload";
			}
		},
		isShowPopup : {
			get: function () {
			if (this.openPopup) return true;
				else return false;
			},
			set: function (newValue) {}
		},
	},
	watch: {
		modelId(newVal) {
			if (newVal == null || newVal == undefined || newVal == "") {
				this.model = new IncomingDocumentAttachmentDetail();
				this.model.incomingDocument = this.parent;
			} else {
				this.getIncomingDocumentAttachmentDetail(newVal)
			}
		}
	}
}
</script>
