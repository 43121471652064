<template>
	<PopupRouterView label="Disposition Input"  entityName="disposition" :documentId="mainRecordId"  @saveMethod="saveAction" @hideMethod="onHidden" okTitle="Kirim">
		<b-card>
			<b-col cols="12" lg="6">
				<app-input-combobox-autocomplete v-if="false" :options="incomingDocuments" optionsLabel="documentCode" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" id="input-" size="sm" :model.sync="model.incomingDocumentId"  :selected="model.incomingDocument != null ? model.incomingDocument.documentCode : ''" @input="getIncomingDocuments" />
				<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" label-for="filter-document-code">
					{{ model != null ? model.incomingDocument != null ? model.incomingDocument.documentCode : "" : "" }}
				</b-form-group>
				<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Tanggal & Waktu" label-for="filter-document-code">
					{{ model != null ? model.incomingDocument != null ? model.incomingDocument.createdAt : "" : "" | moment('DD-MMM-YYYY HH:mm')}}
				</b-form-group>
				<b-form-group id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Lokasi Surat" label-for="filter-document-location">
					{{ model != null ? model.incomingDocument != null ? model.incomingDocument.documentLocationName : "" : "" }}
				</b-form-group>
				<b-form-group id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Pengirim Disposisi" label-for="filter-document-location">
					{{ user }}
				</b-form-group>
				<b-form-group id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Jenis Surat" label-for="filter-document-location">
					{{ model != null ? model.incomingDocument != null ? model.incomingDocument.documentTypeName : "" : "" }}
				</b-form-group>
				<b-form-group id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Perihal / Ringkasan Surat" label-for="filter-document-location">
					{{ model != null ? model.incomingDocument != null ? model.incomingDocument.remarks : "" : "" }}
				</b-form-group>
				<app-input-combobox-autocomplete :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Penerima Disposisi" id="input-" size="sm" :model.sync="model.userReceiverId"  :selected="model.userReceiver != null ? model.userReceiver.fullName : ''" @input="getUserProfiles" :isSelectMethodExists="true" @onSelectMethod="changeUserReseiver" :isRequired="true" :state="validateState('userReceiverId')" :errorMessage="getErrorMessage(errorMessage.userReceiverId, 'userReceiverId')" />
				<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Jabatan Pegawai" label-for="filter-document-code">
					{{ model != null ? (model.userReceiver != null ? model.userReceiver.jobDescription : '-') : "-" }}
				</b-form-group>
				<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Email" label-for="filter-document-code">
					{{ model != null ? (model.userReceiver != null ? model.userReceiver.email : '-') : "-" }}
				</b-form-group>
				<app-input-textarea id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Pesan Disposisi" label-for="input-remarks" size="sm" :model.sync="model.remarks" :isRequired="true" :state="validateState('remarks')" :errorMessage="getErrorMessage(errorMessage.remarks, 'remarks')"  />
			</b-col>
			<b-col>
				<span class="float-right" v-if="modelId != null && modelId != undefined && modelId != ''">
					<b-button variant="success" class="float-right ml-2" @click="downloadData" size="sm"><b-icon-download class="mr-1"></b-icon-download> Download</b-button>
				</span>
			</b-col>
		</b-card>
		<PopupConfirmationDetail :openPopup="isShowPopupConfirmation" :model="model" @saveActionMethod="saveAction" @resetMethod="resetModal" />
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</PopupRouterView>
</template>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import Disposition from '@/models/Disposition/Disposition';
import DispositionList from '@/models/Disposition/DispositionList';
import DispositionFilter from '@/filters/Disposition/DispositionFilter';

import PopupConfirmationDetail from './PopupConfirmationDetail';

import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import AppInputTextarea from '@/components/AppInputTextarea';
import PopupRouterView from '@/components/PopupRouterView';
import { mapGetters } from 'vuex'

export default {
	components : {AppInputComboboxAutocomplete,AppInputTextarea,PopupRouterView,PopupConfirmationDetail},
	mixins : [validationMixin],
	validations: {
		model: {
			userReceiverId : {required},
			remarks : {required}
		}
	},
	data() {
		return {
			errorMessage : {
				userReceiverId: {required: "Penerima Disposisi is Required"},
				remarks: {required: "Pesan Disposis is Required"},
			},
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			modelId: null,
			model: {},
			filterDisposition : null,
			currentDispositionPage : 1,
			dispositionPageSize: 10,
			sortByDisposition: '',
			sortDescDisposition: '',
			incomingDocuments: [],
			userProfiles: [],
			isShowPopupConfirmation : false,
			isDataCommited : false,
			mainRecordId : '',
		}
	},
	methods : {
		getDisposition : async function(id) {
			this.isBusy = true;
			Disposition.getData(this, id).then(result => { this.model = result; this.mainRecordId = String(result.mainRecordId); this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		downloadData() {
			this.isDownloadDisposition = true;
			this.filterDisposition.id = this.modelId;
			Disposition.downloadDataDetail(this, this.filterDisposition).then(result => {
				this.isDownloadDisposition = false;
			}).catch(error => { this.isDownloadDisposition = false; })
		},
		getIncomingDocuments : async function(input) {
			let filter = new IncomingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			IncomingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		getIncomingDocument: async function(id) {
			this.isBusy = true;
			IncomingDocument.getData(this, id).then(result => {
				if (this.model == null) {
					this.model = {};
				}
				this.model.incomingDocument = result; 
				this.model.incomingDocumentId = result.id;
				this.mainRecordId = String(result.mainRecordId); 
				this.isBusy = false; 
			}).catch(error => { this.isBusy = false; });
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			var menu_name = this.$route.name;
			if (menu_name == 'dispositioncreatedata') {
				let id = this.$route.params.id;
				filter["bylevel"] = true;
			}
			
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		validateState(name) {
			if (!this.$v.model[name]) return null;
			const { $dirty, $error } = this.$v.model[name];
			return $dirty ? !$error : null;
		},
		getErrorMessage(message, name) {
			if (!this.$v.model[name]) return "";
			if (this.$v.model[name].required != undefined && !this.$v.model[name].required) {
				if (message.required) return message.required;
			}
		},
		saveActionConfirmation(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isShowPopupConfirmation = true;
			}
		},
		saveAction(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isBusy = true;
				let id = this.modelId;
				let model = this.model;

				if (id != '' && id != null && id != undefined) {
					Disposition.updateData(this, model, id).then(result => {
						this.isDataCommited = true;
						this.$router.push({ name: 'dispositionindex' });
						this.isBusy = false;
					}).catch(error => { this.isBusy = false; });
			} else {
				Disposition.insertData(this, model).then(result => {
					this.$router.push({ name: 'dispositionindex' });
					this.isBusy = false;
				}).catch(error => { this.isBusy = false; });
			}
			}
		},
		onHidden() {
			this.getDispositions();
		},
		resetModal(modalType) {
			this.showPopupCopyData = false; 
			this.isShowPopupConfirmation = false;
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		changeUserReseiver(selectedValue){
			console.log(selectedValue);
			if (selectedValue) {
				this.model.userReceiverId = selectedValue.id;
				this.model.userReceiver = selectedValue;
			} else {
				this.model.userReceiverId = "";
				this.model.userReceiver = {};
			}
		}
	},
	beforeMount() {
		this.filterDisposition = new DispositionFilter();
		this.getIncomingDocuments();
		this.getUserProfiles();
	},
	async mounted(){
		let id = this.$route.params.id;
		var menu_name = this.$route.name;
		if (menu_name == 'dispositioncreatedata') {
			this.getIncomingDocument(id);
		} else {
			let copyData = this.$route.params.copydata;
			if (id != '' && id != null && id != undefined) {
				if (copyData != null && copyData != undefined) {
					this.getDisposition(id);
				} else {
					this.modelId = id;
					this.getDisposition(id);
				}
			}
		}

		
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	computed: {
		...mapGetters({
			accessToken: 'accessToken',
			isLoggedIn: 'isLoggedIn',
			user: 'user',
			userName: 'userName',
		}),
	},
	watch : {
	},
	beforeDestroy() {
	},
}
</script>
