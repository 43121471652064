import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';

export default class IncomingDocumentFilter {

	constructor() {
	/*APP_FILTER_DEFINITION*/
		this.id = '';
		this.documentCode = '';
		this.documentLocationId  = '';
		this.documentLocationName = '';
		this.senderName = '';
		this.senderStatusId  = '';
		this.senderStatusName = '';
		this.contactPerson = '';
		this.senderAddress = '';
		this.senderEmail = '';
		this.senderPhoneNumber = '';
		this.destinationUserId  = '';
		this.destinationUserFullName = '';
		this.copyUserId  = '';
		this.copyUserFullName = '';
		this.sifatSuratId  = '';
		this.sifatSuratName = '';
		this.documentTypeId  = '';
		this.documentTypeName = '';
		this.documentNo = '';
		this.documentDateFrom = '';
		this.documentDateTo = '';
		this.descriptionMultiple = '';
		this.contractDateFrom = '';
		this.contractDateTo = '';
		this.contractDescriptionMultiple = '';
		this.documentAttachmentId  = '';
		this.documentAttachmentOriginalFileName = '';
		this.distributionTypeId  = '';
		this.distributionTypeName = '';
		this.receiptDocument = '';
		this.isReminder = false;
		this.reminderDateFrom = '';
		this.reminderDateTo = '';
		this.remarksMultiple = '';
		this.documentStatusId  = '';
		this.documentStatusName = '';
		this.copyDocumentStatusId  = '';
		this.copyDocumentStatusName = '';
		this.isFromUpload = false;
		this.isSavedToBackend = false;
		this.draftFromUpload = '';
		this.draftMode = '';
	/*END_APP_FILTER_DEFINITION*/
		this.exactType = {
	/*APP_APP_FILTER_CRITERIA*/
			id : '0',
			documentCode : '0',
			documentLocationId : '0',
			documentLocationName : '0',
			senderName : '0',
			senderStatusId : '0',
			senderStatusName : '0',
			contactPerson : '0',
			senderAddress : '0',
			senderEmail : '0',
			senderPhoneNumber : '0',
			destinationUserId : '0',
			destinationUserFullName : '0',
			copyUserId : '0',
			copyUserFullName : '0',
			sifatSuratId : '0',
			sifatSuratName : '0',
			documentTypeId : '0',
			documentTypeName : '0',
			documentNo : '0',
			documentDateFrom : '0',
			documentDateTo : '0',
			descriptionMultiple : '0',
			contractDateFrom : '0',
			contractDateTo : '0',
			contractDescriptionMultiple : '0',
			documentAttachmentId : '0',
			documentAttachmentOriginalFileName : '0',
			distributionTypeId : '0',
			distributionTypeName : '0',
			receiptDocument : '0',
			isReminder : '0',
			reminderDateFrom : '0',
			reminderDateTo : '0',
			remarksMultiple : '0',
			documentStatusId : '0',
			documentStatusName : '0',
			copyDocumentStatusId : '0',
			copyDocumentStatusName : '0',
			isFromUpload : '0',
			isSavedToBackend : '0',
	/*END_APP_FILTER_CRITERIA*/
		};
	}

}