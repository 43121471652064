import Reminder from '@/models/Reminder/Reminder';

export default class ReminderFilter {

	constructor() {
	/*APP_FILTER_DEFINITION*/
		this.id = '';
		this.incomingDocumentId  = '';
		this.incomingDocumentDocumentCode = '';
		this.reminderDateFrom = '';
		this.reminderDateTo = '';
		this.reminderStatusId  = '';
		this.reminderStatusName = '';
		this.createdBy = '';
		this.updatedBy = '';
		this.draftFromUpload = '';
		this.draftMode = '';
	/*END_APP_FILTER_DEFINITION*/
		this.exactType = {
	/*APP_APP_FILTER_CRITERIA*/
			id : '0',
			incomingDocumentId : '0',
			incomingDocumentDocumentCode : '0',
			reminderDateFrom : '0',
			reminderDateTo : '0',
			reminderStatusId : '0',
			reminderStatusName : '0',
			createdBy : '0',
			updatedBy : '0',
	/*END_APP_FILTER_CRITERIA*/
		};
	}

}