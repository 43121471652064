import BaseModelAPI from '../BaseModelAPI';

export default class DocumentAttachmentDetail extends BaseModelAPI {
	static entity = 'documentattachmentdetail';
	static entityName = 'documentattachmentdetail';

	constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.documentAttachmentId = '';
			this.documentAttachment = {};
			this.fileName = '';
			this.attachmentId = '';
			this.attachment = {};
			this.createdBy = '';
			this.updatedBy = '';
			this.uploadValidationMessage = '';
			this.uploadValidationStatus = '';
	/*END_APP_MODEL_DEFINITION*/
	}

}