<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" :cancel-title="hasPrinted ? 'Close' : 'Cancel'" :ok-title="hasPrinted ? 'Print' : 'Save'" @hidden="resetModal" scrollable :no-close-on-backdrop=true @ok="saveButtonAction" title="Berita Acara">
		<!-- APP_DETAIL_HEADER -->
		<b-card>
			<b-col cols="12" lg="6">
					<b-form-group id="fieldset-no-berita-acara" label-cols-sm="4" label-cols-lg="3" label="No. Berita Acara" label-for="filter-no-berita-acara">
						{{ model.noBeritaAcara != null ? model.noBeritaAcara : "Autonumber" }}
					</b-form-group>
					<b-form-group id="fieldset-created-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal & Waktu" label-for="filter-created-date">
						{{ model.createdDate != null ? model.createdDate : Date() | moment('DD-MMM-YYYY HH:mm') }}
					</b-form-group>
					<app-input-datepicker-range  id="fieldset-archived-date-from" label-cols-sm="4" label-cols-lg="3" label="Periode Penghapusan Arsip" size="sm" :modelFrom.sync="model.archivedDateFrom" :modelTo.sync="model.archivedDateTo"  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" />
					<app-input-combobox-autocomplete :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Nama Pelaksana" id="input-" size="sm" :model.sync="model.picId" :selected="model.pic != null ? model.pic.fullName : ''" @input="getUserProfiles" />
					<app-input-combobox-autocomplete-multi-select :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Nama Saksi-saksi" id="input-" size="sm" :model.sync="model.investigatorDetail" @input="getUserProfiles" />
					<app-input-combobox-autocomplete :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Disetujui Oleh" id="input-" size="sm" :model.sync="model.approvedById" :selected="model.approvedBy != null ? model.approvedBy.fullName : ''" @input="getUserProfiles" />
					<app-input-combobox-autocomplete :options="removalMethods" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Cara Penghapusan Arsip (Fisik)" id="input-" size="sm" :model.sync="model.removalMethodId" :selected="model.removalMethod != null ? model.removalMethod.name : ''" @input="getRemovalMethods" />
					<app-input-textarea id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Catatan" label-for="input-remarks" size="sm" :model.sync="model.remarks" />
			</b-col>
		</b-card>
		<!-- END_APP_DETAIL_HEADER -->
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<script>
import ArchivedDocument from '@/models/ArchivedDocument/ArchivedDocument';
import BeritaAcaraArchivedDocument from '@/models/ArchivedDocument/BeritaAcaraArchivedDocument';
import ArchivedDocumentList from '@/models/ArchivedDocument/ArchivedDocumentList';
import ArchivedDocumentFilter from '@/filters/ArchivedDocument/ArchivedDocumentFilter';

import RemovalMethod from '@/models/RemovalMethod/RemovalMethod';
import RemovalMethodFilter from '@/filters/RemovalMethod/RemovalMethodFilter';
import InvestigatorDetail from '@/models/ArchivedDocument/InvestigatorDetail';
import InvestigatorDetailFilter from '@/filters/ArchivedDocument/InvestigatorDetailFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
import AppInputComboboxAutocompleteMultiSelect from '@/components/AppInputComboboxAutocompleteMultiSelect.vue';
import AppInputTextarea from '@/components/AppInputTextarea.vue';

export default {
	props : ["openPopup", "resetMethod"],
	components : {
		PopupRouterViewDetail,AppInputDatepickerRange,AppInputDatetimepicker,AppInputDatetimepickerRange,AppInputComboboxAutocomplete,AppInputComboboxAutocompleteMultiSelect,AppInputTextarea,
	},
	data() {
		return {
			fileupload: null,
			model: {},
			datas: Array(),
			fieldsInvestigatorDetail: [
				{"no" : {  "class" : "th-number" }},
				{"userProfile": {},  "class" : "th-user-profile", "label" : "User Profile", key: 'userProfile', sortable: true}, 
				{"actions": { "class" : "th-actions", "label" : "" }}
			],
			busy:false,
			isBusy: false,
			hasPrinted: false,
			isDataLoading: false,
			selected:'',
			/*APP_DETAIL_PAGINATION*/
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterArchivedDocument : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentArchivedDocumentPage : 1,
			archivedDocumentPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByArchivedDocument: '',
			sortDescArchivedDocument: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			filterInvestigatorDetail : null,
			currentInvestigatorDetailPage : 1,
			investigatorDetailPageSize: 10,
			sortByInvestigatorDetail: '',
			sortDescInvestigatorDetail: '',
			sortingByInvestigatorDetail: [],
			investigatorDetails : [],
			investigatorDetailId : '',
			totalInvestigatorDetailRow : 0,
			isShowInvestigatorDetailFilter: false,
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			userProfiles: [],
			removalMethods: [],
			/*END_APP_DETAIL_OBJ_VARIABLE*/
			printData: {},
			
		}
	},
	methods : {
		getArchivedDocument : async function(id) {
			this.isBusy = true;
			ArchivedDocument.getData(this, id).then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		getInvestigatorDetails : async function() {
			let filter = {};
			Object.assign(filter, this.filterInvestigatorDetail);
			if (this.modelId == null) return;
			filter.archivedDocumentId = this.modelId;
			this.sortingByInvestigatorDetail = Array();
			if (this.sortByInvestigatorDetail != null) {
				this.sortingByInvestigatorDetail[this.sortByInvestigatorDetail] = this.sortDescInvestigatorDetail;
			}
			let currentPage = (this.currentInvestigatorDetailPage - 1);
			InvestigatorDetail.getList(this, filter, this.sortingByInvestigatorDetail, currentPage , this.investigatorDetailPageSize).then(result => {
				this.investigatorDetails = result.data;
				this.totalInvestigatorDetailRow = result.dataCount;
			}).catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		getRemovalMethods : async function(input) {
			let filter = new RemovalMethodFilter();
			if (input != undefined) {
				filter.name = input;
			}
			RemovalMethod.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.removalMethods = result.data
				}
			})
			.catch(error => {});
		},
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'archiveddocumentcopydata', params: { id: this.modelId, copydata : 1 } })
		},
		saveButtonAction(modalEvent) {
			modalEvent.preventDefault();
			let model = this.model;
			if (this.hasPrinted) {
				window.open('/archiveddocument/print/' + printData.id, '_blank');
			} else {
				BeritaAcaraArchivedDocument.insertData(this, model).then(result => {
					window.open('/archiveddocument/print/' + result.id, '_blank');
					this.printData = result;
					this.isBusy = false;
					this.hasPrinted = true;
				}).catch(error => { this.isBusy = false; });
			}

		},
		resetModal() {
			this.$emit('resetMethod'); 
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
				this.isShowInvestigatorDetailFilter = true;
			}
		},
	},
	
	beforeMount() {
	},
	watch : {
		openPopup(newValue, oldValue) {
			console.log(newValue);
			if (newValue) {
				this.getUserProfiles();
				this.getRemovalMethods();
			}
		},
	},
	computed: {
		isShowPopup: {
			get: function(){
				if (this.openPopup) return true; 
				else return false; 
			},
			set: function(newValue){ }
		},
	}
}
</script>
