<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true @ok="saveButtonAction" title="Update Data">
		<b-card>
			<b-col cols="12" lg="6">
				<b-form-group id="fieldset-no-berita-acara" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" label-for="filter-no-berita-acara">
					<span v-if="model.incomingDocument != null">
						{{ model != null ? model.incomingDocument.documentCode : "-" }}
					</span>
					<span v-else-if="model.outgoingDocument != null">
						{{ model != null ? model.outgoingDocument.documentCode : "-" }}
					</span>
				</b-form-group>
				<b-form-group id="fieldset-created-date" label-cols-sm="4" label-cols-lg="3" label="Perihal" label-for="filter-created-date">
					<span v-if="model.incomingDocument != null">
						{{ model != null ? model.incomingDocument.remarks : "-" }}
					</span>
					<span v-else-if="model.outgoingDocument != null">
						{{ model != null ? model.outgoingDocument.remarks : "-" }}
					</span>
				</b-form-group>
				<b-form-group id="fieldset-created-date" label-cols-sm="4" label-cols-lg="3" label="Status Arsip" label-for="filter-created-date">
					{{ model != null ? model.archivedDocumentStatusName : '-' }}
				</b-form-group>
				<b-form-group id="fieldset-is-permanent" label-cols-sm="4" label-cols-lg="3" label="Status Permanen" label-for="input-is-permanent">
					<b-form-checkbox id="input-is-permanent" v-model="model.isPermanent" :switch="true"  ></b-form-checkbox>
				</b-form-group>
				<app-input-textarea id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Catatan" label-for="input-remarks" size="sm" :model.sync="model.remarks" />
			</b-col>
		</b-card>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import ArchivedDocument from '@/models/ArchivedDocument/ArchivedDocument';
import ArchivedDocumentList from '@/models/ArchivedDocument/ArchivedDocumentList';
import ArchivedDocumentFilter from '@/filters/ArchivedDocument/ArchivedDocumentFilter';

import RemovalMethod from '@/models/RemovalMethod/RemovalMethod';
import RemovalMethodFilter from '@/filters/RemovalMethod/RemovalMethodFilter';
import InvestigatorDetail from '@/models/ArchivedDocument/InvestigatorDetail';
import InvestigatorDetailFilter from '@/filters/ArchivedDocument/InvestigatorDetailFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import AppInputTextarea from '@/components/AppInputTextarea.vue';
/*END_APP_DETAIL_IMPORT*/
export default {
	/*APP_DETAIL_PROP_DEFINITION*/
	props : ["openPopup", "modelId", "saveActionMethod", "resetMethod"],
	/*END_APP_DETAIL_PROP_DEFINITION*/
	components : {
	/*APP_DETAIL_PROP_DEFINITION*/
	PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,AppInputComboboxAutocomplete,AppInputTextarea
	/*APP_DETAIL_COMPONENTS_DEFINITION*/
},
	data() {
		return {
			fileupload: null,
			model: {},
			datas: Array(),
			fieldsInvestigatorDetail: [
				{"no" : {  "class" : "th-number" }},
				{"userProfile": {},  "class" : "th-user-profile", "label" : "User Profile", key: 'userProfile', sortable: true}, 
				{"actions": { "class" : "th-actions", "label" : "" }}
			],
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			/*APP_DETAIL_PAGINATION*/
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterArchivedDocument : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentArchivedDocumentPage : 1,
			archivedDocumentPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByArchivedDocument: '',
			sortDescArchivedDocument: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			filterInvestigatorDetail : null,
			currentInvestigatorDetailPage : 1,
			investigatorDetailPageSize: 10,
			sortByInvestigatorDetail: '',
			sortDescInvestigatorDetail: '',
			sortingByInvestigatorDetail: [],
			investigatorDetails : [],
			investigatorDetailId : '',
			totalInvestigatorDetailRow : 0,
			isShowInvestigatorDetailFilter: false,
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			userProfiles: [],
			removalMethods: [],
			/*END_APP_DETAIL_OBJ_VARIABLE*/
		}
	},
	methods : {
		/*APP_DETAIL_GETArchivedDocument*/
		getArchivedDocument : async function(id) {
			this.isBusy = true;
			ArchivedDocument.getData(this, id, 'incomingDocument,outgoingDocument').then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETArchivedDocument*/
		/*APP_DETAIL_GETInvestigatorDetail*/
		getInvestigatorDetails : async function() {
			return;
			let filter = {};
			Object.assign(filter, this.filterInvestigatorDetail);
			if (this.modelId == null) return;
			filter.archivedDocumentId = this.modelId;
			this.sortingByInvestigatorDetail = Array();
			if (this.sortByInvestigatorDetail != null) {
				this.sortingByInvestigatorDetail[this.sortByInvestigatorDetail] = this.sortDescInvestigatorDetail;
			}
			let currentPage = (this.currentInvestigatorDetailPage - 1);
			InvestigatorDetail.getList(this, filter, this.sortingByInvestigatorDetail, currentPage , this.investigatorDetailPageSize).then(result => {
				this.investigatorDetails = result.data;
				this.totalInvestigatorDetailRow = result.dataCount;
			}).catch(error => {});
		},
		/*END_APP_DETAIL_GETInvestigatorDetail*/
		/*APP_DETAIL_GETUSERPROFILE*/
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		/*END_APP_DETAIL_GETUSERPROFILE*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'archiveddocumentcopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_SAVEBUTTONACTION*/
		saveButtonAction(modalEvent) {
			//this.$emit("saveActionMethod", modalEvent);
			let id = this.modelId;
			let model = this.model;

			let promises = Array();

			Promise.all(promises).then((results) => {
				if (id != '' && id != null && id != undefined) {
					ArchivedDocument.updateData(this, model, id);
				}
			});
		},
		/*END_APP_DETAIL_SAVEBUTTONACTION*/
		/*APP_DETAIL_RESETMODAL*/
		resetModal() {
			this.$emit('resetMethod', 'UpdateData'); 
		},
		/*END_APP_DETAIL_RESETMODAL*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
				this.isShowInvestigatorDetailFilter = true;
			}
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
		getRemovalMethods : async function(input) {
			let filter = new RemovalMethodFilter();
			if (input != undefined) {
				filter.name = input;
			}
			RemovalMethod.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.removalMethods = result.data
				}
			})
			.catch(error => {});
		},
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterArchivedDocument = new ArchivedDocumentFilter();
		this.filterInvestigatorDetail = new InvestigatorDetailFilter();
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	watch : {
		/*APP_DETAIL_OPENPOPUP*/
		openPopup(newValue, oldValue) {
			if (newValue) {
				this.getArchivedDocument(this.modelId);
				this.getRemovalMethods();
			}
		},
		/*END_APP_DETAIL_OPENPOPUP*/
		/*APP_DETAIL_WATCH_INVESTIGATORDETAIL*/
		sortByInvestigatorDetail : {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		sortDescInvestigatorDetail: {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		currentInvestigatorDetailPage: {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		investigatorDetailPageSize : {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		filterInvestigatorDetail: {
			handler: function() {
				this.getInvestigatorDetails();
			},
			deep: true,
		},
		/*END_APP_DETAIL_WATCH_INVESTIGATORDETAIL*/
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
		isShowPopup: {
			get: function(){
				if (this.openPopup) return true; 
				else return false; 
			},
			set: function(newValue){ }
		},
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
