<template>
	<PopupRouterView label="Outgoing Document Input"  entityName="outgoingdocument" :documentId="mainRecordId"  @saveMethod="saveAction" @hideMethod="onHidden">
		
			<b-row>
				<b-col cols="12" lg="6">
					<b-card class="mb-3">
						<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" label-for="filter-document-code">
						{{ model != null ? (model.documentCode != null && model.documentCode != "" ? model.documentCode : "autonumber") : "autonumber" }}
						</b-form-group>
						<b-form-group id="fieldset-created-at" label-cols-sm="4" label-cols-lg="3" label="Tanggal & Waktu" label-for="filter-document-location">
							{{ model != null ? ( model.createdAt != null ? model.createdAt : "") : "" | moment("DD-MM-YYYY HH:mm:ss") }}
						</b-form-group>
						<b-form-group id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Lokasi Dokumen" label-for="filter-document-location">
							<span v-if="model.createdByUserProfile != null">
								{{ model != null ? (model.createdByUserProfile != null ? model.createdByUserProfile.branchName : '-') : "-" }}
							</span>
							<span v-else>
								{{ model != null ? ( model.documentLocation != null ? model.documentLocation.name : userProfile.branchName) : userProfile.branchName }}
							</span>
						</b-form-group>
						<b-form-group id="fieldset-created-by-full-name" label-cols-sm="4" label-cols-lg="3" label="Pemohon Surat" label-for="created-by-full-name">
							<span v-if="model.createdByUserProfile != null">
								{{ model != null ? (model.createdByUserProfile != null ? model.createdByUserProfile.fullName : '-') : "-" }}
							</span>
							<span v-else>
								{{ userProfile != null ? userProfile.fullName : "-" }}
							</span>
						</b-form-group>
						<b-form-group id="fieldset-job-description" label-cols-sm="4" label-cols-lg="3" label="Jabatan Pegawai" label-for="job-description">
							<span v-if="model.createdByUserProfile != null">
								{{ model != null ? (model.createdByUserProfile != null ? model.createdByUserProfile.jobDescription : '-') : "-" }}
							</span>
							<span v-else>
								{{ userProfile != null ? userProfile.jobDescription : "-" }}
							</span>
						</b-form-group>
						<b-form-group id="fieldset-email" label-cols-sm="4" label-cols-lg="3" label="Email" label-for="filter-email">
							<span v-if="model.createdByUserProfile != null">
								{{ model != null ? (model.createdByUserProfile != null ? model.createdByUserProfile.email : '-') : "-" }}
							</span>
							<span v-else>
								{{ userProfile != null ? userProfile.email : "-" }}
							</span>
						</b-form-group>
					</b-card>
					<b-card class="mt-3">
						<app-input-combobox-autocomplete-multi-select :disabled="model.isSendToAll != null && model.isSendToAll != undefined ? model.isSendToAll : false"  :isRequired="true" :state="validateState('destinationUsers')" :errorMessage="getErrorMessage(errorMessage.destinationUsers, 'destinationUsers')"  :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Ditujukan Kepada" id="input-" size="sm" :model.sync="model.destinationUsers" :selected="destinationUserDatas" @input="getUserProfiles" />
						<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="" label-for="filter-document-code">
							<b-form-checkbox :id="'checkbox-send-to-all'"  v-model="model.isSendToAll" :name="'checkbox-send-to-all'" :value="true" :unchecked-value="false" >
								<span class="col-form-label">Kirim ke Semua</span>
							</b-form-checkbox>
						</b-form-group>
					</b-card>
					<b-card class="mt-3 mb-3">
						<app-input-combobox-autocomplete-multi-select :options="copyUsers" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Ditembuskan Kepada" id="input-" size="sm" :model.sync="model.copyUsers" :selected="copyUserDatas" @input="getCopyUsers" />
					</b-card>
					<b-card class="mb-3" v-if="false">
						<app-input-textbox id="fieldset-destination-user" label-cols-sm="4" label-cols-lg="3" label="Ditujukan Kepada" label-for="input-destination-user" size="sm" :model.sync="model.destinationUser" :isRequired="true" :state="validateState('destinationUser')" :errorMessage="getErrorMessage(errorMessage.destinationUser, 'destinationUser')"   />
						<app-input-combobox-autocomplete :options="senderStatuss" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Status Tujuan" id="input-" size="sm" :model.sync="model.destinationStatusId" :isRequired="true" :state="validateState('destinationStatusId')" :errorMessage="getErrorMessage(errorMessage.destinationStatusId, 'destinationStatusId')"  :selected="model.destinationStatus != null ? model.destinationStatus.name : ''" @input="getSenderStatuss" :isSelectMethodExists="true" @onSelectMethod="changeDestinationStatus" />
						<app-input-textbox id="fieldset-destination-contact-person" label-cols-sm="4" label-cols-lg="3" label="Contact Person (CP)" label-for="input-destination-contact-person" size="sm" :model.sync="model.destinationContactPerson"   />
						<app-input-textarea id="fieldset-destination-address" label-cols-sm="4" label-cols-lg="3" label="Alamat Kirim" label-for="input-destination-address" size="sm" :model.sync="model.destinationAddress"   />
					</b-card>
					<div v-if="isSuperUser">
						<b-card class="mb-3">
							<app-input-datepicker  id="fieldset-document-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal Surat" size="sm" :model.sync="model.documentDate" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"  reset-button/>
							<app-input-textarea id="fieldset-description" label-cols-sm="4" label-cols-lg="3" label="Perihal/Ringkasan Surat" label-for="input-description" size="sm" :model.sync="model.description" :isRequired="true" :state="validateState('description')" :errorMessage="getErrorMessage(errorMessage.description, 'description')" />
						</b-card>
						<b-card class="mb-3">
							<app-input-datepicker-range id="fieldset-contract-date" label-cols-sm="4" label-cols-lg="3" label="Masa Berlaku Kontrak" size="sm" :modelFrom.sync="model.contractDateFrom" :modelTo.sync="model.contractDateTo" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button />
							<app-input-textarea id="fieldset-contract-description" label-cols-sm="4" label-cols-lg="3" label="Catatan Atas Kontrak" label-for="input-contract-description" size="sm" :model.sync="model.contractDescription"   />
						</b-card>
					</div>
				</b-col>
				<b-col cols="12" lg="6">
					<div v-if="!isSuperUser">
						<b-card class="mb-3">
							<app-input-datepicker  id="fieldset-document-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal Surat" size="sm" :model.sync="model.documentDate"  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button/>
							<app-input-textarea id="fieldset-description" label-cols-sm="4" label-cols-lg="3" label="Perihal/Ringkasan Surat" label-for="input-description" size="sm" :model.sync="model.description" :isRequired="true" :state="validateState('description')" :errorMessage="getErrorMessage(errorMessage.description, 'description')" />
						</b-card>
						<b-card class="mb-3">
							<app-input-datepicker v-if="false"  id="fieldset-contract-date" label-cols-sm="4" label-cols-lg="3" label="Masa Berlaku Kontrak" size="sm" :model.sync="model.contractDate"  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"  />
							<app-input-datepicker-range id="fieldset-contract-date" label-cols-sm="4" label-cols-lg="3" label="Masa Berlaku Kontrak" size="sm" :modelFrom.sync="model.contractDateFrom" :modelTo.sync="model.contractDateTo" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button />
							<app-input-textarea id="fieldset-contract-description" label-cols-sm="4" label-cols-lg="3" label="Catatan Atas Kontrak" label-for="input-contract-description" size="sm" :model.sync="model.contractDescription"   />
						</b-card>
						<b-card class="mb-3">
							<b-form-group v-if="false" id="fieldset-is-draft" label-cols-sm="4" label-cols-lg="3" label="Draft" label-for="input-is-draft">
								<b-form-checkbox id="input-is-draft" v-model="model.isDraft" ></b-form-checkbox>
							</b-form-group>
							<app-input-fileupload v-if="model.isDraft" label-cols-sm="4" label-cols-lg="3" label=" " size="sm" :model.sync="draftAttachment"  :maxFileSize="5" description="Ukuran maksimum file 5MB." :currentModel="model.draftAttachment"  />
							<b-form-group id="fieldset-is-lampiran" label-cols-sm="4" label-cols-lg="3" label="File Dokumen" label-for="input-is-lampiran">
								<b-form-checkbox id="input-is-lampiran" v-model="model.isLampiran" ></b-form-checkbox>
							</b-form-group>
							<b-form-group id="fieldset-is-received" label-cols-sm="4" label-cols-lg="3" v-if="model.isLampiran" label="File Dokumen" >
								<template v-slot:label>File Dokumen <span class="required"></span></template>
								<div>
									<b-input-group :prepend="model.documentAttachmentCount + ' Attachment(s)'">
										<b-input-group-append>
											<b-button variant="light" class="btn-upload" style="border: 1px #ddd solid" size="sm" @click="showUploadFile"><b-icon-upload /></b-button>
										</b-input-group-append>
									</b-input-group>
								</div>
							</b-form-group>
						</b-card>
					</div>
					<div v-if="isSuperUser">
						<b-card class="mb-3">
							<app-input-combobox-autocomplete :options="sifatSurats" :isRequired="true" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Sifat Surat" id="input-" size="sm" :model.sync="model.sifatSuratId" :state="validateState('sifatSuratId')" :errorMessage="getErrorMessage(errorMessage.documentTypeId, 'sifatSuratId')" :selected="model.sifatSurat != null ? model.sifatSurat.name : ''" @input="getSifatSurats" />
							<app-input-combobox-autocomplete :options="documentTypes" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Jenis Surat" id="input-" size="sm" :model.sync="model.documentTypeId"  :isRequired="true" :state="validateState('documentTypeId')" :errorMessage="getErrorMessage(errorMessage.documentTypeId, 'documentTypeId')" :selected="model.documentType != null ? model.documentType.name : ''" @input="getDocumentTypes" />
						</b-card>
						<b-card class="mb-3">
							<app-input-combobox-autocomplete-multi-select :isRequired="true" :state="validateState('checkerUsers')" :errorMessage="getErrorMessage(errorMessage.checkerUsers, 'checkerUsers')"  :options="checkerUserProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Pemeriksa Surat" id="input-" size="sm" :model.sync="model.checkerUsers" :selected="checkerUserDatas" @input="getCheckerUserProfiles" maxItem="2" />
						</b-card>
						<b-card class="mb-3">
							<app-input-combobox-autocomplete-multi-select :isRequired="true" :state="validateState('signerUsers')" :errorMessage="getErrorMessage(errorMessage.signerUsers, 'signerUsers')"  :options="signerUserProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Penandatangan Surat" id="input-" size="sm" :model.sync="model.signerUsers" :selected="signerUserDatas" @input="getSignerUserProfiles" />
						</b-card>
						<b-card class="mb-3">
							<b-form-group id="fieldset-is-reminder" label-cols-sm="4" label-cols-lg="3" label="Pengesahan Surat" label-for="input-pengesahan-surat" :state="pengesahanState" :invalid-feedback="!pengesahanState ? 'Pengesahan Surat is Required' : ''">
								<template v-slot:label>Pengesahan Surat<span class="required"></span></template>
								<b-form-checkbox id="input-is-tanda-tangan-asli" inline v-model="model.isTandaTanganAsli">Tanda Tangan Asli</b-form-checkbox>
								<b-form-checkbox id="input-is-paraf-asli" inline v-model="model.isParafAsli">Paraf Asli</b-form-checkbox>
								<b-form-checkbox id="input-is-stempel" inline v-model="model.isStempel">Stempel</b-form-checkbox>
								<b-form-checkbox id="input-is-tanda-tangan-digital" inline v-model="model.isTandaTanganDigital">Tanda Tangan Scan/Digital</b-form-checkbox>
								<b-form-checkbox id="input-is-paraf-digital" inline v-model="model.isParafDigital">Paraf Scan/Digital</b-form-checkbox>
								<b-form-checkbox id="input-is-materai" inline v-model="model.isMaterai">Materai</b-form-checkbox>
							</b-form-group>
						</b-card>
						<b-card class="mb-3">
							<b-form-group id="fieldset-is-reminder" label-cols-sm="4" label-cols-lg="3" label="Bentuk Distribusi" label-for="input-distribution-type" :state="distributionTypeState" :invalid-feedback="!distributionTypeState ? 'Bentuk distribusi is Required' : ''">
								<template v-slot:label>Bentuk Distribusi<span class="required"></span></template>
								<b-form-checkbox id="input-is-fisik" inline v-model="model.isFisik">Fisik</b-form-checkbox>
								<b-form-checkbox id="input-is-file" inline v-model="model.isFile">File</b-form-checkbox>
							</b-form-group>
						</b-card>
						<b-card class="mb-3">
							<b-form-group v-if="false" id="fieldset-is-draft" label-cols-sm="4" label-cols-lg="3" label="Draft" label-for="input-is-draft">
								<b-form-checkbox id="input-is-draft" v-model="model.isDraft" ></b-form-checkbox>
							</b-form-group>
							<app-input-fileupload v-if="model.isDraft" label-cols-sm="4" label-cols-lg="3" label=" " size="sm" :model.sync="draftAttachment"  :maxFileSize="5" description="Ukuran maksimum file 5MB." :currentModel="model.draftAttachment"  />
							<b-form-group id="fieldset-is-lampiran" label-cols-sm="4" label-cols-lg="3" label="File Dokumen" label-for="input-is-lampiran" :state="isLampiranState" :invalid-feedback="'File Dokumen is Required'">
								<template v-slot:label>File Dokumen<span class="required"></span></template>
								<b-form-checkbox id="input-is-lampiran" v-model="model.isLampiran" ></b-form-checkbox>
							</b-form-group>
							<app-input-fileupload v-if="false" label-cols-sm="4" label-cols-lg="3" label=" " size="sm" :maxFileSize="5" description="Ukuran maksimum file 5MB." :model.sync="lampiranAttachment"  :currentModel="model.lampiranAttachment"  />
							<b-form-group id="fieldset-is-received" label-cols-sm="4" label-cols-lg="3" v-if="model.isLampiran" label="File Dokumen" >
								<template v-slot:label>File Dokumen <span class="required"></span></template>
								<div>
									<b-input-group :prepend="model.documentAttachmentCount + ' Attachment(s)'" :invalid-feedback="'File Dokumen is Required'">
										<b-input-group-append>
											<b-button variant="light" class="btn-upload" style="border: 1px #ddd solid" size="sm" @click="showUploadFile"><b-icon-upload /></b-button>
										</b-input-group-append>
									</b-input-group>
								</div>
							</b-form-group>
						</b-card>
						<b-card class="mb-3" v-if="model.outgoingDocumentStatusId == 'SENT' ? true : false">
							<b-form-group id="fieldset-is-reminder" label-cols-sm="4" label-cols-lg="3" label="Reminder Arsip" label-for="input-distribution-type">
								<b-form-checkbox id="input-is-reminder" inline v-model="model.isReminder"></b-form-checkbox>
							</b-form-group>
							<app-input-datepicker-range v-if="model.isReminder" id="fieldset-reminder-date" :isMinActive="true" label-cols-sm="4" label-cols-lg="3" label="Tanggal Reminder" size="sm" :modelFrom.sync="model.reminderDateFrom" :modelTo.sync="model.reminderDateTo" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button />
							<app-input-textarea v-if="model.isReminder" id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Keterangan" label-for="input-remarks" size="sm" :model.sync="model.reminderRemarks"   />
						</b-card>
					</div>
					<b-card class="mb-3">
						<app-input-textarea maxlength="1000" id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Catatan" label-for="input-remarks" size="sm" :model.sync="model.remarks"   />
						<app-input-combobox-autocomplete v-if="false" :options="outgoingDocumentStatuss" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Status" id="input-" size="sm" :model.sync="model.outgoingDocumentStatusId"  :selected="model.outgoingDocumentStatus != null ? model.outgoingDocumentStatus.name : ''" @input="getOutgoingDocumentStatuss" />
					</b-card>
			</b-col>
		</b-row>
		<PopupConfirmationDetail :openPopup="isShowPopupConfirmation" :model="model" @saveActionMethod="saveAction" @resetMethod="resetModal" />
		<b-overlay :show="isBusy" no-wrap></b-overlay>
		<PopupDownloadDocument :allowUpdate="true" :openPopup="isShowPopupDocument" :parent="model" @resetMethod="resetModalUpload" />
	</PopupRouterView>
</template>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import OutgoingDocumentList from '@/models/OutgoingDocument/OutgoingDocumentList';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';

import PopupConfirmationDetail from './PopupConfirmationDetail';
import PopupDownloadDocument from './PopupDownloadDocument';

import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import SenderStatus from '@/models/SenderStatus/SenderStatus';
import SenderStatusFilter from '@/filters/SenderStatus/SenderStatusFilter';
import SifatSurat from '@/models/SifatSurat/SifatSurat';
import SifatSuratFilter from '@/filters/SifatSurat/SifatSuratFilter';
import DocumentType from '@/models/DocumentType/DocumentType';
import DocumentTypeFilter from '@/filters/DocumentType/DocumentTypeFilter';
import AppInputTextarea from '@/components/AppInputTextarea';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import AppInputFileupload from '@/components/AppInputFileupload';
import Attachment from '@/models/Core/Attachment';
import AttachmentFilter from '@/filters/Core/AttachmentFilter';
import OutgoingDocumentStatus from '@/models/OutgoingDocumentStatus/OutgoingDocumentStatus';
import OutgoingDocumentStatusFilter from '@/filters/OutgoingDocumentStatus/OutgoingDocumentStatusFilter';
import PopupRouterView from '@/components/PopupRouterView';
import AppInputComboboxAutocompleteMultiSelect from '@/components/AppInputComboboxAutocompleteMultiSelect.vue';
import { mapGetters } from 'vuex';
const pengesahanSurat = (value) => !helpers.req(value) || value.indexOf('cool') >= 0
export default {
	components : {PopupDownloadDocument,AppInputTextbox,AppInputComboboxAutocomplete,AppInputTextarea,AppInputDatepicker,AppInputDatepickerRange,AppInputFileupload,PopupRouterView,PopupConfirmationDetail,AppInputComboboxAutocompleteMultiSelect},
	mixins : [validationMixin],
	validations() {
		if (this.isSuperUser) {
			if (this.model.isSendToAll) {
				return {
					model : {
						//destinationUsers: {required},
						documentTypeId: {required},
						description: {required},
						sifatSuratId: {required},
						checkerUsers: {required},
						signerUsers: {required},
						isLampiran: {required}
					}
				};
			}
			return {
				model : {
					destinationUsers: {required},
					documentTypeId: {required},
					description: {required},
					sifatSuratId: {required},
					checkerUsers: {required},
					signerUsers: {required},
					isLampiran: {required}
				}
			};
		} else {
			if (this.model.isSendToAll) {
				return {
					model : {
						//destinationUsers: {required},
						description: {required},
					}
				};
			}
			return {
				model : {
					destinationUsers: {required},
					description: {required},
				}
			};
		}
		/*model: this.isValidateSuperUser ? this.validateSuperUser : this.validateUser
		/*{
			//applicantId: {required},
			//destinationUser: {required},
			//destinationStatusId: {required},
			destinationUsers: {required},
			documentTypeId: {required},
			documentDate: {required},
		}*/
	},
	data() {
		return {
			draftAttachment: null,
			lampiranAttachment: null,
			errorMessage : {
				//applicantId: { required : "Ditujukan Kepada is Required."},
				destinationUsers: { required : "Ditujukan Kepada is Required."},
				//destinationStatusId: { required : "Status Tujuan is Required."},
				documentTypeId: {required : "Jenis Surat is Required."},
				description: { required : "Perihal/Ringkasan Surat is Required."},
				sifatSuratId: {required : "Sifat Surat is Required."},
				checkerUsers: {required: "Pemeriksa Surat is Required"},
				signerUsers: {required: "Penanda Tangan is Required"},
				isLampiran: {required: "File Dokumen is Required"}
			},
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			modelId: null,
			model: {},
			filterOutgoingDocument : null,
			currentOutgoingDocumentPage : 1,
			outgoingDocumentPageSize: 10,
			sortByOutgoingDocument: '',
			sortDescOutgoingDocument: '',
			documentLocations: [],
			userProfiles: [],
			senderStatuss: [],
			outgoingDocumentStatuss: [],
			isShowPopupConfirmation : false,
			isDataCommited : false,
			mainRecordId : '',
			userProfile: {},
			sifatSurats: [],
			documentTypes: [],
			signerUserProfiles:[],
			checkerUserProfiles:[],
			copyUsers: [],
			isShowPopupDocument: false,
			pengesahanState: true,
			distributionTypeState: true,
			isLampiranState: true,
		}
	},
	methods : {
		showUploadFile() {
			this.isShowPopupDocument = true;
		},
		resetModalUpload() {
			this.getOutgoingDocumentAttachment();
			this.isShowPopupDocument = false;
		},
		getOutgoingDocumentAttachment : async function() {
			//this.isBusy = true;
			let id = this.modelId;
			OutgoingDocument.getData(this, id, '').then(result => { 
				this.model.documentAttachmentCount = result.documentAttachmentCount;
				//this.isBusy = false; 
			}).catch(error => { this.isBusy = false; });
		},
		getOutgoingDocument : async function(id) {
			this.isBusy = true;
			OutgoingDocument.getData(this, id, 'documentLocation,applicant,destinationStatus,draftAttachment,lampiranAttachment,outgoingDocumentStatus,createdByUserProfile,sifatSurat,documentType,outgoingDocumentCheckerUser,outgoingDocumentSignerUser,outgoingDocumentDestinationUser,outgoingDocumentCopyUser,outgoingDocumentAttachmentDetail').then(result => { this.model = result; this.mainRecordId = String(result.mainRecordId); this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		downloadData() {
			this.isDownloadOutgoingDocument = true;
			this.filterOutgoingDocument.id = this.modelId;
			OutgoingDocument.downloadDataDetail(this, this.filterOutgoingDocument).then(result => {
				this.isDownloadOutgoingDocument = false;
			}).catch(error => { this.isDownloadOutgoingDocument = false; })
		},
		getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentLocation.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			let copyUsers = this.model.copyUsers;
			if (copyUsers != null && copyUsers.length > 0) {
				let excludeIds = Array.prototype.map.call(copyUsers, function(item) { return item.id; }).join("|");
				filter["notinslash-id"] = excludeIds;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					if (result.data != null) {
						this.userProfiles = result.data;
					}
				}
			})
			.catch(error => {});
		},
		getCopyUsers : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			let destinationUsers = this.model.destinationUsers;
			if (destinationUsers != null && destinationUsers.length > 0) {
				let excludeIds = Array.prototype.map.call(destinationUsers, function(item) { return item.id; }).join("|");
				filter["notinslash-id"] = excludeIds;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					if (result.data != null) {
						this.copyUsers = result.data;
					}
				}
			})
			.catch(error => {});
		},
		getUserLoginProfile : async function() {
			UserProfile.userProfileLogin(this)
			.then(result => {
				if (result != null) {
					this.userProfile = result;
				}
			})
			.catch(error => {});
		},
		getSenderStatuss : async function(input) {
			let filter = new SenderStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			SenderStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.senderStatuss = result.data
				}
			})
			.catch(error => {});
		},
		getOutgoingDocumentStatuss : async function(input) {
			let filter = new OutgoingDocumentStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			OutgoingDocumentStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.outgoingDocumentStatuss = result.data
				}
			})
			.catch(error => {});
		},
		getSifatSurats : async function(input) {
			let filter = new SifatSuratFilter();
			if (input != undefined) {
				filter.name = input;
			}
			SifatSurat.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.sifatSurats = result.data
				}
			})
			.catch(error => {});
		},
		getDocumentTypes : async function(input) {
			let filter = new DocumentTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentTypes = result.data
				}
			})
			.catch(error => {});
		},
		getCheckerUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					if (result.data != null) {
						this.checkerUserProfiles = result.data;
					}
				}
			})
			.catch(error => {});
		},
		getSignerUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					if (result.data != null) {
						this.signerUserProfiles = result.data;
					}
				}
			})
			.catch(error => {});
		},
		validateState(name) {
			if (!this.$v.model[name]) return null;
			const { $dirty, $error } = this.$v.model[name];
			return $dirty ? !$error : null;
		},
		getErrorMessage(message, name) {
			if (!this.$v.model[name]) return "";
			if (this.$v.model[name].required != undefined && !this.$v.model[name].required) {
				if (message.required) return message.required;
			}
		},
		saveActionConfirmation(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isShowPopupConfirmation = true;
			}
		},
		validateCheckbox() {
			this.pengesahanState = true;
			this.distributionTypeState = true;
			this.isLampiranState = true;

			if (this.isSuperUser) {
				if (!this.model.isTandaTanganAsli &&
					!this.model.isParafAsli &&
					!this.model.isStempel &&
					!this.model.isTandaTanganDigital &&
					!this.model.isParafDigital &&
					!this.model.isMaterai) {
						this.pengesahanState = false;
				}
				if (!this.model.isFile &&
				!this.model.isFisik) {
					this.distributionTypeState = false;
				}
				
				if (!this.model.isLampiran) {
					this.isLampiranState = false;
				}
				
				if (this.model.documentAttachmentCount <= 0) {
					this.isLampiranState = false;
				}
			}

			return this.pengesahanState && this.distributionTypeState && this.isLampiranState;
		},
		saveAction(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch();
			if (!this.validateCheckbox()) {
				return;
			}
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isBusy = true;
				let id = this.modelId;
				let model = this.model;
				model.contractDate = model.contractDateFrom;
				let promises = Array();

				if (this.draftAttachment != null) {
					let data = new FormData();
					data.append('file', this.draftAttachment);
					let _this = this;
					promises.push(this.$http.post(process.env.VUE_APP_API_URL + 'attachment/upload',
						data,
						{ headers: { 'Content-Type': 'multipart/form-data' } }
						).then(async function(response){
							if (response != null) {
								if (response.data != null) {
									model.draftAttachmentId = response.data.data.id;
									model.draftAttachment = response.data.data;;
								}
							}
							return true;
						})
						.catch(error => {
							this.$store.dispatch('addErrorMessageGlobal', error);
							throw error;
						}));
				}

				if (this.lampiranAttachment != null) {
					let data = new FormData();
					data.append('file', this.lampiranAttachment);
					let _this = this;
					promises.push(this.$http.post(process.env.VUE_APP_API_URL + 'attachment/upload',
						data,
						{ headers: { 'Content-Type': 'multipart/form-data' } }
						).then(async function(response){
							if (response != null) {
								if (response.data != null) {
									model.lampiranAttachmentId = response.data.data.id;
									model.lampiranAttachment = response.data.data;
								}
							}
							return true;
						})
						.catch(error => {
							this.$store.dispatch('addErrorMessageGlobal', error);
							throw error;
						}));
				}
				model.isInternal = true;
				Promise.all(promises).then((results) => {
					if (id != '' && id != null && id != undefined) {
						OutgoingDocument.updateData(this, model, id).then(result => {
							this.isDataCommited = true;
							if (result) {
								this.$store.dispatch('addSuccessMessageGlobal', "Data Berhasil disimpan");
								this.$router.push({ name: 'outgoingdocumentindex' });
							}
							this.isBusy = false;
						}).catch(error => { this.isBusy = false; });
					} else {
						OutgoingDocument.insertData(this, model).then(result => {
							if (result) {
								this.$store.dispatch('addSuccessMessageGlobal', "Data Berhasil disimpan");
								this.$router.push({ name: 'outgoingdocumentindex' });
							}
							this.isBusy = false;
						}).catch(error => { this.isBusy = false; });
					}
				});
			}
		},
		onHidden() {
			this.getOutgoingDocuments();
		},
		resetModal(modalType) {
			this.showPopupCopyData = false; 
			this.isShowPopupConfirmation = false;
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		changeApplicant(selectedValue){
			if (selectedValue) {
				this.model.applicantId = selectedValue.id;
				this.model.applicant = selectedValue;
			} else {
				this.model.applicantId = "";
				this.model.applicant = {};
			}
		},
		changeDestinationStatus(selectedValue) {
			if (selectedValue) {
				this.model.destinationStatusId = selectedValue.id;
				this.model.destinationStatus = selectedValue;
			} else {
				this.model.destinationStatusId = "";
				this.model.destinationStatus = {};
			}
		}
	},
	beforeMount() {
		this.filterOutgoingDocument = new OutgoingDocumentFilter();
		this.getDocumentLocations();
		this.getUserProfiles();
		this.getCopyUsers();
		this.getSenderStatuss();
		this.getOutgoingDocumentStatuss();
		this.getSifatSurats();
		this.getDocumentTypes();
	},
	async mounted(){
		let id = this.$route.params.id;
		let copyData = this.$route.params.copydata;
		if (id != '' && id != null && id != undefined) {
			if (copyData != null && copyData != undefined) {
				this.getOutgoingDocument(id);
			} else {
				this.modelId = id;
				this.getOutgoingDocument(id);
			}
		} else {
			this.model.createdAt = new Date();
			this.getUserLoginProfile();
		}
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	computed: {
		...mapGetters({
			isSuperUser: 'isSuperUser',
			user: 'user',
			userId: 'userId',
		}),
		checkerUserDatas() {
			let $datas = new Array();
			let $ids = new Array();
			if (this.model.outgoingDocumentCheckerUser != null) {
				this.model.outgoingDocumentCheckerUser.forEach(item => {
					$datas.push(item.userProfile);
					$ids.push(item.userProfileId);
				})
			}
			return $datas;
		},
		signerUserDatas() {
			let $datas = new Array();
			let $ids = new Array();
			if (this.model.outgoingDocumentSignerUser != null) {
				this.model.outgoingDocumentSignerUser.forEach(item => {
					$datas.push(item.userProfile);
					$ids.push(item.userProfileId);
				})
			}
			return $datas;
		},
		destinationUserDatas() {
			let $datas = new Array();
			let $ids = new Array();
			if (this.model.outgoingDocumentDestinationUser != null) {
				this.model.outgoingDocumentDestinationUser.forEach(item => {
					$datas.push(item.destinationUser);
					$ids.push(item.destinationUserId);
				})
			}
			return $datas;
		},
		copyUserDatas() {
			let $datas = new Array();
			let $ids = new Array();
			if (this.model.outgoingDocumentCopyUser != null) {
				this.model.outgoingDocumentCopyUser.forEach(item => {
					$datas.push(item.copyUser);
					$ids.push(item.copyUserId);
				})
			}
			return $datas;
		}
	},
	watch : {
	},
	beforeDestroy() {
	},
}
</script>
