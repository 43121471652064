import Disposition from '@/models/Disposition/Disposition';

export default class DispositionFilter {

	constructor() {
	/*APP_FILTER_DEFINITION*/
		this.id = '';
		this.incomingDocumentId  = '';
		this.incomingDocumentDocumentCode = '';
		this.userReceiverId  = '';
		this.userReceiverFullName = '';
		this.remarksMultiple = '';
		this.draftFromUpload = '';
		this.draftMode = '';
	/*END_APP_FILTER_DEFINITION*/
		this.exactType = {
	/*APP_APP_FILTER_CRITERIA*/
			id : '0',
			incomingDocumentId : '0',
			incomingDocumentDocumentCode : '0',
			userReceiverId : '0',
			userReceiverFullName : '0',
			remarksMultiple : '0',
	/*END_APP_FILTER_CRITERIA*/
		};
	}

}