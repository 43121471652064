<template>
	<PopupRouterViewDetail label="Incoming Document Detail" @copyDataMethod="copyDataAction">
<!-- APP_DETAIL_HEADER -->
		<b-row>
			<b-col cols="12" lg="6">
				<b-card>
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" label-for="filter-document-code">
						{{ model != null ? model.documentCode : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Lokasi Surat" label-for="filter-document-location">
						{{ model != null ? ( model.documentLocation != null ? model.documentLocation.name : "") : "" }}
					</b-form-group>
					<b-form-group v-if="false" id="fieldset-document-no" label-cols-sm="4" label-cols-lg="3" label="Nama Pemohon" label-for="filter-document-no">
						{{ model != null ? (model.createdBy != null ? model.createdBy.fullName : "") : "" }}
					</b-form-group>
					
				</b-card>
				<b-card class="mt-3">
					<b-form-group id="fieldset-sender-name" label-cols-sm="4" label-cols-lg="3" label="Nama Pengirim" label-for="filter-sender-name">
						{{ model != null ? model.senderName : "" }}
					</b-form-group>
					<b-form-group id="fieldset-sender-status" label-cols-sm="4" label-cols-lg="3" label="Status Pengirim" label-for="filter-sender-status">
						{{ model != null ? ( model.senderStatus != null ? model.senderStatus.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-contact-person" label-cols-sm="4" label-cols-lg="3" label="Contact Person (CP)" label-for="filter-contact-person">
						{{ model != null ? model.contactPerson : "" }}
					</b-form-group>
					<b-form-group id="fieldset-sender-address" label-cols-sm="4" label-cols-lg="3" label="Alamat Pengirim" label-for="filter-sender-address">
						{{ model != null ? model.senderAddress : "" }}
					</b-form-group>
					<b-form-group id="fieldset-sender-email" label-cols-sm="4" label-cols-lg="3" label="E-Mail Pengirim/CP" label-for="filter-sender-email">
						{{ model != null ? model.senderEmail : "" }}
					</b-form-group>
					<b-form-group id="fieldset-sender-phone-number" label-cols-sm="4" label-cols-lg="3" label="No. Telepon" label-for="filter-sender-phone-number">
						{{ model != null ? model.senderPhoneNumber : "" }}
					</b-form-group>
				</b-card>
				<b-card class="mt-3">
					<b-form-group id="fieldset-destination-user" label-cols-sm="4" label-cols-lg="3" label="Ditujukan Kepada" label-for="filter-destination-user">
						<span v-if="model.isSendToAll">
							Semua Karyawan
						</span>
						<span v-else>
							<b-badge variant="secondary" v-for="item in model.incomingDocumentDestinationUser" :key="item.id" class="mr-2" style="font-size:13px; font-weight: 400;background-color:#bbb">
								{{ item.destinationUser ? item.destinationUser.fullName : "-" }}
							</b-badge>
						</span>
					</b-form-group>
				</b-card>
				<b-card class="mt-3" v-if="false">
					<b-form-group id="fieldset-destination-user" label-cols-sm="4" label-cols-lg="3" label="Ditujukan Kepada" label-for="filter-destination-user">
						{{ model != null ? ( model.destinationUser != null ? model.destinationUser.fullName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Jabatan Pegawai" label-for="filter-document-code">
						{{ model != null ? (model.destinationUser != null ? model.destinationUser.jobDescription : '-') : "-" }}
					</b-form-group>
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Email" label-for="filter-document-code">
						{{ model != null ? (model.destinationUser != null ? model.destinationUser.email : '-') : "-" }}
					</b-form-group>
				</b-card>
				<b-card class="mt-3">
					<b-form-group id="fieldset-copy-user" label-cols-sm="4" label-cols-lg="3" label="Ditembuskan Kepada" label-for="filter-copy-user">
						<b-badge variant="secondary" v-for="item in model.incomingDocumentCopyUser" :key="item.id" class="mr-2" style="font-size:13px; font-weight: 400;background-color:#bbb">
							{{ item.copyUser ? item.copyUser.fullName : "-" }}
						</b-badge>
					</b-form-group>
				</b-card>
				<b-card class="mt-3" v-if="false">
					<b-form-group id="fieldset-copy-user" label-cols-sm="4" label-cols-lg="3" label="Ditembuskan Kepada" label-for="filter-copy-user">
						{{ model != null ? ( model.copyUser != null ? model.copyUser.fullName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Jabatan Pegawai" label-for="filter-document-code">
						{{ model != null ? (model.copyUser != null ? model.copyUser.jobDescription : '-') : "-" }}
					</b-form-group>
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Email" label-for="filter-document-code">
						{{ model != null ? (model.copyUser != null ? model.copyUser.email : '-') : "-" }}
					</b-form-group>
				</b-card>
			</b-col>
			<b-col cols="12" lg="6">
				<b-card class="mt-lg-3">
					<b-form-group id="fieldset-sifat-surat" label-cols-sm="4" label-cols-lg="3" label="Sifat Surat" label-for="filter-sifat-surat">
						{{ model != null ? ( model.sifatSurat != null ? model.sifatSurat.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-type" label-cols-sm="4" label-cols-lg="3" label="Jenis Surat/Barang" label-for="filter-document-type">
						{{ model != null ? ( model.documentType != null ? model.documentType.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-no" v-if="model != null ? model.documentNo != null && model.documentNo != '' ? true : false : false" label-cols-sm="4" label-cols-lg="3" label="Nomor Surat Masuk" label-for="filter-document-no">
						{{ model != null ? model.documentNo : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-date" v-if="model != null ? model.documentDate != null && model.documentDate != '' ? true : false : false" label-cols-sm="4" label-cols-lg="3" label="Tanggal Surat" label-for="filter-document-date">
						{{ (model != null ? model.documentDate : "") | moment("DD-MMM-YYYY") }}
					</b-form-group>
					<b-form-group id="fieldset-description" label-cols-sm="4" label-cols-lg="3" label="Ringkasan Surat" label-for="filter-description">
						{{ model != null ? model.description : "" }}
					</b-form-group>
					<b-form-group v-if="model.outgoingDocument != null" id="fieldset-reference-no" label-cols-sm="4" label-cols-lg="3" label="Nomor Referensi" label-for="filter-reference-no">
						{{ model != null ? model.outgoingDocumentDocumentCode : "" }}
					</b-form-group>
				</b-card>
				<b-card class="mt-3">
					<b-form-group id="fieldset-contract-date" label-cols-sm="4" label-cols-lg="3" label="Masa Berlaku Kontrak" label-for="filter-contract-date">
						{{ (model != null ? model.contractDateFrom : "") | moment("DD-MMM-YYYY") }}{{ (model.contractDateFrom != null && model.contractDateFrom != "") && (model.contractDateTo != null && model.contractDateTo != "") ? " - " : "" }}{{ (model != null ? model.contractDateTo : "") | moment("DD-MMM-YYYY") }}
					</b-form-group>
					<b-form-group id="fieldset-contract-description" label-cols-sm="4" label-cols-lg="3" label="Catatan Atas Kontrak" label-for="filter-contract-description">
						{{ model != null ? model.contractDescription : "" }}
					</b-form-group>
				</b-card>
				<b-card class="mt-3">
					<b-form-group v-if="model.isFile" id="fieldset-distribution-type" label-cols-sm="4" label-cols-lg="3" label="Bentuk Distribusi" label-for="filter-distribution-type">
						{{ model != null ? ( model.isFile != null && model.isFile  ? "File" : "") : "" }}
					</b-form-group>
					<b-form-group v-if="false" id="fieldset-document-attachment" label-cols-sm="4" label-cols-lg="3" label="File Dokumen" label-for="filter-document-attachment">
						<app-label-fileupload :isEditable="false" label-cols-sm="0" label-cols-lg="0" label="" size="sm" :currentModel="model.documentAttachment"  />
					</b-form-group>
					<b-form-group id="fieldset-is-received" label-cols-sm="4" label-cols-lg="3" v-if="model.isFile" label="File Dokumen" >
						<template v-slot:label>File Dokumen</template>
						<div>
							<b-input-group :prepend="model.documentAttachmentCount + ' Attachment(s)'">
								<b-input-group-append>
									<b-button variant="light" class="btn-upload" style="border: 1px #ddd solid" size="sm" @click="showPopupFile"><b-icon-paperclip /></b-button>
								</b-input-group-append>
							</b-input-group>
						</div>
					</b-form-group>
					<b-form-group v-if="model.isFisik" id="fieldset-distribution-type" label-cols-sm="4" label-cols-lg="3" label="Bentuk Distribusi" label-for="filter-distribution-type">
						{{ model != null ? ( model.isFisik != null && model.isFisik  ? "Fisik" : "") : "" }}
					</b-form-group>	
					<b-form-group v-if="model.isFisik" id="fieldset-receipt-document" label-cols-sm="4" label-cols-lg="3" label="No. Tanda Terima Surat" label-for="filter-receipt-document">
						{{ model != null ? (model.receiptDocument != null ? model.receiptDocument.receiptDocumentNo : "") : "" }}
						<b-button variant="secondary" class="ml-1" v-if="model.isFisik && (model.receiptDocument != null && model.receiptDocument.id != null)" size="sm" @click="printReceiptDocument"><b-icon-printer class="mr-1"></b-icon-printer> Cetak Surat</b-button>
					</b-form-group>
				</b-card>
				<b-card class="mt-3">
					<b-form-group id="fieldset-is-reminder" label-cols-sm="4" label-cols-lg="3" label="Reminder Kegiatan" label-for="filter-is-reminder">
						{{ model != null ? (model.isReminder ? "Ya" : "Tidak") : "Tidak" }}
					</b-form-group>
					<b-form-group v-if="model.isReminder" id="fieldset-reminder-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal / Waktu Kegiatan" label-for="filter-reminder-date">
						{{ (model != null ? model.reminderDate : "") | moment("DD-MMM-YYYY HH:mm") }}
					</b-form-group>
					<b-form-group v-if="model.isReminder" id="fieldset-location" label-cols-sm="4" label-cols-lg="3" label="Lokasi" label-for="filter-location">
						{{ model != null ? model.location : "" }}
					</b-form-group>
				</b-card>
				<b-card class="mt-3">
					<b-form-group id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Catatan" label-for="filter-remarks">
						{{ model != null ? model.remarks : "" }}
					</b-form-group>
					<b-form-group v-if="false" id="fieldset-document-status" label-cols-sm="4" label-cols-lg="3" label="DocumentStatus" label-for="filter-document-status">
						{{ model != null ? ( model.documentStatus != null ? model.documentStatus.name : "") : "" }}
					</b-form-group>
					<b-form-group v-if="false" id="fieldset-copy-document-status" label-cols-sm="4" label-cols-lg="3" label="CopyDocumentStatus" label-for="filter-copy-document-status">
						{{ model != null ? ( model.copyDocumentStatus != null ? model.copyDocumentStatus.name : "") : "" }}
					</b-form-group>
				</b-card>
			</b-col>
		</b-row>
<!-- END_APP_DETAIL_HEADER -->
		<b-overlay :show="isBusy" no-wrap></b-overlay>
		<PopupPrintReceiptDocument :openPopup="isShowPopupPrint" @saveActionMethod="resetModal" @resetMethod="resetModal" :receiptDocumentId="model.receiptDocument != null ? model.receiptDocument.id : null" />
		<PopupDownloadDocument :openPopup="isShowPopupDocument" :parent="model" @resetMethod="resetModalUpload" />
	</PopupRouterViewDetail>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import IncomingDocumentList from '@/models/IncomingDocument/IncomingDocumentList';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import PopupPrintReceiptDocument from './PopupPrintReceiptDocument';
import PopupDownloadDocument from './PopupDownloadDocument';

import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
import AppLabelFileupload from '@/components/AppLabelFileupload';
/*END_APP_DETAIL_IMPORT*/
export default {
	components : {
	/*APP_DETAIL_PROP_DEFINITION*/
	PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,AppLabelFileupload,PopupPrintReceiptDocument,PopupDownloadDocument
	/*APP_DETAIL_COMPONENTS_DEFINITION*/
},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			model: {},
			isShowPopupPrint: false,
			/*APP_DETAIL_PAGINATION*/
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterIncomingDocument : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentIncomingDocumentPage : 1,
			incomingDocumentPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByIncomingDocument: '',
			sortDescIncomingDocument: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			/*END_APP_DETAIL_OBJ_VARIABLE*/
			isShowPopupDocument: false,
		}
	},
	methods : {
		showPopupFile() {
			this.isShowPopupDocument = true;
		},
		resetModalUpload() {
			this.getIncomingDocumentAttachment();
			this.isShowPopupDocument = false;
		},
		getIncomingDocumentAttachment : async function() {
			//this.isBusy = true;
			let id = this.modelId;
			IncomingDocument.getData(this, id, '').then(result => { 
				this.model.documentAttachmentCount = result.documentAttachmentCount;
				//this.isBusy = false; 
			}).catch(error => { this.isBusy = false; });
		},
		/*APP_DETAIL_GETIncomingDocument*/
		getIncomingDocument : async function(id) {
			this.isBusy = true;
			IncomingDocument.getData(this, id, 'documentLocation,senderStatus,userProfile,destinationUser,copyUser,sifatSurat,documentType,documentAttachment,distributionType,incomingDocumentStatus,copyDocumentStatus,documentStatus,receiptDocument,incomingDocumentDestinationUser,incomingDocumentCopyUser,createdBy,outgoingDocument').then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETIncomingDocument*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'incomingdocumentcopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
		printReceiptDocument() {
			this.isShowPopupPrint = true;
		},
		resetModal(modalType) {
			this.isShowPopupPrint = false;
		},
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterIncomingDocument = new IncomingDocumentFilter();
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	/*APP_DETAIL_MOUNTED*/
	mounted(){
		let id = this.$route.params.id;
		this.modelId = id;
		this.getIncomingDocument(id);
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	/*END_APP_DETAIL_MOUNTED*/
	watch : {
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
