import axios from 'axios'
import { setHeaderToken, removeHeaderToken } from '../utils/auth'
import { domain  } from '../../auth_config.json'

export default {
    state: {
        user: null,
        userId: null,
        isLoggedIn: false,
        accessToken: null,
        isSuperUser: false,
    },
    mutations: {
        set_user(state, data) {
            state.user = data.user;
            state.userId = data.id;
            state.userName = data.username;
            state.accessToken = data.token;
            state.isLoggedIn = true;
            state.isSuperUser = data.isSuperUser
        },
        set_userinfo(state, data) {
            state.userId = data.id;
            state.user = data.fullName;
            state.userName = data.userName;
            state.isSuperUser = data.isSuperUser
        },
        reset_user(state) {
            state.userId = null;
            state.user = null;
            state.userName = null;
            state.accessToken = null;
            state.isLoggedIn = false;
            state.isSuperUser = false
        }
    },
    getters: {
        isLoggedIn(state) {
            return state.isLoggedIn
        },
        isSuperUser(state) {
            return state.isSuperUser
        },
        user(state) {
            return state.user
        },
        userId(state) {
            return state.userId
        },
        accessToken(state) {
            return state.accessToken
        }
    },
    actions: {
        login({ dispatch, commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_API_URL + `login`, data)
                    .then(response => {
                        console.log(response);
                        const token = response.data.access_token;
                        localStorage.setItem('access_token', token);
                        setHeaderToken(token);
                        let userdata = {
                            user: '',
                            token: localStorage.getItem('access_token')
                        };
                        commit('set_user', userdata);
                        dispatch('get_user');
                        resolve(response);
                    })
                    .catch(error => {
                        console.log(error);
                        commit('reset_user');
                        localStorage.removeItem('access_token');
                        reject(error);
                    });
            });
        },
        async get_userinfo({commit}) {
            if(!localStorage.getItem('access_token')) {
                return;
            }

            try {
                let response = await axios.get(process.env.VUE_APP_API_URL + `UserInfo/Me`);
                localStorage.setItem('user_name', response.data.data.userName);
                commit('set_userinfo', response.data.data);
            } catch(error) {
                commit('reset_user');
                removeHeaderToken();
                localStorage.removeItem('access_token');
                return error;
            }
        },
        async get_user({commit}) {
            if(!localStorage.getItem('access_token')) {
                return;
            }

            try {
                let userdata = {
                    user: '',
                    username: '',
                    token: localStorage.getItem('access_token')
                };
                commit('set_user', userdata);
            } catch(error) {
                commit('reset_user');
                removeHeaderToken();
                localStorage.removeItem('access_token');
                return error;
            }
        },
        logout({commit}) {
            return new Promise((resolve) => {
                // http://10.85.44.47/Auth/Logout
                // axios.get(domain + `/Auth/Logout`)
                //     .then(response => {
                //         commit('reset_user');
                //         localStorage.removeItem('access_token');
                //         removeHeaderToken();
                //         resolve(response);
                //     }).catch(error => {
                //         console.log(error);
                //         reject(error);
                //     });
                commit('reset_user');
                localStorage.removeItem('access_token');
                localStorage.removeItem('user_name');
                removeHeaderToken();
                resolve();
            });
        }
    }
}