<template>
	<PopupRouterViewDetail label="Receipt Document Detail">
<!-- APP_DETAIL_HEADER -->
		<b-card>
			<b-col cols="12" lg="6">
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" label-for="filter-document-code">
						{{ model != null ? model.documentCode : "" }}
					</b-form-group>
					<b-form-group id="fieldset-receipt-document-no" label-cols-sm="4" label-cols-lg="3" label="No. Tanda Terima Surat" label-for="filter-receipt-document-no">
						{{ model != null ? model.receiptDocumentNo : "" }}
					</b-form-group>
					<b-form-group id="fieldset-delivery-user" label-cols-sm="4" label-cols-lg="3" label="Nama Penyerah Surat" label-for="filter-delivery-user">
						{{ model != null ? ( model.deliveryUser != null ? model.deliveryUser.fullName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Jabatan" label-for="filter-document-code">
						{{ model != null ? (model.deliveryUser != null ? model.deliveryUser.jobDescription : '-') : "-" }}
					</b-form-group>
					<b-form-group id="fieldset-receipt-user" label-cols-sm="4" label-cols-lg="3" label="Nama Penerima Surat" label-for="filter-receipt-user">
						{{ model != null ? ( model.receiptUser != null ? model.receiptUser.fullName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Jabatan" label-for="filter-document-code">
						{{ model != null ? (model.receiptUser != null ? model.receiptUser.jobDescription : '-') : "-" }}
					</b-form-group>
					<b-form-group id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Catatan" label-for="filter-remarks">
						{{ model != null ? model.remarks : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-created-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal Penyerah" label-for="filter-document-created-date">
						{{ (model != null ? model.documentCreatedDate : "") | moment("DD-MMM-YYYY") }}
					</b-form-group>
					<b-form-group id="fieldset-document-created-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal Penerima" label-for="filter-document-created-date">
						{{ (model != null ? model.receivedDate : "") | moment("DD-MMM-YYYY") }}
					</b-form-group>
					<b-form-group id="fieldset-document-created-date" label-cols-sm="4" label-cols-lg="3" label="Status" label-for="filter-document-created-date">
						{{ (model != null ? model.isReceived ? "Sudah Diterima" : "Belum Diterima" : "") | moment("DD-MMM-YYYY") }}
					</b-form-group>

					<span v-if="false">
						<b-form-group id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Lokasi Surat" label-for="filter-document-location">
							{{ model != null ? ( model.documentLocation != null ? model.documentLocation.name : "") : "" }}
						</b-form-group>
						<b-form-group id="fieldset-sender-name" label-cols-sm="4" label-cols-lg="3" label="Nama Pengirim" label-for="filter-sender-name">
							{{ model != null ? model.senderName : "" }}
						</b-form-group>
						<b-form-group id="fieldset-destination-user" label-cols-sm="4" label-cols-lg="3" label="Ditujukan Kepada" label-for="filter-destination-user">
							<span v-if="model.isSendToAll">
								Semua Karyawan
							</span>
							<span v-else>
								<span v-for="item, index in model.incomingDocumentDestinationUser" :key="item.id">
									{{ item.destinationUser ? item.destinationUser.fullName : "-" }}<span v-if="index < (model.incomingDocumentDestinationUser.length - 1)">, </span>
								</span>
							</span>
						</b-form-group>
						<b-form-group id="fieldset-document-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal Surat" label-for="filter-document-date">
							{{ (model != null ? model.documentDate : "") | moment("DD-MMM-YYYY") }}
						</b-form-group>
						<b-form-group id="fieldset-document-type" label-cols-sm="4" label-cols-lg="3" label="Jenis Surat/Barang" label-for="filter-document-type">
							{{ model != null ? ( model.documentType != null ? model.documentType.name : "") : "" }}
						</b-form-group>
						<b-form-group id="fieldset-document-no" label-cols-sm="4" label-cols-lg="3" label="Nomor Surat" label-for="filter-document-no">
							{{ model != null ? model.documentNo : "" }}
						</b-form-group>
						<b-form-group id="fieldset-document-description" label-cols-sm="4" label-cols-lg="3" label="Ringkasan Surat" label-for="filter-document-description">
							{{ model != null ? model.documentDescription : "" }}
						</b-form-group>
						
						<b-form-group id="fieldset-incoming-document" label-cols-sm="4" label-cols-lg="3" label="Incoming Document" label-for="filter-incoming-document">
							{{ model != null ? ( model.incomingDocument != null ? model.incomingDocument.documentCode : "") : "" }}
						</b-form-group>
						<b-form-group id="fieldset-outgoing-document" label-cols-sm="4" label-cols-lg="3" label="OutgoingDocument" label-for="filter-outgoing-document">
							{{ model != null ? model.outgoingDocument : "" }}
						</b-form-group>
					</span>
			</b-col>
		</b-card>
<!-- END_APP_DETAIL_HEADER -->
		<b-overlay :show="isBusy" no-wrap></b-overlay>
		<template #modal-left-button>
			<b-button variant="primary" v-if="false" class="float-left ml-2" @click="printPage" size="sm"><b-icon-printer class="mr-1"></b-icon-printer> Print</b-button>
		</template>
	</PopupRouterViewDetail>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import ReceiptDocument from '@/models/ReceiptDocument/ReceiptDocument';
import ReceiptDocumentList from '@/models/ReceiptDocument/ReceiptDocumentList';
import ReceiptDocumentFilter from '@/filters/ReceiptDocument/ReceiptDocumentFilter';

import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
/*END_APP_DETAIL_IMPORT*/
export default {
	components : {
	/*APP_DETAIL_PROP_DEFINITION*/
	PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,
	/*APP_DETAIL_COMPONENTS_DEFINITION*/
},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			model: {},
			/*APP_DETAIL_PAGINATION*/
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterReceiptDocument : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentReceiptDocumentPage : 1,
			receiptDocumentPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByReceiptDocument: '',
			sortDescReceiptDocument: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			/*END_APP_DETAIL_OBJ_VARIABLE*/
		}
	},
	methods : {
		printPage() {
			window.open('/receiptdocument/print/' + this.modelId, '_blank');
		},
		/*APP_DETAIL_GETReceiptDocument*/
		getReceiptDocument : async function(id) {
			this.isBusy = true;
			ReceiptDocument.getData(this, id, 'documentLocation,deliveryUser,documentType,destinationUser,receiptUser,incomingDocument,incomingDocumentDestinationUser').then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETReceiptDocument*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'receiptdocumentcopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterReceiptDocument = new ReceiptDocumentFilter();
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	/*APP_DETAIL_MOUNTED*/
	mounted(){
		let id = this.$route.params.id;
		this.modelId = id;
		this.getReceiptDocument(id);
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	/*END_APP_DETAIL_MOUNTED*/
	watch : {
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
