<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true @ok="saveButtonAction" title="Save Confirmation - Incoming Document">
<!-- APP_DETAIL_HEADER -->
		<b-card>
			<b-col cols="12" lg="6">
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" label-for="filter-document-code">
						{{ model != null ? model.documentCode : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Lokasi Surat" label-for="filter-document-location">
						{{ model != null ? ( model.documentLocation != null ? model.documentLocation.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-sender-name" label-cols-sm="4" label-cols-lg="3" label="Nama Pengirim" label-for="filter-sender-name">
						{{ model != null ? model.senderName : "" }}
					</b-form-group>
					<b-form-group id="fieldset-sender-status" label-cols-sm="4" label-cols-lg="3" label="Status Pengirim" label-for="filter-sender-status">
						{{ model != null ? ( model.senderStatus != null ? model.senderStatus.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-contact-person" label-cols-sm="4" label-cols-lg="3" label="Contact Person (CP)" label-for="filter-contact-person">
						{{ model != null ? model.contactPerson : "" }}
					</b-form-group>
					<b-form-group id="fieldset-sender-address" label-cols-sm="4" label-cols-lg="3" label="Alamat Pengirim" label-for="filter-sender-address">
						{{ model != null ? model.senderAddress : "" }}
					</b-form-group>
					<b-form-group id="fieldset-sender-email" label-cols-sm="4" label-cols-lg="3" label="E-Mail Pengirim/CP" label-for="filter-sender-email">
						{{ model != null ? model.senderEmail : "" }}
					</b-form-group>
					<b-form-group id="fieldset-sender-phone-number" label-cols-sm="4" label-cols-lg="3" label="No. Telepon" label-for="filter-sender-phone-number">
						{{ model != null ? model.senderPhoneNumber : "" }}
					</b-form-group>
					<b-form-group id="fieldset-destination-user" label-cols-sm="4" label-cols-lg="3" label="Ditujukan Kepada" label-for="filter-destination-user">
						{{ model != null ? ( model.destinationUser != null ? model.destinationUser.fullName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-copy-user" label-cols-sm="4" label-cols-lg="3" label="Ditembuskan Kepada" label-for="filter-copy-user">
						{{ model != null ? ( model.copyUser != null ? model.copyUser.fullName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-sifat-surat" label-cols-sm="4" label-cols-lg="3" label="Sifat Surat" label-for="filter-sifat-surat">
						{{ model != null ? ( model.sifatSurat != null ? model.sifatSurat.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-type" label-cols-sm="4" label-cols-lg="3" label="Jenis Surat/Barang" label-for="filter-document-type">
						{{ model != null ? ( model.documentType != null ? model.documentType.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-no" label-cols-sm="4" label-cols-lg="3" label="Nomor Surat" label-for="filter-document-no">
						{{ model != null ? model.documentNo : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal Surat" label-for="filter-document-date">
						{{ (model != null ? model.documentDate : "") | moment("DD-MMM-YYYY") }}
					</b-form-group>
					<b-form-group id="fieldset-description" label-cols-sm="4" label-cols-lg="3" label="Ringkasan Surat" label-for="filter-description">
						{{ model != null ? model.description : "" }}
					</b-form-group>
					<b-form-group id="fieldset-contract-date" label-cols-sm="4" label-cols-lg="3" label="Masa Berlaku Kontrak" label-for="filter-contract-date">
						{{ (model != null ? model.contractDate : "") | moment("DD-MMM-YYYY") }}
					</b-form-group>
					<b-form-group id="fieldset-contract-description" label-cols-sm="4" label-cols-lg="3" label="Catatan Atas Kontrak" label-for="filter-contract-description">
						{{ model != null ? model.contractDescription : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-attachment" label-cols-sm="4" label-cols-lg="3" label="File Dokumen" label-for="filter-document-attachment">
						{{ model != null ? ( model.documentAttachment != null ? model.documentAttachment.originalFileName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-distribution-type" label-cols-sm="4" label-cols-lg="3" label="Bentuk Distribusi" label-for="filter-distribution-type">
						{{ model != null ? ( model.distributionType != null ? model.distributionType.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-receipt-document" label-cols-sm="4" label-cols-lg="3" label="No. Tanda Terima Surat" label-for="filter-receipt-document">
						{{ model != null ? model.receiptDocument : "" }}
					</b-form-group>
					<b-form-group id="fieldset-is-reminder" label-cols-sm="4" label-cols-lg="3" label="Reminder Kegiatan" label-for="filter-is-reminder">
						{{ model != null ? model.isReminder : "" }}
					</b-form-group>
					<b-form-group id="fieldset-reminder-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal / Waktu Kegiatan" label-for="filter-reminder-date">
						{{ (model != null ? model.reminderDate : "") | moment("DD-MMM-YYYY HH:mm") }}
					</b-form-group>
					<b-form-group id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Catatan" label-for="filter-remarks">
						{{ model != null ? model.remarks : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-status" label-cols-sm="4" label-cols-lg="3" label="DocumentStatus" label-for="filter-document-status">
						{{ model != null ? ( model.documentStatus != null ? model.documentStatus.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-copy-document-status" label-cols-sm="4" label-cols-lg="3" label="CopyDocumentStatus" label-for="filter-copy-document-status">
						{{ model != null ? ( model.copyDocumentStatus != null ? model.copyDocumentStatus.name : "") : "" }}
					</b-form-group>
			</b-col>
		</b-card>
<!-- END_APP_DETAIL_HEADER -->
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import IncomingDocumentList from '@/models/IncomingDocument/IncomingDocumentList';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';

import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
/*END_APP_DETAIL_IMPORT*/
export default {
	/*APP_DETAIL_PROP_DEFINITION*/
	props : ["openPopup", "model", "saveActionMethod", "resetMethod"],
	/*END_APP_DETAIL_PROP_DEFINITION*/
	components : {
	/*APP_DETAIL_PROP_DEFINITION*/
	PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,
	/*APP_DETAIL_COMPONENTS_DEFINITION*/
},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			/*APP_DETAIL_PAGINATION*/
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterIncomingDocument : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentIncomingDocumentPage : 1,
			incomingDocumentPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByIncomingDocument: '',
			sortDescIncomingDocument: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			/*END_APP_DETAIL_OBJ_VARIABLE*/
		}
	},
	methods : {
		/*APP_DETAIL_GETIncomingDocument*/
		getIncomingDocument : async function(id) {
			this.isBusy = true;
			IncomingDocument.getData(this, id).then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETIncomingDocument*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'incomingdocumentcopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_SAVEBUTTONACTION*/
		saveButtonAction(modalEvent) {
			this.$emit("saveActionMethod", modalEvent);
		},
		/*END_APP_DETAIL_SAVEBUTTONACTION*/
		/*APP_DETAIL_RESETMODAL*/
		resetModal() {
			this.$emit('resetMethod'); 
		},
		/*END_APP_DETAIL_RESETMODAL*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterIncomingDocument = new IncomingDocumentFilter();
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	watch : {
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
		isShowPopup: {
			get: function(){
				if (this.openPopup) return true; 
				else return false; 
			},
			set: function(newValue){ }
		},
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
