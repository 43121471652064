import InvestigatorDetail from '@/models/ArchivedDocument/InvestigatorDetail';

export default class InvestigatorDetailFilter {

	constructor() {
	/*APP_FILTER_DEFINITION*/
		this.id = '';
		this.archivedDocumentId  = '';
		this.archivedDocumentNoBeritaAcara = '';
		this.userProfileId  = '';
		this.userProfileFullName = '';
		this.draftFromUpload = '';
		this.draftMode = '';
	/*END_APP_FILTER_DEFINITION*/
		this.exactType = {
	/*APP_APP_FILTER_CRITERIA*/
			id : '0',
			archivedDocumentId : '0',
			archivedDocumentNoBeritaAcara : '0',
			userProfileId : '0',
			userProfileFullName : '0',
	/*END_APP_FILTER_CRITERIA*/
		};
	}

}