<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true id="popup-upload" @ok="uploadButtonAction" :okTitle="okTitle" title="Upload Data">
		<b-card>
			<b-row>
				<b-col cols="12">
					<app-input-fileupload label="File" size="sm" :model.sync="fileupload" />
				</b-col>
			</b-row>
			<b-row v-if="isUploadSuccess">
				<b-col cols="12">
					<b-card no-body>
						<b-tabs card>
							<b-tab title="Reminder" active>
								<b-row class="mb-2">
									<b-col>
										<span class="float-right">
											<b-form-select v-model="reminderPageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
										</span>
									</b-col>
								</b-row>
								<b-row>
								<b-col>
								<div class="table-corner">
									<b-table id="my-table" stacked="md" head-variant="light" :no-local-sorting="true" :items="reminders" :fields="fieldsReminder" :busy="isDataLoading" :sort-by.sync="sortByReminder" :sort-desc.sync="sortDescReminder" small responsive>
									<template v-slot:head(actions)>
										<b-button @click="isShowReminderFilter = !isShowReminderFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
									</template>
									<template #top-row v-if="isShowReminderFilter">
										<b-th></b-th>
										<b-th stacked-heading="Kode Risalah">
											<v-select append-to-body label="documentCode" :options="incomingDocuments" :value="filterReminder.incomingDocumentId"  :reduce="item => item.id" v-model="filterReminder.incomingDocumentId" @search="getIncomingDocuments" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Reminder Date">
											<app-input-datetimepicker-range id="fieldset-reminder-date" label-cols-sm="4" label-cols-lg="3" :nolabel="true" label="Reminder Date" size="sm" :modelFrom.sync="filter.reminderDateFrom" :modelTo.sync="filter.reminderDateTo" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button/>
										</b-th>
										<b-th stacked-heading="Reminder Status">
											<v-select append-to-body label="name" :options="reminderStatuss" :value="filterReminder.reminderStatusId"  :reduce="item => item.id" v-model="filterReminder.reminderStatusId" @search="getReminderStatuss" :filterable="true"></v-select>
										</b-th>
										<b-th>
										</b-th>
									</template>
									<template v-slot:table-busys>
										<div class="text-center my-2">
									<b-spinner class="align-middle text-primary"></b-spinner>
										<strong class="ml-2">Loading..</strong>
										</div>
									</template>
									<template v-slot:cell(no)="row">
										{{ ((row.index + 1) + ( reminderPageSize * (currentReminderPage - 1))) }}
									</template>
									<template v-slot:cell(incomingDocument)="row">
										{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentCode : "" }}
									</template>
									<template v-slot:cell(reminderDate)="row">
										{{ row.item.reminderDate | moment("DD-MMM-YYYY HH:mm")  }}
									</template>
									<template v-slot:cell(reminderStatus)="row">
										{{ row.item.reminderStatus != null ? row.item.reminderStatus.name : "" }}
									</template>
									</b-table>
									</div>
								</b-col>
								<b-col cols="12">
									<b-pagination
									class="float-right btn-paging"
									v-model="currentReminderPage"
									:total-rows="totalReminderRow"
									:per-page="reminderPageSize"
									aria-controls="my-table"
									></b-pagination>
								</b-col>
								</b-row>
							</b-tab>
						</b-tabs>
					</b-card>
				</b-col>
			</b-row>
		</b-card>
		<template #modal-footer="{ ok, cancel }">
			<b-button v-if="isUploadSuccess" size="sm" variant="primary" @click="downloadLog()">
				Download Log
			</b-button>
			<b-button size="sm" variant="success" class="float-right" @click="ok()">
				{{ okTitle }}
			</b-button>
			<b-button size="sm" variant="secondary" class="float-right" @click="cancel()">
				Cancel
			</b-button>
		</template>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<style lang="scss" scoped>
.card {
	border: 1px solid rgba(0, 0, 0, 0.125) !important;
}
::v-deep .th-message {
	color: red;
}
::v-deep .modal-footer {
	display: block;
}
</style>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate"; 
import { required, minLength } from "vuelidate/lib/validators"; 
import { saveAs } from 'file-saver';

import Reminder from '@/models/Reminder/Reminder';
import ReminderList from '@/models/Reminder/ReminderList';
import ReminderFilter from '@/filters/Reminder/ReminderFilter';

import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import ReminderStatus from '@/models/ReminderStatus/ReminderStatus';
import ReminderStatusFilter from '@/filters/ReminderStatus/ReminderStatusFilter';
import AppInputFileupload from '@/components/AppInputFileupload';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
export default {
	props: ['model', 'openPopup','uploadButtonMethod', 'parentId'],
	components : {AppInputFileupload,AppInputDatetimepicker,AppInputDatetimepickerRange,},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fieldsReminder: [
				{"no" : { "class" : "th-number"}}, 
				{"incomingDocument": { "class" : "th-incoming-document", "label" : "Kode Risalah"}, key: 'incomingDocument', sortable: true}, 
				{"reminderDate": { "class" : "th-reminder-date", "label" : "Reminder Date"}, key: 'reminderDate', sortable: true}, 
				{"reminderStatus": { "class" : "th-reminder-status", "label" : "Reminder Status"}, key: 'reminderStatus', sortable: true}, 
				{"createdBy": { "class" : "th-created-by", "label" : "Created By"}, key: 'createdBy', sortable: true}, 
				{"updatedBy": { "class" : "th-updated-by", "label" : "Updated By"}, key: 'updatedBy', sortable: true}, 
				{"class" : "th-status", "label" : "Status", key: 'uploadValidationStatus', sortable: true},
				{"class" : "th-message", "label" : "Message", key: 'uploadValidationMessage', sortable: true},
				{"actions": { "class" : "th-actions", "label" : "" }}
			],
			busy:false,
			isDataLoading: false,
			isBusy : false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			reminders : [],
			reminderId : '',
			totalReminderRow : 0,
			isShowReminderFilter: false,
			filterReminder : null,
			currentReminderPage : 1,
			reminderPageSize: 10,
			sortByReminder: '',
			sortDescReminder: '',
			incomingDocuments: [],
			reminderStatuss: [],
			processId: "",
			isUploadSuccess: false,
		}
	},
	methods : {
		resetModal() {
			this.datas = Array();
			this.$emit('resetMethod');
		},
		uploadButtonAction(modalEvent) {
			modalEvent.preventDefault();
			this.isBusy = true;
			if (this.isUploadSuccess) {
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'reminder/confirmUpload',
					[],
					).then(async function(response){
						if (response != null) {
						if (response.data != null) {
							_this.resetModal();
							_this.datas = Array();
							_this.fileupload = null;
							_this.isUploadSuccess = false;
						}
						}
						_this.isBusy = false;
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
			} else {
				if (this.fileupload != null) {
				let data = new FormData();
				data.append('file', this.fileupload);
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'reminder/upload',
					data,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
					).then(async function(response){
						_this.isBusy = false;
						_this.isUploadSuccess = true;
						_this.getReminders();
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
				}
			}
		},
		getReminders : async function() {
			let filter = {};
			Object.assign(filter, this.filterReminder);
			filter.draftFromUpload = "1";
			filter.draftMode = "1";
			this.sortingByReminder = Array();
			if (this.sortByReminder != null) {
				this.sortingByReminder[this.sortByReminder] = this.sortDescReminder;
			}
			let currentPage = (this.currentReminderPage - 1);
			Reminder.getList(this, filter, this.sortingByReminder, currentPage , this.reminderPageSize).then(result => {
				this.reminders = result.data;
				this.totalReminderRow = result.dataCount;
			}).catch(error => {});
		},
		getIncomingDocuments : async function(input) {
			let filter = new IncomingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			IncomingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		getReminderStatuss : async function(input) {
			let filter = new ReminderStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			ReminderStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.reminderStatuss = result.data
				}
			})
			.catch(error => {});
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		downloadLog() {
			let _this = this;
			this.$http.post(process.env.VUE_APP_API_URL + 'reminder/downloadlog', [],
			{ headers: { 'Content-Type': 'application/json' }}
			).then(async function(response){
				await saveAs(process.env.VUE_APP_API_URL + 'reminder/downloadlog', "Reminder.xslx");
				_this.isBusy = false;
			})
			.catch(error => {
				_this.$store.dispatch('addErrorMessageGlobal', error);
				_this.isBusy = false;
			});
		}
	},
	beforeMount() {
	},
	mounted(){
	},
	watch: {
		sortByReminder : {
			handler: function() {
				this.getReminders();
			}
		},
		sortDescReminder: {
			handler: function() {
				this.getReminders();
			}
		},
		currentReminderPage: {
			handler: function() {
				this.getReminders();
			}
		},
		reminderPageSize : {
			handler: function() {
				this.getReminders();
			}
		},
		filterReminder: {
			handler: function() {
				this.getReminders();
			},
			deep: true,
		},
		fileupload(newValue, oldValue) {
			this.datas = Array();
			this.isUploadSuccess = false;
		}
	},
	computed: {
		okTitle: {
			get: function() {
				if (this.isUploadSuccess) {
					return "Proses";
				}
				return "Simpan";
				}
		},
		isShowPopup : {
			get: function () {
				if (this.openPopup) {
					this.filterReminder = new ReminderFilter();
					return true;
				}
				else return false;
			},
			set: function (newValue) {}
		},
	}
}
</script>
