<template>
	<PopupRouterView label="Purchase Order Status Input"  entityName="RemovalMethod" :documentId="mainRecordId"  @saveMethod="saveAction" @hideMethod="onHidden">
		<b-card>
			<b-col cols="12" lg="6">
				<app-input-textbox id="fieldset-name" label-cols-sm="4" label-cols-lg="3" label="Name" label-for="input-name" size="sm" :model.sync="model.name" :isRequired="true" :state="validateState('name')" :errorMessage="getErrorMessage(errorMessage.name, 'name')"  @change="updateData" />
			</b-col>
		</b-card>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</PopupRouterView>
</template>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import RemovalMethod from '@/models/RemovalMethod/RemovalMethod';
import RemovalMethodList from '@/models/RemovalMethod/RemovalMethodList';
import RemovalMethodFilter from '@/filters/RemovalMethod/RemovalMethodFilter';

import AppInputTextbox from '@/components/AppInputTextbox';
import PopupRouterView from '@/components/PopupRouterView';
export default {
	components : {AppInputTextbox,PopupRouterView},
	mixins : [validationMixin],
	validations: {
		model: {
			name: {required},
		}
	},
	data() {
		return {
			errorMessage : {
				name: { required : "Name is Required."},
			},
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			modelId: null,
			model: {},
			filterRemovalMethod : null,
			currentRemovalMethodPage : 1,
			removalMethodPageSize: 10,
			sortByRemovalMethod: '',
			sortDescRemovalMethod: '',
			isShowPopupConfirmation : false,
			isDataCommited : false,
			mainRecordId : '',
		}
	},
	methods : {
		getRemovalMethod : async function(id) {
			this.isBusy = true;
			RemovalMethod.getData(this, id).then(result => { this.model = result; this.mainRecordId = String(result.mainRecordId); this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		downloadData() {
			this.isDownloadRemovalMethod = true;
			this.filterRemovalMethod.id = this.modelId;
			RemovalMethod.downloadDataDetail(this, this.filterRemovalMethod).then(result => {
				this.isDownloadRemovalMethod = false;
			}).catch(error => { this.isDownloadRemovalMethod = false; })
		},
		validateState(name) {
			const { $dirty, $error } = this.$v.model[name];
			return $dirty ? !$error : null;
		},
		getErrorMessage(message, name) {
			if (this.$v.model[name].required != undefined && !this.$v.model[name].required) {
				if (message.required) return message.required;
			}
		},
		saveAction(modalEvent) {
			modalEvent.preventDefault();
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isBusy = true;
				let id = this.modelId;
				let model = this.model;

				if (id != '' && id != null && id != undefined) {
					RemovalMethod.commitData(this, model, id).then(result => {
						this.isDataCommited = true;
						this.$router.push({ name: 'removalmethodindex' });
						this.isBusy = false;
						this.$store.dispatch('addSuccessMessageGlobal', "Data Berhasil disimpan");
					}).catch(error => { this.isBusy = false; });
				} else {
					RemovalMethod.insertData(this, model).then(result => {
						this.$router.push({ name: 'removalmethodindex' });
						this.isBusy = false;
						this.$store.dispatch('addSuccessMessageGlobal', "Data Berhasil disimpan");
					}).catch(error => { this.isBusy = false; });
				}
			}
		},
		updateData() {
			let id = this.modelId;
			let model = this.model;

			let promises = Array();

			/*Promise.all(promises).then((results) => {
				if (id != '' && id != null && id != undefined) {
					RemovalMethod.updateData(this, model, id);
				}
			});*/
		},
		onHidden() {
			this.getRemovalMethods();
		},
		resetModal(modalType) {
			this.showPopupCopyData = false; 
			this.isShowPopupConfirmation = false;
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
	},
	beforeMount() {
		this.filterRemovalMethod = new RemovalMethodFilter();
	},
	async mounted(){
		let id = this.$route.params.id;
		let copyData = this.$route.params.copydata;
		if (id != '' && id != null && id != undefined) {
			if (copyData != null && copyData != undefined) {
				this.getRemovalMethod(id);
			} else {
				this.modelId = id;
				this.getRemovalMethod(id);
			}
		}
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	computed: {
	},
	watch : {
	},
	beforeDestroy() {
		if (!this.isDataCommited) {
			//RemovalMethod.discardData(this, this.modelId);
		}
	},
}
</script>
