<template>
	<PopupRouterView label="Document Attachment Input"  entityName="documentattachment" :documentId="mainRecordId"  @saveMethod="saveAction" @hideMethod="onHidden">
		<b-card>
			<b-col cols="12" lg="6">
				<app-input-textbox id="fieldset-name" label-cols-sm="4" label-cols-lg="3" label="Name" label-for="input-name" size="sm" :model.sync="model.name" :isRequired="true" :state="validateState('name')" :errorMessage="getErrorMessage(errorMessage.name, 'name')"  @change="updateData" />
			</b-col>
			<b-col>
				<span class="float-right" v-if="modelId != null && modelId != undefined && modelId != ''">
					<b-button variant="success" class="float-right ml-2" @click="downloadData" size="sm"><b-icon-download class="mr-1"></b-icon-download> Download</b-button>
				</span>
			</b-col>
		</b-card>
	<b-card class="mt-3" header-bg-variant="transparent">
		<b-row class="mb-2">
			<b-col cols="9"><h5 class="card-title font-weight-bolder text-dark">Document Attachment Details</h5></b-col>
			<b-col>
				<span class="float-right">
					<b-form-select v-model="documentAttachmentDetailPageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
				</span>
			</b-col>
		</b-row>
		<b-row>
		<b-col>
			<app-table-input :items="documentAttachmentDetails" :fields="fieldsDocumentAttachmentDetail" :busy="isDataLoading" :sort-by.sync="sortByDocumentAttachmentDetail" :sort-desc.sync="sortDescDocumentAttachmentDetail" small responsive
			@editButtonMethod="editRowDataDocumentAttachmentDetail"
			@deleteButtonMethod="deleteRowDataDocumentAttachmentDetail"
			@showPopupAddMethod="addDataDocumentAttachmentDetail()"
			@showPopupUploadMethod="showPopupUploadDocumentAttachmentDetail()"
			@downloadMethod="downloadDocumentAttachmentDetail()"
			@addFromClipboardMethod="addFromClipboardDocumentAttachmentDetail()"
			@replaceFromClipboardMethod="replaceFromClipboardDocumentAttachmentDetail()"
			:showActionButton="true"
			:showAddButton="true"
			:showAddFromClipboardButton="true"
			:showReplaceFromClipboardButton="true"
			:showUploadButton="true"
			:showDownloadButton = "true"
			:isDownloadBusy = "isDownloadDocumentAttachmentDetail"
			>
			<template v-slot:head(actions)>
				<b-button @click="isShowDocumentAttachmentDetailFilter = !isShowDocumentAttachmentDetailFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
			</template>
			<template #top-row v-if="isShowDocumentAttachmentDetailFilter">
				<b-th></b-th>
				<b-th stacked-heading="File Name">
					<b-form-input id="input-file-name-table" v-model="filterDocumentAttachmentDetail.fileName" size="sm"></b-form-input>
				</b-th>
				<b-th>
				</b-th>
			</template>
			<template v-slot:cell(no)="row">
				{{ ((row.index + 1) + ( documentAttachmentDetailPageSize * (currentDocumentAttachmentDetailPage - 1))) }}
			</template>
			<template v-slot:table-busys>
				<div class="text-center my-2">
			<b-spinner class="align-middle text-primary"></b-spinner>
				<strong class="ml-2">Loading...</strong>
				</div>
			</template>
			<template v-slot:cell(fileName)="row">
				<app-input-textbox id="fieldset-file-name" size="sm" :model.sync="row.item.fileName"   @change="saveInputDocumentAttachmentDetail(row.item)" />
			</template>
			<template v-slot:cell(attachment)="row">
				<app-input-fileupload size="sm" :model.sync="attachment" />
			</template>
				<template v-slot:form-add-detail>
				<DocumentAttachmentDetailInput 
					:model="modelDocumentAttachmentDetail"
					:openPopup="isShowPopupDocumentAttachmentDetail"
					@resetMethod="resetModal"
					@addButtonMethod="addRowDataDocumentAttachmentDetail"/>
				</template>
				<template v-slot:upload-detail>
					<DocumentAttachmentDetailUpload
					:openPopup="isShowPopupUploadDocumentAttachmentDetail"
					@resetMethod="resetModal"
					@uploadButtonMethod="uploadDataDocumentAttachmentDetail"
					:parentId="modelId"
				 />
				</template>
			<template v-slot:table-paging>
				<b-pagination
				class="float-right btn-paging"
				v-model="currentDocumentAttachmentDetailPage"
				:total-rows="totalDocumentAttachmentDetailRow"
				:per-page="documentAttachmentDetailPageSize"
				aria-controls="my-table"
				></b-pagination>
			</template>
			</app-table-input>
		</b-col>
		</b-row>
	</b-card>
		<PopupConfirmationDetail :openPopup="isShowPopupConfirmation" :modelId="modelId" @saveActionMethod="saveAction" @resetMethod="resetModal" />
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</PopupRouterView>
</template>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import DocumentAttachment from '@/models/DocumentAttachment/DocumentAttachment';
import DocumentAttachmentList from '@/models/DocumentAttachment/DocumentAttachmentList';
import DocumentAttachmentFilter from '@/filters/DocumentAttachment/DocumentAttachmentFilter';

import PopupConfirmationDetail from './PopupConfirmationDetail';

import AppInputTextbox from '@/components/AppInputTextbox';
import DocumentAttachmentDetail from '@/models/DocumentAttachment/DocumentAttachmentDetail';
import DocumentAttachmentDetailFilter from '@/filters/DocumentAttachment/DocumentAttachmentDetailFilter';
import Attachment from '@/models/Core/Attachment';
import AttachmentFilter from '@/filters/Core/AttachmentFilter';
import AppTableInput from '@/components/AppTableInput';
import AppTableInputPopup from '@/components/AppTableInputPopup';
import DocumentAttachmentDetailInput from '@/views/DocumentAttachment/DocumentAttachmentDetailInput';
import DocumentAttachmentDetailUpload from '@/views/DocumentAttachment/DocumentAttachmentDetailUpload';
import PopupRouterView from '@/components/PopupRouterView';
export default {
	components : {AppInputTextbox,DocumentAttachmentDetailInput,DocumentAttachmentDetailUpload,AppTableInput,AppTableInputPopup,PopupRouterView,PopupConfirmationDetail},
	mixins : [validationMixin],
	validations: {
		model: {
			name: {required},
		}
	},
	data() {
		return {
			errorMessage : {
				name: { required : "Name is Required."},
			},
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			fieldsDocumentAttachmentDetail: [
				{"no" : { "class" : "th-number"}}, 
				{"fileName": { "class" : "th-file-name"}, "label" : "File Name", key: 'fileName', sortable: true}, 
				{"attachment": { "class" : "th-attachment"}, "label" : "Attachment", key: 'attachment', sortable: true}, 
				{"createdBy": { "class" : "th-created-by"}, "label" : "Created By", key: 'createdBy', sortable: true}, 
				{"updatedBy": { "class" : "th-updated-by"}, "label" : "Updated By", key: 'updatedBy', sortable: true}, 
				{"actions": { "class" : "th-actions", "label" : ""}}
			],
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			modelId: null,
			model: {},
			modelDocumentAttachmentDetail : {},
			isShowPopupDocumentAttachmentDetail : false,
			isShowPopupUploadDocumentAttachmentDetail : false,
			filterDocumentAttachment : null,
			currentDocumentAttachmentPage : 1,
			documentAttachmentPageSize: 10,
			sortByDocumentAttachment: '',
			sortDescDocumentAttachment: '',
			filterDocumentAttachmentDetail : null,
			currentDocumentAttachmentDetailPage : 1,
			documentAttachmentDetailPageSize: 10,
			sortByDocumentAttachmentDetail: '',
			sortDescDocumentAttachmentDetail: '',
			sortingByDocumentAttachmentDetail: [],
			documentAttachmentDetails : [],
			documentAttachmentDetailId : '',
			totalDocumentAttachmentDetailRow : 0,
			isShowDocumentAttachmentDetailFilter: false,
			isDownloadDocumentAttachmentDetail: false,
			isShowPopupConfirmation : false,
			isDataCommited : false,
			mainRecordId : '',
		}
	},
	methods : {
		getDocumentAttachment : async function(id) {
			this.isBusy = true;
			DocumentAttachment.getData(this, id).then(result => { this.model = result; this.mainRecordId = String(result.mainRecordId); this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		downloadData() {
			this.isDownloadDocumentAttachment = true;
			this.filterDocumentAttachment.id = this.modelId;
			DocumentAttachment.downloadDataDetail(this, this.filterDocumentAttachment).then(result => {
				this.isDownloadDocumentAttachment = false;
			}).catch(error => { this.isDownloadDocumentAttachment = false; })
		},
		getDocumentAttachmentDetails : async function(isAddRow) {
			let filter = {};
			Object.assign(filter, this.filterDocumentAttachmentDetail);
			if (this.modelId == null) return;
			filter.documentAttachmentId = this.modelId;
			filter.draftMode = "1";
			if (this.sortByDocumentAttachmentDetail != null) {
				this.sortingByDocumentAttachmentDetail[this.sortByDocumentAttachmentDetail] = this.sortDescDocumentAttachmentDetail;
			}
			let currentPage = (this.currentDocumentAttachmentDetailPage - 1);
			if (isAddRow) {
				let totalData = this.totalDocumentAttachmentDetailRow + 1;
				let page = Math.ceil(totalData/this.documentAttachmentDetailPageSize);
				currentPage = page - 1;
			}
			DocumentAttachmentDetail.getList(this, filter, this.sortingByDocumentAttachmentDetail, currentPage , this.documentAttachmentDetailPageSize).then(result => {
				this.currentDocumentAttachmentDetailPage = currentPage + 1;
				this.documentAttachmentDetails = result.data;
				this.totalDocumentAttachmentDetailRow = result.dataCount;
			}).catch(error => {});
		},
		getDocumentAttachments : async function(input) {
			let filter = new DocumentAttachmentFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentAttachment.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentAttachments = result.data
				}
			})
			.catch(error => {});
		},
		getAttachments : async function(input) {
			let filter = new AttachmentFilter();
			if (input != undefined) {
				filter.originalFileName = input;
			}
			Attachment.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.attachments = result.data
				}
			})
			.catch(error => {});
		},
		validateState(name) {
			if (!this.$v.model[name]) return null;
			const { $dirty, $error } = this.$v.model[name];
			return $dirty ? !$error : null;
		},
		getErrorMessage(message, name) {
			if (!this.$v.model[name]) return "";
			if (this.$v.model[name].required != undefined && !this.$v.model[name].required) {
				if (message.required) return message.required;
			}
		},
		saveActionConfirmation(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isShowPopupConfirmation = true;
			}
		},
		saveAction(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isBusy = true;
				let id = this.modelId;
				let model = this.model;

				if (id != '' && id != null && id != undefined) {
					DocumentAttachment.commitData(this, model, id).then(result => {
						this.isDataCommited = true;
						this.$router.push({ name: 'documentattachmentindex' });
						this.isBusy = false;
					}).catch(error => { this.isBusy = false; });
			} else {
				DocumentAttachment.insertData(this, model).then(result => {
					this.$router.push({ name: 'documentattachmentindex' });
					this.isBusy = false;
				}).catch(error => { this.isBusy = false; });
			}
			}
		},
		updateData() {
			let id = this.modelId;
			let model = this.model;

			let promises = Array();

			Promise.all(promises).then((results) => {
				if (id != '' && id != null && id != undefined) {
					DocumentAttachment.updateData(this, model, id);
				}
			});
		},
		onHidden() {
			this.getDocumentAttachments();
		},
		addRowDataDocumentAttachmentDetail(modalEvent, model) {
			if (model != null) {
				if (model.id != null && model.id != '') {
					model.documentAttachmentId =  this.modelId;
					DocumentAttachmentDetail.updateDataDetail(this, model, model.id)
					.then(result => {
						this.getDocumentAttachmentDetails(true);
						this.isShowPopupDocumentAttachmentDetail = false;
					})
				} else {
					model.documentAttachmentId =  this.modelId;
					DocumentAttachmentDetail.createDataDetail(this, model)
					.then(result => {
						this.getDocumentAttachmentDetails(true);
						this.isShowPopupDocumentAttachmentDetail = false;
					})
				}
			}
		},
		async editRowDataDocumentAttachmentDetail(id) {
			this.documentAttachmentDetailId = id;
			this.isShowPopupDocumentAttachmentDetail = true; 
		},
		async deleteRowDataDocumentAttachmentDetail(id) {
			DocumentAttachmentDetail.deleteData(this, id).then(result => { this.getDocumentAttachmentDetails(); });
		},
		async resetDataDocumentAttachmentDetail() {
			this.documentAttachmentDetailId = "";
			this.isShowPopupDocumentAttachmentDetail = true;
		},
		async addDataDocumentAttachmentDetail() {
			DocumentAttachmentDetail.createDataDetail(this, { documentAttachmentId: this.modelId })
			.then(result => {
				this.getDocumentAttachmentDetails(true);
			})
		},
		async addFromClipboardDocumentAttachmentDetail() {
			let fileNames = new Array();
			navigator.clipboard.readText().then(value => {
				if(value.match(/(?:\r\n|\r|\n)/g)) { value=value.replace(/(?:\r\n|\r|\n)/g, ',') ; }
				let values = value.split(",");
				values.forEach(item => {
					fileNames.push({ fileName: item, documentAttachmentId: this.modelId });
				});
				DocumentAttachmentDetail.addFromClipboard(this, fileNames).then(result => { this.getDocumentAttachmentDetails() });
			});
		},
		async replaceFromClipboardDocumentAttachmentDetail() {
			let fileNames = new Array();
			navigator.clipboard.readText().then(value => {
				if(value.match(/(?:\r\n|\r|\n)/g)) { value=value.replace(/(?:\r\n|\r|\n)/g, ',') ; }
				let values = value.split(",");
				values.forEach(item => {
					fileNames.push({ fileName: item, documentAttachmentId: this.modelId });
				});
				DocumentAttachmentDetail.replaceFromClipboard(this, fileNames).then(result => { this.getDocumentAttachmentDetails() });
			});   
		},
		showPopupUploadDocumentAttachmentDetail() {
			this.isShowPopupUploadDocumentAttachmentDetail = true;
		},
		uploadDataDocumentAttachmentDetail(modalEvent, newDatas) {
			this.isShowPopupUploadDocumentAttachmentDetail = false; 
		},
		downloadDocumentAttachmentDetail(modalEvent) {
			this.isDownloadDocumentAttachmentDetail = true;
			this.filterDocumentAttachmentDetail.documentAttachmentId = this.modelId;
			DocumentAttachmentDetail.downloadDataDetail(this, this.filterDocumentAttachmentDetail).then(result => {
				this.isDownloadDocumentAttachmentDetail = false;
			}).catch(error => { this.isDownloadDocumentAttachmentDetail = false; })
		},
		saveInputDocumentAttachmentDetail(item) {
			DocumentAttachmentDetail.updateDataDetail(this, item, item.id);
		},
		resetModal(modalType) {
			this.isShowPopupUploadDocumentAttachmentDetail = false; 
			this.isShowPopupDocumentAttachmentDetail = false; 
			if (modalType == 'DocumentAttachmentDetail') {
				this.getDocumentAttachmentDetails();
			}
			this.showPopupCopyData = false; 
			this.isShowPopupConfirmation = false;
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
				this.isShowDocumentAttachmentDetailFilter = true;
			}
		},
	},
	beforeMount() {
		this.filterDocumentAttachment = new DocumentAttachmentFilter();
		this.filterDocumentAttachmentDetail = new DocumentAttachmentDetailFilter();
		this.getDocumentAttachments();
	},
	async mounted(){
		let id = this.$route.params.id;
		let copyData = this.$route.params.copydata;
		if (id != '' && id != null && id != undefined) {
			if (copyData != null && copyData != undefined) {
				this.getDocumentAttachment(id);
			} else {
				this.modelId = id;
				this.getDocumentAttachment(id);
			}
		}
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	computed: {
	},
	watch : {
		sortByDocumentAttachmentDetail : {
			handler: function() {
				this.getDocumentAttachmentDetails();
			}
		},
		sortDescDocumentAttachmentDetail: {
			handler: function() {
				this.getDocumentAttachmentDetails();
			}
		},
		currentDocumentAttachmentDetailPage: {
			handler: function() {
				this.getDocumentAttachmentDetails();
			}
		},
		documentAttachmentDetailPageSize : {
			handler: function() {
				this.getDocumentAttachmentDetails();
			}
		},
		filterDocumentAttachmentDetail: {
			handler: function() {
				this.getDocumentAttachmentDetails();
			},
			deep: true,
		},
	},
	beforeDestroy() {
		if (!this.isDataCommited) {
			DocumentAttachment.discardData(this, this.modelId);
		}
	},
}
</script>
