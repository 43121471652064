import BaseModelAPI from '../BaseModelAPI';

export default class SenderStatusList extends BaseModelAPI {

	static entity = 'senderstatuslist'
	static entityName = 'senderstatus';

	constructor() {
		this.pageIndex = 0;
		this.pageSize = 0;
		this.pageCount = 0;
		this.dataCount = 0;
	}
}
