import BaseModelAPI from '../BaseModelAPI';

export default class SifatSuratList extends BaseModelAPI {

	static entity = 'sifatsuratlist'
	static entityName = 'sifatsurat';

	constructor() {
		this.pageIndex = 0;
		this.pageSize = 0;
		this.pageCount = 0;
		this.dataCount = 0;
	}
}
