import BaseModelAPI from '../BaseModelAPI';

export default class EducationLevelList extends BaseModelAPI {

	static entity = 'educationlevellist'
	static entityName = 'educationlevel';

	constructor() {
		this.pageIndex = 0;
		this.pageSize = 0;
		this.pageCount = 0;
		this.dataCount = 0;
	}
}
