import BaseModelAPI from '../BaseModelAPI';

export default class FunctionInfo extends BaseModelAPI {
	static entity = 'functioninfo';
	static entityName = 'functioninfo';

	constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.name = '';
			this.uri = '';
			this.iconName = '';
			this.moduleName = '';
			this.isEnabled = false;
			this.moduleInfoId = '';
			this.moduleInfo = {};
			this.isFromUpload = false;
			this.isSavedToBackend = true;
			this.uploadValidationMessage = '';
			this.uploadValidationStatus = '';
	/*END_APP_MODEL_DEFINITION*/
	}

}