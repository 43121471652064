<template>
	<div>
		<b-button class="filter" variant="light" block href="#" v-b-toggle.accordion-filter>
			<span><b-icon-funnel-fill></b-icon-funnel-fill> Filter</span>
			<span class="menu-arrow">
				<b-icon-chevron-right class="float-right chevron-right"></b-icon-chevron-right><b-icon-chevron-down class="float-right chevron-down"></b-icon-chevron-down>
			</span>
		</b-button>
		<b-collapse id="accordion-filter" accordion="accordion-filter" role="tabpanel" class="menu-accordion">
			<b-card>
				<b-row>
					<b-col cols="12" lg="6">
					<app-input-textbox id="fieldset-name" label-cols-sm="4" label-cols-lg="3" label="Name" label-for="filter-name" size="sm" :model.sync="filter.name" />
					<b-button variant="danger" size="sm" class="float-right" @click="resetFilter"><span style="font-size: 0.75rem" class="mr-1"><b-icon-x></b-icon-x></span> Clear Filter</b-button>
					<b-button variant="primary" size="sm" class="float-right mr-1" @click="getOutgoingDocumentApprovalStatuss"><span style="font-size: 0.75rem" class="mr-1"><b-icon-search></b-icon-search></span> Cari</b-button>
					</b-col>
				</b-row>
			</b-card>
		</b-collapse>
		<b-row class="mt-3">
			<b-col>
				<b-button variant="warning" v-if="(userRole != null ? userRole.allowUpload : false)" class="float-right ml-2" @click="showPopupUploadData" size="sm"><b-icon-upload class="mr-1"></b-icon-upload> Upload</b-button>
				<b-overlay
					v-if="(userRole != null ? userRole.allowDownload : false)"
					:show="isDownloadDataBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="float-right "
					>
					<b-button variant="primary" v-if="(userRole != null ? userRole.allowDownload : false)" class="float-right ml-2" @click="downloadData" size="sm"><b-icon-download class="mr-1"></b-icon-download> Download</b-button>
				</b-overlay>
				<b-button variant="primary" v-if="(userRole != null ? userRole.allowUpload : false)" class="float-right" href="/files/OutgoingDocumentApprovalStatus/OutgoingDocumentApprovalStatus.xlsx" size="sm"><b-icon-wallet-fill class="mr-1"></b-icon-wallet-fill> Download Template</b-button>
			</b-col>
		 </b-row>
		<b-card class="mt-3">
		<b-row class="mb-3">
			<b-col>
				<b-overlay
					v-if="(userRole != null ? userRole.allowCreate : false)"
					:show="isCreateBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<b-button variant="success" v-if="(userRole != null ? userRole.allowCreate : false)" @click="createData" size="sm"><b-icon-pencil class="mr-1"></b-icon-pencil> Create</b-button>
				</b-overlay>
			</b-col>
			<b-col>
				<span class="float-right">
					<b-form-select v-model="pageSizeOptions" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
				</span>
			</b-col>
		</b-row>
		<div class="table-corner">
			<b-table id="my-table" stacked="md" head-variant="light"  :no-local-sorting="true" :items="outgoingDocumentApprovalStatuss" :fields="fields" :busy="isDataLoading" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" small responsive>
			<template v-slot:head(actions)>
				<b-button-group class="float-right"  size="sm">
					<b-button @click="isShowFilter = !isShowFilter" size="sm"><b-icon-funnel-fill /></b-button>
				</b-button-group>
			</template>
			<template #top-row v-if="isShowFilter">
				<b-th></b-th>
				<b-th stacked-heading="Name">
					<b-form-input id="input-name-table" @input="getOutgoingDocumentApprovalStatussWithoutLoading" v-model="filter.name" size="sm"></b-form-input>
				</b-th>
				<b-th stacked-heading="Select All">
					<b-form-checkbox v-if="(userRole != null ? userRole.allowPrint : false)" id="input-select-all" class="float-right d-block d-md-none" v-model="selectAll"></b-form-checkbox>
				</b-th>
			</template>
			<template v-slot:cell(no)="row">
				{{ ((row.index + 1) + ( pageSizeOptions * (currentPage - 1))) }}
			</template>
			<template v-slot:cell(actions)="row" >
				<div class="div-actions float-right">
				<b-button size="sm" v-if="(userRole != null ? userRole.allowUpdate : false)" @click="editData(row.index, row.item.id)" variant ="success"  class="mr-2 btn-xs">
					<b-icon-pencil></b-icon-pencil>
				</b-button>
				<b-overlay
					v-if="(userRole != null ? userRole.allowDelete : false)"
					:show="busy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<click-confirm>
					<b-button size="sm" variant="danger" @click="deleteData(row.item.id)" class="mr-2 btn-xs">
						<b-icon-trash></b-icon-trash>
					</b-button>
					</click-confirm>
				</b-overlay>
				<b-button size="sm" v-if="(userRole != null ? userRole.allowRead : true)" variant="primary" class="mr-2 mt-md-2 mt-lg-0 btn-xs" :to="{path: '/OutgoingDocumentApprovalStatus/detail/' + row.item.id }" >
					<b-icon-eye-fill></b-icon-eye-fill>
				</b-button>
				<b-button size="sm" v-if="(userRole != null ? userRole.allowPrint : false)" variant="light"  @click="printSingleData(row.item.id)" class="mr-2 mt-md-2 mt-lg-0 btn-xs">
					<b-icon-printer-fill></b-icon-printer-fill>
				</b-button>
				<b-form-checkbox v-if="(userRole != null ? userRole.allowPrint : false)" class="d-inline" :id="'input-is-print-' + (row.index + 1)" @change="toggleIsPrint(Number(row.item.id))" :checked="checkAllData.includes(Number(row.item.id))" ></b-form-checkbox>
				</div>
			</template>
			</b-table>
		</div>
		<b-row>
			<b-col cols="12" md="6" lg="6">
				<b-overlay
					v-if="(userRole != null ? userRole.allowCreate : false)"
					:show="isCreateBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<b-button variant="success" v-if="(userRole != null ? userRole.allowCreate : false)" @click="createData" size="sm"><b-icon-pencil class="mr-1"></b-icon-pencil> Create</b-button>
				</b-overlay>
			</b-col>
			<b-col cols="12" md="6" lg="6">
			<b-pagination
				class="float-md-right btn-paging"
				v-model="currentPage"
				:total-rows="(outgoingDocumentApprovalStatussPaging != null ? outgoingDocumentApprovalStatussPaging.total : 0)"
				:per-page="(outgoingDocumentApprovalStatussPaging != null ? outgoingDocumentApprovalStatussPaging.perPage : 0)"
				aria-controls="my-table"
			></b-pagination>
			</b-col>
		</b-row>
		</b-card>
		<RouterView />
	</div>
</template>
<style lang="scss" scoped>
	@import '../../assets/scss/index-page.scss';
</style>
<script>
import OutgoingDocumentApprovalStatus from '@/models/OutgoingDocumentApprovalStatus/OutgoingDocumentApprovalStatus';
import OutgoingDocumentApprovalStatusList from '@/models/OutgoingDocumentApprovalStatus/OutgoingDocumentApprovalStatusList';
import OutgoingDocumentApprovalStatusFilter from '@/filters/OutgoingDocumentApprovalStatus/OutgoingDocumentApprovalStatusFilter';
import RoleDetail from '@/models/Core/RoleDetail';

import AppInputTextbox from '@/components/AppInputTextbox';

export default {
	components : {OutgoingDocumentApprovalStatus, OutgoingDocumentApprovalStatusList, OutgoingDocumentApprovalStatusFilter, AppInputTextbox},
	data() {
		return {
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fields: [
				{"no" : { "class" : "th-number"}}, 
				{"name": {}, "class" : "th-name", "label" : "Name", key: 'name', sortable: true}, 
				{"actions": { "class" : "th-actions", "label" : ""}}
			],
			busy:false,
			isDataLoading: false,
			isDownloadDataBusy: false,
			isMultiplePrintBusy: false,
			showPopupUpload: false,
			showPopupDownload: false,
			isShowPopupPDF: false,
			pdfUrl: "",
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			filter: null,
			processId: "",
			popupDownloadLabel: "",
			hasLoadedData: false,
			showFilter: false,
			isShowFilter: false,
			isCreateBusy: false,
			btnEditData: [],
			currentDataId: null,
			outgoingDocumentApprovalStatuss: [],
			outgoingDocumentApprovalStatussPaging: {},
		}
	},
	methods : {
		getOutgoingDocumentApprovalStatuss : async function() {
			this.isDataLoading = true;
			this.sortingBy = Array();
			if (this.sortBy != null) {
				this.sortingBy[this.sortBy] = this.sortDesc;
			}
			
			OutgoingDocumentApprovalStatus.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions)
			.then(result => {
				if (result != null) {
					this.outgoingDocumentApprovalStatuss = result.data
					this.outgoingDocumentApprovalStatussPaging = result.pagination
				}
				this.isDataLoading = false;
			})
			.catch(error => {
				this.isDataLoading = false;
			});
		},
		getOutgoingDocumentApprovalStatussWithoutLoading : async function() {
			this.sortingBy = Array();
			if (this.sortBy != null) {
				this.sortingBy[this.sortBy] = this.sortDesc;
			}
			OutgoingDocumentApprovalStatus.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions)
			.then(result => {
				if (result != null) {
					this.outgoingDocumentApprovalStatuss = result.data
					this.outgoingDocumentApprovalStatussPaging = result.pagination
				}
			})
			.catch(error => {});
		},
		resetFilter() {
			this.filter = new OutgoingDocumentApprovalStatusFilter();
		},
		showPopupUploadData() {
			this.showPopupUpload = true;
		},
		uploadData(modalEvent) {
			this.showPopupUpload = false;
		},
		resetModal() {
			this.showPopupUpload = false;
			this.showPopupDownload = false;
			this.isShowPopupPDF = false;
		},
		resetModalUpload() {
			this.showPopupUpload = false;
			this.getOutgoingDocumentApprovalStatuss();
		},
		toggleIsPrint(id) {
			if (this.checkAllData.includes(id)) {
				this.$store.dispatch("removeCheckData", id);
			} else {
				this.$store.dispatch("addCheckData", id);
			}
		},
		showPopupPrint(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di cetak";
			this.processId = id;
		},
		showPopupDownloadData(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di unduh";
			this.processId = id;
		},
		showPopupPDF(url) {
			this.isShowPopupPDF = true;
			this.pdfUrl = url;
		},
		printData() {
			let data = this.$store.state.dataIds;
			if (data.length > 0) {
				this.isMultiplePrintBusy = true;
				OutgoingDocumentApprovalStatus.multiPagePrint(this).then(result => {
					if (result) {
						this.showPopupPrint(result.id);
					}
					this.isMultiplePrintBusy = false;
				}).catch(error => { this.isMultiplePrintBusy = false; });
			}
		},
		printSingleData(id) {
			OutgoingDocumentApprovalStatus.singleDataPagePrint(this, id).then(result => {
				if (result) {
					this.showPopupPDF(result.download);
				}
			});
		},
		downloadData() {
			if (this.sortBy != null) {
					this.sortingBy[this.sortBy] = this.sortDesc;
			}
			this.isDownloadDataBusy = true;
			OutgoingDocumentApprovalStatus.downloadData(this, this.filter, this.sortingBy).then(result => {
				if (result) {
					this.showPopupDownloadData(result.id);
				}
				this.isDownloadDataBusy = false;
			}).catch(error => { this.isDownloadDataBusy = false; })
		},
		createData() {
			this.$router.push({ name: 'outgoingdocumentapprovalstatuscreate'});
		},
		editData(index, id) {
			this.$router.push({ name: 'outgoingdocumentapprovalstatusedit', params : { id: id } });
		},
		deleteData(id) {
			OutgoingDocumentApprovalStatus.deleteData(this, id).then(result => {
				if (result)
				{
					this.$store.dispatch('addSuccessMessageGlobal', "Data Berhasil dihapus");
					this.getOutgoingDocumentApprovalStatuss();
				}
			})
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768) {
				this.isShowFilter = true;
			} else {
				this.isShowFilter = this.showFilter;
			}
		},
	},
	beforeMount() {
		this.$store.dispatch("removeCheckAllData");
		this.filter = new OutgoingDocumentApprovalStatusFilter();
	},
	mounted(){
		let breadcrumb =[
			"Master Data", 
			"Outgoing Document Approval Status"
		];
		this.getOutgoingDocumentApprovalStatuss();
		this.$store.dispatch('setBreadCrumb', breadcrumb);
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
		});
	},
	watch: {
		sortBy: {
			handler: function() {
				this.getOutgoingDocumentApprovalStatuss();
			}
		},
		sortDesc: {
			handler: function() {
				this.getOutgoingDocumentApprovalStatuss();
			}
		},
		currentPage: {
			handler: function() {
				this.getOutgoingDocumentApprovalStatuss();
			}
		},
		pageSizeOptions: {
			handler: function() {
				this.getOutgoingDocumentApprovalStatuss();
			}
		},
		selectAll(newValue) {
			if (newValue) {
				OutgoingDocumentApprovalStatus.checkAllData(this, this.filter, this.sortingBy);
			} else {
				this.$store.dispatch("removeCheckAllData");
			}
		},
		'$route'(to, from) {
			if (to != null) {
				if (to.name == "outgoingdocumentapprovalstatusindex") {
					this.getOutgoingDocumentApprovalStatussWithoutLoading();
				}
			}
		},
	},
	computed: {
		checkAllData() {
			return this.$store.state.dataIds;
		},
		userRole() {
			return RoleDetail.query().where("functionInfoId", "outgoing_document_type").first();
		}
	},
	beforeDestroy() {
		this.$store.dispatch("removeCheckAllData");
	},
}
</script>
