<template>
	<PopupRouterViewDetail label="Employee Level Detail" @copyDataMethod="copyDataAction">
<!-- APP_DETAIL_HEADER -->
		<b-card>
			<b-col cols="12" lg="6">
					<b-form-group id="fieldset-name" label-cols-sm="4" label-cols-lg="3" label="Name" label-for="filter-name">
						{{ model != null ? model.name : "" }}
					</b-form-group>
					<b-form-group id="fieldset-description" label-cols-sm="4" label-cols-lg="3" label="Description" label-for="filter-description">
						{{ model != null ? model.description : "" }}
					</b-form-group>
					<b-form-group id="fieldset-parent" label-cols-sm="4" label-cols-lg="3" label="Parent" label-for="filter-parent">
						{{ model != null ? ( model.parent != null ? model.parent.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-created-by" label-cols-sm="4" label-cols-lg="3" label="Created By" label-for="filter-created-by">
						{{ model != null ? model.createdBy : "" }}
					</b-form-group>
					<b-form-group id="fieldset-updated-by" label-cols-sm="4" label-cols-lg="3" label="Updated By" label-for="filter-updated-by">
						{{ model != null ? model.updatedBy : "" }}
					</b-form-group>
			</b-col>
		</b-card>
<!-- END_APP_DETAIL_HEADER -->
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</PopupRouterViewDetail>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import EmployeeLevel from '@/models/EmployeeLevel/EmployeeLevel';
import EmployeeLevelList from '@/models/EmployeeLevel/EmployeeLevelList';
import EmployeeLevelFilter from '@/filters/EmployeeLevel/EmployeeLevelFilter';

import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
/*END_APP_DETAIL_IMPORT*/
export default {
	components : {
	/*APP_DETAIL_PROP_DEFINITION*/
	PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,
	/*APP_DETAIL_COMPONENTS_DEFINITION*/
},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			model: {},
			/*APP_DETAIL_PAGINATION*/
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterEmployeeLevel : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentEmployeeLevelPage : 1,
			employeeLevelPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByEmployeeLevel: '',
			sortDescEmployeeLevel: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			/*END_APP_DETAIL_OBJ_VARIABLE*/
		}
	},
	methods : {
		/*APP_DETAIL_GETEmployeeLevel*/
		getEmployeeLevel : async function(id) {
			this.isBusy = true;
			EmployeeLevel.getData(this, id).then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETEmployeeLevel*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'employeelevelcopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterEmployeeLevel = new EmployeeLevelFilter();
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	/*APP_DETAIL_MOUNTED*/
	mounted(){
		let id = this.$route.params.id;
		this.modelId = id;
		this.getEmployeeLevel(id);
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	/*END_APP_DETAIL_MOUNTED*/
	watch : {
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
