import BaseModelAPI from '../BaseModelAPI';

export default class OutgoingDocument extends BaseModelAPI {
	static entity = 'outgoingdocument';
	static entityName = 'outgoingdocument';

	constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.documentCode = '';
			this.documentLocationId = '';
			this.documentLocation = {};
			this.applicantId = '';
			this.applicant = {};
			this.destinationUser = '';
			this.destinationStatusId = '';
			this.destinationStatus = {};
			this.destinationContactPerson = '';
			this.destinationAddress = '';
			this.documentDate = '';
			this.description = '';
			this.contractDate = '';
			this.contractDescription = '';
			this.isDraft = false;
			this.isLampiran = false;
			this.draftAttachmentId = '';
			this.draftAttachment = {};
			this.lampiranAttachmentId = '';
			this.lampiranAttachment = {};
			this.remarks = '';
			this.outgoingDocumentStatusId = '';
			this.outgoingDocumentStatus = {};
			this.uploadValidationMessage = '';
			this.uploadValidationStatus = '';
	/*END_APP_MODEL_DEFINITION*/
	}

	static async getDestinationUserList(this_, filter, sorting, pageIndex, pageSize, include) {
        //console.log(...arguments);
        let filterArray = Array();
        if (filter != null && filter != undefined) {
            //console.log(filter);
            filterArray = this.getFilter(filter);
        }
        if (sorting != null && sorting != undefined) {
            Object.entries(sorting).map(([key, value]) => { 
                if(key != '') {
                    filterArray.push("sort=" + (value ? "-" : "+") + key);     // draftFromUpload & draftMode
                }
            });
                
        }
        if (pageIndex != null) {
            filterArray.push("page=" + pageIndex);
        }
        if (pageSize != null) {
            filterArray.push("per_page=" + pageSize);
        }
        if (include != null) {
            filterArray.push("include=" + include);
        }
        let params = "";
        if (filterArray.length > 0) {
            params = "?" + filterArray.join("&");
        }
        
        //DEBUG

        return this_.$http.get(`${this.entityName}destinationuserlist` + params)
        .then(response => {
            if (response != null) {
                return response.data;
            }
            return response;
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }
}