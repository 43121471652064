<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true id="popup-upload" @ok="uploadButtonAction" :okTitle="okTitle" title="Upload Data">
		<b-card>
			<b-row>
				<b-col cols="12">
					<app-input-fileupload label="File" size="sm" :model.sync="fileupload" />
				</b-col>
			</b-row>
			<b-row v-if="isUploadSuccess">
				<b-col cols="12">
					<b-card no-body>
						<b-tabs card>
							<b-tab title="Archived Document" active>
								<b-row class="mb-2">
									<b-col>
										<span class="float-right">
											<b-form-select v-model="archivedDocumentPageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
										</span>
									</b-col>
								</b-row>
								<b-row>
								<b-col>
								<div class="table-corner">
									<b-table id="my-table" stacked="md" head-variant="light" :no-local-sorting="true" :items="archivedDocuments" :fields="fieldsArchivedDocument" :busy="isDataLoading" :sort-by.sync="sortByArchivedDocument" :sort-desc.sync="sortDescArchivedDocument" small responsive>
									<template v-slot:head(actions)>
										<b-button @click="isShowArchivedDocumentFilter = !isShowArchivedDocumentFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
									</template>
									<template #top-row v-if="isShowArchivedDocumentFilter">
										<b-th></b-th>
										<b-th stacked-heading="No. Berita Acara">
											<b-form-input id="input-no-berita-acara-table" v-model="filterArchivedDocument.noBeritaAcara" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Periode Arsip Dari">
											<b-form-datepicker boundary="viewport" placeholder="" id="input-archived-date-from-from-table" v-model="filterArchivedDocument.archivedDateFromFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
											<b-form-datepicker boundary="viewport" placeholder="" id="input-archived-date-from-to-table" v-model="filterArchivedDocument.archivedDateFromTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
										</b-th>
										<b-th stacked-heading="Periode Arsip Sampai">
											<b-form-datepicker boundary="viewport" placeholder="" id="input-archived-date-to-from-table" v-model="filterArchivedDocument.archivedDateToFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
											<b-form-datepicker boundary="viewport" placeholder="" id="input-archived-date-to-to-table" v-model="filterArchivedDocument.archivedDateToTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button></b-form-datepicker>
										</b-th>
										<b-th stacked-heading="Lokasi Arsip">
											<v-select append-to-body label="name" :options="documentLocations" :value="filterArchivedDocument.documentLocationId"  :reduce="item => item.id" v-model="filterArchivedDocument.documentLocationId" @search="getDocumentLocations" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Pelaksana">
											<v-select append-to-body label="fullName" :options="userProfiles" :value="filterArchivedDocument.pICId"  :reduce="item => item.id" v-model="filterArchivedDocument.pICId" @search="getUserProfiles" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Menyetujui">
											<v-select append-to-body label="fullName" :options="userProfiles" :value="filterArchivedDocument.approvedById"  :reduce="item => item.id" v-model="filterArchivedDocument.approvedById" @search="getUserProfiles" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Metode Hapus">
											<v-select append-to-body label="name" :options="removalMethods" :value="filterArchivedDocument.removalMethodId"  :reduce="item => item.id" v-model="filterArchivedDocument.removalMethodId" @search="getRemovalMethods" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Catatan">
											<b-form-input id="input-remarks-table" v-model="filterArchivedDocument.remarks" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Surat Masuk">
											<v-select append-to-body label="documentCode" :options="incomingDocuments" :value="filterArchivedDocument.incomingDocumentId"  :reduce="item => item.id" v-model="filterArchivedDocument.incomingDocumentId" @search="getIncomingDocuments" :filterable="true"></v-select>
										</b-th>
										<b-th stacked-heading="Surat Keluar">
											<v-select append-to-body label="documentCode" :options="outgoingDocuments" :value="filterArchivedDocument.outgoingDocumentId"  :reduce="item => item.id" v-model="filterArchivedDocument.outgoingDocumentId" @search="getOutgoingDocuments" :filterable="true"></v-select>
										</b-th>
										<b-th>
										</b-th>
									</template>
									<template v-slot:table-busys>
										<div class="text-center my-2">
									<b-spinner class="align-middle text-primary"></b-spinner>
										<strong class="ml-2">Loading..</strong>
										</div>
									</template>
									<template v-slot:cell(no)="row">
										{{ ((row.index + 1) + ( archivedDocumentPageSize * (currentArchivedDocumentPage - 1))) }}
									</template>
									<template v-slot:cell(archivedDateFrom)="row">
										{{ row.item.archivedDateFrom | moment("DD-MMM-YYYY")  }}
									</template>
									<template v-slot:cell(archivedDateTo)="row">
										{{ row.item.archivedDateTo | moment("DD-MMM-YYYY")  }}
									</template>
									<template v-slot:cell(documentLocation)="row">
										{{ row.item.documentLocation != null ? row.item.documentLocation.name : "" }}
									</template>
									<template v-slot:cell(pIC)="row">
										{{ row.item.pIC != null ? row.item.pIC.fullName : "" }}
									</template>
									<template v-slot:cell(approvedBy)="row">
										{{ row.item.approvedBy != null ? row.item.approvedBy.fullName : "" }}
									</template>
									<template v-slot:cell(removalMethod)="row">
										{{ row.item.removalMethod != null ? row.item.removalMethod.name : "" }}
									</template>
									<template v-slot:cell(incomingDocument)="row">
										{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentCode : "" }}
									</template>
									<template v-slot:cell(outgoingDocument)="row">
										{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentCode : "" }}
									</template>
									</b-table>
									</div>
								</b-col>
								<b-col cols="12">
									<b-pagination
									class="float-right btn-paging"
									v-model="currentArchivedDocumentPage"
									:total-rows="totalArchivedDocumentRow"
									:per-page="archivedDocumentPageSize"
									aria-controls="my-table"
									></b-pagination>
								</b-col>
								</b-row>
							</b-tab>
							<b-tab title="Investigator Detail" active>
								<b-row class="mb-2">
									<b-col>
										<span class="float-right">
											<b-form-select v-model="investigatorDetailPageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
										</span>
									</b-col>
								</b-row>
								<b-row>
								<b-col>
								<div class="table-corner">
									<b-table id="my-table" stacked="md" head-variant="light" :no-local-sorting="true" :items="investigatorDetails" :fields="fieldsInvestigatorDetail" :busy="isDataLoading" :sort-by.sync="sortByInvestigatorDetail" :sort-desc.sync="sortDescInvestigatorDetail" small responsive>
									<template v-slot:head(actions)>
										<b-button @click="isShowInvestigatorDetailFilter = !isShowInvestigatorDetailFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
									</template>
									<template #top-row v-if="isShowInvestigatorDetailFilter">
										<b-th></b-th>
										<b-th>
										</b-th>
										<b-th stacked-heading="User Profile">
											<v-select append-to-body label="fullName" :options="userProfiles" :value="filterInvestigatorDetail.userProfileId"  :reduce="item => item.id" v-model="filterInvestigatorDetail.userProfileId" @search="getUserProfiles" :filterable="true"></v-select>
										</b-th>
										<b-th>
										</b-th>
									</template>
									<template v-slot:table-busys>
										<div class="text-center my-2">
									<b-spinner class="align-middle text-primary"></b-spinner>
										<strong class="ml-2">Loading..</strong>
										</div>
									</template>
									<template v-slot:cell(no)="row">
										{{ ((row.index + 1) + ( investigatorDetailPageSize * (currentInvestigatorDetailPage - 1))) }}
									</template>
									<template v-slot:cell(archivedDocument)="row">
										{{ row.item.archivedDocument != null ? row.item.archivedDocument.noBeritaAcara : "" }}
									</template>
									<template v-slot:cell(userProfile)="row">
										{{ row.item.userProfile != null ? row.item.userProfile.fullName : "" }}
									</template>
									</b-table>
									</div>
								</b-col>
								<b-col cols="12">
									<b-pagination
									class="float-right btn-paging"
									v-model="currentInvestigatorDetailPage"
									:total-rows="totalInvestigatorDetailRow"
									:per-page="investigatorDetailPageSize"
									aria-controls="my-table"
									></b-pagination>
								</b-col>
								</b-row>
							</b-tab>
						</b-tabs>
					</b-card>
				</b-col>
			</b-row>
		</b-card>
		<template #modal-footer="{ ok, cancel }">
			<b-button v-if="isUploadSuccess" size="sm" variant="primary" @click="downloadLog()">
				Download Log
			</b-button>
			<b-button size="sm" variant="success" class="float-right" @click="ok()">
				{{ okTitle }}
			</b-button>
			<b-button size="sm" variant="secondary" class="float-right" @click="cancel()">
				Cancel
			</b-button>
		</template>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<style lang="scss" scoped>
.card {
	border: 1px solid rgba(0, 0, 0, 0.125) !important;
}
::v-deep .th-message {
	color: red;
}
::v-deep .modal-footer {
	display: block;
}
</style>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate"; 
import { required, minLength } from "vuelidate/lib/validators"; 
import { saveAs } from 'file-saver';

import ArchivedDocument from '@/models/ArchivedDocument/ArchivedDocument';
import ArchivedDocumentList from '@/models/ArchivedDocument/ArchivedDocumentList';
import ArchivedDocumentFilter from '@/filters/ArchivedDocument/ArchivedDocumentFilter';

import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import RemovalMethod from '@/models/RemovalMethod/RemovalMethod';
import RemovalMethodFilter from '@/filters/RemovalMethod/RemovalMethodFilter';
import InvestigatorDetail from '@/models/ArchivedDocument/InvestigatorDetail';
import InvestigatorDetailFilter from '@/filters/ArchivedDocument/InvestigatorDetailFilter';
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';
import AppInputFileupload from '@/components/AppInputFileupload';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
export default {
	props: ['model', 'openPopup','uploadButtonMethod', 'parentId'],
	components : {AppInputFileupload,AppInputDatetimepicker,AppInputDatetimepickerRange,},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fieldsArchivedDocument: [
				{"no" : { "class" : "th-number"}}, 
				{"noBeritaAcara": { "class" : "th-no-berita-acara", "label" : "No. Berita Acara"}, key: 'noBeritaAcara', sortable: true}, 
				{"archivedDateFrom": { "class" : "th-archived-date-from", "label" : "Periode Arsip Dari"}, key: 'archivedDateFrom', sortable: true}, 
				{"archivedDateTo": { "class" : "th-archived-date-to", "label" : "Periode Arsip Sampai"}, key: 'archivedDateTo', sortable: true}, 
				{"documentLocation": { "class" : "th-document-location", "label" : "Lokasi Arsip"}, key: 'documentLocation', sortable: true}, 
				{"pIC": { "class" : "th-p-i-c", "label" : "Pelaksana"}, key: 'pIC', sortable: true}, 
				{"approvedBy": { "class" : "th-approved-by", "label" : "Menyetujui"}, key: 'approvedBy', sortable: true}, 
				{"removalMethod": { "class" : "th-removal-method", "label" : "Metode Hapus"}, key: 'removalMethod', sortable: true}, 
				{"remarks": { "class" : "th-remarks", "label" : "Catatan"}, key: 'remarks', sortable: true}, 
				{"incomingDocument": { "class" : "th-incoming-document", "label" : "Surat Masuk"}, key: 'incomingDocument', sortable: true}, 
				{"outgoingDocument": { "class" : "th-outgoing-document", "label" : "Surat Keluar"}, key: 'outgoingDocument', sortable: true}, 
				{"class" : "th-status", "label" : "Status", key: 'uploadValidationStatus', sortable: true},
				{"class" : "th-message", "label" : "Message", key: 'uploadValidationMessage', sortable: true},
				{"actions": { "class" : "th-actions", "label" : "" }}
			],
			fieldsInvestigatorDetail: [
				{"no" : { "class" : "th-number"}}, 
				{"archivedDocument": { "class" : "th-archived-document", "label" : "ArchivedDocument"}, key: 'archivedDocument', sortable: true}, 
				{"userProfile": { "class" : "th-user-profile", "label" : "User Profile"}, key: 'userProfile', sortable: true}, 
				{"class" : "th-status", "label" : "Status", key: 'uploadValidationStatus', sortable: true},
				{"class" : "th-message", "label" : "Message", key: 'uploadValidationMessage', sortable: true},
				{"actions": { "class" : "th-actions", "label" : "" }}
			],
			busy:false,
			isDataLoading: false,
			isBusy : false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			archivedDocuments : [],
			archivedDocumentId : '',
			totalArchivedDocumentRow : 0,
			isShowArchivedDocumentFilter: false,
			filterArchivedDocument : null,
			currentArchivedDocumentPage : 1,
			archivedDocumentPageSize: 10,
			sortByArchivedDocument: '',
			sortDescArchivedDocument: '',
			filterInvestigatorDetail : null,
			currentInvestigatorDetailPage : 1,
			investigatorDetailPageSize: 10,
			sortByInvestigatorDetail: '',
			sortDescInvestigatorDetail: '',
			sortingByInvestigatorDetail: [],
			investigatorDetails : [],
			investigatorDetailId : '',
			totalInvestigatorDetailRow : 0,
			isShowInvestigatorDetailFilter: false,
			archivedDocuments: [],
			userProfiles: [],
			documentLocations: [],
			removalMethods: [],
			incomingDocuments: [],
			outgoingDocuments: [],
			processId: "",
			isUploadSuccess: false,
		}
	},
	methods : {
		resetModal() {
			this.datas = Array();
			this.$emit('resetMethod');
		},
		uploadButtonAction(modalEvent) {
			modalEvent.preventDefault();
			this.isBusy = true;
			if (this.isUploadSuccess) {
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'archiveddocument/confirmUpload',
					[],
					).then(async function(response){
						if (response != null) {
						if (response.data != null) {
							_this.resetModal();
							_this.datas = Array();
							_this.fileupload = null;
							_this.isUploadSuccess = false;
						}
						}
						_this.isBusy = false;
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
			} else {
				if (this.fileupload != null) {
				let data = new FormData();
				data.append('file', this.fileupload);
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'archiveddocument/upload',
					data,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
					).then(async function(response){
						_this.isBusy = false;
						_this.isUploadSuccess = true;
						_this.getArchivedDocuments();
						_this.getInvestigatorDetails();
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
				}
			}
		},
		getArchivedDocuments : async function() {
			let filter = {};
			Object.assign(filter, this.filterArchivedDocument);
			filter.draftFromUpload = "1";
			filter.draftMode = "1";
			this.sortingByArchivedDocument = Array();
			if (this.sortByArchivedDocument != null) {
				this.sortingByArchivedDocument[this.sortByArchivedDocument] = this.sortDescArchivedDocument;
			}
			let currentPage = (this.currentArchivedDocumentPage - 1);
			ArchivedDocument.getList(this, filter, this.sortingByArchivedDocument, currentPage , this.archivedDocumentPageSize).then(result => {
				this.archivedDocuments = result.data;
				this.totalArchivedDocumentRow = result.dataCount;
			}).catch(error => {});
		},
		getInvestigatorDetails : async function() {
			let filter = {};
			Object.assign(filter, this.filterInvestigatorDetail);
			filter.draftFromUpload = "1";
			filter.draftMode = "1";
			this.sortingByInvestigatorDetail = Array();
			if (this.sortByInvestigatorDetail != null) {
				this.sortingByInvestigatorDetail[this.sortByInvestigatorDetail] = this.sortDescInvestigatorDetail;
			}
			let currentPage = (this.currentInvestigatorDetailPage - 1);
			InvestigatorDetail.getList(this, filter, this.sortingByInvestigatorDetail, currentPage , this.investigatorDetailPageSize).then(result => {
				this.investigatorDetails = result.data;
				this.totalInvestigatorDetailRow = result.dataCount;
			}).catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentLocation.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		getRemovalMethods : async function(input) {
			let filter = new RemovalMethodFilter();
			if (input != undefined) {
				filter.name = input;
			}
			RemovalMethod.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.removalMethods = result.data
				}
			})
			.catch(error => {});
		},
		getIncomingDocuments : async function(input) {
			let filter = new IncomingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			IncomingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		getOutgoingDocuments : async function(input) {
			let filter = new OutgoingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			OutgoingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.outgoingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
				this.isShowInvestigatorDetailFilter = true;
			}
		},
		downloadLog() {
			let _this = this;
			this.$http.post(process.env.VUE_APP_API_URL + 'archiveddocument/downloadlog', [],
			{ headers: { 'Content-Type': 'application/json' }}
			).then(async function(response){
				await saveAs(process.env.VUE_APP_API_URL + 'archiveddocument/downloadlog', "ArchivedDocument.xslx");
				_this.isBusy = false;
			})
			.catch(error => {
				_this.$store.dispatch('addErrorMessageGlobal', error);
				_this.isBusy = false;
			});
		}
	},
	beforeMount() {
	},
	mounted(){
	},
	watch: {
		sortByArchivedDocument : {
			handler: function() {
				this.getArchivedDocuments();
			}
		},
		sortDescArchivedDocument: {
			handler: function() {
				this.getArchivedDocuments();
			}
		},
		currentArchivedDocumentPage: {
			handler: function() {
				this.getArchivedDocuments();
			}
		},
		archivedDocumentPageSize : {
			handler: function() {
				this.getArchivedDocuments();
			}
		},
		filterArchivedDocument: {
			handler: function() {
				this.getArchivedDocuments();
			},
			deep: true,
		},
		sortByInvestigatorDetail : {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		sortDescInvestigatorDetail: {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		currentInvestigatorDetailPage: {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		investigatorDetailPageSize : {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		filterInvestigatorDetail: {
			handler: function() {
				this.getInvestigatorDetails();
			},
			deep: true,
		},
		fileupload(newValue, oldValue) {
			this.datas = Array();
			this.isUploadSuccess = false;
		}
	},
	computed: {
		okTitle: {
			get: function() {
				if (this.isUploadSuccess) {
					return "Proses";
				}
				return "Simpan";
				}
		},
		isShowPopup : {
			get: function () {
				if (this.openPopup) {
					this.filterArchivedDocument = new ArchivedDocumentFilter();
					this.filterInvestigatorDetail = new InvestigatorDetailFilter();
					return true;
				}
				else return false;
			},
			set: function (newValue) {}
		},
	}
}
</script>
