import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';

export default class DocumentLocationFilter {

	constructor() {
	/*APP_FILTER_DEFINITION*/
		this.id = '';
		this.name = '';
		this.isFromUpload = false;
		this.isSavedToBackend = false;
	/*END_APP_FILTER_DEFINITION*/
		this.exactType = {
	/*APP_APP_FILTER_CRITERIA*/
			id : '1',
			name : '0',
	/*END_APP_FILTER_CRITERIA*/
		};
	}

}