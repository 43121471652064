import BaseModelAPI from '../BaseModelAPI';

export default class ReceiptDocument extends BaseModelAPI {
	static entity = 'receiptdocument';
	static entityName = 'receiptdocument';

	constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.receiptDocumentNo = '';
			this.documentCode = '';
			this.documentCreatedDate = '';
			this.documentLocationId = '';
			this.documentLocation = {};
			this.senderName = '';
			this.destinationUserId = '';
			this.destinationUser = {};
			this.documentDate = '';
			this.documentTypeId = '';
			this.documentType = {};
			this.documentNo = '';
			this.documentDescription = '';
			this.deliveryUserId = '';
			this.deliveryUser = {};
			this.receiptUserId = '';
			this.receiptUser = {};
			this.remarks = '';
			this.incomingDocumentId = '';
			this.incomingDocument = {};
			this.outgoingDocument = '';
			this.isFromUpload = false;
			this.isSavedToBackend = true;
			this.uploadValidationMessage = '';
			this.uploadValidationStatus = '';
	/*END_APP_MODEL_DEFINITION*/
	}

	static async insertMultipleData(this_, data) {
        return this_.$http.post(process.env.VUE_APP_API_URL + `${this.entityName}multiple`, data)
        .then(response => {
            if (response.data) {
                if (response.data.data) {
                    return response.data.data;
                }
                return response.data;
            }
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

}