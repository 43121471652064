import BaseModelAPI from '../BaseModelAPI';

export default class ReceiptDocumentList extends BaseModelAPI {

	static entity = 'receiptdocumentlist'
	static entityName = 'receiptdocument';

	constructor() {
		this.pageIndex = 0;
		this.pageSize = 0;
		this.pageCount = 0;
		this.dataCount = 0;
	}
}
