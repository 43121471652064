import BaseModelAPI from '../BaseModelAPI';

export default class UserProfile extends BaseModelAPI {
	static entity = 'userprofile';
	static entityName = 'userprofile';

	constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.fullName = '';
			this.jobDescription = '';
			this.email = '';
			this.coreUserId = '';
			this.coreUser = {};
			this.branchId = '';
			this.branch = {};
			this.directorateId = '';
			this.directorate = {};
			this.employeeLevelId = '';
			this.employeeLevel = {};
			this.employeeStatusId = '';
			this.employeeStatus = {};
			this.educationLevelId = '';
			this.educationLevel = {};
			this.bloodTypeId = '';
			this.bloodType = {};
			this.maritalStatusId = '';
			this.maritalStatus = {};
			this.joinDate = '';
			this.birthDate = '';
			this.kidsCount = '';
			this.address = '';
			this.phoneNumber = '';
			this.npwpNumber = '';
			this.nip = '';
			this.realEmail = '';
			this.uploadValidationMessage = '';
			this.uploadValidationStatus = '';
	/*END_APP_MODEL_DEFINITION*/
	}

}