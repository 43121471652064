import ReceiptDocument from '@/models/ReceiptDocument/ReceiptDocument';

export default class ReceiptDocumentFilter {

	constructor() {
	/*APP_FILTER_DEFINITION*/
		this.id = '';
		this.receiptDocumentNo = '';
		this.documentCode = '';
		this.documentCreatedDateFrom = '';
		this.documentCreatedDateTo = '';
		this.documentLocationId  = '';
		this.documentLocationName = '';
		this.senderName = '';
		this.destinationUserId  = '';
		this.destinationUserFullName = '';
		this.documentDateFrom = '';
		this.documentDateTo = '';
		this.documentTypeId  = '';
		this.documentTypeName = '';
		this.documentNo = '';
		this.documentDescriptionMultiple = '';
		this.deliveryUserId  = '';
		this.deliveryUserFullName = '';
		this.receiptUserId  = '';
		this.receiptUserFullName = '';
		this.remarksMultiple = '';
		this.incomingDocumentId  = '';
		this.incomingDocumentDocumentCode = '';
		this.outgoingDocument = '';
		this.isFromUpload = false;
		this.isSavedToBackend = false;
		this.draftFromUpload = '';
		this.draftMode = '';
	/*END_APP_FILTER_DEFINITION*/
		this.exactType = {
	/*APP_APP_FILTER_CRITERIA*/
			id : '0',
			receiptDocumentNo : '0',
			documentCode : '0',
			documentCreatedDateFrom : '0',
			documentCreatedDateTo : '0',
			documentLocationId : '0',
			documentLocationName : '0',
			senderName : '0',
			destinationUserId : '0',
			destinationUserFullName : '0',
			documentDateFrom : '0',
			documentDateTo : '0',
			documentTypeId : '0',
			documentTypeName : '0',
			documentNo : '0',
			documentDescriptionMultiple : '0',
			deliveryUserId : '0',
			deliveryUserFullName : '0',
			receiptUserId : '0',
			receiptUserFullName : '0',
			remarksMultiple : '0',
			incomingDocumentId : '0',
			incomingDocumentDocumentCode : '0',
			outgoingDocument : '0',
			isFromUpload : '0',
			isSavedToBackend : '0',
	/*END_APP_FILTER_CRITERIA*/
		};
	}

}