import BaseModelAPI from '../BaseModelAPI';

export default class IncomingDocumentStatusList extends BaseModelAPI {

	static entity = 'incomingdocumentstatuslist'
	static entityName = 'incomingdocumentstatus';

	constructor() {
		this.pageIndex = 0;
		this.pageSize = 0;
		this.pageCount = 0;
		this.dataCount = 0;
	}
}
