import BaseModelAPI from '../BaseModelAPI';

export default class InvestigatorDetail extends BaseModelAPI {
	static entity = 'investigatordetail';
	static entityName = 'investigatordetail';

	constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.archivedDocumentId = '';
			this.archivedDocument = {};
			this.userProfileId = '';
			this.userProfile = {};
			this.uploadValidationMessage = '';
			this.uploadValidationStatus = '';
	/*END_APP_MODEL_DEFINITION*/
	}

}