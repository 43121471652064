import BaseModelAPI from '../BaseModelAPI';

export default class OutgoingDocumentAttachmentDetail extends BaseModelAPI {
	static entity = 'outgoingdocumentattachmentdetail';
	static entityName = 'outgoingdocumentattachmentdetail';

	constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.outgoingDocumentId = '';
			this.outgoingDocumentId = {};
			this.fileName = '';
			this.attachmentId = '';
			this.attachment = {};
			this.createdBy = '';
			this.updatedBy = '';
			this.uploadValidationMessage = '';
			this.uploadValidationStatus = '';
	/*END_APP_MODEL_DEFINITION*/
	}

}