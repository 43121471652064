import DocumentStatus from '@/models/DocumentStatus/DocumentStatus';

export default class DocumentStatusFilter {

	constructor() {
	/*APP_FILTER_DEFINITION*/
		this.id = '';
		this.code = '';
		this.name = '';
		this.isFromUpload = false;
		this.isSavedToBackend = false;
		this.draftFromUpload = '';
		this.draftMode = '';
	/*END_APP_FILTER_DEFINITION*/
		this.exactType = {
	/*APP_APP_FILTER_CRITERIA*/
			id : '0',
			code : '0',
			name : '0',
			isFromUpload : '0',
			isSavedToBackend : '0',
	/*END_APP_FILTER_CRITERIA*/
		};
	}

}