import BaseModelAPI from '../BaseModelAPI';

export default class FunctionInfoList extends BaseModelAPI {

	static entity = 'functioninfolist'
	static entityName = 'functioninfo';

	constructor() {
		this.pageIndex = 0;
		this.pageSize = 0;
		this.pageCount = 0;
		this.dataCount = 0;
	}
}
