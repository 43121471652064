<template>
    <div>
        <b-form-group :id="groupId" v-bind="$attrs">
            <template v-slot:label>{{ labelStr }} <span v-if="isRequired" class="required"></span></template>
            <v-select :options="options" :label="optionsLabel" multiple :value="selectedValue" :filterable="true" @input="setSelected" @search="searchData" v-bind="$attrs"></v-select>
            <b-form-invalid-feedback :id="feedbackId" :state="state">
                {{ errorMessage }}
            </b-form-invalid-feedback>
        </b-form-group>
    </div>
</template>
<script>
export default {
    props: ["model", "name", "selected", "errorMessage", "options", "isEditable", "label", "isRequired", "optionsLabel", "optionsKey", "change", "state", "maxItem"],
    data() {
        return {
            labelStr: this.label,
            value:"",
            selectedValue: this.selected,
            inputModel: this.model,
            groupId : "fieldset-" + name,
            feedbackId : "input-" + name + "-live-feedback",
        }
    }, 
    methods: {
        setSelected(value) {
            console.log(value);
            let allowSelect = true;

            if (this.maxItem != null && this.maxItem != undefined && this.maxItem != "") {
                if (value.length > this.maxItem) {
                    allowSelect = false;
                }
            }

            if (allowSelect) {
                this.inputModel = value;
                this.selectedValue = value;
                this.$emit('update:model', this.inputModel);
                this.$emit("change");
            }
        },
        searchData(search, loading) {
            if (this.maxItem != null && this.maxItem != undefined && this.maxItem != "") {
                if (this.selectedValue.length <= this.maxItem) {
                    this.$emit("input", search);
                }
            } else {
                this.$emit("input", search);
            }
        },
    },
    watch: {
        model(newValue) {
            this.inputModel = newValue;
            if (newValue == null || newValue == '') {
                this.selectedValue = '';
            }
        },
        selected(newValue) {
            this.inputModel = newValue;
            this.$emit('update:model', this.inputModel);
            this.selectedValue = newValue;
        }
    }
}
</script>
<style scoped>
.input-group-text {
    font-size: 0.7rem !important;
}
</style>