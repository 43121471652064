import BaseModelAPI from '../BaseModelAPI';

export default class Disposition extends BaseModelAPI {
	static entity = 'disposition';
	static entityName = 'disposition';

	constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.incomingDocumentId = '';
			this.incomingDocument = {};
			this.userReceiverId = '';
			this.userReceiver = {};
			this.remarks = '';
			this.uploadValidationMessage = '';
			this.uploadValidationStatus = '';
	/*END_APP_MODEL_DEFINITION*/
	}

}