<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true @ok="saveButtonAction" title="Save Confirmation - Contract Document">
		<div>
			<b-card>
				<b-row>
					<b-col cols="12" lg="6">
					<app-input-combobox-autocomplete :options="senderNames" optionsLabel="sendername" optionsKey="sendername" label-cols-sm="4" label-cols-lg="3" label="Nama Mitra" id="filter-" size="sm" :model.sync="filter.senderName" @input="getSenderNames" @focus="getSenderNames" />
					<app-input-textbox v-if="false" id="fieldset-no-berita-acara" label-cols-sm="4" label-cols-lg="3" label="Nama Mitra" label-for="filter-no-berita-acara" size="sm" :model.sync="filter.senderName" />
					<app-input-datepicker-range  id="fieldset-contract-date-from" label-cols-sm="4" label-cols-lg="3" label="Periode" size="sm" :modelFrom.sync="filter.contractDateFromStart" :modelTo.sync="filter.contractDateToStop" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button />
					<app-input-datepicker-range v-if="false"  id="fieldset-contract-date-to" label-cols-sm="4" label-cols-lg="3" label="Periode Sampai" size="sm" :modelFrom.sync="filter.contractDateToStart" :modelTo.sync="filter.contractDateToStop" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button />
					<b-button variant="danger" size="sm" class="float-right" @click="resetFilter"><span style="font-size: 0.75rem" class="mr-1"><b-icon-x></b-icon-x></span> Clear Filter</b-button>
					<b-button variant="primary" size="sm" class="float-right mr-1" @click="getContractDocuments"><span style="font-size: 0.75rem" class="mr-1"><b-icon-search></b-icon-search></span> Cari</b-button>
					</b-col>
				</b-row>
			</b-card>
			<b-row class="mt-3">
				<b-col>
					<b-button variant="warning" v-if="(userRole != null ? userRole.allowUpload : false)" class="float-right ml-2" @click="showPopupUploadData" size="sm"><b-icon-upload class="mr-1"></b-icon-upload> Upload</b-button>
				</b-col>
			</b-row>
			<b-row v-if="contractDocuments != null && contractDocuments.length > 0">
				<b-col>
					<b-card>
						<b-button class="float-right mb-2" variant="primary" size="sm" @click="printPage"><b-icon-printer-fill /> Print Preview</b-button>
						<b-table id="my-table" stacked="md" head-variant="light"  :no-local-sorting="true" :items="contractDocuments" :fields="fields" :busy="isDataLoading" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" small responsive>
							<template v-slot:head(actions)>
								<b-button-group class="float-right"  size="sm">
									<b-button @click="isShowFilter = !isShowFilter" size="sm"><b-icon-funnel-fill /></b-button>
									<b-button><b-form-checkbox v-if="(userRole != null ? userRole.allowPrint : false)" id="input-select-all" class="float-right" v-model="selectAll"></b-form-checkbox></b-button>
								</b-button-group>
							</template>
							<template #top-row v-if="isShowFilter">
							</template>
							<template v-slot:cell(no)="row">
								{{ row.index + 1 }}
							</template>
							<template v-slot:cell(incomingDocument)="row">
								{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentCode : "" }}
							</template>
							<template v-slot:cell(outgoingDocument)="row">
								{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentCode : "" }}
							</template>
							<template v-slot:cell(contractDate)="row">
								{{ row.item.contractDate | moment("DD-MMM-YYYY") }}
							</template>
							<template v-slot:cell(contractStatus)="row">
								{{ row.item.contractStatus != null ? row.item.contractStatus.name : "" }}
							</template>
							<template v-slot:cell(documentCode)="row">
								<span v-if="row.item.incomingDocument != null">
									{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentCode : "" }}
								</span>
								<span v-else-if="row.item.outgoingDocument != null">
									{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentCode : "" }}
								</span>
							</template>
							<template v-slot:cell(senderName)="row">
								<span v-if="row.item.incomingDocument != null">
									{{ row.item.incomingDocument != null ? row.item.incomingDocument.senderName : "" }}
								</span>
								<span v-if="row.item.outgoingDocument != null">
									<span v-if="row.item.outgoingDocument.destinationUser != null">
										{{ row.item.outgoingDocument.destinationUser }}
									</span>
									<span v-else-if="row.item.outgoingDocumentDestinationUser != null">
										<b-badge variant="secondary" v-for="item in row.item.outgoingDocumentDestinationUser.slice(0,3)" :key="item.id" class="mr-2" style="font-size:13px; font-weight: 400;background-color:#bbb">
											{{ item.destinationUser ? item.destinationUser.fullName : "-" }}
										</b-badge>
									</span>
								</span>
							</template>
							<template v-slot:cell(contactPerson)="row">
								<span v-if="row.item.incomingDocument != null">
									{{ row.item.incomingDocument != null ? row.item.incomingDocument.contactPerson : "" }}
								</span>
								<span v-else-if="row.item.outgoingDocument != null">
									{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.contactPerson : "" }}
								</span>
							</template>
							<template v-slot:cell(documentDate)="row">
								<span v-if="row.item.incomingDocument != null">
									{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentDate : "" | moment("DD-MMM-YYYY")}}
								</span>
								<span v-else-if="row.item.outgoingDocument != null">
									{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentDate : "" | moment("DD-MMM-YYYY")}}
								</span>
							</template>
							<template v-slot:cell(documentType)="row">
								<span v-if="row.item.incomingDocument != null">
									{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentType != null ? row.item.incomingDocument.documentType.name : "" : "" }}
								</span>
								<span v-else-if="row.item.outgoingDocument != null">
									{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentType != null ? row.item.outgoingDocument.documentType.name : "" : "" }}
								</span>
							</template>
							<template v-slot:cell(documentNo)="row">
								<span v-if="row.item.incomingDocument != null">
									{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentNo : "" }}
								</span>
								<span v-else-if="row.item.outgoingDocument != null">
									{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentNo : "" }}
								</span>
							</template>
							<template v-slot:cell(remainingContractDay)="row">
								{{ row.item.contractDateFrom | moment("DD-MMM-YYYY") }} s/d {{ row.item.contractDateTo | moment("DD-MMM-YYYY") }}
							</template>
						</b-table>
					</b-card>
				</b-col>
			</b-row>
		</div>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<style lang="scss" scoped>
	@import '../../assets/scss/index-page.scss';
</style>
<script>
import ContractDocument from '@/models/ContractDocument/ContractDocument';
import ContractDocumentList from '@/models/ContractDocument/ContractDocumentList';
import ContractDocumentFilter from '@/filters/ContractDocument/ContractDocumentFilter';
import RoleDetail from '@/models/Core/RoleDetail';

import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import AppInputTextarea from '@/components/AppInputTextarea';
import AppInputTextbox from '@/components/AppInputTextbox';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import ContractStatusFilter from '@/filters/ContractStatus/ContractStatusFilter';
import ContractStatus from '@/models/ContractStatus/ContractStatus';
import PopupDownload from '@/components/PopupDownload';
import PopupPDF from '@/components/PopupPDF';
import PopupDataEditNotification from '@/components/PopupDataEditNotification';
import Upload from './Upload';

export default {
	/*APP_DETAIL_PROP_DEFINITION*/
	props : ["openPopup", "model", "saveActionMethod", "resetMethod"],
	/*END_APP_DETAIL_PROP_DEFINITION*/
	components : {
	/*APP_DETAIL_PROP_DEFINITION*/
	AppInputDatepicker,AppInputDatepickerRange,AppInputComboboxAutocomplete,AppInputTextarea,AppInputTextbox
	/*APP_DETAIL_COMPONENTS_DEFINITION*/
	},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			filter : null,
			currentContractDocumentPage : 1,
			contractDocumentPageSize: 10,
			sortByContractDocument: '',
			sortDescContractDocument: '',
			incomingDocuments : [],
			sortingBy: Array(),
			filter: null,
			sortBy: '',
			sortDesc: '',
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fields: [
				{"actionsCheck": { "class" : "th-actions-check", "label" : ""}},
				{"no" : { "class" : "th-number"}}, 
				{"documentCode": {}, "class" : "th-incoming-document", "label" : "Kode Risalah", key: 'documentCode', sortable: true}, 
				{"senderName": {}, "class" : "th-incoming-document", "label" : "Nama Mitra Kerjasama", key: 'senderName', sortable: true}, 
				{"contactPerson": {}, "class" : "th-incoming-document", "label" : "No. Telepon", key: 'contactPerson', sortable: true}, 
				{"documentDate": {}, "class" : "th-document-date", "label" : "Tanggal Surat", key: 'documentDate', sortable: true}, 
				{"documentType": {}, "class" : "th-document-type", "label" : "Jenis Surat", key: 'documentType', sortable: true}, 
				{"documentNo": {}, "class" : "th-document-no", "label" : "Nomor Surat", key: 'documentNo', sortable: true},
				{"documentAttachment": {}, "class" : "th-document-attachment", "label" : "File Dokumen", key: 'documentAttachment', sortable: true}, 
				{"remainingDay": {}, "class" : "th-remaining-day", "label" : "Masa Berlaku Kontrak", key: 'remainingContractDay', sortable: true}, 
				{"contractStatus": {}, "class" : "th-contract-status", "label" : "Status Kontrak", key: 'contractStatus', sortable: true}, 
				{"actions": { "class" : "th-actions", "label" : ""}}
			],
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			contractDocuments: [],
			isShowFilter: false,
			senderNames: [],
		}
	},
	methods : {
		printPage() {
			if (this.filter != null && this.filter != undefined && this.filter != "") {
				let senderName = this.filter.senderName != null && this.filter.senderName != undefined && this.filter.senderName != "" ? this.filter.senderName : 0;
				let contractDateFromStart = this.filter.contractDateFromStart != null && this.filter.contractDateFromStart != undefined ? this.filter.contractDateFromStart : "";
				let contractDateToStop = this.filter.contractDateToStop != null && this.filter.contractDateToStop != undefined ? this.filter.contractDateToStop : "";
				window.open('/contractdocument/print/' + senderName + '?contractDateFromStart=' + contractDateFromStart + '&contractDateToStop=' + contractDateToStop, '_blank');
			}
		},
		resetFilter() {
			this.filter = {};
		},
		getContractDocument : async function(id) {
			this.isBusy = true;
			ContractDocument.getData(this, id).then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		saveButtonAction(modalEvent) {
			this.$emit("saveActionMethod", modalEvent);
		},
		resetModal() {
			this.$emit('resetMethod'); 
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		getContractDocuments() {
			this.isDataLoading = true;
			this.sortingBy = Array();
			if (this.sortBy != null && this.sortBy !=  "") {
				this.sortingBy[this.sortBy] = this.sortDesc;
			} else {
				this.sortingBy["contractDate"] = true;
			}
			
			ContractDocument.getList(this, this.filter, this.sortingBy, 0, 0, 'incomingDocument,outgoingDocument,contractStatus,outgoingDocumentDestinationUser,outgoingDocumentAttachmentDetail,incomingDocumentDestinationUser')
			.then(result => {
				if (result != null) {
					this.contractDocuments = result.data
					this.contractDocumentsPaging = result.pagination
				}
				this.isDataLoading = false;
			})
			.catch(error => {
				this.isDataLoading = false;
			});
		},
		getIncomingDocuments : async function(input) {
			let filter = new IncomingDocumentFilter();
			if (input != undefined) {
				filter.senderName = input;
			}
			IncomingDocument.getContactPersonList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		getSenderNames(input) {
			this.$http.get(`contractdocumentsendername?senderName=` + input)
			.then(response => {
				if (response != null) {
					
					this.senderNames = response.data;
				}
			})
			.catch(function(error){
				this_.$store.dispatch('addErrorMessageGlobal', error);
				throw error;
			});
		}
		
	},
	beforeMount() {
		this.filter = new ContractDocumentFilter();
	},
	watch : {
		openPopup(newValue, oldValue) {
			if (newValue) {
				this.getIncomingDocuments();
			}
		},
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
		isShowPopup: {
			get: function(){
				if (this.openPopup) return true; 
				else return false; 
			},
			set: function(newValue){ }
		},
		userRole() {
			return RoleDetail.query().where("functionInfoId", "contract_document").first();
		}
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
