import BaseModelAPI from '../BaseModelAPI';

export default class UserProfileList extends BaseModelAPI {

	static entity = 'userprofilelist'
	static entityName = 'userprofile';

	constructor() {
		this.pageIndex = 0;
		this.pageSize = 0;
		this.pageCount = 0;
		this.dataCount = 0;
	}
}
