import EmployeeLevel from '@/models/EmployeeLevel/EmployeeLevel';

export default class EmployeeLevelFilter {

	constructor() {
	/*APP_FILTER_DEFINITION*/
		this.id = '';
		this.name = '';
		this.description = '';
		this.parentId  = '';
		this.parentName = '';
		this.createdBy = '';
		this.updatedBy = '';
		this.draftFromUpload = '';
		this.draftMode = '';
	/*END_APP_FILTER_DEFINITION*/
		this.exactType = {
	/*APP_APP_FILTER_CRITERIA*/
			id : '0',
			name : '0',
			description : '0',
			parentId : '0',
			parentName : '0',
			createdBy : '0',
			updatedBy : '0',
	/*END_APP_FILTER_CRITERIA*/
		};
	}

}