import BaseModelAPI from '../BaseModelAPI';

export default class ReminderList extends BaseModelAPI {

	static entity = 'reminderlist'
	static entityName = 'reminder';

	constructor() {
		this.pageIndex = 0;
		this.pageSize = 0;
		this.pageCount = 0;
		this.dataCount = 0;
	}
}
