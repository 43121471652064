<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true :ok-title="okTitle" @ok="saveButtonAction" title="Tanda Terima Surat">
		<ReceiptDocumentDetailComponent :mainRecordId="mainRecordId" ref="childComponentDetail" />
	</b-modal>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import ReceiptDocumentInputComponent from '@/views/ReceiptDocument/InputComponent';
import ReceiptDocumentDetailComponent from '@/views/ReceiptDocument/DetailComponent';
import IncomingDocumentList from '@/models/IncomingDocument/IncomingDocumentList';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';

import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
/*END_APP_DETAIL_IMPORT*/
export default {
	/*APP_DETAIL_PROP_DEFINITION*/
	props : ["openPopup", "model", "saveActionMethod", "resetMethod","incomingDocumentId", "receiptDocumentId"],
	/*END_APP_DETAIL_PROP_DEFINITION*/
	components : {
		/*APP_DETAIL_PROP_DEFINITION*/
		PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,ReceiptDocumentInputComponent,ReceiptDocumentDetailComponent
		/*APP_DETAIL_COMPONENTS_DEFINITION*/
	},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			/*APP_DETAIL_PAGINATION*/
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterIncomingDocument : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentIncomingDocumentPage : 1,
			incomingDocumentPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByIncomingDocument: '',
			sortDescIncomingDocument: '',
			mainRecordId: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			/*END_APP_DETAIL_OBJ_VARIABLE*/
			okTitle : 'Save',
			hasPrint : false,
		}
	},
	methods : {
		/*APP_DETAIL_GETIncomingDocument*/
		getIncomingDocument : async function(id) {
			this.isBusy = true;
			IncomingDocument.getData(this, id).then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETIncomingDocument*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'incomingdocumentcopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_SAVEBUTTONACTION*/
		saveButtonAction(modalEvent) {
			if (this.mainRecordId == null || this.mainRecordId == undefined || this.mainRecordId == '') {
				this.$refs.childComponent.saveAction(modalEvent).then(result => {
					this.mainRecordId = result.id;
					this.$emit('update:model', result);
					this.okTitle = "Print";
				});
			} else {
				if (!this.hasPrint) {
					modalEvent.preventDefault();
					this.okTitle = "Close";
					this.hasPrint = true;
					window.open('/receiptdocument/print/' + this.mainRecordId, '_blank');
				} else {
					this.resetModal();
				}
			}
		},
		/*END_APP_DETAIL_SAVEBUTTONACTION*/
		/*APP_DETAIL_RESETMODAL*/
		resetModal() {
			this.$emit('resetMethod'); 
		},
		/*END_APP_DETAIL_RESETMODAL*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterIncomingDocument = new IncomingDocumentFilter();
	},
	mounted() {
		if (this.receiptDocumentId != null && this.receiptDocumentId != "") {
			this.mainRecordId = this.receiptDocumentId;
		}
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	watch : {
		openPopup(newValue, oldValue) {
			console.log(this.receiptDocumentId);
			console.log(newValue);
			this.mainRecordId = '';
			if (newValue) {
				if (this.receiptDocumentId != null && this.receiptDocumentId != "") {
					this.mainRecordId = this.receiptDocumentId;
					this.okTitle = "Print";
				}
			}
		}
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
		isShowPopup: {
			get: function(){
				if (this.openPopup) { return true; }
				else { return false; }
			},
			set: function(newValue){ }
		},
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
