import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';

export default class OutgoingDocumentFilter {

	constructor() {
	/*APP_FILTER_DEFINITION*/
		this.id = '';
		this.documentCode = '';
		this.documentLocationId  = '';
		this.documentLocationName = '';
		this.applicantId  = '';
		this.applicantFullName = '';
		this.destinationUser = '';
		this.destinationStatusId  = '';
		this.destinationStatusName = '';
		this.destinationContactPerson = '';
		this.destinationAddressMultiple = '';
		this.documentDateFrom = '';
		this.documentDateTo = '';
		this.descriptionMultiple = '';
		this.contractDateFrom = '';
		this.contractDateTo = '';
		this.contractDescriptionMultiple = '';
		this.isDraft = false;
		this.isLampiran = false;
		this.draftAttachmentId  = '';
		this.draftAttachmentOriginalFileName = '';
		this.lampiranAttachmentId  = '';
		this.lampiranAttachmentOriginalFileName = '';
		this.remarksMultiple = '';
		this.outgoingDocumentStatusId  = '';
		this.outgoingDocumentStatusName = '';
		this.draftFromUpload = '';
		this.draftMode = '';
	/*END_APP_FILTER_DEFINITION*/
		this.exactType = {
	/*APP_APP_FILTER_CRITERIA*/
			id : '0',
			documentCode : '0',
			documentLocationId : '0',
			documentLocationName : '0',
			applicantId : '0',
			applicantFullName : '0',
			destinationUser : '0',
			destinationStatusId : '0',
			destinationStatusName : '0',
			destinationContactPerson : '0',
			destinationAddressMultiple : '0',
			documentDateFrom : '0',
			documentDateTo : '0',
			descriptionMultiple : '0',
			contractDateFrom : '0',
			contractDateTo : '0',
			contractDescriptionMultiple : '0',
			isDraft : '0',
			isLampiran : '0',
			draftAttachmentId : '0',
			draftAttachmentOriginalFileName : '0',
			lampiranAttachmentId : '0',
			lampiranAttachmentOriginalFileName : '0',
			remarksMultiple : '0',
			outgoingDocumentStatusId : '0',
			outgoingDocumentStatusName : '0',
	/*END_APP_FILTER_CRITERIA*/
		};
	}

}