import router from '../router';
export const CHECKED_DATA_STORAGE_KEY = 'data-check-all'
export const APP_BREADCRUMB = 'app-bread-crumb'
export const PDF_PROCESSED_IDS_STORAGE_KEY = 'pdf-processed-id'
export const ERROR_MESSAGE_GLOBAL = 'error-message-global'
export const SUCCESS_MESSAGE_GLOBAL = 'success-message-global'
export const APP_ROLE_LOADED = 'app-role-loaded'
export const DESTINATIONUSER_IDS = 'destination-user-id'
export const COPYUSER_IDS = 'copy-user-id'

// for testing
if (navigator.userAgent.indexOf('PhantomJS') > -1) {
  window.localStorage.clear()
}

export const mutations = {
  addCheckAllData (state, dataIds) {
    state.dataIds = dataIds;
  },
  removeCheckAllData (state) {
    state.dataIds = []
  },
  addCheckData (state, id) {
    state.dataIds.push(id);
  },
  removeCheckData (state, id) {
    let index = state.dataIds.indexOf(id);
    state.dataIds.splice(index, 1);
  },
  addDownloadPDFId(state, id) {
    state.dataPDFIds.push(id);
  },
  removeDownloadPDFId(state, id) {
    let index = state.dataPDFIds.indexOf(id);
    state.dataPDFIds.splice(index, 1);
  },
  addErrorMessageGlobal(state, msg) {
    let errors = Array();
    let errorExists = false;
    
    if (msg != null) {
      if (msg.response != null) {
        if (msg.response.status == 401) {
          state.user = null;
          state.userId = null;
          state.accessToken = null;
          state.isLoggedIn = false;
          state.isSuperUser = false;
          localStorage.removeItem('access_token');
          
          router.push({ name : 'login' });
          return;
        }

        if (msg.response.status >= 500) {
          errors.push("System Error - Hubungi Admin");
          state.errorMessageGlobal = errors;
          return;
        }

        if (msg.response.status >= 404) {
          errors.push("Data Tidak ditemukan");
          state.errorMessageGlobal = errors;
          return;
        }

        if (msg.response.data != null) {
          if (msg.response.data.errors != null) {
            if (msg.response.data.errors != null && typeof msg.response.data.errors === "object") {
              let errorArr = Object.values(msg.response.data.errors);
              errorArr.forEach(item => {
                if (item != "" && item != null) {
                  if (Array.isArray(item)) {   
                    errors.push(item[0]);
                  } else {
                    if (item.detail != undefined)
                      errors.push(item.detail);
                    else 
                      errors.push(item);
                  }
                }
              });
            } else {
              errors = msg.response.data.errors;
            }
          }
          if (errors.length > 0) {
            errorExists = true;
          }
          if (!errorExists) {
            if (msg.response.data.title != null && msg.response.data.title != undefined && msg.response.data.title != '') {
              errors.push(msg.response.data.title);
              errorExists = true;
            }
          }
        }
      }
    }
    if (!errorExists) {
      errors.push(msg);
    }
    state.errorMessageGlobal = errors;
  },
  removeErrorMessageGlobal(state) {
    state.errorMessageGlobal = [];
  },
  addSuccessMessageGlobal(state, msg) {
    state.successMessageGlobal = msg;
  },
  removeSuccessMessageGlobal(state) {
    state.successMessageGlobal = {};
  },
  setBreadCrumb(state, datas) {
    state.breadcrumbs = datas;
  },
  setRoleLoaded(state) {
    state.isRoleLoaded = true;
  },
  unsetRoleLoaded(state) {
    state.isRoleLoaded = false;
  },
  addDestinationUser(state, id) {
    state.dataDestinationUserIds.push(id);
  },
  removeDestinationUser(state, id) {
    let index = state.dataDestinationUserIds.indexOf(id);
    state.dataDestinationUserIds.splice(index, 1);
  },
  removeAllDestinationUser(state) {
    state.dataDestinationUserIds = [];
  },
  addCopyUser(state, id) {
    state.dataCopyUserIds.push(id);
  },
  removeCopyUser(state, id) {
    let index = state.dataCopyUserIds.indexOf(id);
    state.dataCopyUserIds.splice(index, 1);
  },
  removeAllCopyUser(state) {
    state.dataCopyUserIds = [];
  },
}
