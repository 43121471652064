import BaseModelAPI from '../BaseModelAPI';

export default class DocumentStatusList extends BaseModelAPI {

	static entity = 'documentstatuslist'
	static entityName = 'documentstatus';

	constructor() {
		this.pageIndex = 0;
		this.pageSize = 0;
		this.pageCount = 0;
		this.dataCount = 0;
	}
}
