<template>
  <div class="fasearch" v-click-outside="()=>{hide_solid=true}">
    <div class="input-group">
      <div class="input-group-append">
        <button type="button" class="btn btn-light">
            <b-icon id="preview" :icon="value" aria-hidden="true"></b-icon>
        </button>
      </div>
      <input
        type="text"
        class="form-control"
        v-model="query"
        autocomplete="off"
        @focus="hide_solid=false"
        placeholder="Type to Search"
      />
      <div class="input-group-append">
        <button type="button" class="btn btn-light" @click="query=''">X</button>
      </div>
    </div>
    <div class="fasearch-content" v-if="!hide_solid">
      <button
        type="button"
        @click="()=>{query=si;hide_solid=true}"
        :title="si"
        class="m-1 btn btn-outline-dark border-light"
        v-for="(si,sikey) in solid_items"
        :key="sikey"
      >
        <b-icon :icon="si" aria-hidden="true"></b-icon>
      </button>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import * as exports from 'bootstrap-icons-vue';

export default {
  name: "AppInputIconPicker",
  directives: {
    ClickOutside
  },
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      hide_solid: true,
      query: "",
      solid_items: [],
      all: [],
      solid: [],
      regular: [],
      brands: []
    };
  },
  mounted() {
    this.query = this.value;
    this.solid = exports.iconNames;
    this.all = [...this.solid];
    this.solid_items = this.all;
  },
  methods: {
    filterSolid(e) {
      this.solid_items = this.all.filter(
        item => item.toLowerCase().indexOf(e.trim().toLowerCase()) > -1
      );
    }
  },
  watch: {
    query(v) {
      this.filterSolid(v);
      this.$emit("input", v);
    }
  }
};
</script>

<style scoped lang="scss">
.fasearch {
  .fasearch-content {
    padding: 5px;
    max-height: 400px;
    min-height: 100px;
    overflow: hidden auto;
    position: absolute;
    background: white;
    z-index: 999;
    left: 6px;
    right: 6px;
    box-shadow: 0 0 1px 0px;

    button {
      width: 70px;
    }
  }

  .tab-pane {
    box-sizing: border-box;
    position: relative;
  }
}
</style>
