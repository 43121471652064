import BaseModelAPI from '../BaseModelAPI';

export default class ArchivedDocument extends BaseModelAPI {
	static entity = 'archiveddocument';
	static entityName = 'archiveddocument';

	constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.noBeritaAcara = '';
			this.archivedDateFrom = '';
			this.archivedDateTo = '';
			this.documentLocationId = '';
			this.documentLocation = {};
			this.picId = '';
			this.pIC = {};
			this.approvedById = '';
			this.approvedBy = {};
			this.removalMethodId = '';
			this.removalMethod = {};
			this.remarks = '';
			this.investigatorDetail = []
			this.incomingDocumentId = '';
			this.incomingDocument = {};
			this.outgoingDocumentId = '';
			this.outgoingDocument = {};
			this.uploadValidationMessage = '';
			this.uploadValidationStatus = '';
	/*END_APP_MODEL_DEFINITION*/
	}

}