import BaseModelAPI from '../BaseModelAPI';

export default class RemovalMethodList extends BaseModelAPI {

	static entity = 'removalmethodlist'
	static entityName = 'removalmethod';

	constructor() {
		this.pageIndex = 0;
		this.pageSize = 0;
		this.pageCount = 0;
		this.dataCount = 0;
	}
}
