import BaseModelAPI from '../BaseModelAPI';

export default class IncomingDocumentList extends BaseModelAPI {

	static entity = 'incomingdocumentlist'
	static entityName = 'incomingdocument';

	constructor() {
		this.pageIndex = 0;
		this.pageSize = 0;
		this.pageCount = 0;
		this.dataCount = 0;
	}
}
