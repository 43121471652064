<template>
	<PopupRouterView label="Receipt Document Input"  entityName="receiptdocument" :documentId="mainRecordId"  @saveMethod="saveAction" @hideMethod="onHidden">
		<b-card>
			<b-col cols="12" lg="6">
				<b-form-group label="Pilih Dokumen" label-cols-sm="4" label-cols-lg="3" v-slot="{ ariaDescribedby }">
					<b-form-radio-group
						id="radio-group-2"
						v-model="selectedDocument"
						:aria-describedby="ariaDescribedby"
						name="radio-sub-component"
					>
						<b-form-radio value="incomingdocument">Surat Masuk</b-form-radio>
						<b-form-radio value="outgoingdocument">Surat Keluar</b-form-radio>
					</b-form-radio-group>
				</b-form-group>
				<span v-if="selectedDocument != '' && selectedDocument != null">
					<app-input-combobox-autocomplete v-if="selectedDocument == 'incomingdocument'" :options="incomingDocuments" optionsLabel="documentCode" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" id="input-" size="sm" :model.sync="model.incomingDocumentId"  :selected="model.incomingDocument != null ? model.incomingDocument.documentCode : ''" @input="getIncomingDocuments" />
					<app-input-combobox-autocomplete v-if="selectedDocument == 'outgoingdocument'" :options="outgoingDocuments" optionsLabel="documentCode" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" id="input-" size="sm" :model.sync="model.outgoingDocumentId"  :selected="model.outgoingDocument != null ? model.outgoingDocument.documentCode : ''" @input="getOutgoingDocuments" />
					<app-input-textbox id="fieldset-receipt-document-no" label-cols-sm="4" label-cols-lg="3" label="No. Tanda Terima Surat" :isEditable="false" label-for="input-receipt-document-no" size="sm" :model="model.receiptDocumentNo != null && model.receiptDocumentNo != '' ? model.receiptDocumentNo : 'Autonumber' "   />
					<app-input-combobox-autocomplete :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Penyerah Surat" id="input-" size="sm" :model.sync="model.deliveryUserId"  :selected="model.deliveryUser != null ? model.deliveryUser.fullName : ''" @input="getUserProfiles" :isSelectMethodExists="true" @onSelectMethod="changeDeliveryUser" />
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Jabatan" label-for="filter-document-code">
						{{ model != null ? (model.deliveryUser != null ? model.deliveryUser.jobDescription : '-') : "-" }}
					</b-form-group>
					<app-input-combobox-autocomplete :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Penerima Surat" id="input-" size="sm" :model.sync="model.receiptUserId"  :selected="model.receiptUser != null ? model.receiptUser.fullName : ''" @input="getUserProfiles" :isSelectMethodExists="true" @onSelectMethod="changeReceiptUser" />
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Jabatan" label-for="filter-document-code">
						{{ model != null ? (model.receiptUser != null ? model.receiptUser.jobDescription : '-') : "-" }}
					</b-form-group>
					<app-input-textarea id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Catatan" label-for="input-remarks" size="sm" :model.sync="model.remarks"   />
					<app-input-datepicker  id="fieldset-document-created-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal Penyerah" size="sm" :model.sync="model.documentCreatedDate"  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"  />
					<app-input-datepicker  id="fieldset-document-created-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal Penerima" size="sm" :model.sync="model.receivedDate"  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"  />
					<b-form-group v-if="false" id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Status" label-for="filter-document-code">
						<b-button size="sm" v-b-popover.hover.left="model.isReceived ? 'Sudah Diterima' : 'Belum Diterima'" :variant="model.isReceived ? 'success' : 'light'"  @click="toggleIsReceived(model)" class="mr-2 mt-md-2 mt-lg-0 btn-xs">
							<b-icon-toggle-on v-if="model.isReceived"></b-icon-toggle-on>
							<b-icon-toggle-off v-else></b-icon-toggle-off>
						</b-button>
					</b-form-group>
					<app-input-combobox-autocomplete :options="documentStatuses" optionsLabel="text" optionsKey="value" label-cols-sm="4" label-cols-lg="3" label="Status" id="input-" size="sm" :model.sync="statusSelected"  :selected="model.isReceived ? 'Diterima' : 'Belum Diterima'" :isSelectMethodExists="true" @onSelectMethod="changeDocumentStatus" />
				</span>
				<span v-if="false">
					<app-input-textbox id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" label-for="input-document-code" size="sm" :model.sync="model.documentCode"   />
					<app-input-combobox-autocomplete :options="documentLocations" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Lokasi Surat" id="input-" size="sm" :model.sync="model.documentLocationId"  :selected="model.documentLocation != null ? model.documentLocation.name : ''" @input="getDocumentLocations" />
					<app-input-textbox id="fieldset-sender-name" label-cols-sm="4" label-cols-lg="3" label="Nama Pengirim" label-for="input-sender-name" size="sm" :model.sync="model.senderName"   />
					<app-input-combobox-autocomplete :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Ditujukan Kepada" id="input-" size="sm" :model.sync="model.destinationUserId"  :selected="model.destinationUser != null ? model.destinationUser.fullName : ''" @input="getUserProfiles" />
					<app-input-datepicker  id="fieldset-document-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal Surat" size="sm" :model.sync="model.documentDate"  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"  />
					<app-input-combobox-autocomplete :options="documentTypes" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Jenis Surat/Barang" id="input-" size="sm" :model.sync="model.documentTypeId"  :selected="model.documentType != null ? model.documentType.name : ''" @input="getDocumentTypes" />
					<app-input-textbox id="fieldset-document-no" label-cols-sm="4" label-cols-lg="3" label="Nomor Surat" label-for="input-document-no" size="sm" :model.sync="model.documentNo"   />
					<app-input-textarea id="fieldset-document-description" label-cols-sm="4" label-cols-lg="3" label="Ringkasan Surat" label-for="input-document-description" size="sm" :model.sync="model.documentDescription"   />
					
					<app-input-textbox id="fieldset-outgoing-document" label-cols-sm="4" label-cols-lg="3" label="Outgoing Document" label-for="input-outgoing-document" size="sm" :model.sync="model.outgoingDocument"   />
					<app-input-combobox-autocomplete :options="incomingDocuments" optionsLabel="documentCode" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Incoming Document" id="input-" size="sm" :model.sync="model.incomingDocumentId"  :selected="model.incomingDocument != null ? model.incomingDocument.documentCode : ''" @input="getIncomingDocuments" />
				</span>
			</b-col>
		</b-card>
		<PopupConfirmationDetail :openPopup="isShowPopupConfirmation" :model="model" @saveActionMethod="saveAction" @resetMethod="resetModal" />
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</PopupRouterView>
</template>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import ReceiptDocument from '@/models/ReceiptDocument/ReceiptDocument';
import ReceiptDocumentList from '@/models/ReceiptDocument/ReceiptDocumentList';
import ReceiptDocumentFilter from '@/filters/ReceiptDocument/ReceiptDocumentFilter';

import PopupConfirmationDetail from './PopupConfirmationDetail';

import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import DocumentType from '@/models/DocumentType/DocumentType';
import DocumentTypeFilter from '@/filters/DocumentType/DocumentTypeFilter';
import AppInputTextarea from '@/components/AppInputTextarea';
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';
import PopupRouterView from '@/components/PopupRouterView';
export default {
	components : {AppInputTextbox,AppInputDatepicker,AppInputDatepickerRange,AppInputComboboxAutocomplete,AppInputTextarea,PopupRouterView,PopupConfirmationDetail},
	mixins : [validationMixin],
	validations: {
		model: {
		}
	},
	data() {
		return {
			errorMessage : {
			},
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			modelId: null,
			model: {},
			filterReceiptDocument : null,
			currentReceiptDocumentPage : 1,
			receiptDocumentPageSize: 10,
			sortByReceiptDocument: '',
			sortDescReceiptDocument: '',
			documentLocations: [],
			userProfiles: [],
			documentTypes: [],
			incomingDocuments: [],
			outgoingDocuments: [],
			isShowPopupConfirmation : false,
			isDataCommited : false,
			mainRecordId : '',
			isIncoming: false,
			isOutgoing: false,
			selectedDocument: '',
			documentStatuses:[
				{ value : true, text : 'Diterima' },
				{ value : false, text : 'Belum Diterima' },
			],
			statusSelected: true
		}
	},
	methods : {
		changeDeliveryUser(selectedValue){
			if (selectedValue) {
				this.model.deliveryUserId = selectedValue.id;
				this.model.deliveryUser = selectedValue;
			} else {
				this.model.deliveryUserId = "";
				this.model.deliveryUser = {};
			}
		},
		changeReceiptUser(selectedValue){
			if (selectedValue) {
				this.model.receiptUserId = selectedValue.id;
				this.model.receiptUser = selectedValue;
			} else {
				this.model.receiptUserId = "";
				this.model.receiptUser = {};
			}
		},
		toggleIsReceived(model) {
			if (this.model.isReceived) {
				this.model.isReceived = false;
			} else {
				this.model.isReceived = true;
			}

		},
		getReceiptDocument : async function(id) {
			this.isBusy = true;
			ReceiptDocument.getData(this, id, 'incomingDocument,outgoingDocument,deliveryUser,receiptUser').then(result => { this.model = result; this.mainRecordId = String(result.mainRecordId); this.isBusy = false;
				if (result.incomingDocument != null && result.incomingDocument != '') {
					if (result.incomingDocument.id != null && result.incomingDocument.id != '') {
						this.selectedDocument = 'incomingdocument';
					}
				}
				if (result.outgoingDocument != null && result.outgoingDocument != '') {
					if (result.outgoingDocument.id != null && result.outgoingDocument.id != '') {
						this.selectedDocument = 'outgoingdocument';
					}
				}
				if (result.isReceived == null) {
					result.isReceived = false;
				}
				this.statusSelected = result.isReceived;
			}).catch(error => { this.isBusy = false; });
		},
		downloadData() {
			this.isDownloadReceiptDocument = true;
			this.filterReceiptDocument.id = this.modelId;
			ReceiptDocument.downloadDataDetail(this, this.filterReceiptDocument).then(result => {
				this.isDownloadReceiptDocument = false;
			}).catch(error => { this.isDownloadReceiptDocument = false; })
		},
		getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentLocation.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		getDocumentTypes : async function(input) {
			let filter = new DocumentTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentTypes = result.data
				}
			})
			.catch(error => {});
		},
		getIncomingDocuments : async function(input) {
			let filter = new IncomingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			IncomingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data;
					let datas = [];
					if (result.data != null) {
						result.data.forEach(item => {
							if (item != null) {
								if (item.documentCode != null && item.documentCode != "") {
									datas.push(item);
								}
							}
						});
					}
					this.incomingDocuments = datas;
				}
			})
			.catch(error => {});
		},
		getOutgoingDocuments : async function(input) {
			let filter = new OutgoingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			OutgoingDocument.getList(this, filter, null, 0, 30)
			.then(result => {
				if (result != null) {
					this.outgoingDocuments = result.data
					let datas = [];
					if (result.data != null) {
						result.data.forEach(item => {
							if (item != null) {
								if (item.documentCode != null && item.documentCode != "") {
									datas.push(item);
								}
							}
						});
					}
					this.outgoingDocuments = datas;
				}
			})
			.catch(error => {});
		},
		validateState(name) {
			if (!this.$v.model[name]) return null;
			const { $dirty, $error } = this.$v.model[name];
			return $dirty ? !$error : null;
		},
		getErrorMessage(message, name) {
			if (!this.$v.model[name]) return "";
			if (this.$v.model[name].required != undefined && !this.$v.model[name].required) {
				if (message.required) return message.required;
			}
		},
		saveActionConfirmation(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isShowPopupConfirmation = true;
			}
		},
		saveAction(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isBusy = true;
				let id = this.modelId;
				let model = this.model;

				if (id != '' && id != null && id != undefined) {
					ReceiptDocument.updateData(this, model, id).then(result => {
						this.isDataCommited = true;
						this.$router.push({ name: 'receiptdocumentindex' });
						this.isBusy = false;
					}).catch(error => { this.isBusy = false; });
			} else {
				ReceiptDocument.insertData(this, model).then(result => {
					this.$router.push({ name: 'receiptdocumentindex' });
					this.isBusy = false;
				}).catch(error => { this.isBusy = false; });
			}
			}
		},
		onHidden() {
			this.getReceiptDocuments();
		},
		resetModal(modalType) {
			this.showPopupCopyData = false; 
			this.isShowPopupConfirmation = false;
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		changeDocumentStatus(selectedValue){
			if (selectedValue) {
				this.model.isReceived = selectedValue.value;
			} else {
				this.model.isReceived = false
			}
		}
	},
	beforeMount() {
		this.filterReceiptDocument = new ReceiptDocumentFilter();
		this.getDocumentLocations();
		this.getUserProfiles();
		this.getDocumentTypes();
		this.getIncomingDocuments();
		this.getOutgoingDocuments();
	},
	async mounted(){
		let id = this.$route.params.id;
		let copyData = this.$route.params.copydata;
		if (id != '' && id != null && id != undefined) {
			if (copyData != null && copyData != undefined) {
				this.getReceiptDocument(id);
			} else {
				this.modelId = id;
				this.getReceiptDocument(id);
			}

		} else {
			this.model.isReceived = this.statusSelected;
		}
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	computed: {
	},
	watch : {
	},
	beforeDestroy() {
	},
}
</script>
