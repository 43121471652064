import BaseModelAPI from '../BaseModelAPI';

export default class OutgoingDocumentApprovalStatus extends BaseModelAPI {
	static entity = 'outgoingdocumentapprovalstatus';
	static entityName = 'outgoingdocumentapprovalstatus';

	constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.name = '';
			this.isFromUpload = false;
			this.isSavedToBackend = true;
			this.uploadValidationMessage = '';
			this.uploadValidationApprovalStatus = '';
	/*END_APP_MODEL_DEFINITION*/
	}

}