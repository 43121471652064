import BaseModelAPI from '../BaseModelAPI';

export default class IncomingDocument extends BaseModelAPI {
	static entity = 'incomingdocument';
	static entityName = 'incomingdocument';

	constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.documentCode = '';
			this.documentLocationId = '';
			this.documentLocation = {};
			this.senderName = '';
			this.senderStatusId = '';
			this.senderStatus = {};
			this.contactPerson = '';
			this.senderAddress = '';
			this.senderEmail = '';
			this.senderPhoneNumber = '';
			this.destinationUserId = '';
			this.destinationUserIds = '';
			this.destinationUser = {};
			this.destinationUsers = [];
			this.copyUserId = '';
			this.copyUserIds = '';
			this.copyUser = {};
			this.copyUser = [];
			this.sifatSuratId = '';
			this.sifatSurat = {};
			this.documentTypeId = '';
			this.documentType = {};
			this.documentNo = '';
			this.documentDate = '';
			this.description = '';
			this.contractDate = '';
			this.contractDescription = '';
			this.documentAttachmentId = '';
			this.documentAttachment = {};
			this.distributionTypeId = '';
			this.distributionType = {};
			this.receiptDocument = '';
			this.isReminder = false;
			this.reminderDate = '';
			this.remarks = '';
			this.documentStatusId = '';
			this.documentStatus = {};
			this.copyDocumentStatusId = '';
			this.copyDocumentStatus = {};
			this.isFromUpload = false;
			this.isSavedToBackend = true;
			this.uploadValidationMessage = '';
			this.uploadValidationStatus = '';
			this.isFisik = false;
			this.isFile = false;
			this.incomingDocumentAttachmentDetails = [];
	/*END_APP_MODEL_DEFINITION*/
	}

	static async getContactPersonList(this_, filter, sorting, pageIndex, pageSize, include) {
        //console.log(...arguments);
        let filterArray = Array();
        if (filter != null && filter != undefined) {
            //console.log(filter);
            filterArray = this.getFilter(filter);
        }
        if (sorting != null && sorting != undefined) {
            Object.entries(sorting).map(([key, value]) => { 
                if(key != '') {
                    filterArray.push("sort=" + (value ? "-" : "+") + key);     // draftFromUpload & draftMode
                }
            });
                
        }
        if (pageIndex != null) {
            filterArray.push("page=" + pageIndex);
        }
        if (pageSize != null) {
            filterArray.push("per_page=" + pageSize);
        }
        if (include != null) {
            filterArray.push("include=" + include);
        }
        let params = "";
        if (filterArray.length > 0) {
            params = "?" + filterArray.join("&");
        }
        
        //DEBUG

        return this_.$http.get(`${this.entityName}contactpersonlist` + params)
        .then(response => {
            if (response != null) {
                return response.data;
            }
            return response;
        })
        .catch(function(error){
            this_.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
        });
    }

}