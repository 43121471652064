<template>
  <span></span>
</template>

<script>
export default {
  components: {
  },
  props: ["openPopup", "pdfUrl","label","resetModalMethod"],
  data () {
    return {
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: 'page-width',
      src: null,
      progress: 0,
      loadedRatio: 0,
      isLoaded: true,
    }
  },
  computed: {
  },
  mounted () {
  },
  watch: {
  },
  methods: {
  }
}
</script>