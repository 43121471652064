import BaseModelAPI from '../BaseModelAPI';

export default class DocumentStatus extends BaseModelAPI {
	static entity = 'documentstatus';
	static entityName = 'documentstatus';

	constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.code = '';
			this.name = '';
			this.isFromUpload = false;
			this.isSavedToBackend = true;
			this.uploadValidationMessage = '';
			this.uploadValidationStatus = '';
	/*END_APP_MODEL_DEFINITION*/
	}

}