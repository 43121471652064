<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true @ok="downloadData" title="Backup Data - Archived Document">
		<b-card>
			<b-col cols="12" lg="6">
				<app-input-combobox-autocomplete :options="archivedDocuments" optionsLabel="noBeritaAcara" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="No. Berita Acara" id="input-" size="sm" :model.sync="beritaAcaraId"  :selected="beritaAcara != null ? beritaAcara.noBeritaAcara : ''" @input="getArchivedDocuments" @change="updateData" />
			</b-col>
		</b-card>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import ArchivedDocument from '@/models/ArchivedDocument/BeritaAcaraArchivedDocument';
import ArchivedDocumentList from '@/models/ArchivedDocument/ArchivedDocumentList';
import ArchivedDocumentFilter from '@/filters/ArchivedDocument/ArchivedDocumentFilter';

import InvestigatorDetail from '@/models/ArchivedDocument/InvestigatorDetail';
import InvestigatorDetailFilter from '@/filters/ArchivedDocument/InvestigatorDetailFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
import AppInputComboboxAutocomplete from '../../components/AppInputComboboxAutocomplete.vue';
import { saveAs } from 'file-saver';

/*END_APP_DETAIL_IMPORT*/
export default {
	props : ["openPopup", "modelId", "saveActionMethod", "resetMethod"],
	components : {
		PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,AppInputComboboxAutocomplete,
	},
	data() {
		return {
			fileupload: null,
			model: {},
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			/*APP_DETAIL_PAGINATION*/
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterArchivedDocument : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentArchivedDocumentPage : 1,
			archivedDocumentPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByArchivedDocument: '',
			sortDescArchivedDocument: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			filterInvestigatorDetail : null,
			currentInvestigatorDetailPage : 1,
			investigatorDetailPageSize: 10,
			sortByInvestigatorDetail: '',
			sortDescInvestigatorDetail: '',
			sortingByInvestigatorDetail: [],
			investigatorDetails : [],
			investigatorDetailId : '',
			totalInvestigatorDetailRow : 0,
			isShowInvestigatorDetailFilter: false,
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			userProfiles: [],
			archivedDocuments: [],
			/*END_APP_DETAIL_OBJ_VARIABLE*/
			beritaAcaraId: '',
			beritaAcara: {}
		}
	},
	methods : {
		getArchivedDocuments : async function(input) {
			let filter = new ArchivedDocumentFilter();
			if (input != undefined) {
				filter.noBeritaAcara = input;
			}
			ArchivedDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.archivedDocuments = result.data
				}
			})
			.catch(error => {});
		},
		/*APP_DETAIL_GETArchivedDocument*/
		getArchivedDocument : async function(id) {
			this.isBusy = true;
			ArchivedDocument.getData(this, id).then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETArchivedDocument*/
		/*APP_DETAIL_GETInvestigatorDetail*/
		getInvestigatorDetails : async function() {
			let filter = {};
			Object.assign(filter, this.filterInvestigatorDetail);
			if (this.modelId == null) return;
			filter.archivedDocumentId = this.modelId;
			this.sortingByInvestigatorDetail = Array();
			if (this.sortByInvestigatorDetail != null) {
				this.sortingByInvestigatorDetail[this.sortByInvestigatorDetail] = this.sortDescInvestigatorDetail;
			}
			let currentPage = (this.currentInvestigatorDetailPage - 1);
			InvestigatorDetail.getList(this, filter, this.sortingByInvestigatorDetail, currentPage , this.investigatorDetailPageSize).then(result => {
				this.investigatorDetails = result.data;
				this.totalInvestigatorDetailRow = result.dataCount;
			}).catch(error => {});
		},
		/*END_APP_DETAIL_GETInvestigatorDetail*/
		/*APP_DETAIL_GETUSERPROFILE*/
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		/*END_APP_DETAIL_GETUSERPROFILE*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'archiveddocumentcopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_SAVEBUTTONACTION*/
		saveButtonAction(modalEvent) {
			//this.$emit("saveActionMethod", modalEvent);
		},
		/*END_APP_DETAIL_SAVEBUTTONACTION*/
		/*APP_DETAIL_RESETMODAL*/
		resetModal() {
			this.$emit('resetMethod'); 
		},
		/*END_APP_DETAIL_RESETMODAL*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
				this.isShowInvestigatorDetailFilter = true;
			}
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
		async downloadData(modalEvent) {
			modalEvent.preventDefault();
			let fileName = 'Risala_DataBackup_';
			let dataIndex = this.archivedDocuments.findIndex(item => item.id === this.beritaAcaraId);
			
			if (this.archivedDocuments[dataIndex] != null) {
				if (this.archivedDocuments[dataIndex].noBeritaAcara != "") {
					fileName = fileName + this.archivedDocuments[dataIndex].noBeritaAcara;
				}
			}
			
			let this_ = this;
			return await this.$http.get(process.env.VUE_APP_API_URL + `beritaacaraarchiveddocument/download/` + this.beritaAcaraId, {responseType: 'blob'})
            .then(async function(response){
                if (response.data) {
                    await saveAs(response.data, fileName + ".zip");
                }
                return null;
            })
            .catch(function(error){
				console.log(...arguments);
				console.log(error);
                this_.$store.dispatch('addErrorMessageGlobal', error);
                throw error;
            });
		},
		updateData() {
			console.log(...arguments);
		}
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	watch : {
		openPopup(newValue, oldValue) {
			if (newValue) {
				this.getArchivedDocuments();
			}
		},
	},
	computed: {
		isShowPopup: {
			get: function(){
				if (this.openPopup) return true; 
				else return false; 
			},
			set: function(newValue){ }
		},
	}
}
</script>
