<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    model: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      chartData: {
        labels: [
          'Surat Masuk',
          'Surat Keluar External',
          'Surat Keluar Internal',
        ],
        datasets: [
          {
            backgroundColor: '#3492eb',
            barThickness: 40,
            data: this.model
          }
        ]
      },
      chartOptions: {
        plugins: {
            legend: {
              display: false,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  }
}
</script>
