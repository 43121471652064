<template>
	<PopupRouterView label="Function Info Input"  entityName="functioninfo" :documentId="mainRecordId"  @saveMethod="saveActionConfirmation" @hideMethod="onHidden">
		<b-card>
			<b-col cols="12" lg="6">
				<span v-if="!(mainRecordId != null && mainRecordId != undefined && mainRecordId != ''  && mainRecordId != 'null')" >
					<app-input-textbox id="fieldset-name" label-cols-sm="4" label-cols-lg="3" label="Name" label-for="input-name" size="sm" :model.sync="model.name"   />
				</span>
				<span v-if="mainRecordId != null && mainRecordId != undefined && mainRecordId != ''  && mainRecordId != 'null'" >
					<b-form-group id="fieldset-mode" label-cols-sm="4" label-cols-lg="3" label="Mode" label-for="filter-mode">
							{{ model != null && model.id }}
						</b-form-group>
					<!-- <app-input-textbox id="fieldset-name" label-cols-sm="4" label-cols-lg="3" label="Name" label-for="input-name" size="sm" :model.sync="model.name"   /> -->
				</span>
				<app-input-textbox id="fieldset-uri" label-cols-sm="4" label-cols-lg="3" label="URL" label-for="input-uri" size="sm" :model.sync="model.uri"   />
				<!-- <app-input-textbox id="fieldset-icon-name" label-cols-sm="4" label-cols-lg="3" label="Icon Name" label-for="input-icon-name" size="sm" :model.sync="model.iconName"   /> -->
				<b-form-group id="fieldset-is-enabled" label-cols-sm="4" label-cols-lg="3" label="Is Enabled" label-for="input-is-enabled">
					<b-form-checkbox id="input-is-enabled" v-model="model.isEnabled"  ></b-form-checkbox>
				</b-form-group>
				<!-- <app-input-combobox-autocomplete placeholder="Silahkan Pilih" :isRequired="true" :options="parts" optionsLabel="partNoName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Part Number" id="input-part-number" size="sm" :model.sync="model.partNumberId" :state="validateState('partNumberId')" :errorMessage="getErrorMessage(errorMessage.partNumberId, 'partNumberId')"  :selected="model.partNumber != null ? model.partNumber.partNoName : ''" @input="getParts" @change="updateData" :disabled="((this.model.warehouseId != null && this.model.warehouseId != undefined && this.model.warehouseId != '') && (this.model.supplierId != null && this.model.supplierId != undefined && this.model.supplierId != '') ? false: true)" :isEditable="model.problemSheetStatusId == 2 ? false : true"/> -->
				<app-input-combobox-autocomplete placeholder="Silahkan Pilih" :isRequired="true" :options="moduleInfos" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Module Info" id="input-" size="sm" :model.sync="model.moduleInfoId"  :selected="model.moduleInfo != null ? model.moduleInfo.name : ''" @input="getModuleInfos" />
				<b-form-group id="fieldset-icon-name" label-cols-sm="4" label-cols-lg="3" label="Icon Name" label-for="input-icon-name">
					<app-input-icon-picker v-model="model.iconName" label-cols-sm="4" label-cols-lg="3" label="Icon Name" label-for="input-icon-name" size="sm" :model.sync="model.iconName"></app-input-icon-picker>
				</b-form-group>
			</b-col>
			<b-col>
				<span class="float-right" v-if="modelId != null && modelId != undefined && modelId != ''">
					<b-button variant="success" class="float-right ml-2" @click="downloadData" size="sm"><b-icon-download class="mr-1"></b-icon-download> Download</b-button>
				</span>
			</b-col>
		</b-card>
		<PopupConfirmationDetail :openPopup="isShowPopupConfirmation" :model="model" @saveActionMethod="saveAction" @resetMethod="resetModal" />
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</PopupRouterView>
</template>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import FunctionInfo from '@/models/FunctionInfo/FunctionInfo';
import FunctionInfoList from '@/models/FunctionInfo/FunctionInfoList';
import FunctionInfoFilter from '@/filters/FunctionInfo/FunctionInfoFilter';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import PopupConfirmationDetail from './PopupConfirmationDetail';
import FontAwesomePicker from "bootstrap-vue-font-awesome-picker";
import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import ModuleInfo from '@/models/ModuleInfo/ModuleInfo';
import ModuleInfoFilter from '@/filters/ModuleInfo/ModuleInfoFilter';
import PopupRouterView from '@/components/PopupRouterView';
import AppInputIconPicker from '@/components/AppInputIconPicker';
export default {
	components : {AppInputIconPicker,AppInputTextbox,AppInputComboboxAutocomplete,PopupRouterView,PopupConfirmationDetail,FontAwesomePicker, BootstrapVue, IconsPlugin},
	mixins : [validationMixin],
	validations: {
		model: {
		}
	},
	data() {
		return {
			errorMessage : {
			},
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			modelId: null,
			model: {},
			filterFunctionInfo : null,
			currentFunctionInfoPage : 1,
			functionInfoPageSize: 10,
			sortByFunctionInfo: '',
			sortDescFunctionInfo: '',
			moduleInfos: [],
			isShowPopupConfirmation : false,
			isDataCommited : false,
			mainRecordId : '',
		}
	},
	methods : {
		getFunctionInfo : async function(id) {
			this.isBusy = true;
			FunctionInfo.getData(this, id).then(result => { this.model = result; this.mainRecordId = String(result.mainRecordId); this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		downloadData() {
			this.isDownloadFunctionInfo = true;
			this.filterFunctionInfo.id = this.modelId;
			FunctionInfo.downloadDataDetail(this, this.filterFunctionInfo).then(result => {
				this.isDownloadFunctionInfo = false;
			}).catch(error => { this.isDownloadFunctionInfo = false; })
		},
		getModuleInfos : async function(input) {
			let filter = new ModuleInfoFilter();
			if (input != undefined) {
				filter.name = input;
			}
			ModuleInfo.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.moduleInfos = result.data
				}
			})
			.catch(error => {});
		},
		validateState(name) {
			if (!this.$v.model[name]) return null;
			const { $dirty, $error } = this.$v.model[name];
			return $dirty ? !$error : null;
		},
		getErrorMessage(message, name) {
			if (!this.$v.model[name]) return "";
			if (this.$v.model[name].required != undefined && !this.$v.model[name].required) {
				if (message.required) return message.required;
			}
		},
		saveActionConfirmation(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isShowPopupConfirmation = true;
			}
		},
		saveAction(modalEvent) {
			this.isBusy = true;
			let id = this.modelId;
			let model = this.model;

			if (id != '' && id != null && id != undefined) {
				FunctionInfo.updateData(this, model, id).then(result => {
					this.isDataCommited = true;
					this.$router.push({ name: 'functioninfoindex' });
					this.isBusy = false;
				}).catch(error => { this.isBusy = false; });
			} else {
				FunctionInfo.insertData(this, model).then(result => {
					this.$router.push({ name: 'functioninfoindex' });
					this.isBusy = false;
				}).catch(error => { this.isBusy = false; });
			}
		},
		onHidden() {
			this.getFunctionInfos();
		},
		resetModal(modalType) {
			this.showPopupCopyData = false; 
			this.isShowPopupConfirmation = false;
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
	},
	beforeMount() {
		this.filterFunctionInfo = new FunctionInfoFilter();
		this.getModuleInfos();
	},
	async mounted(){
		let id = this.$route.params.id;
		let copyData = this.$route.params.copydata;
		if (id != '' && id != null && id != undefined) {
			if (copyData != null && copyData != undefined) {
				this.getFunctionInfo(id);
			} else {
				this.modelId = id;
				this.getFunctionInfo(id);
			}
		}
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	computed: {
	},
	watch : {
	},
	beforeDestroy() {
	},
}
</script>
