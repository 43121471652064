<template>
	<div>
		<b-button class="filter" variant="light" block href="#" v-b-toggle.accordion-filter>
			<span><b-icon-funnel-fill></b-icon-funnel-fill> Filter</span>
			<span class="menu-arrow">
				<b-icon-chevron-right class="float-right chevron-right"></b-icon-chevron-right><b-icon-chevron-down class="float-right chevron-down"></b-icon-chevron-down>
			</span>
		</b-button>
		<b-collapse id="accordion-filter" accordion="accordion-filter" role="tabpanel" class="menu-accordion">
			<b-card>
				<b-row>
					<b-col cols="12" lg="6">
					<app-input-textbox id="fieldset-full-name" label-cols-sm="4" label-cols-lg="3" label="Name" label-for="filter-full-name" size="sm" :model.sync="filter.fullName" />
					<app-input-combobox-autocomplete :options="branchs" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Kantor" id="filter-" size="sm" :model.sync="filter.branchId" @input="getBranchs" @focus="getBranchs" />
					<b-button variant="danger" size="sm" class="float-right" @click="resetFilter"><span style="font-size: 0.75rem" class="mr-1"><b-icon-x></b-icon-x></span> Clear Filter</b-button>
					<b-button variant="primary" size="sm" class="float-right mr-1" @click="getUserProfiles"><span style="font-size: 0.75rem" class="mr-1"><b-icon-search></b-icon-search></span> Cari</b-button>
					</b-col>
				</b-row>
			</b-card>
		</b-collapse>
		<b-card class="mt-3">
		<b-row class="mb-3">
			<b-col>
			</b-col>
			<b-col>
				<span class="float-right">
					<b-form-select v-model="pageSizeOptions" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
				</span>
			</b-col>
		</b-row>
		<div class="table-corner">
			<b-table id="my-table" stacked="md" head-variant="light"  :no-local-sorting="true" :items="userProfiles" :fields="fields" :busy="isDataLoading" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" small responsive>
			<template v-slot:head(actions)>
				<b-button-group class="float-right"  size="sm">
					<b-button @click="isShowFilter = !isShowFilter" size="sm"><b-icon-funnel-fill /></b-button>
					<b-button><b-form-checkbox v-if="(userRole != null ? userRole.allowPrint : false)" id="input-select-all" class="float-right" v-model="selectAll"></b-form-checkbox></b-button>
				</b-button-group>
			</template>
			<template #top-row v-if="isShowFilter">
				<b-th></b-th>
				<b-th stacked-heading="Name">
					<b-form-input id="input-full-name-table" @input="getUserProfilesWithoutLoading" v-model="filter.fullName" size="sm"></b-form-input>
				</b-th>
				<b-th stacked-heading="Job">
					<b-form-input id="input-job-description-table" @input="getUserProfilesWithoutLoading" v-model="filter.jobDescription" size="sm"></b-form-input>
				</b-th>
				<b-th stacked-heading="Email">
					<b-form-input id="input-email-table" @input="getUserProfilesWithoutLoading" v-model="filter.email" size="sm"></b-form-input>
				</b-th>
				<b-th stacked-heading="Kantor">
					<v-select append-to-body label="name" :options="branchs" :value="filter.branchId" :reduce="item => item.id" v-model="filter.branchId" :filterable="true" @input="getUserProfilesWithoutLoading" @search="getBranchs"></v-select>
				</b-th>
				<b-th stacked-heading="Select All">
					<b-form-checkbox v-if="(userRole != null ? userRole.allowPrint : false)" id="input-select-all" class="float-right d-block d-md-none" v-model="selectAll"></b-form-checkbox>
				</b-th>
			</template>
			<template v-slot:cell(no)="row">
				{{ ((row.index + 1) + ( pageSizeOptions * (currentPage - 1))) }}
			</template>
			<template v-slot:cell(branch)="row">
				{{ row.item.branch != null ? row.item.branch.name : "" }}
			</template>
			<template v-slot:cell(directorate)="row">
				{{ row.item.directorate != null ? row.item.directorate.name : "" }}
			</template>
			<template v-slot:cell(employeeStatus)="row">
				{{ row.item.employeeStatus != null ? row.item.employeeStatus.name : "" }}
			</template>
			<template v-slot:cell(joinDate)="row">
				{{ row.item.joinDate | moment("DD-MMM-YYYY") }}
			</template>
			<template v-slot:cell(isSuperUser)="row">
				{{ row.item.isSuperUser ? 'Yes' : 'No' }}
			</template>
			<template v-slot:cell(actions)="row" >
				<div class="div-actions float-right">
					<b-button size="sm" v-if="(userRole != null ? userRole.allowRead : false)" variant="primary" class="mr-2 mt-md-2 mt-lg-0 btn-xs" :to="{path: '/userprofile/detail/' + row.item.id }" >
						<b-icon-eye-fill></b-icon-eye-fill>
					</b-button>
				</div>
			</template>
			</b-table>
		</div>
		<b-row>
			<b-col cols="12" md="6" lg="6">
			</b-col>
			<b-col cols="12" md="6" lg="6">
			<b-pagination
				class="float-md-right btn-paging"
				v-model="currentPage"
				:total-rows="(userProfilesPaging != null ? userProfilesPaging.total : 0)"
				:per-page="(userProfilesPaging != null ? userProfilesPaging.perPage : 0)"
				aria-controls="my-table"
			></b-pagination>
			</b-col>
		</b-row>
		</b-card>
	</div>
</template>
<style lang="scss" scoped>
	@import '../../assets/scss/index-page.scss';
</style>
<script>
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileList from '@/models/UserProfile/UserProfileList';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import RoleDetail from '@/models/Core/RoleDetail';

import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import AppInputTextarea from '@/components/AppInputTextarea';
import CoreUserFilter from '@/filters/Core/CoreUserFilter';
import CoreUser from '@/models/Core/CoreUser';
import BranchFilter from '@/filters/Branch/BranchFilter';
import Branch from '@/models/Branch/Branch';
import DirectorateFilter from '@/filters/Directorate/DirectorateFilter';
import Directorate from '@/models/Directorate/Directorate';
import EmployeeLevelFilter from '@/filters/EmployeeLevel/EmployeeLevelFilter';
import EmployeeLevel from '@/models/EmployeeLevel/EmployeeLevel';
import EmployeeStatusFilter from '@/filters/EmployeeStatus/EmployeeStatusFilter';
import EmployeeStatus from '@/models/EmployeeStatus/EmployeeStatus';
import EducationLevelFilter from '@/filters/EducationLevel/EducationLevelFilter';
import EducationLevel from '@/models/EducationLevel/EducationLevel';
import BloodTypeFilter from '@/filters/BloodType/BloodTypeFilter';
import BloodType from '@/models/BloodType/BloodType';
import MaritalStatusFilter from '@/filters/MaritalStatus/MaritalStatusFilter';
import MaritalStatus from '@/models/MaritalStatus/MaritalStatus';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import PopupDownload from '@/components/PopupDownload';
import PopupPDF from '@/components/PopupPDF';
import PopupDataEditNotification from '@/components/PopupDataEditNotification';
import Upload from './Upload';

export default {
	components : {UserProfile, UserProfileList, UserProfileFilter, AppInputTextbox,AppInputTextarea,AppInputComboboxAutocomplete,AppInputDatepicker,AppInputDatepickerRange,AppInputTextarea,PopupDownload,PopupPDF,Upload,PopupDataEditNotification,},
	data() {
		return {
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fields: [
				{"no" : { "class" : "th-number"}}, 
				{"fullName": {}, "class" : "th-full-name", "label" : "Name", key: 'fullName', sortable: true}, 
				{"jobDescription": {}, "class" : "th-job-description", "label" : "Job", key: 'jobDescription', sortable: true}, 
				{"email": {}, "class" : "th-email", "label" : "Email", key: 'email', sortable: true}, 
				{"branch": {}, "class" : "th-branch", "label" : "Kantor", key: 'branch', sortable: true},
				{"isSuperUser": {}, "class" : "th-isSuperUser", "label" : "Super User", key: 'isSuperUser'},
				{"actions": { "class" : "th-actions", "label" : ""}}
			],
			busy:false,
			isDataLoading: false,
			isDownloadDataBusy: false,
			isMultiplePrintBusy: false,
			showPopupUpload: false,
			showPopupDownload: false,
			isShowPopupPDF: false,
			pdfUrl: "",
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			filter: null,
			processId: "",
			popupDownloadLabel: "",
			hasLoadedData: false,
			showFilter: false,
			isShowFilter: false,
			isCreateBusy: false,
			btnEditData: [],
			currentDataId: null,
			userProfiles: [],
			userProfilesPaging: {},
			coreUsers : [],
			branchs : [],
			directorates : [],
			employeeLevels : [],
			employeeStatuss : [],
			educationLevels : [],
			bloodTypes : [],
			maritalStatuss : [],
		}
	},
	methods : {
		getUserProfiles : async function() {
			this.isDataLoading = true;
			this.sortingBy = Array();
			if (this.sortBy != null) {
				this.sortingBy[this.sortBy] = this.sortDesc;
			}
			UserProfile.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'branch,directorate,employeeStatus')
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
					this.userProfilesPaging = result.pagination
				}
				this.isDataLoading = false;
			})
			.catch(error => {
				this.isDataLoading = false;
			});
		},
		getUserProfilesWithoutLoading : async function() {
			this.sortingBy = Array();
			if (this.sortBy != null) {
				this.sortingBy[this.sortBy] = this.sortDesc;
			}
			UserProfile.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'branch,directorate,employeeStatus')
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
					this.userProfilesPaging = result.pagination
				}
			})
			.catch(error => {});
		},
		getCoreUsers : async function(input) {
			let filter = new CoreUserFilter();
			if (input != undefined) {
				filter.firstName = input;
			}
			CoreUser.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.coreUsers = result.data
				}
			})
			.catch(error => {});
		},
		getBranchs : async function(input) {
			let filter = new BranchFilter();
			if (input != undefined) {
				filter.name = input;
			}
			Branch.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.branchs = result.data
				}
			})
			.catch(error => {});
		},
		getDirectorates : async function(input) {
			let filter = new DirectorateFilter();
			if (input != undefined) {
				filter.name = input;
			}
			Directorate.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.directorates = result.data
				}
			})
			.catch(error => {});
		},
		getEmployeeLevels : async function(input) {
			let filter = new EmployeeLevelFilter();
			if (input != undefined) {
				filter.name = input;
			}
			EmployeeLevel.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.employeeLevels = result.data
				}
			})
			.catch(error => {});
		},
		getEmployeeStatuss : async function(input) {
			let filter = new EmployeeStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			EmployeeStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.employeeStatuss = result.data
				}
			})
			.catch(error => {});
		},
		getEducationLevels : async function(input) {
			let filter = new EducationLevelFilter();
			if (input != undefined) {
				filter.name = input;
			}
			EducationLevel.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.educationLevels = result.data
				}
			})
			.catch(error => {});
		},
		getBloodTypes : async function(input) {
			let filter = new BloodTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			BloodType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.bloodTypes = result.data
				}
			})
			.catch(error => {});
		},
		getMaritalStatuss : async function(input) {
			let filter = new MaritalStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			MaritalStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.maritalStatuss = result.data
				}
			})
			.catch(error => {});
		},
		resetFilter() {
			this.filter = new UserProfileFilter();
		},
		showPopupUploadData() {
			this.showPopupUpload = true;
		},
		uploadData(modalEvent) {
			this.showPopupUpload = false;
		},
		resetModal() {
			this.showPopupUpload = false;
			this.showPopupDownload = false;
			this.isShowPopupPDF = false;
		},
		resetModalUpload() {
			this.showPopupUpload = false;
			this.getUserProfiles();
		},
		toggleIsPrint(id) {
			if (this.checkAllData.includes(id)) {
				this.$store.dispatch("removeCheckData", id);
			} else {
				this.$store.dispatch("addCheckData", id);
			}
		},
		showPopupPrint(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di cetak";
			this.processId = id;
		},
		showPopupDownloadData(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di unduh";
			this.processId = id;
		},
		showPopupPDF(url) {
			this.isShowPopupPDF = true;
			this.pdfUrl = url;
		},
		printData() {
			let data = this.$store.state.dataIds;
			if (data.length > 0) {
				this.isMultiplePrintBusy = true;
				UserProfile.multiPagePrint(this).then(result => {
					if (result) {
						this.showPopupPrint(result.id);
					}
					this.isMultiplePrintBusy = false;
				}).catch(error => { this.isMultiplePrintBusy = false; });
			}
		},
		printSingleData(id) {
			UserProfile.singleDataPagePrint(this, id).then(result => {
				if (result) {
					this.showPopupPDF(result.download);
				}
			});
		},
		downloadData() {
			if (this.sortBy != null) {
					this.sortingBy[this.sortBy] = this.sortDesc;
			}
			this.isDownloadDataBusy = true;
			UserProfile.downloadData(this, this.filter, this.sortingBy).then(result => {
				if (result) {
					this.showPopupDownloadData(result.id);
				}
				this.isDownloadDataBusy = false;
			}).catch(error => { this.isDownloadDataBusy = false; })
		},
		deleteData(id) {
			UserProfile.deleteData(this, id).then(result => {
				if (result)
				{
					this.getUserProfiles();
				}
			})
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768) {
				this.isShowFilter = true;
			} else {
				this.isShowFilter = this.showFilter;
			}
		},
	},
	beforeMount() {
		this.$store.dispatch("removeCheckAllData");
		this.filter = new UserProfileFilter();
		this.getBranchs();
	},
	mounted(){
		let breadcrumb =[
			"User Management", 
			"User Profile"
		];
		this.getUserProfiles();
		this.$store.dispatch('setBreadCrumb', breadcrumb);
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
		});
	},
	watch: {
		sortBy: {
			handler: function() {
				this.getUserProfiles();
			}
		},
		sortDesc: {
			handler: function() {
				this.getUserProfiles();
			}
		},
		currentPage: {
			handler: function() {
				this.getUserProfiles();
			}
		},
		pageSizeOptions: {
			handler: function() {
				this.getUserProfiles();
			}
		},
		selectAll(newValue) {
			if (newValue) {
				UserProfile.checkAllData(this, this.filter, this.sortingBy);
			} else {
				this.$store.dispatch("removeCheckAllData");
			}
		},
		'$route'(to, from) {
			if (to != null) {
				if (to.name == "userprofileindex") {
					this.getUserProfilesWithoutLoading();
				}
			}
		},
	},
	computed: {
		checkAllData() {
			return this.$store.state.dataIds;
		},
		userRole() {
			return RoleDetail.query().where("functionInfoId", "user_profile").first();
		}
	},
	beforeDestroy() {
		this.$store.dispatch("removeCheckAllData");
	},
}
</script>
