<template>
	<div>
		<b-button class="filter" variant="light" block href="#" v-b-toggle.accordion-filter>
			<span><b-icon-funnel-fill></b-icon-funnel-fill> Filter</span>
			<span class="menu-arrow">
				<b-icon-chevron-right class="float-right chevron-right"></b-icon-chevron-right><b-icon-chevron-down class="float-right chevron-down"></b-icon-chevron-down>
			</span>
		</b-button>
		<b-collapse id="accordion-filter" accordion="accordion-filter" role="tabpanel" class="menu-accordion">
			<b-card>
				<b-row>
					<b-col cols="12" lg="6">
					<app-input-combobox-autocomplete :options="documentLocations" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Lokasi Surat" id="filter-" size="sm" :model.sync="filter.documentLocationId" @input="getDocumentLocations" @focus="getDocumentLocations" />
					<app-input-combobox-autocomplete v-if="false" :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Ditujukan Kepada" id="filter-" size="sm" :model.sync="filter.destinationUserId" @input="getUserProfiles" @focus="getUserProfiles" />
					<app-input-combobox-autocomplete v-if="false" :options="documentTypes" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Jenis Surat/Barang" id="filter-" size="sm" :model.sync="filter.documentTypeId" @input="getDocumentTypes" @focus="getDocumentTypes" />
					<app-input-textbox v-if="false" id="fieldset-document-no" label-cols-sm="4" label-cols-lg="3" label="Nomor Surat" label-for="filter-document-no" size="sm" :model.sync="filter.documentNo" />
					<app-input-textarea v-if="false" id="fieldset-description" label-cols-sm="4" label-cols-lg="3" label="Ringkasan Surat" label-for="filter-description" size="sm" :model.sync="filter.descriptionMultiple" />
					<app-input-combobox-autocomplete v-if="false" :options="distributionTypes" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Bentuk Distribusi" id="filter-" size="sm" :model.sync="filter.distributionTypeId" @input="getDistributionTypes" @focus="getDistributionTypes" />
					<app-input-combobox-autocomplete v-if="false" :options="incomingDocumentStatuss" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="DocumentStatus" id="filter-" size="sm" :model.sync="filter.documentStatusId" @input="getIncomingDocumentStatuss" @focus="getIncomingDocumentStatuss" />
					<b-button variant="danger" size="sm" class="float-right" @click="resetFilter"><span style="font-size: 0.75rem" class="mr-1"><b-icon-x></b-icon-x></span> Clear Filter</b-button>
					<b-button variant="primary" size="sm" class="float-right mr-1" @click="getIncomingDocuments"><span style="font-size: 0.75rem" class="mr-1"><b-icon-search></b-icon-search></span> Cari</b-button>
					</b-col>
				</b-row>
			</b-card>
		</b-collapse>
		<b-row class="mt-3">
			<b-col>
				<b-button variant="warning" v-if="(userRole != null ? userRole.allowUpload : false)" class="float-right ml-2" @click="showPopupUploadData" size="sm"><b-icon-upload class="mr-1"></b-icon-upload> Upload</b-button>
				<b-overlay
					v-if="(userRole != null ? userRole.allowDownload : false)"
					:show="isDownloadDataBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="float-right "
					>
					<b-button variant="primary" v-if="(userRole != null ? userRole.allowDownload : false)" class="float-right ml-2" @click="downloadData" size="sm"><b-icon-download class="mr-1"></b-icon-download> Download</b-button>
				</b-overlay>
				<b-button variant="primary" v-if="(userRole != null ? userRole.allowDownload : false)" class="float-right" href="/files/IncomingDocument/IncomingDocument.xlsx" size="sm"><b-icon-wallet-fill class="mr-1"></b-icon-wallet-fill> Download Template</b-button>
			</b-col>
		 </b-row>
		<b-card class="mt-3">
		<b-row class="mb-3">
			<b-col>
				<span v-if="isSuperUser">
					<b-button variant="success" v-if="(userRole != null ? userRole.allowCreate : false)" @click="createData" size="sm"><b-icon-pencil class="mr-1"></b-icon-pencil> Tambah</b-button>
					<b-overlay
						v-if="(userRole != null ? userRole.allowCreate : false)"
						:show="isSendEmailBusy"
						rounded
						opacity="0.6"
						spinner-small
						spinner-variant="primary"
						class="d-inline-block"
						>
						<b-button variant="primary" v-if="(userRole != null ? userRole.allowCreate : false)" @click="sendData" size="sm" class="ml-1"><b-icon-cursor-fill class="mr-1"></b-icon-cursor-fill> Kirim</b-button>
					</b-overlay>
					<b-overlay
						v-if="(userRole != null ? userRole.allowPrint : false)"
						:show="isMultiplePrintBusy"
						rounded
						opacity="0.6"
						spinner-small
						spinner-variant="primary"
						class="d-inline-block"
						>
						<b-button variant="primary" v-if="(userRole != null ? userRole.allowPrint : false)" class="ml-2" @click="printData" size="sm"><b-icon-printer-fill class="mr-1"></b-icon-printer-fill> Multiple Print</b-button>
					</b-overlay>
				</span>
			</b-col>
			<b-col>
				<span class="float-right">
					<b-form-select v-model="pageSizeOptions" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
				</span>
			</b-col>
		</b-row>
		<div class="table-corner">
			<b-table id="my-table" stacked="md" head-variant="light"  :no-local-sorting="true" :items="incomingDocuments" :fields="fields" :busy="isDataLoading" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" small responsive>
			<template v-slot:head(actions)>
				<b-button-group class="float-right"  size="sm">
					<b-button @click="isShowFilter = !isShowFilter" size="sm"><b-icon-funnel-fill /></b-button>
				</b-button-group>
			</template>
			<template #top-row v-if="isShowFilter">
				<b-th></b-th>
				<b-th></b-th>
				<b-th stacked-heading="Kode Risalah">
					<b-form-input id="input-document-code-table" @input="getIncomingDocumentsWithoutLoading" v-model="filter.documentCode" size="sm"></b-form-input>
				</b-th>
				<b-th stacked-heading="Lokasi Surat" v-if="false">
					<v-select append-to-body label="name" :options="documentLocations" :value="filter.documentLocationId" :reduce="item => item.id" v-model="filter.documentLocationId" :filterable="true" @input="getIncomingDocumentsWithoutLoading" @search="getDocumentLocations"></v-select>
				</b-th>
				<b-th stacked-heading="Tanggal">
					<b-form-datepicker boundary="viewport" placeholder="" id="input-created-date-from-table" reset-button v-model="createdAtFrom" @input="setBetweenDateFilter('createdAt', createdAtFrom, createdAtTo)" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"></b-form-datepicker>
					<b-form-datepicker boundary="viewport" placeholder="" id="input-created-date-to-table" reset-button v-model="createdAtTo" @input="setBetweenDateFilter('createdAt', createdAtFrom, createdAtTo)" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"></b-form-datepicker>
				</b-th>
				<b-th stacked-heading="Nama Pengirim">
					<b-form-input id="input-sender-name-table" @input="getIncomingDocumentsWithoutLoading" v-model="filter.senderName" size="sm"></b-form-input>
				</b-th>
				<b-th></b-th>
				<b-th stacked-heading="Ditujukan Kepada" v-if="false">
					<v-select append-to-body label="fullName" :options="userProfiles" :value="filter.destinationUserId" :reduce="item => item.id" v-model="filter.destinationUserId" :filterable="true" @input="getIncomingDocumentsWithoutLoading" @search="getUserProfiles"></v-select>
				</b-th>
				<b-th stacked-heading="Jenis Surat/Barang">
					<v-select append-to-body label="name" :options="documentTypes" :value="filter.documentTypeId" :reduce="item => item.id" v-model="filter.documentTypeId" :filterable="true" @input="getIncomingDocumentsWithoutLoading" @search="getDocumentTypes"></v-select>
				</b-th>
				<b-th stacked-heading="Ringkasan Surat">
					<b-form-input id="input-description-table" @input="getIncomingDocumentsWithoutLoading" v-model="filter.description" size="sm"></b-form-input>
				</b-th>
				<b-th></b-th>
				<b-th stacked-heading="Bentuk Distribusi">
					<v-select append-to-body label="name" :options="distributionTypes" :value="filter.distributionTypeId" :reduce="item => item.id" v-model="filter.distributionTypeId" :filterable="true" @input="getIncomingDocumentsWithoutLoading" @search="getDistributionTypes"></v-select>
				</b-th>
				<b-th></b-th>
				<b-th stacked-heading="Select All">
					<b-form-checkbox v-if="false" id="input-select-all" class="float-right d-block d-md-none" v-model="selectAll"></b-form-checkbox>
				</b-th>
			</template>
			<template v-slot:cell(no)="row">
				{{ ((row.index + 1) + ( pageSizeOptions * (currentPage - 1))) }}
			</template>
			<template v-slot:cell(documentLocation)="row">
				{{ row.item.documentLocation != null ? row.item.documentLocation.name : "" }}
			</template>
			<template v-slot:cell(createdAt)="row">
				{{ row.item.createdAt | moment('DD-MMM-YYYY') }}
			</template>
			<template v-slot:cell(destinationUser)="row">
				<span v-if="row.item.isSendToAll">
					Semua Karyawan
				</span>
				<span v-else>
					<span v-for="item in row.item.incomingDocumentDestinationUser.slice(0,3)" :key="item.id" >
						<b-badge v-if="item.destinationUser != null ? item.destinationUser.fullName != null && item.destinationUser.fullName != undefined && item.destinationUser.fullName != '' ? true : false : false" variant="secondary" class="mr-2" style="font-size:13px; font-weight: 400;background-color:#bbb">
							{{ item.destinationUser ? item.destinationUser.fullName : "-" }}
						</b-badge>
					</span>
					<span v-if="row.item.incomingDocumentDestinationUser.length > 3">
						<br /><b-button size="sm" variant="secondary" class="btn-xs" @click="showPopupDestinationUser(row.item)">Show All</b-button>
					</span>
				</span>
			</template>
			<template v-slot:cell(documentType)="row">
				{{ row.item.documentType != null ? row.item.documentType.name : "" }}
			</template>
			<template v-slot:cell(documentAttachment)="row">
				<app-label-fileupload v-if="false" :isEditable="false" label-cols-sm="0" label-cols-lg="0" label="" size="sm" :currentModel="row.item.documentAttachment"  />
				<b-btn-group v-if="row.item.isFile" @click="showPopupDocument(row.item)">
					<b-button size="sm" variant="secondary" class="btn-xs">
						{{ row.item.documentAttachmentCount }} Attachment(s)
					</b-button>
					<b-button size="sm" variant="secondary" class="btn-xs">
						<b-icon-paperclip></b-icon-paperclip>
					</b-button>
				</b-btn-group>
			</template>
			<template v-slot:cell(distributionType)="row">
				<span>{{ row.item.isFisik != null ? (row.item.isFisik ? "Fisik" : "") : "" }}</span>
				<span class="ml-2">{{ row.item.isFile != null ? (row.item.isFile ? "File" : "") : "" }}</span>
			</template>
			<template v-slot:cell(documentStatus)="row">
				{{ row.item.documentStatusUser != null ? row.item.documentStatusUser : "" }}
			</template>
			<template v-slot:cell(actions)="row" >
				<div class="div-actions float-right">
				<span v-if="row.item.isEditable"></span>
				<span v-if="row.item.isEditable">
					<span v-if="row.item.documentStatusUser !== 'Tembusan'">
						<b-button size="sm" v-b-popover.hover.left="'Edit'" v-if="(userRole != null ? userRole.allowUpdate : false)" :to="{path: '/incomingdocument/edit/' + row.item.id }" variant ="success"  class="mr-2 btn-xs">
							<b-icon-pencil></b-icon-pencil>
						</b-button>
						<b-overlay
							v-if="(userRole != null ? userRole.allowDelete : false)"
							:show="busy"
							rounded
							opacity="0.6"
							spinner-small
							spinner-variant="primary"
							class="d-inline-block"
							>
							<click-confirm>
							<b-button v-b-popover.hover.left="'Delete'" size="sm" variant="danger" @click="deleteData(row.item.id)" class="mr-2 btn-xs">
								<b-icon-trash></b-icon-trash>
							</b-button>
							</click-confirm>
						</b-overlay>
					</span>
				</span>
				<b-button size="sm"  v-b-popover.hover.left="'Detail'"  v-if="(userRole != null ? userRole.allowRead : true)" variant="primary" class="mr-2 mt-md-2 mt-lg-0 btn-xs" :to="{path: '/incomingdocument/detail/' + row.item.id }" >
					<b-icon-eye-fill></b-icon-eye-fill>
				</b-button>
				<span v-if="row.item.isEditable">
					<span v-if="row.item.documentStatusUser !== 'Tembusan' && isSuperUser && row.item.incomingDocumentStatusId == 'SENT'">
						<b-button size="sm" v-b-popover.hover.left="row.item.isReminder ? 'Matikan Reminder' : 'Nyalakan Reminder'" :variant="row.item.isReminder ? 'success' : 'light'"  @click="toggleIsReminder(row.item)" class="mr-2 mt-md-2 mt-lg-0 btn-xs">
							<b-icon-toggle-on v-if="row.item.isReminder"></b-icon-toggle-on>
							<b-icon-toggle-off v-else></b-icon-toggle-off>
						</b-button>
					</span>
				</span>
				<span v-if="row.item.incomingDocumentStatus != null ? (row.item.incomingDocumentStatus.id == 'SENT' ? true : false) : false">
					<span v-if="row.item.documentStatusUser !== 'Tembusan'">
						<b-button v-b-popover.hover.left="'Buat Disposisi'"  size="sm" v-if="(userRole != null ? userRole.allowRead : true)" variant="primary" class="mr-2 mt-md-2 mt-lg-0 btn-xs" :to="{path: '/disposition/createdata/' + row.item.id }" >
							<b-icon-clipboard-plus></b-icon-clipboard-plus>
						</b-button>
					</span>
				</span>
				<span v-if="false">
					<span v-if="row.item.documentStatusUser !== 'Tembusan'">
						<b-form-checkbox class="d-inline" :id="'input-is-print-' + (row.index + 1)" @change="toggleIsPrint(Number(row.item.id))" :checked="checkAllData.includes(Number(row.item.id))" ></b-form-checkbox>
					</span>
				</span>
				</div>
			</template>
			<template v-slot:cell(actionsCheck)="row" >
				<span v-if="row.item.documentStatusUser !== 'Tembusan'">
					<b-form-checkbox class="d-inline" :id="'input-is-print-' + (row.index + 1)" @change="toggleIsPrint(Number(row.item.id))" :checked="checkAllData.includes(Number(row.item.id))" ></b-form-checkbox>
				</span>
			</template>
			</b-table>
		</div>
		<b-row>
			<b-col cols="12" md="6" lg="6">
				<span v-if="isSuperUser">
					<b-button variant="success" v-if="(userRole != null ? userRole.allowCreate : false)" @click="createData" size="sm"><b-icon-pencil class="mr-1"></b-icon-pencil> Tambah</b-button>
					<b-overlay
						v-if="(userRole != null ? userRole.allowCreate : false)"
						:show="isSendEmailBusy"
						rounded
						opacity="0.6"
						spinner-small
						spinner-variant="primary"
						class="d-inline-block"
						>
					<b-button variant="primary" v-if="(userRole != null ? userRole.allowCreate : false)" @click="sendData" size="sm" class="ml-1"><b-icon-cursor-fill class="mr-1"></b-icon-cursor-fill> Kirim</b-button>
					</b-overlay>
					<b-overlay
						v-if="(userRole != null ? userRole.allowPrint : false)"
						:show="isMultiplePrintBusy"
						rounded
						opacity="0.6"
						spinner-small
						spinner-variant="primary"
						class="d-inline-block"
						>
						<b-button variant="primary" v-if="(userRole != null ? userRole.allowPrint : false)" class="ml-2" @click="printData" size="sm"><b-icon-printer-fill class="mr-1"></b-icon-printer-fill> Multiple Print</b-button>
					</b-overlay>
				</span>
			</b-col>
			<b-col cols="12" md="6" lg="6">
			<b-pagination
				class="float-md-right btn-paging"
				v-model="currentPage"
				:total-rows="(incomingDocumentsPaging != null ? incomingDocumentsPaging.total : 0)"
				:per-page="(incomingDocumentsPaging != null ? incomingDocumentsPaging.perPage : 0)"
				aria-controls="my-table"
			></b-pagination>
			</b-col>
		</b-row>
		</b-card>
		<PopupDownloadDocument :openPopup="isShowPopupDocument" :allowUpdate="allowUpdate" :parent="parent" @resetMethod="resetModalUpload" />
		<PopupDestinationUsers :openPopup="isShowPopupDestinationUser" :parent="parent" @resetMethod="resetModalUpload" />
	</div>
</template>
<style lang="scss" scoped>
	@import '../../assets/scss/index-page.scss';
</style>
<script>
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import IncomingDocumentList from '@/models/IncomingDocument/IncomingDocumentList';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import RoleDetail from '@/models/Core/RoleDetail';

import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import AppInputTextarea from '@/components/AppInputTextarea';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';
import Branch from '@/models/Branch/Branch';
import SenderStatusFilter from '@/filters/SenderStatus/SenderStatusFilter';
import SenderStatus from '@/models/SenderStatus/SenderStatus';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import SifatSuratFilter from '@/filters/SifatSurat/SifatSuratFilter';
import SifatSurat from '@/models/SifatSurat/SifatSurat';
import DocumentTypeFilter from '@/filters/DocumentType/DocumentTypeFilter';
import DocumentType from '@/models/DocumentType/DocumentType';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import AppLabelFileupload from '@/components/AppLabelFileupload';
import AttachmentFilter from '@/filters/Core/AttachmentFilter';
import Attachment from '@/models/Core/Attachment';
import DistributionTypeFilter from '@/filters/DistributionType/DistributionTypeFilter';
import DistributionType from '@/models/DistributionType/DistributionType';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
import IncomingDocumentStatusFilter from '@/filters/IncomingDocumentStatus/IncomingDocumentStatusFilter';
import IncomingDocumentStatus from '@/models/IncomingDocumentStatus/IncomingDocumentStatus';
import PopupDownload from '@/components/PopupDownload';
import PopupPDF from '@/components/PopupPDF';
import PopupDataEditNotification from '@/components/PopupDataEditNotification';
import Upload from './Upload';
import PopupDownloadDocument from './PopupDownloadDocument';
import PopupDestinationUsers from './PopupDestinationUsers';
import { mapGetters } from 'vuex';

export default {
	components : {PopupDestinationUsers,PopupDownloadDocument,IncomingDocument, IncomingDocumentList, IncomingDocumentFilter, AppInputTextbox,AppInputTextarea,AppInputComboboxAutocomplete,AppInputDatepicker,AppInputDatepickerRange,AppInputTextarea,AppInputDatetimepicker,AppInputDatetimepickerRange,PopupDownload,PopupPDF,Upload,PopupDataEditNotification,AppLabelFileupload},
	data() {
		return {
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fields: [
				//{"actionsCheck": { "class" : "th-actions-check", "label" : ""}},
				{"no" : { "class" : "th-number"}}, 
				{"documentCode": {}, "class" : "th-document-code", "label" : "Kode Risalah", key: 'documentCode', sortable: true}, 
				{"createdAt": {}, "class" : "th-created-at", "label" : "Tanggal", key: 'createdAt', sortable: true}, 
				{"senderName": {}, "class" : "th-sender-name", "label" : "Pengirim", key: 'senderName', sortable: true}, 
				{"destinationUser": {}, "class" : "th-destination-user", "label" : "Ditujukan Kepada", key: 'destinationUser', sortable: false}, 
				{"documentType": {}, "class" : "th-document-type", "label" : "Jenis Surat/Barang", key: 'documentType', sortable: true}, 
				//{"documentNo": {}, "class" : "th-document-no", "label" : "Nomor Surat", key: 'documentNo', sortable: true}, 
				{"description": {}, "class" : "th-description", "label" : "Ringkasan Surat", key: 'description', sortable: true}, 
				{"documentAttachment": {}, "class" : "th-document-attachment", "label" : "File Dokumen", key: 'documentAttachment', sortable: true}, 
				{"distributionType": {}, "class" : "th-distribution-type", "label" : "Bentuk Distribusi", key: 'distributionType', sortable: false},
				{"documentStatus": {}, "class" : "th-document-status", "label" : "Status", key: 'documentStatus', sortable: true},
				{"actions": { "class" : "th-actions", "label" : ""}},
			],
			busy:false,
			isDataLoading: false,
			isDownloadDataBusy: false,
			isMultiplePrintBusy: false,
			showPopupUpload: false,
			showPopupDownload: false,
			isShowPopupPDF: false,
			pdfUrl: "",
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			filter: null,
			processId: "",
			popupDownloadLabel: "",
			hasLoadedData: false,
			showFilter: false,
			isShowFilter: false,
			isCreateBusy: false,
			btnEditData: [],
			currentDataId: null,
			incomingDocuments: [],
			incomingDocumentsPaging: {},
			documentLocations : [],
			senderStatuss : [],
			userProfiles : [],
			sifatSurats : [],
			documentTypes : [],
			distributionTypes : [],
			incomingDocumentStatuss : [],
			parent:{},
			isSendEmailBusy: false,
			createdAtFrom: "",
			createdAtTo: "",
			isShowPopupDestinationUser: false,
			allowUpdate: false,
			isShowPopupDocument: false,
		}
	},
	methods : {
		showPopupDocument(parent) {
			
			this.allowUpdate = false;
			if (this.userId == parent.createdBy) {
				this.allowUpdate = true;
			}
			
			this.parent=parent;
			this.isShowPopupDocument = true;
		},
		showPopupDestinationUser(parent) {
			this.parent=parent;
			this.isShowPopupDestinationUser = true;
		},
		getIncomingDocuments : async function() {
			this.isDataLoading = true;
			this.sortingBy = Array();
			if (this.sortBy != null) {
				this.sortingBy[this.sortBy] = this.sortDesc;
			} else {
				this.sortingBy["documentDate"] = true;
			}
			IncomingDocument.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions,'documentLocation,senderStatus,userProfile,destinationUser,copyUser,sifatSurat,documentType,distributionType,incomingDocumentStatus,copyDocumentStatus,documentStatus,documentAttachment,incomingDocumentDestinationUser,incomingDocumentCopyUser')
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
					this.incomingDocumentsPaging = result.pagination
				}
				this.isDataLoading = false;
			})
			.catch(error => {
				this.isDataLoading = false;
			});
		},
		getIncomingDocumentsWithoutLoading : async function() {
			this.sortingBy = Array();
			if (this.sortBy != null) {
				this.sortingBy[this.sortBy] = this.sortDesc;
			} else {
				this.sortingBy["documentDate"] = true;
			}
			IncomingDocument.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'documentLocation,senderStatus,userProfile,destinationUser,copyUser,sifatSurat,documentType,distributionType,incomingDocumentStatus,copyDocumentStatus,documentStatus,documentAttachment,incomingDocumentDestinationUser,incomingDocumentCopyUser')
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
					this.incomingDocumentsPaging = result.pagination
				}
			})
			.catch(error => {});
		},
		getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			Branch.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		getSenderStatuss : async function(input) {
			let filter = new SenderStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			SenderStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.senderStatuss = result.data
				}
			})
			.catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		getSifatSurats : async function(input) {
			let filter = new SifatSuratFilter();
			if (input != undefined) {
				filter.name = input;
			}
			SifatSurat.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.sifatSurats = result.data
				}
			})
			.catch(error => {});
		},
		getDocumentTypes : async function(input) {
			let filter = new DocumentTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentTypes = result.data
				}
			})
			.catch(error => {});
		},
		getDistributionTypes : async function(input) {
			let filter = new DistributionTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DistributionType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.distributionTypes = result.data
				}
			})
			.catch(error => {});
		},
		getIncomingDocumentStatuss : async function(input) {
			let filter = new IncomingDocumentStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			IncomingDocumentStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocumentStatuss = result.data
				}
			})
			.catch(error => {});
		},
		resetFilter() {
			this.filter = new IncomingDocumentFilter();
		},
		showPopupUploadData() {
			this.showPopupUpload = true;
		},
		uploadData(modalEvent) {
			this.showPopupUpload = false;
		},
		resetModal() {
			this.showPopupUpload = false;
			this.showPopupDownload = false;
			this.isShowPopupPDF = false;
		},
		resetModalUpload() {
			this.isShowPopupDocument = false;
			this.showPopupUpload = false;
			this.isShowPopupDestinationUser = false;
			this.getIncomingDocuments();
		},
		toggleIsPrint(id) {
			if (this.checkAllData.includes(id)) {
				this.$store.dispatch("removeCheckData", id);
			} else {
				this.$store.dispatch("addCheckData", id);
			}
		},
		toggleIsReminder(model) {
			if (model.reminderDate == null || model.reminderDate == undefined) {
				this.$store.dispatch('addErrorMessageGlobal', "Untuk menyalakan reminder, silahkan set Reminder Date terlebih dahulu.");  
			} else {
				this.$http.get(process.env.VUE_APP_API_URL + 'incomingdocument/toggleisreminder/' + model.id).then(result => {
					this.getIncomingDocumentsWithoutLoading();
				});
			}
		},
		showPopupPrint(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di cetak";
			this.processId = id;
		},
		showPopupDownloadData(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di unduh";
			this.processId = id;
		},
		showPopupPDF(url) {
			this.isShowPopupPDF = true;
			this.pdfUrl = url;
		},
		printData() {
			let data = this.$store.state.dataIds;
			if (data.length > 0) {
				this.isMultiplePrintBusy = true;
				IncomingDocument.multiPagePrint(this).then(result => {
					if (result) {
						this.showPopupPrint(result.id);
					}
					this.isMultiplePrintBusy = false;
				}).catch(error => { this.isMultiplePrintBusy = false; });
			}
		},
		printSingleData(id) {
			IncomingDocument.singleDataPagePrint(this, id).then(result => {
				if (result) {
					this.showPopupPDF(result.download);
				}
			});
		},
		downloadData() {
			if (this.sortBy != null) {
					this.sortingBy[this.sortBy] = this.sortDesc;
			}
			this.isDownloadDataBusy = true;
			IncomingDocument.downloadData(this, this.filter, this.sortingBy).then(result => {
				if (result) {
					this.showPopupDownloadData(result.id);
				}
				this.isDownloadDataBusy = false;
			}).catch(error => { this.isDownloadDataBusy = false; })
		},
		deleteData(id) {
			IncomingDocument.deleteData(this, id).then(result => {
				if (result)
				{
					this.$store.dispatch('addSuccessMessageGlobal', "Data Berhasil dihapus");
					this.getIncomingDocuments();
				}
			})
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768) {
				this.isShowFilter = true;
			} else {
				this.isShowFilter = this.showFilter;
			}
		},
		createData() {
			this.isCreateBusy = true;
			IncomingDocument.createData(this).then(result => {
				if (result != null) {
					if (result.id != null && result.id != "") {
						this.$router.push({ name: 'incomingdocumentedit', params : { id: result.id } });
					}
				}
				this.isCreateBusy = false;
			}).catch(error => {
				this.isCreateBusy = false;
			})
		},
		sendData() {
			let data = this.$store.state.dataIds;
			if (data.length > 0) {
				this.isSendEmailBusy = true;
				IncomingDocument.sendEmail(this).then(result => {
					this.getIncomingDocuments();
					this.isSendEmailBusy = false;
				}).catch(error => { this.isSendEmailBusy = false; });
			}
		},
		setBetweenDateFilter(filterName, from, to) {
			this.filter[filterName + "From"] = from;
			this.filter[filterName + "To"] = to;
			this.getIncomingDocumentsWithoutLoading();
		}
	},
	beforeMount() {
		this.$store.dispatch("removeCheckAllData");
		this.filter = new IncomingDocumentFilter();
		this.getDocumentLocations()
		//this.getSenderStatuss()
		//this.getUserProfiles()
		//this.getSifatSurats()
		this.getDocumentTypes()
		this.getDistributionTypes()
		//this.getIncomingDocumentStatuss()
	},
	mounted(){
		let breadcrumb =[
			"Surat Masuk"
		];
		this.getIncomingDocuments();
		this.$store.dispatch('setBreadCrumb', breadcrumb);
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
		});
	},
	watch: {
		sortBy: {
			handler: function() {
				this.getIncomingDocuments();
			}
		},
		sortDesc: {
			handler: function() {
				this.getIncomingDocuments();
			}
		},
		currentPage: {
			handler: function() {
				this.getIncomingDocuments();
			}
		},
		pageSizeOptions: {
			handler: function() {
				this.getIncomingDocuments();
			}
		},
		selectAll(newValue) {
			if (newValue) {
				IncomingDocument.checkAllData(this, this.filter, this.sortingBy);
			} else {
				this.$store.dispatch("removeCheckAllData");
			}
		},
		'$route'(to, from) {
			if (to != null) {
				if (to.name == "incomingdocumentindex") {
					this.getIncomingDocumentsWithoutLoading();
				}
			}
		},
	},
	computed: {
		checkAllData() {
			return this.$store.state.dataIds;
		},
		userRole() {
			return RoleDetail.query().where("functionInfoId", "incoming_document").first();
		},
		...mapGetters({
			isSuperUser: 'isSuperUser',
			user: 'user',
			userId: 'userId',
		}),
	},
	beforeDestroy() {
		this.$store.dispatch("removeCheckAllData");
	},
}
</script>
