<template>
	<div>
		<b-button class="filter" variant="light" block href="#" v-b-toggle.accordion-filter>
			<span><b-icon-funnel-fill></b-icon-funnel-fill> Filter</span>
			<span class="menu-arrow">
				<b-icon-chevron-right class="float-right chevron-right"></b-icon-chevron-right><b-icon-chevron-down class="float-right chevron-down"></b-icon-chevron-down>
			</span>
		</b-button>
		<b-collapse id="accordion-filter" accordion="accordion-filter" role="tabpanel" class="menu-accordion">
			<b-card>
				<b-row>
					<b-col cols="12" lg="6">
					<app-input-combobox-autocomplete :options="documentLocations" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Lokasi Surat" id="filter-" size="sm" :model.sync="filter.documentLocationId" @input="getDocumentLocations" @focus="getDocumentLocations" />
					<app-input-combobox :options="documentCategory" optionsLabel="text" optionsKey="value"  label-cols-sm="4" label-cols-lg="3" label="Kategori Surat" id="filter-" size="sm" :model.sync="filter.documentCategory"  :isSelectMethodExists="true" @onSelectMethod="changeDocumentCategory" />
					<app-input-datepicker-range v-if="false" id="fieldset-archived-date-from" label-cols-sm="4" label-cols-lg="3" label="Periode Arsip Dari" size="sm" :modelFrom.sync="filter.archivedDateFromFrom" :modelTo.sync="filter.archivedDateFromTo" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button />
					<app-input-datepicker-range v-if="false"  id="fieldset-archived-date-to" label-cols-sm="4" label-cols-lg="3" label="Periode Arsip Sampai" size="sm" :modelFrom.sync="filter.archivedDateToFrom" :modelTo.sync="filter.archivedDateToTo" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button />
					<b-button variant="danger" size="sm" class="float-right" @click="resetFilter"><span style="font-size: 0.75rem" class="mr-1"><b-icon-x></b-icon-x></span> Clear Filter</b-button>
					<b-button variant="primary" size="sm" class="float-right mr-1" @click="getArchivedDocuments"><span style="font-size: 0.75rem" class="mr-1"><b-icon-search></b-icon-search></span> Cari</b-button>
					</b-col>
				</b-row>
			</b-card>
		</b-collapse>
		<b-card class="mt-3">
		<b-row class="mb-3">
			<b-col>
				<b-overlay
					:show="isBeritaAcaraBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<b-button variant="primary" @click="createBeritaAcara" size="sm"><b-icon-file-earmark-post-fill class="mr-1"></b-icon-file-earmark-post-fill> Berita Acara</b-button>
				</b-overlay>
				<b-overlay
					:show="isBackupDataBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block ml-3"
					>
					<b-button variant="success" @click="backupData" size="sm"><b-icon-download class="mr-1"></b-icon-download> Backup Data</b-button>
				</b-overlay>
			</b-col>
			<b-col>
				<span class="float-right">
					<b-form-select v-model="pageSizeOptions" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
				</span>
			</b-col>
		</b-row>
		<div class="table-corner">
			<b-table id="my-table" stacked="md" head-variant="light"  :no-local-sorting="true" :items="archivedDocuments" :fields="fields" :busy="isDataLoading" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" small responsive>
				<template v-slot:head(actions)>
					<b-button-group class="float-right"  size="sm">
						<b-button @click="isShowFilter = !isShowFilter" size="sm"><b-icon-funnel-fill /></b-button>
						<b-button><b-form-checkbox v-if="(userRole != null ? userRole.allowPrint : false)" id="input-select-all" class="float-right" v-model="selectAll"></b-form-checkbox></b-button>
					</b-button-group>
				</template>
				<template #top-row v-if="isShowFilter">
					<b-th></b-th>
					<b-th stacked-heading="No. Berita Acara">
						<b-form-input id="input-no-berita-acara-table" @input="getArchivedDocumentsWithoutLoading" v-model="filter.noBeritaAcara" size="sm"></b-form-input>
					</b-th>
					<b-th stacked-heading="Periode Arsip Dari">
						<b-form-datepicker boundary="viewport" placeholder="" id="input-archived-date-from-from-table" reset-button @input="getArchivedDocumentsWithoutLoading" v-model="filter.archivedDateFromFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"></b-form-datepicker>
						<b-form-datepicker boundary="viewport" placeholder="" id="input-archived-date-from-to-table" reset-button @input="getArchivedDocumentsWithoutLoading" v-model="filter.archivedDateFromTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"></b-form-datepicker>
					</b-th>
					<b-th stacked-heading="Periode Arsip Sampai">
						<b-form-datepicker boundary="viewport" placeholder="" id="input-archived-date-to-from-table" reset-button @input="getArchivedDocumentsWithoutLoading" v-model="filter.archivedDateToFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"></b-form-datepicker>
						<b-form-datepicker boundary="viewport" placeholder="" id="input-archived-date-to-to-table" reset-button @input="getArchivedDocumentsWithoutLoading" v-model="filter.archivedDateToTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"></b-form-datepicker>
					</b-th>
					<b-th stacked-heading="Lokasi Arsip">
						<v-select append-to-body label="name" :options="documentLocations" :value="filter.documentLocationId" :reduce="item => item.id" v-model="filter.documentLocationId" :filterable="true" @input="getArchivedDocumentsWithoutLoading" @search="getDocumentLocations"></v-select>
					</b-th>
					<b-th stacked-heading="Pelaksana">
						<v-select append-to-body label="fullName" :options="userProfiles" :value="filter.pICId" :reduce="item => item.id" v-model="filter.pICId" :filterable="true" @input="getArchivedDocumentsWithoutLoading" @search="getUserProfiles"></v-select>
					</b-th>
					<b-th stacked-heading="Menyetujui">
						<v-select append-to-body label="fullName" :options="userProfiles" :value="filter.approvedById" :reduce="item => item.id" v-model="filter.approvedById" :filterable="true" @input="getArchivedDocumentsWithoutLoading" @search="getUserProfiles"></v-select>
					</b-th>
					<b-th stacked-heading="Metode Hapus">
						<v-select append-to-body label="name" :options="removalMethods" :value="filter.removalMethodId" :reduce="item => item.id" v-model="filter.removalMethodId" :filterable="true" @input="getArchivedDocumentsWithoutLoading" @search="getRemovalMethods"></v-select>
					</b-th>
					<b-th stacked-heading="Select All">
						<b-form-checkbox v-if="(userRole != null ? userRole.allowPrint : false)" id="input-select-all" class="float-right d-block d-md-none" v-model="selectAll"></b-form-checkbox>
					</b-th>
				</template>
				<template v-slot:cell(no)="row">
					{{ ((row.index + 1) + ( pageSizeOptions * (currentPage - 1))) }}
				</template>
				<template v-slot:cell(archivedDateFrom)="row">
					{{ row.item.archivedDateFrom | moment("DD-MMM-YYYY") }}
				</template>
				<template v-slot:cell(archivedDateTo)="row">
					{{ row.item.archivedDateTo | moment("DD-MMM-YYYY") }}
				</template>
				<template v-slot:cell(pIC)="row">
					{{ row.item.pIC != null ? row.item.pIC.fullName : "" }}
				</template>
				<template v-slot:cell(approvedBy)="row">
					{{ row.item.approvedBy != null ? row.item.approvedBy.fullName : "" }}
				</template>
				<template v-slot:cell(removalMethod)="row">
					{{ row.item.removalMethod != null ? row.item.removalMethod.name : "" }}
				</template>
				<template v-slot:cell(documentCode)="row">
					<span v-if="row.item.incomingDocument != null">
						{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentCode : "" }}
					</span>
					<span v-else-if="row.item.outgoingDocument != null">
						{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentCode : "" }}
					</span>
				</template>
				<template v-slot:cell(createdAt)="row">
					{{ row.item != null ? row.item.createdAt : "" | moment("DD-MMM-YYYY")}}
				</template>
				<template v-slot:cell(documentDate)="row">
					<span v-if="row.item.incomingDocument != null">
						{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentDate : "" | moment("DD-MMM-YYYY")}}
					</span>
					<span v-else-if="row.item.outgoingDocument != null">
						{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentDate : "" | moment("DD-MMM-YYYY")}}
					</span>
				</template>
				<template v-slot:cell(distributionType)="row">
					<span v-if="row.item.incomingDocument != null">
						{{ row.item.incomingDocument != null ? row.item.incomingDocument.distributionType != null ? row.item.incomingDocument.distributionType.name : "" : "" }}
					</span>
					<span v-else-if="row.item.outgoingDocument != null">
						{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.distributionType != null ? row.item.outgoingDocument.distributionType.name : "" : "" }}
					</span>
				</template>
				<template v-slot:cell(documentLocation)="row">
					<span v-if="row.item.incomingDocument != null">
						{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentLocationName : "" }}
					</span>
					<span v-else-if="row.item.outgoingDocument != null">
						{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentLocationName : "" }}
					</span>
				</template>
				<template v-slot:cell(remarks)="row">
					<span v-if="row.item.incomingDocument != null">
						{{ row.item.incomingDocument != null ? row.item.incomingDocument.remarks : "" }}
					</span>
					<span v-else-if="row.item.outgoingDocument != null">
						{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.remarks : "" }}
					</span>
				</template>
				<template v-slot:cell(documentType)="row">
					<span v-if="row.item.incomingDocument != null">
						{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentTypeName : "" }}
					</span>
					<span v-else-if="row.item.outgoingDocument != null">
						{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentTypeName : "" }}
					</span>
				</template>
				<template v-slot:cell(documentNo)="row">
					<span v-if="row.item.incomingDocument != null">
						{{ row.item.incomingDocument != null ? row.item.incomingDocument.documentNo : "" }}
					</span>
					<span v-else-if="row.item.outgoingDocument != null">
						{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.documentNo : "" }}
					</span>
				</template>
				
				<template v-slot:cell(documentAttachment)="row">
					<b-btn-group @click="showPopupDocument((row.item.incomingDocument != null ? row.item.incomingDocument : (row.item.outgoingDocument != null ? row.item.outgoingDocument : null)), (row.item.incomingDocument != null ? 'INCOMING' : (row.item.outgoingDocument != null ? 'OUTGOING' : '')))">
						<b-button size="sm" variant="secondary" class="btn-xs">
							{{ row.item.documentAttachmentCount }} Attachment(s)
						</b-button>
						<b-button size="sm" variant="secondary" class="btn-xs">
							<b-icon-paperclip></b-icon-paperclip>
						</b-button>
					</b-btn-group>
				</template>
				<template v-slot:cell(sifatSurat)="row">
					<span v-if="row.item.incomingDocument != null">
						{{ row.item.incomingDocument != null ? row.item.incomingDocument.sifatSuratName : "" }}
					</span>
					<span v-else-if="row.item.outgoingDocument != null">
						{{ row.item.outgoingDocument != null ? row.item.outgoingDocument.sifatSuratName : "" }}
					</span>
				</template>
				<template v-slot:cell(archivedStatus)="row">
					<span v-if="row.item.isPermanent">
						Permanen
					</span>
					<span v-else>
						<span v-if="row.item.archivedDocumentStatusName != '' && row.item.archivedDocumentStatusName != null">
							{{ row.item.archivedDocumentStatusName }}
						</span>
						<span v-else-if="row.item.outgoingDocument != null">
							<span v-if="row.item.outgoingDocument.outgoingDocumentStatusId == 'REJECTED'">
								{{ row.item.outgoingDocument.outgoingDocumentStatusName }}
							</span>
						</span>
					</span>
				</template>
				<template v-slot:cell(actions)="row" >
					<div class="div-actions float-right">
						<b-button size="sm" v-if="(userRole != null ? userRole.allowUpdate : false)" @click="editData(row.index, row.item.id)" variant ="success"  class="mr-2 btn-xs">
							<b-icon-pencil></b-icon-pencil>
						</b-button>
						<span v-if="row.item.outgoingDocument != null">
							<span v-if="row.item.outgoingDocument.outgoingDocumentStatusId == 'REJECTED'">
								<b-overlay
									v-if="(userRole != null ? userRole.allowDelete : false)"
									:show="busy"
									rounded
									opacity="0.6"
									spinner-small
									spinner-variant="primary"
									class="d-inline-block"
									>
									<click-confirm>
									<b-button size="sm" variant="danger" @click="deleteData(row.item.id)" class="mr-2 btn-xs">
										<b-icon-trash></b-icon-trash>
									</b-button>
									</click-confirm>
								</b-overlay>
							</span>
						</span>
						<span v-if="row.item.incomingDocument != null">
							<b-button size="sm" v-if="(userRole != null ? userRole.allowRead : false)" variant="primary" class="mr-2 mt-md-2 mt-lg-0 btn-xs" :to="{path: '/incomingdocument/detail/' + row.item.incomingDocument.id }" >
								<b-icon-eye-fill></b-icon-eye-fill>
							</b-button>
						</span>
						<span v-else-if="row.item.outgoingDocument != null">
							<b-button size="sm" v-if="(userRole != null ? userRole.allowRead : false)" variant="primary" class="mr-2 mt-md-2 mt-lg-0 btn-xs" :to="{path: '/outgoingdocument/detail/' + row.item.outgoingDocument.id }" >
								<b-icon-eye-fill></b-icon-eye-fill>
							</b-button>
						</span>
						<b-button size="sm" v-if="(userRole != null ? userRole.allowPrint : false)" variant="light"  @click="printSingleData(row.item.id)" class="mr-2 mt-md-2 mt-lg-0 btn-xs">
							<b-icon-printer-fill></b-icon-printer-fill>
						</b-button>
						<b-form-checkbox v-if="(userRole != null ? userRole.allowPrint : false)" class="d-inline" :id="'input-is-print-' + (row.index + 1)" @change="toggleIsPrint(Number(row.item.id))" :checked="checkAllData.includes(Number(row.item.id))" ></b-form-checkbox>
					</div>
				</template>
			</b-table>
		</div>
		<b-row>
			<b-col cols="12" md="6" lg="6">
				<b-overlay
					:show="isBeritaAcaraBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<b-button variant="primary" @click="createBeritaAcara" size="sm"><b-icon-file-earmark-post-fill class="mr-1"></b-icon-file-earmark-post-fill> Berita Acara</b-button>
				</b-overlay>
				<b-overlay
					:show="isBackupDataBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block ml-3"
					>
					<b-button variant="success" @click="backupData" size="sm"><b-icon-download class="mr-1"></b-icon-download> Backup Data</b-button>
				</b-overlay>
			</b-col>
			<b-col cols="12" md="6" lg="6">
			<b-pagination
				class="float-md-right btn-paging"
				v-model="currentPage"
				:total-rows="(archivedDocumentsPaging != null ? archivedDocumentsPaging.total : 0)"
				:per-page="(archivedDocumentsPaging != null ? archivedDocumentsPaging.perPage : 0)"
				aria-controls="my-table"
			></b-pagination>
			</b-col>
		</b-row>
		</b-card>
		<PopupUpdateData :openPopup="isShowUpdateData" :modelId="modelId" @resetMethod="resetModal" />
		<PopupDeleteData :openPopup="isShowDeleteData" :modelId="modelId" @resetMethod="resetModal" />
		<PopupCreateBeritaAcara :openPopup="isShowCreateBeritaAcara" @resetMethod="resetModal" />
		<PopupBackupData :openPopup="isShowBackupData" @resetMethod="resetModal" />
		<PopupDownloadIncomingDocument :openPopup="isShowPopupIncomingDocument" :allowUpdate="false" :parent="parent" @resetMethod="resetModalUpload" />
		<PopupDownloadOutgoingDocument :openPopup="isShowPopupOutgoingDocument" :allowUpdate="false" :parent="parent" @resetMethod="resetModalUpload" />
	</div>
</template>
<style lang="scss" scoped>
	@import '../../assets/scss/index-page.scss';
</style>
<script>
import ArchivedDocument from '@/models/ArchivedDocument/ArchivedDocument';
import ArchivedDocumentList from '@/models/ArchivedDocument/ArchivedDocumentList';
import ArchivedDocumentFilter from '@/filters/ArchivedDocument/ArchivedDocumentFilter';
import RoleDetail from '@/models/Core/RoleDetail';

import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import AppInputCombobox from '@/components/AppInputCombobox';
import AppInputTextarea from '@/components/AppInputTextarea';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import Branch from '@/models/Branch/Branch';
import RemovalMethodFilter from '@/filters/RemovalMethod/RemovalMethodFilter';
import RemovalMethod from '@/models/RemovalMethod/RemovalMethod';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import PopupDownload from '@/components/PopupDownload';
import PopupPDF from '@/components/PopupPDF';
import PopupDataEditNotification from '@/components/PopupDataEditNotification';
import Upload from './Upload';
import PopupUpdateData from './PopupUpdateData';
import PopupDeleteData from './PopupDeleteData';
import PopupBackupData from './PopupBackupData';
import PopupCreateBeritaAcara from './PopupCreateBeritaAcara';
import PopupDownloadIncomingDocument from '@/views/IncomingDocument/PopupDownloadDocument';
import PopupDownloadOutgoingDocument from '@/views/OutgoingDocument/PopupDownloadDocument';
import { mapGetters } from 'vuex';

export default {
	components : {PopupDownloadIncomingDocument,PopupDownloadOutgoingDocument,PopupUpdateData, PopupDeleteData, PopupBackupData, PopupCreateBeritaAcara, AppInputCombobox, ArchivedDocument, ArchivedDocumentList, ArchivedDocumentFilter, AppInputTextbox,AppInputDatepicker,AppInputDatepickerRange,AppInputTextarea,AppInputComboboxAutocomplete,AppInputTextarea,PopupDownload,PopupPDF,Upload,PopupDataEditNotification,},
	data() {
		return {
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fields: [
				{"no" : { "class" : "th-number"}}, 
				{"documentCode": {}, "class" : "th-incoming-document", "label" : "Kode Risalah", key: 'documentCode', sortable: false}, 
				{"documentNo": {}, "class" : "th-document-no", "label" : "Nomor Surat", key: 'documentNo', sortable: false},
				//{"createdDate": {}, "class" : "th-document-date", "label" : "Tanggal & Waktu", key: 'createdDate', sortable: true}, 
				{"createdAt": {}, "class" : "th-document-date", "label" : "Tanggal", key: 'createdAt', sortable: false}, 
				{"sifatSurat": {}, "class" : "th-sifat-surat", "label" : "Sifat Surat", key: 'sifatSurat', sortable: false}, 
				{"documentType": {}, "class" : "th-document-type", "label" : "Jenis Surat", key: 'documentType', sortable: false}, 
				{"description": {}, "class" : "th-description", "label" : "Perihal Surat", key: 'description', sortable: false}, 
				{"documentAttachment": {}, "class" : "th-document-attachment", "label" : "File Dokumen", key: 'documentAttachment', sortable: false}, 
				//{"distributionType": {}, "class" : "th-remaining-day", "label" : "Bentuk Distribusi", key: 'distributionType', sortable: true}, 
				{"archivedStatus": {}, "class" : "th-contract-status", "label" : "Status Arsip", key: 'archivedStatus', sortable: false}, 
				{"documentLocation": {}, "class" : "th-document-location", "label" : "Lokasi Arsip", key: 'documentLocation', sortable: false}, 
				{"remarks": {}, "class" : "th-remarks", "label" : "Catatan", key: 'remarks', sortable: false}, 
				{"actions": { "class" : "th-actions", "label" : ""}}
			],
			busy:false,
			isDataLoading: false,
			isDownloadDataBusy: false,
			isMultiplePrintBusy: false,
			showPopupUpload: false,
			showPopupDownload: false,
			isShowPopupPDF: false,
			pdfUrl: "",
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			filter: null,
			processId: "",
			popupDownloadLabel: "",
			hasLoadedData: false,
			showFilter: false,
			isShowFilter: false,
			isCreateBusy: false,
			btnEditData: [],
			currentDataId: null,
			archivedDocuments: [],
			archivedDocumentsPaging: {},
			documentLocations : [],
			userProfiles : [],
			removalMethods : [],
			incomingDocuments : [],
			outgoingDocuments : [],
			documentCategory:[
				{ value : null, text : 'All' },
				//{ value : "SMA", text : 'Surat Masuk' },
				{ value : "SKI", text : 'Surat Keluar Internal' },
				{ value : "SKE", text : 'Surat Keluar External' },
			],
			documentLocations: [],
			isShowUpdateData: false,
			isShowDeleteData: false,
			isShowCreateBeritaAcara: false,
			isShowBackupData: false,
			isBeritaAcaraBusy: false,
			isBackupDataBusy: false,
			modelId: '',
			parent: {},
			isShowPopupIncomingDocument: false,
			isShowPopupOutgoingDocument: false,
		}
	},
	methods : {
		showPopupDocument(parent, documentType) {
			this.parent = parent;
			if (documentType == 'INCOMING')
				this.isShowPopupIncomingDocument = true;
			if (documentType == 'OUTGOING')
				this.isShowPopupOutgoingDocument = true;
		},
		/*getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			Branch.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},*/
		getArchivedDocuments : async function() {
			this.isDataLoading = true;
			this.sortingBy = Array();
			if (this.sortBy != null) {
				this.sortingBy[this.sortBy] = this.sortDesc;
			}
			this.filter["dataType"] = "OUTGOING";
			this.filter.dataType = "OUTGOING";
			ArchivedDocument.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'incomingDocument,outgoingDocument')
			.then(result => {
				if (result != null) {
					this.archivedDocuments = result.data
					this.archivedDocumentsPaging = result.pagination
				}
				this.isDataLoading = false;
			})
			.catch(error => {
				this.isDataLoading = false;
			});
		},
		getArchivedDocumentsWithoutLoading : async function() {
			this.sortingBy = Array();
			if (this.sortBy != null) {
				this.sortingBy[this.sortBy] = this.sortDesc;
			}
			this.filter["dataType"] = "OUTGOING";
			this.filter.dataType = "OUTGOING";
			ArchivedDocument.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions, 'incomingDocument,outgoingDocument')
			.then(result => {
				if (result != null) {
					this.archivedDocuments = result.data
					this.archivedDocumentsPaging = result.pagination
				}
			})
			.catch(error => {});
		},
		getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			Branch.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		getRemovalMethods : async function(input) {
			let filter = new RemovalMethodFilter();
			if (input != undefined) {
				filter.name = input;
			}
			RemovalMethod.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.removalMethods = result.data
				}
			})
			.catch(error => {});
		},
		getIncomingDocuments : async function(input) {
			let filter = new IncomingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			IncomingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		getOutgoingDocuments : async function(input) {
			let filter = new OutgoingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			OutgoingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.outgoingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		resetFilter() {
			this.filter = new ArchivedDocumentFilter();
		},
		showPopupUploadData() {
			this.showPopupUpload = true;
		},
		uploadData(modalEvent) {
			this.showPopupUpload = false;
		},
		resetModal(sourceType) {
			this.showPopupUpload = false;
			this.showPopupDownload = false;
			this.isShowPopupPDF = false;
			this.isShowUpdateData = false;
			this.isShowDeleteData = false;
			this.isShowCreateBeritaAcara = false;
			this.isShowBackupData = false;
			if (sourceType == 'UpdateData') {
				this.getArchivedDocumentsWithoutLoading();
			}
		},
		resetModalUpload() {
			this.isShowPopupIncomingDocument = false,
			this.isShowPopupOutgoingDocument = false,
			this.showPopupUpload = false;
			this.getArchivedDocuments();
		},
		toggleIsPrint(id) {
			if (this.checkAllData.includes(id)) {
				this.$store.dispatch("removeCheckData", id);
			} else {
				this.$store.dispatch("addCheckData", id);
			}
		},
		showPopupPrint(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di cetak";
			this.processId = id;
		},
		showPopupDownloadData(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di unduh";
			this.processId = id;
		},
		showPopupPDF(url) {
			this.isShowPopupPDF = true;
			this.pdfUrl = url;
		},
		printData() {
			let data = this.$store.state.dataIds;
			if (data.length > 0) {
				this.isMultiplePrintBusy = true;
				ArchivedDocument.multiPagePrint(this).then(result => {
					if (result) {
						this.showPopupPrint(result.id);
					}
					this.isMultiplePrintBusy = false;
				}).catch(error => { this.isMultiplePrintBusy = false; });
			}
		},
		printSingleData(id) {
			ArchivedDocument.singleDataPagePrint(this, id).then(result => {
				if (result) {
					this.showPopupPDF(result.download);
				}
			});
		},
		downloadData() {
			if (this.sortBy != null) {
					this.sortingBy[this.sortBy] = this.sortDesc;
			}
			this.isDownloadDataBusy = true;
			ArchivedDocument.downloadData(this, this.filter, this.sortingBy).then(result => {
				if (result) {
					this.showPopupDownloadData(result.id);
				}
				this.isDownloadDataBusy = false;
			}).catch(error => { this.isDownloadDataBusy = false; })
		},
		createData() {
			this.isCreateBusy = true;
			ArchivedDocument.createData(this).then(result => {
				if (result != null) {
					if (result.id != null && result.id != "") {
						this.$router.push({ name: 'archiveddocumentedit', params : { id: result.id } });
					}
				}
				this.isCreateBusy = false;
			}).catch(error => {
				this.isCreateBusy = false;
			})
		},
		editData(index, id) {
			this.btnEditData[index] = true;
			this.modelId = id;
			this.isShowUpdateData = true;
			/*ArchivedDocument.editData(this, id).then(result => {
				if (result != null) {
					if (result.id != null && result.id != "") {
						this.$router.push({ name: 'archiveddocumentedit', params : { id: result.id } });
					}
				}
				this.btnEditData[index] = false;
			}).catch(error => { this.btnEditData[index] = false; })*/
		},
		deleteData(id) {
			this.modelId = id;
			//this.isShowDeleteData = true;
			ArchivedDocument.deleteData(this, id).then(result => {
				if (result)
				{
					this.getArchivedDocuments();
				}
			})
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768) {
				this.isShowFilter = true;
			} else {
				this.isShowFilter = this.showFilter;
			}
		},
		changeDocumentCategory(selectedValue, data){
			if (selectedValue) {
				this.filter.documentCategory = selectedValue.value;
			} else {
				this.filter.documentCategory = null;
			}
		},
		createBeritaAcara() {
			this.isShowCreateBeritaAcara = true;
		},
		backupData() {
			this.isShowBackupData = true;
		}
	},
	beforeMount() {
		this.$store.dispatch("removeCheckAllData");
		this.filter = new ArchivedDocumentFilter();
		//this.getDocumentLocations()
		//this.getUserProfiles();
		//this.getRemovalMethods();
		//this.getIncomingDocuments();
		//this.getOutgoingDocuments();
		this.getDocumentLocations();
	},
	mounted(){
		let breadcrumb =[
			"Arsip Surat", 
			"Surat Keluar"
		];
		this.getArchivedDocuments();
		this.$store.dispatch('setBreadCrumb', breadcrumb);
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
		});
	},
	watch: {
		sortBy: {
			handler: function() {
				this.getArchivedDocuments();
			}
		},
		sortDesc: {
			handler: function() {
				this.getArchivedDocuments();
			}
		},
		currentPage: {
			handler: function() {
				this.getArchivedDocuments();
			}
		},
		pageSizeOptions: {
			handler: function() {
				this.getArchivedDocuments();
			}
		},
		selectAll(newValue) {
			if (newValue) {
				ArchivedDocument.checkAllData(this, this.filter, this.sortingBy);
			} else {
				this.$store.dispatch("removeCheckAllData");
			}
		},
		'$route'(to, from) {
			if (to != null) {
				if (to.name == "archiveddocumentindex") {
					this.getArchivedDocumentsWithoutLoading();
				}
			}
		},
	},
	computed: {
		checkAllData() {
			return this.$store.state.dataIds;
		},
		userRole() {
			return RoleDetail.query().where("functionInfoId", "incoming_document").first();
		},
		...mapGetters({
			isSuperUser: 'isSuperUser',
			user: 'user',
			userId: 'userId',
		}),
	},
	beforeDestroy() {
		this.$store.dispatch("removeCheckAllData");
	},
}
</script>
