<template>
	<PopupRouterView label="Incoming Document Input"  entityName="incomingdocument" :documentId="mainRecordId"  @saveMethod="saveAction" @hideMethod="onHidden">
		
			<b-row>
				<b-col cols="12" lg="6">
					<b-card>
						<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" label-for="filter-document-code">
							{{ model != null ? (model.documentCode != null && model.documentCode != "" ? model.documentCode : "Autonumber") : "Autonumber" }}
						</b-form-group>
						<b-form-group id="fieldset-created-at" label-cols-sm="4" label-cols-lg="3" label="Tanggal & Waktu" label-for="filter-created-at">
							{{ (model != null ? model.createdAt : '-') | moment("DD-MM-YYYY HH:mm:ss") }}
						</b-form-group>
						<b-form-group id="fieldset-branch" label-cols-sm="4" label-cols-lg="3" label="Lokasi Surat" label-for="filter-branch">
							{{ model != null ? (model.documentLocation != null ? model.documentLocation.name : '-') : '-' }}
						</b-form-group>
						</b-card>
						<b-card class="mt-3">
						<app-input-textbox :isRequired="true" :state="validateState('senderName')" :errorMessage="getErrorMessage(errorMessage.senderName, 'senderName')" id="fieldset-sender-name" label-cols-sm="4" label-cols-lg="3" label="Nama Pengirim" label-for="input-sender-name" size="sm" :model.sync="model.senderName"   />
						<app-input-combobox-autocomplete :isRequired="true" :state="validateState('senderStatusId')" :errorMessage="getErrorMessage(errorMessage.senderStatusId, 'senderStatusId')"  :options="senderStatuss" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Status Pengirim" id="input-" size="sm" :model.sync="model.senderStatusId"  :selected="model.senderStatus != null ? model.senderStatus.name : ''" @input="getSenderStatuss" />
						<app-input-textbox id="fieldset-contact-person" label-cols-sm="4" label-cols-lg="3" label="Contact Person (CP)" label-for="input-contact-person" size="sm" :model.sync="model.contactPerson"   />
						<app-input-textbox id="fieldset-sender-address" label-cols-sm="4" label-cols-lg="3" label="Alamat Pengirim" label-for="input-sender-address" size="sm" :model.sync="model.senderAddress"   />
						<app-input-textbox id="fieldset-sender-email" label-cols-sm="4" label-cols-lg="3" label="E-Mail Pengirim/CP" label-for="input-sender-email" size="sm" :model.sync="model.senderEmail"   />
						<app-input-textbox id="fieldset-sender-phone-number" label-cols-sm="4" label-cols-lg="3" label="No. Telepon" label-for="input-sender-phone-number" size="sm" :model.sync="model.senderPhoneNumber"   />
					</b-card>
					<b-card class="mt-3">
						<app-input-combobox-autocomplete-multi-select :disabled="model.isSendToAll != null && model.isSendToAll != undefined ? model.isSendToAll : false" :isRequired="true" :state="validateState('destinationUsers')" :errorMessage="getErrorMessage(errorMessage.destinationUsers, 'destinationUsers')"  :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Ditujukan Kepada" id="input-" size="sm" :model.sync="model.destinationUsers" :selected="destinationUserDatas" @input="getUserProfiles" />
						<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="" label-for="filter-document-code">
							<b-form-checkbox :id="'checkbox-send-to-all'"  v-model="model.isSendToAll" :name="'checkbox-send-to-all'" :value="true" :unchecked-value="false" >
								<span class="col-form-label">Kirim ke Semua</span>
							</b-form-checkbox>
						</b-form-group>
					</b-card>
					<b-card class="mt-3">
						<app-input-combobox-autocomplete-multi-select :options="copyUsers" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Ditembuskan Kepada" id="input-" size="sm" :model.sync="model.copyUsers" :selected="copyUserDatas" @input="getCopyUsers" />
					</b-card>
					<b-card class="mt-3" v-if="false">
						<app-input-combobox-autocomplete :isRequired="true" :state="validateState('destinationUserId')" :errorMessage="getErrorMessage(errorMessage.destinationUserId, 'destinationUserId')"  :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Ditujukan Kepada" id="input-" size="sm" :model.sync="model.destinationUserId"  :selected="model.destinationUser != null ? model.destinationUser.fullName : ''" @input="getUserProfiles"  :isSelectMethodExists="true" @onSelectMethod="changeDestinationUser" />
						<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Jabatan Pegawai" label-for="filter-document-code">
							{{ model != null ? (model.destinationUser != null ? model.destinationUser.jobDescription : '-') : "-" }}
						</b-form-group>
						<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Email" label-for="filter-document-code">
							{{ model != null ? (model.destinationUser != null ? model.destinationUser.email : '-') : "-" }}
						</b-form-group>
					</b-card>
					<b-card class="mt-3" v-if="false">
						<app-input-combobox-autocomplete :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Ditembuskan Kepada" id="input-" size="sm" :model.sync="model.copyUserId"  :selected="model.copyUser != null ? model.copyUser.fullName : ''" @input="getUserProfiles" :isSelectMethodExists="true" @onSelectMethod="changeCopyUser" />
						<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Jabatan Pegawai" label-for="filter-document-code">
							{{ model != null ? (model.copyUser != null ? model.copyUser.jobDescription : '-') : "-" }}
						</b-form-group>
						<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Email" label-for="filter-document-code">
							{{ model != null ? (model.copyUser != null ? model.copyUser.email : '-') : "-" }}
						</b-form-group>
					</b-card>
				</b-col>
				<b-col cols="12" lg="6">
					<b-card class="mt-3 mt-lg-0">
						<app-input-combobox-autocomplete :options="sifatSurats" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Sifat Surat" id="input-" size="sm" :model.sync="model.sifatSuratId"  :selected="model.sifatSurat != null ? model.sifatSurat.name : ''" @input="getSifatSurats" :isRequired="true" :state="validateState('sifatSuratId')" :errorMessage="getErrorMessage(errorMessage.sifatSuratId, 'sifatSuratId')" />
						<app-input-combobox-autocomplete :options="documentTypes" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Jenis Surat/Barang" id="input-" size="sm" :model.sync="model.documentTypeId"  :selected="model.documentType != null ? model.documentType.name : ''" @input="getDocumentTypes" :isRequired="true" :state="validateState('documentTypeId')" :errorMessage="getErrorMessage(errorMessage.documentTypeId, 'documentTypeId')" />
						<app-input-textbox id="fieldset-document-no" label-cols-sm="4" label-cols-lg="3" label="Nomor Surat Masuk" label-for="input-document-no" size="sm" :model.sync="model.documentNo"   />
						<app-input-datepicker  id="fieldset-document-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal Surat" size="sm" :model.sync="model.documentDate"  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button />
						<app-input-textarea id="fieldset-description" label-cols-sm="4" label-cols-lg="3" label="Ringkasan Surat" label-for="input-description" size="sm" :model.sync="model.description"   />
						</b-card>
						<b-card class="mt-3">
						<app-input-datepicker-range id="fieldset-contract-date" label-cols-sm="4" label-cols-lg="3" label="Masa Berlaku Kontrak" size="sm" :modelFrom.sync="model.contractDateFrom" :modelTo.sync="model.contractDateTo" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button />
						<app-input-textarea id="fieldset-contract-description" label-cols-sm="4" label-cols-lg="3" label="Catatan Atas Kontrak" label-for="input-contract-description" size="sm" :model.sync="model.contractDescription"   />
						</b-card>
						<b-card class="mt-3">
						<app-input-combobox-autocomplete v-if="false" :options="distributionTypes" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Bentuk Distribusi" id="input-" size="sm" :model.sync="model.distributionTypeId"  :selected="model.distributionType != null ? model.distributionType.name : ''" @input="getDistributionTypes" />
						<b-form-group id="fieldset-is-reminder" label-cols-sm="4" label-cols-lg="3" label="Bentuk Distribusi" label-for="input-distribution-type">
							<template v-slot:label>Bentuk Distribusi<span class="required"></span></template>
							<b-form-checkbox id="input-is-fisik" inline v-model="model.isFisik">Fisik</b-form-checkbox>
							<b-form-checkbox id="input-is-file" inline v-model="model.isFile">File</b-form-checkbox>
						</b-form-group>
						<app-input-fileupload v-if="false" :isRequired="true" label-cols-sm="4" label-cols-lg="3" label="File Dokumen" :maxFileSize="5" description="Ukuran maksimum file 5MB." size="sm" :model.sync="documentAttachment"  :currentModel="model.documentAttachment"  />
						<b-form-group id="fieldset-is-received" label-cols-sm="4" label-cols-lg="3" v-if="model.isFile" label="File Dokumen" >
							<template v-slot:label>File Dokumen <span class="required"></span></template>
							<div>
								<b-input-group :prepend="model.documentAttachmentCount + ' Attachment(s)'">
									<b-input-group-append>
										<b-button variant="light" class="btn-upload" style="border: 1px #ddd solid" size="sm" @click="showUploadFile"><b-icon-upload /></b-button>
									</b-input-group-append>
								</b-input-group>
							</div>
						</b-form-group>
						<b-form-group v-if="model.isFisik" id="fieldset-is-reminder" label-cols-sm="4" label-cols-lg="3" label="No. Tanda Terima Surat" label-for="input-is-reminder">
							<template v-slot:label>No. Tanda Terima Surat<span class="required"></span></template>
							<span v-if="receiptDocument != null && receiptDocument.receiptDocumentNo != null && receiptDocument.receiptDocumentNo != undefined">
								{{ receiptDocument != null ? receiptDocument.receiptDocumentNo : "" }}
							</span>
							<span v-else>
								{{ model != null ? (model.receiptDocument != null ? model.receiptDocument.receiptDocumentNo : "") : "" }}
							</span>
							<span v-if="model.documentCode">
								<b-button variant="success" v-if="model.isFisik" size="sm" @click="createReceiptDocument(true)"><b-icon-envelope class="mr-1"></b-icon-envelope> Terima Surat</b-button>
							</span>
							<span v-else></span>
							<b-button variant="secondary" class="ml-1" v-if="model.isFisik && ((model.receiptDocument != null && model.receiptDocument.id != null) || (receiptDocument != null && receiptDocument.id != null))" size="sm" @click="printReceiptDocument"><b-icon-printer class="mr-1"></b-icon-printer> Cetak Surat</b-button>
						</b-form-group>
						</b-card>
						<b-card class="mt-3" v-if="model.incomingDocumentStatusId == 'SENT'">
							<b-form-group id="fieldset-is-reminder" label-cols-sm="4" label-cols-lg="3" label="Reminder Kegiatan" label-for="input-is-reminder">
								<b-form-checkbox id="input-is-reminder" v-model="model.isReminder" :switch="true"  ></b-form-checkbox>
							</b-form-group>
							<app-input-datetimepicker :isRequired="true" :isMinActive="true" v-if="model.isReminder" id="fieldset-reminder-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal / Waktu Kegiatan" size="sm" :model.sync="model.reminderDate"  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" :min="minDate" />
							<app-input-textbox id="fieldset-location" v-if="model.isReminder" label-cols-sm="4" label-cols-lg="3" label="Lokasi" label-for="input-location" size="sm" :model.sync="model.location"   />
						</b-card>
						<b-card class="mt-3">
						<app-input-textarea id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Catatan" label-for="input-remarks" size="sm" :model.sync="model.remarks"   />
						<app-input-combobox-autocomplete v-if="false" :options="incomingDocumentStatuss" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="DocumentStatus" id="input-" size="sm" :model.sync="model.documentStatusId"  :selected="model.documentStatus != null ? model.documentStatus.name : ''" @input="getIncomingDocumentStatuss" />
						<app-input-combobox-autocomplete v-if="false"  :options="incomingDocumentStatuss" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="CopyDocumentStatus" id="input-" size="sm" :model.sync="model.copyDocumentStatusId"  :selected="model.copyDocumentStatus != null ? model.copyDocumentStatus.name : ''" @input="getIncomingDocumentStatuss" />
					</b-card>
				</b-col>
			</b-row>
		<PopupCreateReceiptDocument :openPopup="isShowPopupConfirmation" :incomingDocumentId="model.id" :model.sync="receiptDocument" @saveActionMethod="resetModal" @resetMethod="resetModal" :receiptDocumentId="receiptDocumentId" />
		<PopupPrintReceiptDocument :openPopup="isShowPopupPrint" @saveActionMethod="resetModal" @resetMethod="resetModal" :receiptDocumentId="model.receiptDocument != null ? model.receiptDocument.id : null" />
		<b-overlay :show="isBusy" no-wrap></b-overlay>
		<PopupDownloadDocument :allowUpdate="true" :openPopup="isShowPopupDocument" :parent="model" @resetMethod="resetModalUpload" />
	</PopupRouterView>
</template>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import IncomingDocumentList from '@/models/IncomingDocument/IncomingDocumentList';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';

import PopupConfirmationDetail from './PopupConfirmationDetail';
import PopupCreateReceiptDocument from './PopupCreateReceiptDocument';
import PopupPrintReceiptDocument from './PopupPrintReceiptDocument';
import PopupDownloadDocument from './PopupDownloadDocument';

import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import Branch from '@/models/Branch/Branch';
import BranchFilter from '@/filters/Branch/BranchFilter';
import SenderStatus from '@/models/SenderStatus/SenderStatus';
import SenderStatusFilter from '@/filters/SenderStatus/SenderStatusFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import SifatSurat from '@/models/SifatSurat/SifatSurat';
import SifatSuratFilter from '@/filters/SifatSurat/SifatSuratFilter';
import DocumentType from '@/models/DocumentType/DocumentType';
import DocumentTypeFilter from '@/filters/DocumentType/DocumentTypeFilter';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import AppInputTextarea from '@/components/AppInputTextarea';
import AppInputFileupload from '@/components/AppInputFileupload';
import Attachment from '@/models/Core/Attachment';
import AttachmentFilter from '@/filters/Core/AttachmentFilter';
import DistributionType from '@/models/DistributionType/DistributionType';
import DistributionTypeFilter from '@/filters/DistributionType/DistributionTypeFilter';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
import IncomingDocumentStatus from '@/models/IncomingDocumentStatus/IncomingDocumentStatus';
import IncomingDocumentStatusFilter from '@/filters/IncomingDocumentStatus/IncomingDocumentStatusFilter';
import PopupRouterView from '@/components/PopupRouterView';
import AppInputComboboxAutocompleteMultiSelect from '../../components/AppInputComboboxAutocompleteMultiSelect.vue';

const isFissik = function(value) {
	console.log(value);
}

const isFisik = function(value) {
	var regex = /(^[0-9-]*$)/;
	var result = regex.exec(value);
	if (!result) {
		return false;
	} else {
		return true;
	}
};

export default {
	components : {PopupDownloadDocument,AppInputTextbox,AppInputComboboxAutocomplete,AppInputComboboxAutocompleteMultiSelect,AppInputDatepicker,AppInputDatepickerRange,AppInputTextarea,AppInputFileupload,AppInputDatetimepicker,AppInputDatetimepickerRange,PopupRouterView,PopupConfirmationDetail,PopupCreateReceiptDocument,PopupPrintReceiptDocument},
	mixins : [validationMixin],
	validations() {
		if (this.model.isSendToAll) {
			return {
				model: {
					senderName: {required},
					senderStatusId: {required},
					sifatSuratId: {required},
					documentTypeId: {required},
				}
			};
		}
		return {
			model: {
				//documentLocationId: {required},
				senderName: {required},
				senderStatusId: {required},
				//destinationUserId: {required},
				//destinationUserIds: {required},
				destinationUsers: {required},
				sifatSuratId: {required},
				documentTypeId: {required},
			}
		};
	},
	data() {
		const now = new Date()
      	const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      	const minDate = new Date(today)

		return {
			minDate: minDate,
			isShowPopupDocument: false,
			documentAttachment: null,
			errorMessage : {
				//documentLocationId: { required : "Lokasi is Required."},
				senderName: { required : "Nama Pengirim is Required."},
				senderStatusId: { required : "Status Pengirim is Required."},
				//destinationUserId: { required : "Ditujukan Kepada is Required."},
				//destinationUserIds: { required : "Ditujukan Kepada is Required."},
				destinationUsers: { required : "Ditujukan Kepada is Required."},
				sifatSuratId: { required : "Sifat Surat is Required."},
				documentTypeId: { required : "Jenis Surat is Required."},
			},
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			modelId: null,
			model: {},
			filterIncomingDocument : null,
			currentIncomingDocumentPage : 1,
			incomingDocumentPageSize: 10,
			sortByIncomingDocument: '',
			sortDescIncomingDocument: '',
			documentLocations: [],
			senderStatuss: [],
			userProfiles: [],
			copyUsers: [],
			sifatSurats: [],
			documentTypes: [],
			distributionTypes: [],
			incomingDocumentStatuss: [],
			isShowPopupConfirmation : false,
			isShowPopupPrint : false,
			isDataCommited : false,
			mainRecordId : '',
			receiptDocument: {},
			receiptDocumentId: '',
			isCreateReceiptDocumentManual: false,
			sendToAll : [{
				id : 'ALL',
				fullName : 'Semua Karyawan',
			}],
		}
	},
	methods : {
		showUploadFile() {
			this.isShowPopupDocument = true;
		},
		resetModalUpload() {
			this.getIncomingDocumentAttachment();
			this.isShowPopupDocument = false;
		},
		getIncomingDocumentAttachment : async function() {
			//this.isBusy = true;
			let id = this.modelId;
			IncomingDocument.getData(this, id, '').then(result => { 
				this.model.documentAttachmentCount = result.documentAttachmentCount;
				//this.isBusy = false; 
			}).catch(error => { this.isBusy = false; });
		},
		getIncomingDocument : async function(id) {
			this.isBusy = true;
			return IncomingDocument.getData(this, id, 'documentLocation,senderStatus,userProfile,destinationUser,copyUser,sifatSurat,documentType,documentAttachment,distributionType,incomingDocumentStatus,copyDocumentStatus,documentStatus,createdByUserProfile,receiptDocument,documentAttachment,incomingDocumentDestinationUser,incomingDocumentCopyUser').then(result => { this.model = result; this.mainRecordId = String(result.mainRecordId); this.isBusy = false; return result; }).catch(error => { this.isBusy = false; });
		},
		downloadData() {
			this.isDownloadIncomingDocument = true;
			this.filterIncomingDocument.id = this.modelId;
			IncomingDocument.downloadDataDetail(this, this.filterIncomingDocument).then(result => {
				this.isDownloadIncomingDocument = false;
			}).catch(error => { this.isDownloadIncomingDocument = false; })
		},
		getDocumentLocations : async function(input) {
			let filter = new BranchFilter();
			if (input != undefined) {
				filter.name = input;
			}
			Branch.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		getSenderStatuss : async function(input) {
			let filter = new SenderStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			SenderStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.senderStatuss = result.data
				}
			})
			.catch(error => {});
		},
		getAllUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			let copyUsers = this.model.copyUsers;
			if (copyUsers != null && copyUsers.length > 0) {
				let excludeIds = Array.prototype.map.call(copyUsers, function(item) { return item.id; }).join("|");
				filter["notinslash-id"] = excludeIds;
			}
			
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					if (result.data != null) {
						var datas = this.sendToAll.concat(result.data);
						console.log(datas);
						this.userProfiles = datas;
					}
				}
			})
			.catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			let copyUsers = this.model.copyUsers;
			if (copyUsers != null && copyUsers.length > 0) {
				let excludeIds = Array.prototype.map.call(copyUsers, function(item) { return item.id; }).join("|");
				filter["notinslash-id"] = excludeIds;
			}
			console.log(copyUsers);
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					if (result.data != null) {
						this.userProfiles = result.data;
					}
				}
			})
			.catch(error => {});
		},
		getCopyUsers : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			let destinationUsers = this.model.destinationUsers;
			if (destinationUsers != null && destinationUsers.length > 0) {
				let excludeIds = Array.prototype.map.call(destinationUsers, function(item) { return item.id; }).join("|");
				filter["notinslash-id"] = excludeIds;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					if (result.data != null) {
						this.copyUsers = result.data;
					}
				}
			})
			.catch(error => {});
		},
		getSifatSurats : async function(input) {
			let filter = new SifatSuratFilter();
			if (input != undefined) {
				filter.name = input;
			}
			SifatSurat.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.sifatSurats = result.data
				}
			})
			.catch(error => {});
		},
		getDocumentTypes : async function(input) {
			let filter = new DocumentTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentTypes = result.data
				}
			})
			.catch(error => {});
		},
		getDistributionTypes : async function(input) {
			let filter = new DistributionTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DistributionType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.distributionTypes = result.data
				}
			})
			.catch(error => {});
		},
		getIncomingDocumentStatuss : async function(input) {
			let filter = new IncomingDocumentStatusFilter();
			if (input != undefined) {
				filter.name = input;
			}
			IncomingDocumentStatus.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocumentStatuss = result.data
				}
			})
			.catch(error => {});
		},
		validateState(name) {
			if (!this.$v.model[name]) return null;
			const { $dirty, $error } = this.$v.model[name];
			return $dirty ? !$error : null;
		},
		getErrorMessage(message, name) {
			if (!this.$v.model[name]) return "";
			if (this.$v.model[name].required != undefined && !this.$v.model[name].required) {
				if (message.required) return message.required;
			}
		},
		saveActionConfirmation(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isShowPopupConfirmation = true;
			}
		},
		saveAction(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isBusy = true;
				let id = this.modelId;
				let model = this.model;
				model.contractDate = model.contractDateFrom;
				if (this.receiptDocument)
					if (this.receiptDocument.id)
						model.receiptDocument = this.receiptDocument;

				let promises = Array();

				if (this.documentAttachment != null) {
					let data = new FormData();
					data.append('file', this.documentAttachment);
					let _this = this;
					promises.push(this.$http.post(process.env.VUE_APP_API_URL + 'attachment/upload',
						data,
						{ headers: { 'Content-Type': 'multipart/form-data' } }
						).then(async function(response){
							if (response != null) {
								if (response.data != null) {
									model.documentAttachmentId = response.data.data.id;
									model.documentAttachment = response.data.data;
								}
							}
							return true;
						})
						.catch(error => {
							this.isBusy = false;
							this.$store.dispatch('addErrorMessageGlobal', error);
							throw error;
						}));
				}

				Promise.all(promises).then((results) => {
					if (id != '' && id != null && id != undefined) {
						IncomingDocument.commitData(this, model, id).then(result => {
							this.isDataCommited = true;
							if (result) {
								this.$store.dispatch('addSuccessMessageGlobal', "Data Berhasil disimpan");
								if (model.isFisik) {
									if (this.createReceiptDocument() == "gotoindex") {
										this.$router.push({ name: 'incomingdocumentindex' });
									}
								} else {
									this.$router.push({ name: 'incomingdocumentindex' });
								}
							}
							this.isBusy = false;
						}).catch(error => { this.isBusy = false; });
					} else {
						IncomingDocument.insertData(this, model).then(result => {
							if (result) {
								this.$store.dispatch('addSuccessMessageGlobal', "Data Berhasil disimpan");
								this.$router.push({ name: 'incomingdocumentindex' });
							}
							this.isBusy = false;
						}).catch(error => { this.isBusy = false; });
					}
				});
			}
		},
		validateData() {
			if (this.model.isFile) {
				if (this.model.documentAttachmentCount <= 0) {
					this.$store.dispatch('addErrorMessageGlobal', "Silahkan Upload File Terlebih Dahulu");  
				}
			}
		},
		onHidden() {
			this.getIncomingDocuments();
		},
		resetModal(modalType) {
			this.showPopupCopyData = false; 
			let redirectToIndex = false;

			if (this.isShowPopupConfirmation || this.isShowPopupPrint) {
				if (!this.isCreateReceiptDocumentManual) {
					redirectToIndex = true;
				} else {
					this.isCreateReceiptDocumentManual = false;
				}
			}

			this.isShowPopupConfirmation = false;
			this.isShowPopupPrint = false;
			
			if (redirectToIndex) {
				this.getIncomingDocument(this.model.id).then(result => {
					let receiptDocumentId = (result.receiptDocument != null ? result.receiptDocument.id : "");
					if (receiptDocumentId != null && receiptDocumentId != undefined && receiptDocumentId != "")
						this.$router.push({ name: 'incomingdocumentindex' });
				})
				
			}
				
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		createReceiptDocument(isManual) {
			let isCreateReceiptDocument = true;

			if (this.model != null) {
				if (this.model.receiptDocument != null) {
					if (this.model.receiptDocument.id != null && this.model.receiptDocument.id != undefined && this.model.receiptDocument.id != "") {
						isCreateReceiptDocument = false;
					}
				}
			}

			if (isManual != null && isManual != undefined && isManual) {
				this.isCreateReceiptDocumentManual = true;
				isCreateReceiptDocument = true;
			}
			

			if (!isCreateReceiptDocument) return "gotoindex";
			
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				let id = this.modelId;
				let model = this.model;
				if (id != '' && id != null && id != undefined) {
					IncomingDocument.updateData(this, model, id).then(result => {
						if (result) {
							this.isShowPopupConfirmation = true;
						}
						this.isBusy = false;
					}).catch(error => { this.isBusy = false; });
				}
			}
		},
		printReceiptDocument() {
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				let id = this.modelId;
				let model = this.model;
				if (id != '' && id != null && id != undefined) {
					if (this.model.receiptDocument == null || this.model.receiptDocument == undefined) {
						if (this.receiptDocument != null && this.receiptDocument != undefined) {
							this.model.receiptDocument = this.receiptDocument;
						}
					}
					if (this.model.receiptDocument != null) {
						this.receiptDocumentId = (this.model.receiptDocument != null ? this.model.receiptDocument.id : "");
						IncomingDocument.updateData(this, model, id).then(result => {
						if (result) {
							this.isShowPopupPrint = true;
						}
						this.isBusy = false;
						}).catch(error => { this.isBusy = false; });
					}
					
				}
			}
		},
		changeDestinationUser(selectedValue){
			if (selectedValue) {
				this.model.destinationUserId = selectedValue.id;
				this.model.destinationUser = selectedValue;
			} else {
				this.model.destinationUserId = "";
				this.model.destinationUser = {};
			}
		},
		changeCopyUser(selectedValue){
			if (selectedValue) {
				this.model.copyUserId = selectedValue.id;
				this.model.copyUser = selectedValue;
			} else {
				this.model.copyUserId = "";
				this.model.copyUser = {};
			}
		}
	},
	beforeMount() {
		this.filterIncomingDocument = new IncomingDocumentFilter();
		this.getDocumentLocations();
		this.getSenderStatuss();
		//this.getUserProfiles();
		this.getSifatSurats();
		this.getDocumentTypes();
		this.getDistributionTypes();
		this.getIncomingDocumentStatuss();
	},
	async mounted(){
		let breadcrumb =[
			"Surat Masuk",
			"Create / Edit"
		];
		this.$store.dispatch('setBreadCrumb', breadcrumb);

		let id = this.$route.params.id;
		let copyData = this.$route.params.copydata;
		if (id != '' && id != null && id != undefined) {
			if (copyData != null && copyData != undefined) {
				this.getIncomingDocument(id);
			} else {
				this.modelId = id;
				this.getIncomingDocument(id);
			}
		} else {
			this.model = new IncomingDocument();
			console.log(this.model);
		}
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	computed: {
		destinationUserDatas() {
			let $datas = new Array();
			let $ids = new Array();
			if (this.model.incomingDocumentDestinationUser != null) {
				this.model.incomingDocumentDestinationUser.forEach(item => {
					$datas.push(item.destinationUser);
					$ids.push(item.destinationUserId);
				})
			}
			return $datas;
		},
		copyUserDatas() {
			let $datas = new Array();
			let $ids = new Array();
			if (this.model.incomingDocumentCopyUser != null) {
				this.model.incomingDocumentCopyUser.forEach(item => {
					$datas.push(item.copyUser);
					$ids.push(item.copyUserId);
				})
			}
			return $datas;
		}
	},
	watch : {
	},
	beforeDestroy() {
		if (this.model.documentStatusesId == "DRAFT") {
			IncomingDocument.discardData(this, this.modelId);
		}
	},
}
</script>
