<template>
	<div>
		<b-card>
			<b-col cols="12" lg="6">
				<app-input-textbox :isEditable="false" id="fieldset-receipt-document-no" label-cols-sm="4" label-cols-lg="3" label="No. Tanda Terima Surat" label-for="input-receipt-document-no" size="sm" :model.sync="model.receiptDocumentNo"   />
				<app-input-textbox :isEditable="false"  id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" label-for="input-document-code" size="sm" :model.sync="model.documentCode"   />
				<app-input-datepicker :isEditable="false"  id="fieldset-document-created-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal & Waktu" size="sm" :model.sync="model.documentCreatedDate"  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"  />
				<app-input-combobox-autocomplete :isEditable="false" :options="documentLocations" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Lokasi Surat" id="input-" size="sm" :model.sync="model.documentLocationId"  :selected="model.documentLocation != null ? model.documentLocation.name : ''" @input="getDocumentLocations" />
				<app-input-textbox :isEditable="false"  id="fieldset-sender-name" label-cols-sm="4" label-cols-lg="3" label="Nama Pengirim" label-for="input-sender-name" size="sm" :model.sync="model.senderName"   />
				<app-input-combobox-autocomplete v-if="false" :isEditable="false"  :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Ditujukan Kepada" id="input-" size="sm" :model.sync="model.destinationUserId"  :selected="model.destinationUser != null ? model.destinationUser.fullName : ''" @input="getUserProfiles" />
				<b-form-group id="fieldset-destination-user" label-cols-sm="4" label-cols-lg="3" label="Ditujukan Kepada" label-for="filter-destination-user">
					<span v-if="model.incomingDocument != null && model.incomingDocument.isSendToAll">
						Semua Karyawan
					</span>
					<span v-else>
						<b-badge variant="secondary" v-for="item in model.incomingDocumentDestinationUser" :key="item.id" class="mr-2" style="font-size:13px; font-weight: 400;background-color:#bbb">
							{{ item.destinationUser ? item.destinationUser.fullName : "-" }}
						</b-badge>
					</span>
				</b-form-group>
				<app-input-datepicker :isEditable="false"  id="fieldset-document-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal Surat" size="sm" :model.sync="model.documentDate"  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"  />
				<app-input-combobox-autocomplete :isEditable="false"  :options="documentTypes" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Jenis Surat/Barang" id="input-" size="sm" :model.sync="model.documentTypeId"  :selected="model.documentType != null ? model.documentType.name : ''" @input="getDocumentTypes" />
				<app-input-textbox :isEditable="false"  id="fieldset-document-no" label-cols-sm="4" label-cols-lg="3" label="Nomor Surat" label-for="input-document-no" size="sm" :model.sync="model.documentNo"   />
				<app-input-combobox-autocomplete :isEditable="false"  :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Nama Penyerah Surat" id="input-" size="sm" :model.sync="model.deliveryUserId"  :selected="model.deliveryUser != null ? model.deliveryUser.fullName : ''" @input="getUserProfiles" />
				<app-input-combobox-autocomplete :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Nama Penerima Surat" id="input-" size="sm" :model.sync="model.receiptUserId"  :selected="model.receiptUser != null ? model.receiptUser.fullName : ''" @input="getUserProfiles" :isSelectMethodExists="true" @onSelectMethod="changeReceiptUser" />
				<b-form-group id="fieldset-job-description" label-cols-sm="4" label-cols-lg="3" label="Jabatan Pegawai" label-for="filter-job-description">
					{{ model != null ? (model.receiptUser != null ? model.receiptUser.jobDescription : '-') : "-" }}
				</b-form-group>
				<app-input-textarea id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Catatan" label-for="input-remarks" size="sm" :model.sync="model.remarks"   />
				<app-input-combobox-autocomplete v-if="false" :options="incomingDocuments" optionsLabel="documentCode" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Incoming Document" id="input-" size="sm" :model.sync="model.incomingDocumentId"  :selected="model.incomingDocument != null ? model.incomingDocument.documentCode : ''" @input="getIncomingDocuments" />
				<app-input-textbox v-if="false" id="fieldset-outgoing-document" label-cols-sm="4" label-cols-lg="3" label="OutgoingDocument" label-for="input-outgoing-document" size="sm" :model.sync="model.outgoingDocument"   />
			</b-col>
		</b-card>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</div>
</template>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import ReceiptDocument from '@/models/ReceiptDocument/ReceiptDocument';
import ReceiptDocumentList from '@/models/ReceiptDocument/ReceiptDocumentList';
import ReceiptDocumentFilter from '@/filters/ReceiptDocument/ReceiptDocumentFilter';

import PopupConfirmationDetail from './PopupConfirmationDetail';

import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import DocumentType from '@/models/DocumentType/DocumentType';
import DocumentTypeFilter from '@/filters/DocumentType/DocumentTypeFilter';
import AppInputTextarea from '@/components/AppInputTextarea';
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import PopupRouterView from '@/components/PopupRouterView';

export default {
	components : {AppInputTextbox,AppInputDatepicker,AppInputDatepickerRange,AppInputComboboxAutocomplete,AppInputTextarea,PopupRouterView,PopupConfirmationDetail},
	props : ["incomingDocumentId"],
	mixins : [validationMixin],
	validations: {
		model: {
		}
	},
	data() {
		return {
			errorMessage : {
			},
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			modelId: null,
			model: {},
			filterReceiptDocument : null,
			currentReceiptDocumentPage : 1,
			receiptDocumentPageSize: 10,
			sortByReceiptDocument: '',
			sortDescReceiptDocument: '',
			documentLocations: [],
			userProfiles: [],
			documentTypes: [],
			incomingDocuments: [],
			isShowPopupConfirmation : false,
			isDataCommited : false,
			mainRecordId : '',
		}
	},
	methods : {
		getReceiptDocument : async function(id) {
			this.isBusy = true;
			ReceiptDocument.getData(this, id, 'documentLocation,deliveryUser,documentType,destinationUser,receiptUser,incomingDocument').then(result => { this.model = result; this.mainRecordId = String(result.mainRecordId); this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		downloadData() {
			this.isDownloadReceiptDocument = true;
			this.filterReceiptDocument.id = this.modelId;
			ReceiptDocument.downloadDataDetail(this, this.filterReceiptDocument).then(result => {
				this.isDownloadReceiptDocument = false;
			}).catch(error => { this.isDownloadReceiptDocument = false; })
		},
		getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentLocation.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		getDocumentTypes : async function(input) {
			let filter = new DocumentTypeFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentType.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentTypes = result.data
				}
			})
			.catch(error => {});
		},
		getIncomingDocuments : async function(input) {
			let filter = new IncomingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			IncomingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		validateState(name) {
			if (!this.$v.model[name]) return null;
			const { $dirty, $error } = this.$v.model[name];
			return $dirty ? !$error : null;
		},
		getErrorMessage(message, name) {
			if (!this.$v.model[name]) return "";
			if (this.$v.model[name].required != undefined && !this.$v.model[name].required) {
				if (message.required) return message.required;
			}
		},
		saveActionConfirmation(modalEvent) {
			modalEvent.preventDefault();
			let id = this.$route.params.id;
			this.$v.model.$touch(); 
			if (this.$v.model.$anyError) {
				return;
			} else {
				this.isShowPopupConfirmation = true;
			}
		},
		saveAction(modalEvent) {
			if (this.mainRecordId == null || this.mainRecordId == '') {
				modalEvent.preventDefault();
				return ReceiptDocument.insertMultipleData(this, this.model).then(result => {
					console.log(result);
					this.isBusy = false;
					this.mainRecordId = result.id;
					this.getReceiptDocument(this.mainRecordId);
					return result;
				}).catch(error => { this.isBusy = false; });
			}
		},
		onHidden() {
			this.getReceiptDocuments();
		},
		resetModal(modalType) {
			this.showPopupCopyData = false; 
			this.isShowPopupConfirmation = false;
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		getIncomingDocument : async function(id) {
			this.isBusy = true;
			IncomingDocument.getData(this, id, 'documentLocation,senderStatus,userProfile,destinationUser,copyUser,sifatSurat,documentType,attachment,distributionType,incomingDocumentStatus,copyDocumentStatus,documentStatus,createdByUserProfile,incomingDocumentDestinationUser,incomingDocumentCopyUser').then(result => { this.copyDataFromIncomingDocument(result); this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		copyDataFromIncomingDocument(incomingDocument) {
			this.model.receiptDocumentNo = 'Autonumber';
			this.model.documentCode = incomingDocument.documentCode;
			this.model.documentCreatedDate = incomingDocument.createdAt;
			this.model.documentLocationId = incomingDocument.documentLocation;
			this.model.documentLocation = incomingDocument.documentLocation;
			this.model.senderName = incomingDocument.senderName;
			this.model.destinationUserId = incomingDocument.destinationUserId;
			this.model.destinationUser = incomingDocument.destinationUser;
			this.model.documentDate = incomingDocument.documentDate;
			this.model.documentTypeId = incomingDocument.documentTypeId;
			this.model.documentType = incomingDocument.documentType;
			this.model.documentNo = incomingDocument.documentNo;
			this.model.documentDescription = incomingDocument.description;
			this.model.incomingDocumentId = incomingDocument.id;
			this.model.incomingDocument = incomingDocument;
			//this.model.deliveryUserId = incomingDocument.createdByUserProfile.id;
			//this.model.deliveryUser = incomingDocument.createdByUserProfile;
			this.model.incomingDocumentDestinationUser = incomingDocument.incomingDocumentDestinationUser;
		},
		changeReceiptUser(selectedValue){
			if (selectedValue) {
				this.model.receiptUserId = selectedValue.id;
				this.model.receiptUser = selectedValue;
			} else {
				this.model.receiptUserId = "";
				this.model.receiptUser = {};
			}
		},
	},
	beforeMount() {
		this.filterReceiptDocument = new ReceiptDocumentFilter();
		this.getDocumentLocations();
		this.getUserProfiles();
		this.getDocumentTypes();
		//this.getIncomingDocuments();
	},
	async mounted(){
		let id = this.incomingDocumentId; //this.$route.params.id;
		let copyData = this.$route.params.copydata;
		if (id != '' && id != null && id != undefined) {
			this.getIncomingDocument(id);
		}
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	computed: {
	},
	watch : {
	},
	beforeDestroy() {
	},
}
</script>
