<template>
  <div style="margin-bottom: 10rem !important;">
    <b-row>
        <b-col>
            <b-card>
                <b-row>
                    <b-col cols="12" md="6">
                        <app-input-combobox-autocomplete :options="documentLocations" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Lokasi Surat" id="filter-" size="sm" :model.sync="filter.documentLocationId" @input="getDocumentLocations" @focus="getDocumentLocations" :isSelectMethodExists="true" @onSelectMethod="getDashboardData" />
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
    <b-row class="mt-3">
        <b-col><h5>Quick Reminder</h5></b-col>
    </b-row>
    <b-row class="mt-2">
        <b-col cols="12" md="6">
            <b-card header-bg-variant="white">
                <template #header>
                    <b-row>
                        <b-col cols="9">
                            <h6 class="mb-0">Reminder Kegiatan Hari Ini</h6>
                        </b-col>
                        <b-col>
                            <b-link v-if="false" class="float-right">View More</b-link>
                        </b-col>
                    </b-row>
                </template>
                <b-table head-variant="light" bordered striped hover :items="todayReminders" :fields="fieldsTodayReminders" show-empty :responsive="true" sticky-header>
                    <template #empty="scope">
                        <h5 class="text-center">{{ scope.emptyText }}</h5>
                    </template>
                    <template v-slot:cell(tanggal)="row">
                        {{ row.item.tanggal | moment('DD-MMM-yyyy') }}
                    </template>
                </b-table>
            </b-card>
        </b-col>
        <b-col cols="12" md="6">
            <b-card header-bg-variant="white">
                <template #header>
                    <b-row>
                        <b-col cols="9">
                            <h6 class="mb-0">Reminder Habis Kontrak Hari Ini</h6>
                        </b-col>
                        <b-col>
                            <span v-if="false" class="float-right">View More</span>
                        </b-col>
                    </b-row>
                </template>
                <b-table head-variant="light" bordered striped hover :items="todayContractReminders" :fields="fieldsTodayContractReminders" show-empty :responsive="true" sticky-header>
                    <template #empty="scope">
                        <h5 class="text-center">{{ scope.emptyText }}</h5>
                    </template>
                </b-table>
            </b-card>
        </b-col>
    </b-row>
    <b-row class="mt-2">
        <b-col cols="12" md="6">
            <b-card header-bg-variant="white">
                <template #header>
                    <b-row>
                        <b-col cols="9">
                            <h6 class="mb-0">Reminder Kegiatan 5 Hari ke Depan</h6>
                        </b-col>
                        <b-col>
                            <span v-if="false" class="float-right">View More</span>
                        </b-col>
                    </b-row>
                </template>
                <b-table head-variant="light" bordered striped hover :items="remainingReminders" :fields="fieldsRemainingReminders" show-empty :responsive="true" sticky-header>
                    <template #empty="scope">
                        <h5 class="text-center">{{ scope.emptyText }}</h5>
                    </template>
                </b-table>
            </b-card>
        </b-col>
        <b-col cols="12" md="6">
            <b-card header-bg-variant="white">
                <template #header>
                    <b-row>
                        <b-col cols="9">
                            <h6 class="mb-0">Reminder Habis Kontrak 5 Hari ke Depan</h6>
                        </b-col>
                        <b-col>
                            <span v-if="false" class="float-right">View More</span>
                        </b-col>
                    </b-row>
                </template>
                <b-table head-variant="light" bordered striped hover :items="remainingContractReminders" :fields="fieldsRemainingContractReminders" show-empty :responsive="true" sticky-header>
                    <template #empty="scope">
                        <h5 class="text-center">{{ scope.emptyText }}</h5>
                    </template>
                </b-table>
            </b-card>
        </b-col>
    </b-row>
    <b-row class="mt-2">
        <b-col>
            <b-card  header-bg-variant="white">
                <template #header>
                    <b-row>
                        <b-col cols="9">
                            <h6 class="mb-0">Reminder Pengumpulan Arsip Surat Keluar Untuk 5 Hari ke Depan</h6>
                        </b-col>
                        <b-col>
                            <span v-if="false" class="float-right">View More</span>
                        </b-col>
                    </b-row>
                </template>
                <b-row>
                    <b-col cols="2" v-for="(item, index) in reminderDocument5Day" :key="index">
                        <b-card bg-variant="light" body-class="p-1">
                            <b-avatar variant="light" class="mr-3"></b-avatar>
                            <span class="mr-auto">{{ item.full_name }}</span>
                        </b-card>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
    <b-row class="mt-3">
        <b-col cols="12"><h5>Today's Activity</h5></b-col>
        <b-col cols="12"><h6>Surat Keluar</h6></b-col>
    </b-row>
    <b-row class="mt-2">
        <b-col>
            <b-card header-bg-variant="white">
                <template #header>
                    <b-row>
                        <b-col cols="9">
                            <h6 class="mb-0">Pengajuan Surat Keluar (Internal) Hari Ini</h6>
                        </b-col>
                        <b-col>
                            <span v-if="false" class="float-right">View More</span>
                        </b-col>
                    </b-row>
                </template>
                <b-table head-variant="light" bordered striped hover :items="todayInternalOutgoingDocument" :fields="fieldsTodayInternalOutgoingDocument" show-empty :responsive="true" sticky-header>
                    <template #empty="scope">
                        <h5 class="text-center">{{ scope.emptyText }}</h5>
                    </template>
                </b-table>
            </b-card>
            <b-card class="mt-2" body-class="p-1 pl-3 pr-2">
                <b-media right-align vertical-align="center" no-body>
                    <b-media-body vertical-align="center" class="w-75 pt-3">
                        <h5>Jumlah Pengajuan Surat Keluar (Internal) Hari Ini</h5>
                    </b-media-body>
                    <b-media-aside vertical-align="center" class="w-25 mr-0">
                        <b-card bg-variant="success" class="w-100" body-class="p-1 text-center"><h3>{{ todayInternalOutgoingDocumentCount }}</h3></b-card>
                    </b-media-aside>
                </b-media>
            </b-card>
        </b-col>
        <b-col>
            <b-card header-bg-variant="white">
                <template #header>
                    <b-row>
                        <b-col>
                            <h6 class="mb-0">Pengajuan Surat Keluar (Eksternal) Hari Ini</h6>
                        </b-col>
                        <b-col>
                            <span v-if="false" class="float-right">View More</span>
                        </b-col>
                    </b-row>
                </template>
                <b-table head-variant="light" bordered striped hover :items="todayExternalOutgoingDocument" :fields="fieldsTodayExternalOutgoingDocument" show-empty :responsive="true" sticky-header>
                    <template #empty="scope">
                        <h5 class="text-center">{{ scope.emptyText }}</h5>
                    </template>
                </b-table>
            </b-card>
            <b-card class="mt-2" body-class="p-1 pl-3 pr-2">
                <b-media right-align vertical-align="center" no-body>
                    <b-media-body vertical-align="center" class="w-75 pt-3">
                        <h5>Jumlah Pengajuan Surat Keluar (Eksternal) Hari Ini</h5>
                    </b-media-body>
                    <b-media-aside vertical-align="center" class="w-25 mr-0">
                        <b-card bg-variant="success" class="w-100" body-class="p-1 text-center"><h3>{{ todayExternalOutgoingDocumentCount }}</h3></b-card>
                    </b-media-aside>
                </b-media>
            </b-card>
        </b-col>
    </b-row>
    <b-row class="mt-2">
        <b-col><h6>Surat Masuk</h6></b-col>
    </b-row>
    <b-row class="mt-2">
        <b-col>
            <b-card header-bg-variant="white">
                <template #header>
                    <b-row>
                        <b-col cols="9">
                            <h6 class="mb-0">Surat Masuk (Penting/Rahasia) Status Terkirim Hari Ini</h6>
                        </b-col>
                        <b-col>
                            <span v-if="false" class="float-right">View More</span>
                        </b-col>
                    </b-row>
                </template>
                <b-table head-variant="light" bordered striped hover :items="todayImportantIncomingDocument" :fields="fieldsTodayImportantIncomingDocument" show-empty :responsive="true" sticky-header>
                    <template #empty="scope">
                        <h5 class="text-center">{{ scope.emptyText }}</h5>
                    </template>
                </b-table>
            </b-card>
            <b-card class="mt-2 p-0" body-class="p-1 pl-3 pr-2" >
                <b-media right-align vertical-align="center" no-body>
                    <b-media-body vertical-align="center" class="w-75 pt-3">
                        <h5>Jumlah Surat Masuk (Penting/Rahasia) Terkirim Hari Ini</h5>
                    </b-media-body>
                    <b-media-aside vertical-align="center" class="w-25 mr-0">
                        <b-card bg-variant="success" class="w-100" body-class="p-1 text-center"><h3>{{ todayImportantIncomingDocumentCount }}</h3></b-card>
                    </b-media-aside>
                </b-media>
            </b-card>
        </b-col>
        <b-col>
            <b-card header-bg-variant="white">
                <template #header>
                    <b-row>
                        <b-col cols="9">
                            <h6 class="mb-0">Surat Masuk (Biasa) Status Terkirim Hari Ini</h6>
                        </b-col>
                        <b-col>
                            <span v-if="false" class="float-right">View More</span>
                        </b-col>
                    </b-row>
                </template>
                <b-table head-variant="light" bordered striped hover :items="todayRegularIncomingDocument" :fields="fieldsTodayRegularIncomingDocument" show-empty :responsive="true" sticky-header>
                    <template #empty="scope">
                        <h5 class="text-center">{{ scope.emptyText }}</h5>
                    </template>
                </b-table>
            </b-card>
            <b-card class="mt-2"  body-class="p-1 pl-3 pr-2">
                <b-media right-align vertical-align="center" no-body>
                    <b-media-body vertical-align="center" class="w-75 pt-3">
                        <h5>Jumlah Surat Masuk (Biasa) Terkirim Hari Ini</h5>
                    </b-media-body>
                    <b-media-aside vertical-align="center" class="w-25 mr-0">
                        <b-card bg-variant="success" class="w-100" body-class="p-1 text-center"><h3>{{ todayRegularIncomingDocumentCount }}</h3></b-card>
                    </b-media-aside>
                </b-media>
            </b-card>
        </b-col>
    </b-row>
    <b-row class="mt-3">
        <b-col><h5>Data Recap & Graphics</h5></b-col>
    </b-row>
    <b-row class="mt-2">
        <b-col cols="6">
            <b-card :title="'Rekapitulasi Data Risalah Pekan/Minggu Ke-' + getWeekOfMonth()">
                <b-table head-variant="light" sticky-header bordered striped hover :items="weeklyRecap" :fields="fieldsWeeklyRecap" show-empty :responsive="true">
                    <template #empty="scope">
                        <h5 class="text-center">{{ scope.emptyText }}</h5>
                    </template>
                    <template v-slot:cell(no)="row">
                        {{ row.index + 1}}
                    </template>
                </b-table>
            </b-card>
            <b-card class="mt-2">
                <WeeklyChart v-if="!isBusy" :model="weeklyRecapGraph" />
            </b-card>
        </b-col>
        <b-col cols="6">
            <b-card :title="'Rekapitulasi Data Risalah Bulan ' + getMonth()">
                <b-table head-variant="light" sticky-header bordered striped hover :items="monthlyRecap" :fields="fieldsMonthlyRecap" show-empty :responsive="true">
                    <template #empty="scope">
                        <h5 class="text-center">{{ scope.emptyText }}</h5>
                    </template>
                    <template v-slot:cell(no)="row">
                        {{ row.index + 1}}
                    </template>
                </b-table>
            </b-card>
            <b-card class="mt-2">
                <MonthlyChart v-if="!isBusy" :model="monthlyRecapGraph" />
            </b-card>
        </b-col>
    </b-row>
    <b-row class="mt-2">
        <b-col cols="12">
            <b-card class="mt-2">
                <YearlyChart v-if="!isBusy" :modelOutgoing="yearlyOutgoingRecapGraph" :modelIncoming="yearlyIncomingRecapGraph" />
            </b-card>
            <b-card class="mt-2">
                <ByDocumentLocationChart v-if="!isBusy" :modelOutgoing="outgoingDocLoc" :modelIncoming="incomingDocLoc" :modelTotal="totalDocLoc" :labels="labelDocLoc" />
            </b-card>
        </b-col>
    </b-row>
    <b-row class="mt-3">
        <b-col><h5>Akumulasi Data Risalah Nasional Tahun {{ currentYear }} (Kantor Pusat & Perwakilan)</h5></b-col>
    </b-row>
    <b-row class="mt-2 mb-5">
        <b-col cols="4">
            <b-card bg-variant="warning" class="p-0" text-variant="white">
                <p>Surat Masuk</p>
                <h1>{{ totalIncomingDocument }}</h1>
            </b-card>
        </b-col>
        <b-col cols="4">
            <b-card bg-variant="secondary" class="p-0" text-variant="white">
                <p>Surat Keluar</p>
                <h1>{{ totalOutgoingDocument }}</h1>
            </b-card>
        </b-col>
        <b-col cols="4">
            <b-card bg-variant="success" class="p-0" text-variant="white">
                <p>Total</p>
                <h1>{{ totalDocument }}</h1>
            </b-card>
        </b-col>
    </b-row>
    
  </div>
</template>
<style lang="scss" scoped>
    ::v-deep h5.card-title {
        margin: 0;
    }

    ::v-deep h6.card-subtitle {
        font-size: 0.75rem !important;
        margin-top: 0.5px !important;
    }
    .btn-primary {
        background-color: #029FE2;
        border-color: #029FE2;
    }

    .btn-primary:hover {
        background-color: #029FE2;
        border-color: #029FE2;
    }

    .menu-group {
        width: 190px;
    }

    .menu-icon {
        width: 85px;
        height: 85px;
        /*border: 1px solid #ddd;*/
        background-color: transparent;
        border-radius: 5px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        /*padding: 15px;*/
    }

        .menu-icon i {
            font-size: 45px;
            color: #222;
        }

        .menu-icon-img {
            width: 80px;
        }

        .menu-group-title {
            font-size: 14px;
            font-weight: bold;
            /*color: #565656;*/
            /*word-spacing: 100vw;*/
        }

        .menu-group {
            float: left;
            margin-right: 10px;
        }

        .button-menu-footer, .card-footer {
            padding: 8px;
        }

            .button-menu-footer .button-menu-item {
                padding-top: 5px;
                padding-bottom: 5px;
                text-align: center;
            }

                .button-menu-footer .button-menu-item:hover {
                    background-color: #eee;
                    cursor: pointer;
                }

        .box-news-information {
            /*border: 1px solid #dcdcdc;*/
            padding: 0px;
            right: 0px;
            /*background-color: white;*/
            border-radius: 5px;
            height: 500px;
        }

        #box-news-information {
            overflow-y: scroll;
            overflow-x: hidden;
            height: 484px;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .badge-info {
            font: bold 11px/1 "Roboto", Arial, sans-serif;
            background-color: #fff;
        }

        .badge-info-news {
            color: #0085b6;
        }

        .badge-information {
            color: #8360a8;
        }

        .news-title {
            margin-bottom: -4px;
            font: 500 14px/1.4em "Roboto", Arial, sans-serif;
            color: #4a4a4a;
        }

        .news-info {
            margin-bottom: 3px;
        }

        .panel-newsinfo {
            border-color: #d2d2d2;
            margin-right: 10px;
            padding-left: 0px;
        }

            .panel-newsinfo > .panel-heading {
                background-color: #fff;
                border-color: #d2d2d2;
            }

            .panel-newsinfo > .panel-body {
                padding: 0px;
            }

        .panel-heading {
            border-bottom: 1px solid #ddd;
        }
        /*
        .col-custom {
            width: 149px;
            padding: 0px 3px 0px 3px;
            display: inline-flex;
            flex-direction: column;
        }*/

        .panel-body-leave {
            background-color: #fff;
        }

        .panel-footer {
            background-color: #fff;
        }

        .own_contain {
            padding-top: 5px;
        }

        .notification-counter {
            border-radius: 16px;
            background-color: #ff6124;
            padding: 3px 5px;
            color: #fff;
            margin-left: -8px;
            font-size: 11px;
            top: 2px;
            position: absolute;
        }

        .btn-default {
            font-size: 11px;
        }

        .panel-body-leave .panel-heading {
            background-color: #f1f4ff;
            color: #545c79;
        }

        .panel-body-reimbursement .panel-heading {
            background-color: #dff0d8;
            color: #526b48;
        }

        .panel-body-overtime .panel-heading {
            background-color: #fcf8e3;
            color: #88773a;
        }

        .panel-body-medical .panel-heading {
            background-color: #d8f0dd;
            color: #1b6961;
        }

        .panel-body-attendance .panel-heading {
            background-color: #d8eff0;
            color: #1b6961;
        }

        .panel-body-resignation .panel-heading {
            background-color: #f9eaea;
            color: #9e6b66;
        }

        .menu-group .panel .panel-heading {
            padding: 3px;
        }

        .button-menu-footer .row .col-xs-6 {
            padding-left: 11px;
        }

            .button-menu-footer .row .col-xs-6:not(:first-child) {
                padding-left: 5px;
            }

        .btn-first, .btn-second {
            font-size: 10.5px;
            width: 85px;
        }

        .btn-second {
            padding-left: 5px;
        }

        .fa-envelope {
            font-size: 20px;
        }

        #my-inbox-txt {
            font-size: 14px;
            margin-left: 10px;
        }

        @media (min-width: 768px) {
            .panel-right {
                padding-left: 0px;
            }
        }

        @media (max-width: 410px) {
            .menu-group {
                width: 100%;
            }

            .col-xs-11 {
                width: 89.666667%;
            }

            .hidden-xxs {
                display: none;
            }

            .panel-newsinfo {
                margin-right: 0px;
            }

            .btn-first, .btn-second {
                font-size: 11px;
                width: 100%;
            }
        }

        @media (min-width: 411px) {
            .menu-group {
                width: 180px;
            }

            .btn-create-request {
                padding-left: 4px;
                margin-left: -2px;
            }

            .btn-first, .btn-second {
                font-size: 10px;
                width: 80px;
            }
        }

        h4.card-title {
            font-size: 1rem;
        }

        h5 {
            font-weight: 500;
        }

        .table .thead-light th {
            font-size: 0.85rem !important;
        }

        $sticky-header:200px;
</style>
<script>
import axios from 'axios';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';

import Branch from '@/models/Branch/Branch';
import BranchList from '@/models/Branch/BranchList';
import BranchFilter from '@/filters/Branch/BranchFilter';
import WeeklyChart from './Chart/WeeklyChart.vue';
import MonthlyChart from './Chart/MonthlyChart.vue';
import YearlyChart from './Chart/YearlyChart.vue';
import ByDocumentLocationChart from './Chart/ByDocumentLocationChart.vue';

export default {
    components : { AppInputComboboxAutocomplete, WeeklyChart, MonthlyChart, YearlyChart, ByDocumentLocationChart },
    name: 'Home',
    methods : {
      getDashboardData : function(selectedValue) {
        var url = `dashboard-index`;
        if (selectedValue != undefined && selectedValue != null) {
            if (selectedValue.id != null && selectedValue.id != '' && selectedValue.id != undefined) {
                url = `dashboard-index?documentLocationId=` + selectedValue.id;
            }
        }

        this.isBusy = true;
        this.$http.get(url).then((result) => {
            if (result != null) {
                if (result.data != null) {
                    this.todayReminders = result.data.reminderToday;
                    this.remainingReminders = result.data.remainingReminders;

                    this.todayContractReminders= result.data.todayContractReminders;
                    this.remainingContractReminders= result.data.remainingContractReminders;

                    this.todayInternalOutgoingDocument = result.data.todayInternalOutgoingDocument;
                    this.todayExternalOutgoingDocument = result.data.todayExternalOutgoingDocument;

                    this.todayImportantIncomingDocument = result.data.todayImportantIncomingDocument;
                    this.todayRegularIncomingDocument = result.data.todayRegularIncomingDocument;

                    this.todayInternalOutgoingDocumentCount = result.data.todayInternalOutgoingDocumentCount != null ? result.data.todayInternalOutgoingDocumentCount[0].cnt : 0;
                    this.todayExternalOutgoingDocumentCount = result.data.todayExternalOutgoingDocumentCount != null ? result.data.todayExternalOutgoingDocumentCount[0].cnt : 0;

                    this.todayImportantIncomingDocumentCount = result.data.todayImportantIncomingDocumentCount != null ? result.data.todayImportantIncomingDocumentCount[0].cnt : 0;
                    this.todayRegularIncomingDocumentCount = result.data.todayRegularIncomingDocumentCount != null ? result.data.todayRegularIncomingDocumentCount[0].cnt : 0;

                    this.weeklyRecap = result.data.weeklyRecap;
                    this.monthlyRecap = result.data.monthlyRecap;

                    if (result.data.currentYear && result.data.currentYear != null) {
                        this.currentYear = result.data.currentYear;
                    }

                    if (result.data.totalDocument != null) {
                        let totalDocument = result.data.totalDocument[0];
                        this.totalIncomingDocument = totalDocument.surat_masuk;
                        this.totalOutgoingDocument = totalDocument.surat_keluar;
                        this.totalDocument = totalDocument.total;
                    }

                    this.weeklyRecapGraph = [];
                    console.log(result.data);
                    if (result.data.weeklyRecapGraph != null) {
                        let totalDocument = result.data.weeklyRecapGraph[0];
                        
                        this.weeklyRecapGraph.push(totalDocument.surat_masuk);
                        this.weeklyRecapGraph.push(totalDocument.surat_keluar_internal);
                        this.weeklyRecapGraph.push(totalDocument.surat_keluar_external);
                    }

                    this.monthlyRecapGraph = [];
                    if (result.data.monthlyRecapGraph != null) {
                        let totalDocument = result.data.monthlyRecapGraph[0];
                        
                        this.monthlyRecapGraph.push(totalDocument.surat_masuk);
                        this.monthlyRecapGraph.push(totalDocument.surat_keluar_internal);
                        this.monthlyRecapGraph.push(totalDocument.surat_keluar_external);
                    }
                    
                    this.yearlyIncomingRecapGraph = [];
                    if (result.data.incomingDataYearlyRecapGraph != null) {
                        this.yearlyIncomingRecapGraph = result.data.incomingDataYearlyRecapGraph.map(function (obj) {
                            return obj.sum;
                        });
                    }
                    console.log(this.yearlyIncomingRecapGraph);

                    this.yearlyOutgoingRecapGraph = [];
                    if (result.data.outgoingDataYearlyRecapGraph != null) {
                        this.yearlyOutgoingRecapGraph = result.data.outgoingDataYearlyRecapGraph.map(function (obj) {
                            return obj.sum;
                        });
                    }
                    console.log(this.yearlyOutgoingRecapGraph);

                    this.labelDocLoc = [];
                    this.incomingDocLoc = [];
                    this.outgoingDocLoc = [];
                    this.totalDocLoc = [];
                    console.log(result.data.documentLocationGraph);
                    if (result.data.documentLocationGraph != null) {
                        result.data.documentLocationGraph.forEach(item => {
                            console.log(item);
                            console.log(this.labelDocLoc);
                            this.labelDocLoc.push(item.id);
                            this.incomingDocLoc.push(item.surat_masuk);
                            this.outgoingDocLoc.push(item.surat_keluar);
                            this.totalDocLoc.push(item.total);
                        })   
                    }

                    this.reminderDocument5Day = result.data.reminderDocument5Day;
                    console.log(this.reminderDocument5Day);
                }
            }
            this.isBusy = false;
        });
      },
      getDocumentLocations : async function(input) {
			let filter = new BranchFilter();
			if (input != undefined) {
				filter.name = input;
			}
			Branch.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
        getWeekOfMonth : function() {
            var today = new Date();
            var dayOfMonth = today.getDay();
            var month = today.getMonth();
            var year = today.getFullYear();
            var checkDate = new Date(year, month, today.getDate());
            var checkDateTime = checkDate.getTime();
            var currentWeek = 0;

            for (var i = 1; i < 32; i++) {
                var loopDate = new Date(year, month, i);

                if (loopDate.getDay() == dayOfMonth) {
                    currentWeek++;
                }

                if (loopDate.getTime() == checkDateTime) {
                    return currentWeek;
                }
            }
        },
        getMonth() {
            const monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
                "Juli", "Agustus", "September", "Oktober", "November", "Desember"
            ];

            const d = new Date();
            return monthNames[d.getMonth()];

        }
    },
    data() {
      return {
        isBusy: false,
        perPage: 14,
        currentPage: 1,
        name:'',
        fields: ["no", "applicationName", "createdDate", "actions"],
        items: [],
        isDataLoading: true,
        documentLocations: [],
        filter: {},
        weeklyRecapGraph: [],
        monthlyRecapGraph: [],
        yearlyIncomingRecapGraph: [],
        yearlyOutgoingRecapGraph: [],
        labelDocLoc: [],
        incomingDocLoc: [],
        outgoingDocLoc: [],
        totalDocLoc: [],
        todayReminders: [],
        fieldsTodayReminders: [ 'no','perihal_kegiatan','tanggal','waktu','lokasi'],
        remainingReminders: [],
        fieldsRemainingReminders: [ 'no','perihal_kegiatan','tanggal','waktu','lokasi'],
        todayContractReminders: [],
        fieldsTodayContractReminders: [ 'no','nama_mitra','perihal_kontrak' ],
        remainingContractReminders: [],
        fieldsRemainingContractReminders: [ 'no','nama_mitra','perihal_kontrak' ],
        todayInternalOutgoingDocument: [],
        fieldsTodayInternalOutgoingDocument: [ 'no','pemohon_surat','ditujukan_kepada','jenis_surat' ],
        todayExternalOutgoingDocument: [],
        fieldsTodayExternalOutgoingDocument: ['no','pemohon_surat','ditujukan_kepada','jenis_surat'],
        todayImportantIncomingDocument: [],
        fieldsTodayImportantIncomingDocument: ['no','pemohon_surat','ditujukan_kepada','jenis_surat'],
        todayRegularIncomingDocument: [],
        fieldsTodayRegularIncomingDocument: ['no','pemohon_surat','ditujukan_kepada','jenis_surat'],
		weeklyRecap: [],
        fieldsWeeklyRecap: [ 'no','jenis_surat','surat_masuk','surat_keluar_internal','surat_keluar_external'],
        monthlyRecap: [],
        fieldsMonthlyRecap: [ 'no','jenis_surat','surat_masuk','surat_keluar_internal','surat_keluar_external'],
        reminderDocument5Day: [],
        totalIncomingDocument : 0,
        totalOutgoingDocument : 0,
        totalDocument : 0,
        todayInternalOutgoingDocumentCount: 0,
        todayExternalOutgoingDocumentCount: 0,
        todayImportantIncomingDocumentCount: 0,
        todayRegularIncomingDocumentCount: 0,
        currentYear : new Date().getFullYear(),
      }
    },
    beforeMount() {
        this.getDocumentLocations();
    },
    mounted() {
        let breadcrumb = [];
        this.$store.dispatch('setBreadCrumb', breadcrumb);
        this.getDashboardData();
    },
    computed: {
      rows() {
        return this.items.length
      },
      dataList() 
      {
          if (this.items != undefined) {
              return this.items.slice((this.currentPage * this.perPage),(this.currentPage * this.perPage) + this.perPage);
          }
          return [];
      }
    }
}
</script>