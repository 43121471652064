import Vue from 'vue'
import router from "./router.js"

import { BootstrapVue, BootstrapVueIcons, IconsPlugin, BIcon  } from 'bootstrap-vue'
import { VclFacebook } from 'vue-content-loading';
// import { Auth0Plugin } from "./auth";
// import { domain, clientId } from "../auth_config.json";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import './assets/css/docs.min.css'
import './assets/css/button.css'
import './assets/scss/apps.scss'
import 'vue-select/dist/vue-select.css';
import 'pdfvuer/dist/pdfvuer.css';

import axios from 'axios'
import App from './App.vue'
import store from './store'
import ClickConfirm from './components/ClickConfirm.vue'
import titleMixin from './mixins/titleMixin'
import PortalVue from 'portal-vue'
import "wicg-inert"
import api from "./services/api";
// import { makeServer } from "./server"
import LiquorTree from 'liquor-tree'
import { setHeaderToken } from './utils/auth'
import VueMask from 'v-mask'

Vue.use(VueMask);


axios.defaults.baseURL = process.env.VUE_APP_API_URL;
const token = localStorage.getItem('access_token');

if (token) { 
  setHeaderToken(token);
} 

// global registration
Vue.use(LiquorTree)

if (process.env.NODE_ENV === "development") {
  //makeServer()
}

var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo)

// // You can also pass in the default options
Vue.use(VueScrollTo, {
      container: "body",
      duration: 500,
      easing: "ease",
      offset: 0,
      force: true,
      cancelable: true,
      onStart: false,
      onDone: false,
      onCancel: false,
      x: false,
      y: true
  })

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

Vue.mixin(titleMixin)
Vue.use(PortalVue)
Vue.use(BootstrapVue, axios)
Vue.use(BootstrapVueIcons)
Vue.use(IconsPlugin)

const moment = require('moment')
require('moment/locale/id')
 
Vue.use(require('vue-moment'), {
    moment
})

Vue.component('vclFacebook', VclFacebook)
Vue.component('BIcon', BIcon)

const clickConfirmPlugin = (Vue, params) => {
  let name = 'click-confirm';
  if (typeof params === 'string') name = params;

  Vue.component(name, ClickConfirm);
};

ClickConfirm.install = clickConfirmPlugin;
Vue.component('clickConfirm', ClickConfirm);

Vue.config.productionTip = false
Vue.prototype.$http = api; 
api.defaults.timeout = 1000000;

api.interceptors.request.use(
  config => {
    const token = window.localStorage.getItem("access_token");
    if (token) {
      config.headers.common["Authorization"] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

store.dispatch('get_user').then(()=>{
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
}).catch((error) => {
  console.log(error);
})
