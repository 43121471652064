export default class CoreUserFilter {
  
  constructor () {
      this.id = "";
      this.userName = "";
      this.name = "";
      this.firstName = "";
      this.lastName = "";
  }
}
