<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true @ok="saveButtonAction" title="Save Confirmation - Archived Document">
<!-- APP_DETAIL_HEADER -->
		<b-card>
			<b-col cols="12" lg="6">
					<b-form-group id="fieldset-no-berita-acara" label-cols-sm="4" label-cols-lg="3" label="No. Berita Acara" label-for="filter-no-berita-acara">
						{{ model != null ? model.noBeritaAcara : "" }}
					</b-form-group>
					<b-form-group id="fieldset-archived-date-from" label-cols-sm="4" label-cols-lg="3" label="Periode Arsip Dari" label-for="filter-archived-date-from">
						{{ (model != null ? model.archivedDateFrom : "") | moment("DD-MMM-YYYY") }}
					</b-form-group>
					<b-form-group id="fieldset-archived-date-to" label-cols-sm="4" label-cols-lg="3" label="Periode Arsip Sampai" label-for="filter-archived-date-to">
						{{ (model != null ? model.archivedDateTo : "") | moment("DD-MMM-YYYY") }}
					</b-form-group>
					<b-form-group id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Lokasi Arsip" label-for="filter-document-location">
						{{ model != null ? ( model.documentLocation != null ? model.documentLocation.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-p-i-c" label-cols-sm="4" label-cols-lg="3" label="Pelaksana" label-for="filter-p-i-c">
						{{ model != null ? ( model.pIC != null ? model.pIC.fullName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-approved-by" label-cols-sm="4" label-cols-lg="3" label="Menyetujui" label-for="filter-approved-by">
						{{ model != null ? ( model.approvedBy != null ? model.approvedBy.fullName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-removal-method" label-cols-sm="4" label-cols-lg="3" label="Metode Hapus" label-for="filter-removal-method">
						{{ model != null ? ( model.removalMethod != null ? model.removalMethod.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Catatan" label-for="filter-remarks">
						{{ model != null ? model.remarks : "" }}
					</b-form-group>
					<b-form-group id="fieldset-incoming-document" label-cols-sm="4" label-cols-lg="3" label="Surat Masuk" label-for="filter-incoming-document">
						{{ model != null ? ( model.incomingDocument != null ? model.incomingDocument.documentCode : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-outgoing-document" label-cols-sm="4" label-cols-lg="3" label="Surat Keluar" label-for="filter-outgoing-document">
						{{ model != null ? ( model.outgoingDocument != null ? model.outgoingDocument.documentCode : "") : "" }}
					</b-form-group>
			</b-col>
		</b-card>
<!-- END_APP_DETAIL_HEADER -->
<!--APP_DETAIL_TABLE-->
	<b-card class="mt-3" header-bg-variant="transparent">
		<b-row class="mb-2">
			<b-col cols="9"><h5 class="card-title font-weight-bolder text-dark">Investigator Detail</h5></b-col>
			<b-col>
				<span class="float-right">
					<b-form-select v-model="investigatorDetailPageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
				</span>
			</b-col>
		</b-row>
		<b-row>
		<b-col>
			<div class="table-corner">
			<b-table id="my-table" stacked="md" head-variant="light" :no-local-sorting="true" :items="investigatorDetails" :fields="fieldsInvestigatorDetail" :busy="isDataLoading" :sort-by.sync="sortByInvestigatorDetail" :sort-desc.sync="sortDescInvestigatorDetail" small responsive>
			<!--APP_TABLE_FILTER-->
			<template v-slot:head(actions)>
				<b-button @click="isShowInvestigatorDetailFilter = !isShowInvestigatorDetailFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
			</template>
			<template #top-row v-if="isShowInvestigatorDetailFilter">
				<b-th></b-th>
				<b-th stacked-heading="User Profile">
					<v-select append-to-body label="fullName" :options="userProfiles" :value="filterInvestigatorDetail.userProfileId" :reduce="item => item.id" v-model="filterInvestigatorDetail.userProfileId" @search="getUserProfiles" :filterable="true"></v-select>
				</b-th>
				<b-th></b-th>
			</template>
			<!--END_APP_TABLE_FILTER-->
			<!--APP_TABLE_DATA-->
			<template v-slot:cell(no)="row">
				{{ ((row.index + 1) + ( investigatorDetailPageSize * (currentInvestigatorDetailPage - 1))) }}
			</template>
			<template v-slot:cell(archivedDocument)="row">
				{{ row.item.archivedDocument != null ? row.item.archivedDocument.noBeritaAcara : "" }}
			</template>
			<template v-slot:cell(userProfile)="row">
				{{ row.item.userProfile != null ? row.item.userProfile.fullName : "" }}
			</template>
			<!--END_APP_TABLE_DATA-->
			</b-table>
			</div>
		</b-col>
		<b-col cols="12">
			<!--APP_TABLE_PAGINATION-->
			<b-pagination
			class="float-right btn-paging"
			v-model="currentInvestigatorDetailPage"
			:total-rows="totalInvestigatorDetailRow"
			:per-page="investigatorDetailPageSize"
			aria-controls="my-table"
			></b-pagination>
			<!--END_APP_TABLE_PAGINATION-->
		</b-col>
		</b-row>
	</b-card>
<!--END_APP_DETAIL_TABLE-->
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import ArchivedDocument from '@/models/ArchivedDocument/ArchivedDocument';
import ArchivedDocumentList from '@/models/ArchivedDocument/ArchivedDocumentList';
import ArchivedDocumentFilter from '@/filters/ArchivedDocument/ArchivedDocumentFilter';

import InvestigatorDetail from '@/models/ArchivedDocument/InvestigatorDetail';
import InvestigatorDetailFilter from '@/filters/ArchivedDocument/InvestigatorDetailFilter';
import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
/*END_APP_DETAIL_IMPORT*/
export default {
	/*APP_DETAIL_PROP_DEFINITION*/
	props : ["openPopup", "modelId", "saveActionMethod", "resetMethod"],
	/*END_APP_DETAIL_PROP_DEFINITION*/
	components : {
	/*APP_DETAIL_PROP_DEFINITION*/
	PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,
	/*APP_DETAIL_COMPONENTS_DEFINITION*/
},
	data() {
		return {
			fileupload: null,
			model: {},
			datas: Array(),
			fieldsInvestigatorDetail: [
				{"no" : {  "class" : "th-number" }},
				{"userProfile": {},  "class" : "th-user-profile", "label" : "User Profile", key: 'userProfile', sortable: true}, 
				{"actions": { "class" : "th-actions", "label" : "" }}
			],
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			/*APP_DETAIL_PAGINATION*/
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterArchivedDocument : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentArchivedDocumentPage : 1,
			archivedDocumentPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByArchivedDocument: '',
			sortDescArchivedDocument: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			filterInvestigatorDetail : null,
			currentInvestigatorDetailPage : 1,
			investigatorDetailPageSize: 10,
			sortByInvestigatorDetail: '',
			sortDescInvestigatorDetail: '',
			sortingByInvestigatorDetail: [],
			investigatorDetails : [],
			investigatorDetailId : '',
			totalInvestigatorDetailRow : 0,
			isShowInvestigatorDetailFilter: false,
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			userProfiles: [],
			/*END_APP_DETAIL_OBJ_VARIABLE*/
		}
	},
	methods : {
		/*APP_DETAIL_GETArchivedDocument*/
		getArchivedDocument : async function(id) {
			this.isBusy = true;
			ArchivedDocument.getData(this, id).then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETArchivedDocument*/
		/*APP_DETAIL_GETInvestigatorDetail*/
		getInvestigatorDetails : async function() {
			let filter = {};
			Object.assign(filter, this.filterInvestigatorDetail);
			if (this.modelId == null) return;
			filter.archivedDocumentId = this.modelId;
			this.sortingByInvestigatorDetail = Array();
			if (this.sortByInvestigatorDetail != null) {
				this.sortingByInvestigatorDetail[this.sortByInvestigatorDetail] = this.sortDescInvestigatorDetail;
			}
			let currentPage = (this.currentInvestigatorDetailPage - 1);
			InvestigatorDetail.getList(this, filter, this.sortingByInvestigatorDetail, currentPage , this.investigatorDetailPageSize).then(result => {
				this.investigatorDetails = result.data;
				this.totalInvestigatorDetailRow = result.dataCount;
			}).catch(error => {});
		},
		/*END_APP_DETAIL_GETInvestigatorDetail*/
		/*APP_DETAIL_GETUSERPROFILE*/
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		/*END_APP_DETAIL_GETUSERPROFILE*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'archiveddocumentcopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_SAVEBUTTONACTION*/
		saveButtonAction(modalEvent) {
			this.$emit("saveActionMethod", modalEvent);
		},
		/*END_APP_DETAIL_SAVEBUTTONACTION*/
		/*APP_DETAIL_RESETMODAL*/
		resetModal() {
			this.$emit('resetMethod'); 
		},
		/*END_APP_DETAIL_RESETMODAL*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
				this.isShowInvestigatorDetailFilter = true;
			}
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterArchivedDocument = new ArchivedDocumentFilter();
		this.filterInvestigatorDetail = new InvestigatorDetailFilter();
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	watch : {
		/*APP_DETAIL_OPENPOPUP*/
		openPopup(newValue, oldValue) {
			if (newValue) {
				this.getArchivedDocument(this.modelId);
				this.getInvestigatorDetails();
			}
		},
		/*END_APP_DETAIL_OPENPOPUP*/
		/*APP_DETAIL_WATCH_INVESTIGATORDETAIL*/
		sortByInvestigatorDetail : {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		sortDescInvestigatorDetail: {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		currentInvestigatorDetailPage: {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		investigatorDetailPageSize : {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		filterInvestigatorDetail: {
			handler: function() {
				this.getInvestigatorDetails();
			},
			deep: true,
		},
		/*END_APP_DETAIL_WATCH_INVESTIGATORDETAIL*/
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
		isShowPopup: {
			get: function(){
				if (this.openPopup) return true; 
				else return false; 
			},
			set: function(newValue){ }
		},
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
