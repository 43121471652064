<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Array,
      default: () => []
    },
    modelIncoming: {
      type: Array,
      default: () => []
    },
    modelOutgoing: {
      type: Array,
      default: () => []
    },
    modelTotal: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: [
          {
            label: 'Surat Masuk',
            backgroundColor: '#f87979',
            barThickness: 20,
            data: this.modelIncoming
          },
          {
            label: 'Surat Keluar',
            backgroundColor: '#3492eb',
            barThickness: 20,
            data: this.modelOutgoing
          },
          {
            label: 'Total',
            backgroundColor: '#cacbcc',
            barThickness: 20,
            data: this.modelTotal
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  }
}
</script>
