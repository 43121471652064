<template>
	<div class="pb-5">
		<b-button class="filter" variant="light" block href="#" v-b-toggle.accordion-filter>
			<span><b-icon-funnel-fill></b-icon-funnel-fill> Filter</span>
			<span class="menu-arrow">
				<b-icon-chevron-right class="float-right chevron-right"></b-icon-chevron-right><b-icon-chevron-down class="float-right chevron-down"></b-icon-chevron-down>
			</span>
		</b-button>
		<b-collapse id="accordion-filter" accordion="accordion-filter" role="tabpanel" class="menu-accordion">
			<b-card>
				<b-row>
					<b-col cols="12" lg="6">
					<app-input-textbox id="fieldset-no-berita-acara" label-cols-sm="4" label-cols-lg="3" label="No. Berita Acara" label-for="filter-no-berita-acara" size="sm" :model.sync="filter.noBeritaAcara" />
					<app-input-datepicker-range  id="fieldset-archived-date-from" label-cols-sm="4" label-cols-lg="3" label="Periode Arsip Dari" size="sm" :modelFrom.sync="filter.archivedDateFromFrom" :modelTo.sync="filter.archivedDateFromTo" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button />
					<app-input-datepicker-range  id="fieldset-archived-date-to" label-cols-sm="4" label-cols-lg="3" label="Periode Arsip Sampai" size="sm" :modelFrom.sync="filter.archivedDateToFrom" :modelTo.sync="filter.archivedDateToTo" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB" reset-button />
					<!-- enable this if you need combo box, delete if you didn't need it <app-input-combobox-autocomplete :options="documentLocations" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Lokasi Arsip" id="filter-" size="sm" :model.sync="filter.documentLocationId" @input="getDocumentLocations" @focus="getDocumentLocations" /> -->
					<app-input-textarea id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Lokasi Arsip" label-for="filter-document-location" size="sm" :model.sync="filter.documentLocationName" :modelExact.sync="filter.exactType.documentLocationName" />
					<!-- enable this if you need combo box, delete if you didn't need it <app-input-combobox-autocomplete :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Pelaksana" id="filter-" size="sm" :model.sync="filter.pICId" @input="getUserProfiles" @focus="getUserProfiles" /> -->
					<app-input-textarea id="fieldset-p-i-c" label-cols-sm="4" label-cols-lg="3" label="Pelaksana" label-for="filter-p-i-c" size="sm" :model.sync="filter.pICFullName" :modelExact.sync="filter.exactType.pICFullName" />
					<!-- enable this if you need combo box, delete if you didn't need it <app-input-combobox-autocomplete :options="userProfiles" optionsLabel="fullName" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Menyetujui" id="filter-" size="sm" :model.sync="filter.approvedById" @input="getUserProfiles" @focus="getUserProfiles" /> -->
					<app-input-textarea id="fieldset-approved-by" label-cols-sm="4" label-cols-lg="3" label="Menyetujui" label-for="filter-approved-by" size="sm" :model.sync="filter.approvedByFullName" :modelExact.sync="filter.exactType.approvedByFullName" />
					<!-- enable this if you need combo box, delete if you didn't need it <app-input-combobox-autocomplete :options="removalMethods" optionsLabel="name" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Metode Hapus" id="filter-" size="sm" :model.sync="filter.removalMethodId" @input="getRemovalMethods" @focus="getRemovalMethods" /> -->
					<app-input-textarea id="fieldset-removal-method" label-cols-sm="4" label-cols-lg="3" label="Metode Hapus" label-for="filter-removal-method" size="sm" :model.sync="filter.removalMethodName" :modelExact.sync="filter.exactType.removalMethodName" />
					<app-input-textarea id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Catatan" label-for="filter-remarks" size="sm" :model.sync="filter.remarksMultiple" />
					<!-- enable this if you need combo box, delete if you didn't need it <app-input-combobox-autocomplete :options="incomingDocuments" optionsLabel="documentCode" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Surat Masuk" id="filter-" size="sm" :model.sync="filter.incomingDocumentId" @input="getIncomingDocuments" @focus="getIncomingDocuments" /> -->
					<app-input-textarea id="fieldset-incoming-document" label-cols-sm="4" label-cols-lg="3" label="Surat Masuk" label-for="filter-incoming-document" size="sm" :model.sync="filter.incomingDocumentDocumentCode" :modelExact.sync="filter.exactType.incomingDocumentDocumentCode" />
					<!-- enable this if you need combo box, delete if you didn't need it <app-input-combobox-autocomplete :options="outgoingDocuments" optionsLabel="documentCode" optionsKey="id" label-cols-sm="4" label-cols-lg="3" label="Surat Keluar" id="filter-" size="sm" :model.sync="filter.outgoingDocumentId" @input="getOutgoingDocuments" @focus="getOutgoingDocuments" /> -->
					<app-input-textarea id="fieldset-outgoing-document" label-cols-sm="4" label-cols-lg="3" label="Surat Keluar" label-for="filter-outgoing-document" size="sm" :model.sync="filter.outgoingDocumentDocumentCode" :modelExact.sync="filter.exactType.outgoingDocumentDocumentCode" />
					<b-button variant="danger" size="sm" class="float-right" @click="resetFilter"><span style="font-size: 0.75rem" class="mr-1"><b-icon-x></b-icon-x></span> Clear Filter</b-button>
					<b-button variant="primary" size="sm" class="float-right mr-1" @click="getArchivedDocuments"><span style="font-size: 0.75rem" class="mr-1"><b-icon-search></b-icon-search></span> Cari</b-button>
					</b-col>
				</b-row>
			</b-card>
		</b-collapse>
		<b-row class="mt-3">
			<b-col>
				<b-button variant="warning" v-if="(userRole != null ? userRole.allowUpdate : false)" class="float-right ml-2" @click="showPopupUploadData" size="sm"><b-icon-upload class="mr-1"></b-icon-upload> Upload</b-button>
				<b-overlay
					v-if="(userRole != null ? userRole.allowDownload : false)"
					:show="isDownloadDataBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="float-right "
					>
					<b-button variant="primary" v-if="(userRole != null ? userRole.allowDownload : false)" class="float-right ml-2" @click="downloadData" size="sm"><b-icon-download class="mr-1"></b-icon-download> Download</b-button>
				</b-overlay>
				<b-button variant="primary" v-if="(userRole != null ? userRole.allowDownload : false)" class="float-right" href="/files/ArchivedDocument/ArchivedDocument.xlsx" size="sm"><b-icon-wallet-fill class="mr-1"></b-icon-wallet-fill> Download Template</b-button>
			</b-col>
		 </b-row>
		<b-card class="mt-3 mb-3">
		<b-row class="mb-3">
			<b-col>
				<b-overlay
					v-if="(userRole != null ? userRole.allowCreate : false)"
					:show="isCreateBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<b-button variant="success" v-if="(userRole != null ? userRole.allowCreate : false)" @click="createData" size="sm"><b-icon-pencil class="mr-1"></b-icon-pencil> Create</b-button>
				</b-overlay>
				<b-overlay
					v-if="(userRole != null ? userRole.allowPrint : false)"
					:show="isMultiplePrintBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<b-button variant="primary" v-if="(userRole != null ? userRole.allowPrint : false)" class="ml-2" @click="printData" size="sm"><b-icon-printer-fill class="mr-1"></b-icon-printer-fill> Multiple Print</b-button>
				</b-overlay>
			</b-col>
			<b-col>
				<span class="float-right">
					<b-form-select v-model="pageSizeOptions" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
				</span>
			</b-col>
		</b-row>
		<div class="table-corner">
			<b-table id="my-table" stacked="md" head-variant="light"  :no-local-sorting="true" :items="archivedDocuments" :fields="fields" :busy="isDataLoading" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :tbody-tr-class="rowClass" small responsive>
			<template v-slot:head(actions)>
				<b-button-group class="float-right"  size="sm">
					<b-button @click="isShowFilter = !isShowFilter" size="sm"><b-icon-funnel-fill /></b-button>
					<b-button><b-form-checkbox v-if="(userRole != null ? userRole.allowPrint : false)" id="input-select-all" class="float-right" v-model="selectAll"></b-form-checkbox></b-button>
				</b-button-group>
			</template>
			<template #top-row v-if="isShowFilter">
				<b-th></b-th>
				<b-th stacked-heading="No. Berita Acara">
					<b-form-input id="input-no-berita-acara-table" @input="getArchivedDocumentsWithoutLoading" v-model="filter.noBeritaAcara" size="sm"></b-form-input>
				</b-th>
				<b-th stacked-heading="Periode Arsip Dari">
					<b-form-datepicker boundary="viewport" placeholder="" id="input-archived-date-from-from-table" reset-button @input="getArchivedDocumentsWithoutLoading" v-model="filter.archivedDateFromFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"></b-form-datepicker>
					<b-form-datepicker boundary="viewport" placeholder="" id="input-archived-date-from-to-table" reset-button @input="getArchivedDocumentsWithoutLoading" v-model="filter.archivedDateFromTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"></b-form-datepicker>
				</b-th>
				<b-th stacked-heading="Periode Arsip Sampai">
					<b-form-datepicker boundary="viewport" placeholder="" id="input-archived-date-to-from-table" reset-button @input="getArchivedDocumentsWithoutLoading" v-model="filter.archivedDateToFrom" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"></b-form-datepicker>
					<b-form-datepicker boundary="viewport" placeholder="" id="input-archived-date-to-to-table" reset-button @input="getArchivedDocumentsWithoutLoading" v-model="filter.archivedDateToTo" size="sm" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" locale="en-GB"></b-form-datepicker>
				</b-th>
				<b-th stacked-heading="Lokasi Arsip">
					<v-select append-to-body label="name" :options="documentLocations" :value="filter.documentLocationId" :reduce="item => item.id" v-model="filter.documentLocationId" :filterable="true" @input="getArchivedDocumentsWithoutLoading" @search="getDocumentLocations"  @focus="getDocumentLocations"></v-select>
				</b-th>
				<b-th stacked-heading="Pelaksana">
					<v-select append-to-body label="fullName" :options="userProfiles" :value="filter.pICId" :reduce="item => item.id" v-model="filter.pICId" :filterable="true" @input="getArchivedDocumentsWithoutLoading" @search="getUserProfiles"  @focus="getUserProfiles"></v-select>
				</b-th>
				<b-th stacked-heading="Menyetujui">
					<v-select append-to-body label="fullName" :options="userProfiles" :value="filter.approvedById" :reduce="item => item.id" v-model="filter.approvedById" :filterable="true" @input="getArchivedDocumentsWithoutLoading" @search="getUserProfiles"  @focus="getUserProfiles"></v-select>
				</b-th>
				<b-th stacked-heading="Metode Hapus">
					<v-select append-to-body label="name" :options="removalMethods" :value="filter.removalMethodId" :reduce="item => item.id" v-model="filter.removalMethodId" :filterable="true" @input="getArchivedDocumentsWithoutLoading" @search="getRemovalMethods"  @focus="getRemovalMethods"></v-select>
				</b-th>
				<b-th stacked-heading="Select All">
					<b-form-checkbox v-if="(userRole != null ? userRole.allowPrint : false)" id="input-select-all" class="float-right d-block d-md-none" v-model="selectAll"></b-form-checkbox>
				</b-th>
			</template>
			<template v-slot:cell(no)="row">
				{{ ((row.index + 1) + ( pageSizeOptions * (currentPage - 1))) }}
			</template>
			<template v-slot:cell(archivedDateFrom)="row">
				{{ row.item.archivedDateFrom | moment("DD-MMM-YYYY") }}
			</template>
			<template v-slot:cell(archivedDateTo)="row">
				{{ row.item.archivedDateTo | moment("DD-MMM-YYYY") }}
			</template>
			<template v-slot:cell(documentLocation)="row">
				{{ row.item.documentLocation != null ? row.item.documentLocation.name : "" }}
			</template>
			<template v-slot:cell(pIC)="row">
				{{ row.item.pIC != null ? row.item.pIC.fullName : "" }}
			</template>
			<template v-slot:cell(approvedBy)="row">
				{{ row.item.approvedBy != null ? row.item.approvedBy.fullName : "" }}
			</template>
			<template v-slot:cell(removalMethod)="row">
				{{ row.item.removalMethod != null ? row.item.removalMethod.name : "" }}
			</template>
			<template v-slot:cell(actions)="row" >
				<div class="div-actions float-right">
				<b-button size="sm" v-if="(userRole != null ? userRole.allowUpdate : false)" @click="editData(row.index, row.item.id)" variant ="success"  class="mr-2 btn-xs">
					<b-icon-pencil></b-icon-pencil>
				</b-button>
				<b-overlay
					v-if="(userRole != null ? userRole.allowDelete : false)"
					:show="busy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<click-confirm>
					<b-button size="sm" variant="danger" @click="deleteData(row.item.id)" class="mr-2 btn-xs">
						<b-icon-trash></b-icon-trash>
					</b-button>
					</click-confirm>
				</b-overlay>
				<b-button size="sm" v-if="(userRole != null ? userRole.allowRead : false)" variant="primary" class="mr-2 mt-md-2 mt-lg-0 btn-xs" @click="showTableDetail(row.item.id)" >
					<b-icon-eye-fill></b-icon-eye-fill>
				</b-button>
				<b-button size="sm" v-if="(userRole != null ? userRole.allowPrint : false)" variant="light"  @click="printSingleData(row.item.id)" class="mr-2 mt-md-2 mt-lg-0 btn-xs">
					<b-icon-printer-fill></b-icon-printer-fill>
				</b-button>
				<b-form-checkbox v-if="(userRole != null ? userRole.allowPrint : false)" class="d-inline" :id="'input-is-print-' + (row.index + 1)" @change="toggleIsPrint(Number(row.item.id))" :checked="checkAllData.includes(Number(row.item.id))" ></b-form-checkbox>
				</div>
			</template>
			</b-table>
		</div>
		<b-row>
			<b-col cols="12" md="6" lg="6">
				<b-overlay
					v-if="(userRole != null ? userRole.allowCreate : false)"
					:show="isCreateBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<b-button variant="success" v-if="(userRole != null ? userRole.allowCreate : false)" @click="createData" size="sm"><b-icon-pencil class="mr-1"></b-icon-pencil> Create</b-button>
				</b-overlay>
				<b-overlay
					v-if="(userRole != null ? userRole.allowPrint : false)"
					:show="isMultiplePrintBusy"
					rounded
					opacity="0.6"
					spinner-small
					spinner-variant="primary"
					class="d-inline-block"
					>
					<b-button variant="primary" v-if="(userRole != null ? userRole.allowPrint : false)" class="ml-2" @click="printData" size="sm"><b-icon-printer-fill class="mr-1"></b-icon-printer-fill> Multiple Print</b-button>
				</b-overlay>
			</b-col>
			<b-col cols="12" md="6" lg="6">
			<b-pagination
				class="float-md-right btn-paging"
				v-model="currentPage"
				:total-rows="(archivedDocumentsPaging != null ? archivedDocumentsPaging.dataCount : 0)"
				:per-page="(archivedDocumentsPaging != null ? archivedDocumentsPaging.pageSize : 0)"
				aria-controls="my-table"
			></b-pagination>
			</b-col>
		</b-row>
		</b-card>
		<Upload
		:openPopup="showPopupUpload"
		@resetMethod="resetModalUpload"
		@uploadButtonMethod="uploadData"
		/>
		<PopupDownload
		:openPopup="showPopupDownload"
		:processId="processId"
		@resetModalMethod="resetModal"
		label= "Popup Download"
		:contentLabel = "popupDownloadLabel"
		/>
		<PopupPDF
		:openPopup="isShowPopupPDF"
		:pdfUrl="pdfUrl"
		@resetModalMethod="resetModal"
		label= "Popup PDF"
		/>
		<PopupDataEditNotification
		entityName ="archiveddocument"
		:dataId ="currentDataId"
		@resetModalMethod ="resetModal"
		label = "Data Recovery"
		/>
	<b-card class="mb-3" header-bg-variant="transparent" v-if="isShowTableInvestigatorDetail">
		<template #header>
			<b-row>
				<b-col cols="9"><h5 class="card-title font-weight-bolder text-dark">Investigator Detail</h5></b-col>
				<b-col>
					<span class="float-right">
						<button class="close" no-variant @click="isShowTableInvestigatorDetail = false">×</button>
					</span>
				</b-col>
			</b-row>
		</template>
		<b-row class="mb-2">
			<b-col>
				<span class="float-right">
					<b-form-select v-model="investigatorDetailPageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
				</span>
			</b-col>
		</b-row>
		<b-row>
		<b-col>
			<div class="table-corner table-row-selected">
			<b-table id="my-table" stacked="md" head-variant="light" :no-local-sorting="true" :items="investigatorDetails" :fields="fieldsInvestigatorDetail" :busy="isDataLoading" :sort-by.sync="sortByInvestigatorDetail" :sort-desc.sync="sortDescInvestigatorDetail" small responsive>
			<!--APP_TABLE_FILTER-->
			<template v-slot:head(actions)>
				<b-button @click="isShowInvestigatorDetailFilter = !isShowInvestigatorDetailFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
			</template>
			<template #top-row v-if="isShowInvestigatorDetailFilter">
				<b-th></b-th>
				<b-th stacked-heading="User Profile">
					<v-select append-to-body label="fullName" :options="userProfiles" :value="filterInvestigatorDetail.userProfileId" :reduce="item => item.id" v-model="filterInvestigatorDetail.userProfileId" @search="getUserProfiles" :filterable="true"  @focus="getUserProfiles"></v-select>
				</b-th>
				<b-th></b-th>
			</template>
			<!--END_APP_TABLE_FILTER-->
			<!--APP_TABLE_DATA-->
			<template v-slot:cell(no)="row">
				{{ ((row.index + 1) + ( investigatorDetailPageSize * (currentInvestigatorDetailPage - 1))) }}
			</template>
			<template v-slot:cell(archivedDocument)="row">
				{{ row.item.archivedDocument != null ? row.item.archivedDocument.noBeritaAcara : "" }}
			</template>
			<template v-slot:cell(userProfile)="row">
				{{ row.item.userProfile != null ? row.item.userProfile.fullName : "" }}
			</template>
			<!--END_APP_TABLE_DATA-->
			</b-table>
			</div>
		</b-col>
		<b-col cols="12">
			<!--APP_TABLE_PAGINATION-->
			<b-pagination
			class="float-right btn-paging"
			v-model="currentInvestigatorDetailPage"
			:total-rows="totalInvestigatorDetailRow"
			:per-page="investigatorDetailPageSize"
			aria-controls="my-table"
			></b-pagination>
			<!--END_APP_TABLE_PAGINATION-->
		</b-col>
		</b-row>
	</b-card>
		<RouterView />
	</div>
</template>
<style lang="scss" scoped>
	@import '../../assets/scss/index-page.scss';
</style>
<script>
import ArchivedDocument from '@/models/ArchivedDocument/ArchivedDocument';
import ArchivedDocumentList from '@/models/ArchivedDocument/ArchivedDocumentList';
import ArchivedDocumentFilter from '@/filters/ArchivedDocument/ArchivedDocumentFilter';
import RoleDetail from '@/models/Core/RoleDetail';

import UserProfile from '@/models/UserProfile/UserProfile';
import UserProfileFilter from '@/filters/UserProfile/UserProfileFilter';
import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputDatepicker from '@/components/AppInputDatepicker';
import AppInputDatepickerRange from '@/components/AppInputDatepickerRange';
import AppInputComboboxAutocomplete from '@/components/AppInputComboboxAutocomplete';
import DocumentLocationFilter from '@/filters/DocumentLocation/DocumentLocationFilter';
import DocumentLocation from '@/models/DocumentLocation/DocumentLocation';
import RemovalMethodFilter from '@/filters/RemovalMethod/RemovalMethodFilter';
import RemovalMethod from '@/models/RemovalMethod/RemovalMethod';
import AppInputTextarea from '@/components/AppInputTextarea';
import InvestigatorDetail from '@/models/ArchivedDocument/InvestigatorDetail';
import InvestigatorDetailFilter from '@/filters/ArchivedDocument/InvestigatorDetailFilter';
import IncomingDocumentFilter from '@/filters/IncomingDocument/IncomingDocumentFilter';
import IncomingDocument from '@/models/IncomingDocument/IncomingDocument';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import PopupDownload from '@/components/PopupDownload';
import PopupPDF from '@/components/PopupPDF';
import PopupDataEditNotification from '@/components/PopupDataEditNotification';
import Upload from './Upload';

export default {
	components : {ArchivedDocument, ArchivedDocumentList, ArchivedDocumentFilter, AppInputTextbox,AppInputDatepicker,AppInputDatepickerRange,AppInputComboboxAutocomplete,AppInputTextarea,PopupDownload,PopupPDF,Upload,PopupDataEditNotification,},
	data() {
		return {
			isShowTableDetail: false,
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fields: [
				{"no" : { "class" : "th-number"}}, 
				{"noBeritaAcara": {}, "class" : "th-no-berita-acara", "label" : "No. Berita Acara", key: 'noBeritaAcara', sortable: true}, 
				{"archivedDateFrom": {}, "class" : "th-archived-date-from", "label" : "Periode Arsip Dari", key: 'archivedDateFrom', sortable: true}, 
				{"archivedDateTo": {}, "class" : "th-archived-date-to", "label" : "Periode Arsip Sampai", key: 'archivedDateTo', sortable: true}, 
				{"documentLocation": {}, "class" : "th-document-location", "label" : "Lokasi Arsip", key: 'documentLocation', sortable: true}, 
				{"pIC": {}, "class" : "th-p-i-c", "label" : "Pelaksana", key: 'pIC', sortable: true}, 
				{"approvedBy": {}, "class" : "th-approved-by", "label" : "Menyetujui", key: 'approvedBy', sortable: true}, 
				{"removalMethod": {}, "class" : "th-removal-method", "label" : "Metode Hapus", key: 'removalMethod', sortable: true}, 
				{"actions": { "class" : "th-actions", "label" : ""}}
			],
			busy:false,
			isDataLoading: false,
			isDownloadDataBusy: false,
			isMultiplePrintBusy: false,
			showPopupUpload: false,
			showPopupDownload: false,
			isShowPopupPDF: false,
			pdfUrl: "",
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			filter: null,
			processId: "",
			popupDownloadLabel: "",
			hasLoadedData: false,
			showFilter: false,
			isShowFilter: false,
			isCreateBusy: false,
			btnEditData: [],
			currentDataId: null,
			modelId: "",
			archivedDocuments: [],
			archivedDocumentsPaging: {},
			documentLocations : [],
			userProfiles : [],
			removalMethods : [],
			incomingDocuments : [],
			outgoingDocuments : [],
			fieldsInvestigatorDetail: [
				{"no" : {  "class" : "th-number" }},
				{"userProfile": {},  "class" : "th-user-profile", "label" : "User Profile", key: 'userProfile', sortable: true}, 
				{"actions": { "class" : "th-actions", "label" : "" }}
			],
			filterInvestigatorDetail : null,
			currentInvestigatorDetailPage : 1,
			investigatorDetailPageSize: 10,
			sortByInvestigatorDetail: '',
			sortDescInvestigatorDetail: '',
			sortingByInvestigatorDetail: [],
			investigatorDetails : [],
			investigatorDetailId : '',
			totalInvestigatorDetailRow : 0,
			isShowInvestigatorDetailFilter: false,
			isShowTableInvestigatorDetail: false,
			userProfiles: [],
		}
	},
	methods : {
		getArchivedDocuments : async function() {
			this.hideAllTableDetail();
			this.modelId = '';
			this.resetChildFilter();
			this.isDataLoading = true;
			this.sortingBy = Array();
			if (this.sortBy != null) {
				this.sortingBy[this.sortBy] = this.sortDesc;
			}
			ArchivedDocument.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions)
			.then(result => {
				if (result != null) {
					this.archivedDocuments = result.data
					this.archivedDocumentsPaging = result
				}
				this.isDataLoading = false;
			})
			.catch(error => {
				this.isDataLoading = false;
			});
		},
		getArchivedDocumentsWithoutLoading : async function() {
			this.hideAllTableDetail();
			this.modelId = '';
			this.resetChildFilter();
			this.sortingBy = Array();
			if (this.sortBy != null) {
				this.sortingBy[this.sortBy] = this.sortDesc;
			}
			ArchivedDocument.getList(this, this.filter, this.sortingBy, this.currentPage, this.pageSizeOptions)
			.then(result => {
				if (result != null) {
					this.archivedDocuments = result.data
					this.archivedDocumentsPaging = result
				}
			})
			.catch(error => {});
		},
		getDocumentLocations : async function(input) {
			let filter = new DocumentLocationFilter();
			if (input != undefined) {
				filter.name = input;
			}
			DocumentLocation.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.documentLocations = result.data
				}
			})
			.catch(error => {});
		},
		getUserProfiles : async function(input) {
			let filter = new UserProfileFilter();
			if (input != undefined) {
				filter.fullName = input;
			}
			UserProfile.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.userProfiles = result.data
				}
			})
			.catch(error => {});
		},
		getRemovalMethods : async function(input) {
			let filter = new RemovalMethodFilter();
			if (input != undefined) {
				filter.name = input;
			}
			RemovalMethod.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.removalMethods = result.data
				}
			})
			.catch(error => {});
		},
		getIncomingDocuments : async function(input) {
			let filter = new IncomingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			IncomingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.incomingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		getOutgoingDocuments : async function(input) {
			let filter = new OutgoingDocumentFilter();
			if (input != undefined) {
				filter.documentCode = input;
			}
			OutgoingDocument.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.outgoingDocuments = result.data
				}
			})
			.catch(error => {});
		},
		/*APP_DETAIL_GETInvestigatorDetail*/
		getInvestigatorDetails : async function() {
			let filter = {};
			Object.assign(filter, this.filterInvestigatorDetail);
			if (this.modelId == null || this.modelId == undefined || this.modelId == '') return;
			if (!this.isShowTableInvestigatorDetail) return;
			filter.archivedDocumentId = this.modelId;
			this.sortingByInvestigatorDetail = Array();
			if (this.sortByInvestigatorDetail != null) {
				this.sortingByInvestigatorDetail[this.sortByInvestigatorDetail] = this.sortDescInvestigatorDetail;
			}
			let currentPage = (this.currentInvestigatorDetailPage - 1);
			InvestigatorDetail.getList(this, filter, this.sortingByInvestigatorDetail, currentPage , this.investigatorDetailPageSize).then(result => {
				this.investigatorDetails = result.data;
				this.totalInvestigatorDetailRow = result.dataCount;
			}).catch(error => {});
		},
		/*END_APP_DETAIL_GETInvestigatorDetail*/
		resetFilter() {
			this.filter = new ArchivedDocumentFilter();
		},
		showPopupUploadData() {
			this.showPopupUpload = true;
		},
		uploadData(modalEvent) {
			this.showPopupUpload = false;
		},
		resetModal() {
			this.showPopupUpload = false;
			this.showPopupDownload = false;
			this.isShowPopupPDF = false;
		},
		resetModalUpload() {
			this.showPopupUpload = false;
			this.getArchivedDocuments();
		},
		toggleIsPrint(id) {
			if (this.checkAllData.includes(id)) {
				this.$store.dispatch("removeCheckData", id);
			} else {
				this.$store.dispatch("addCheckData", id);
			}
		},
		showPopupPrint(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di cetak";
			this.processId = id;
		},
		showPopupDownloadData(id) {
			this.showPopupDownload = true;
			this.popupDownloadLabel = "Mempersiapkan data untuk di unduh";
			this.processId = id;
		},
		showPopupPDF(url) {
			this.isShowPopupPDF = true;
			this.pdfUrl = url;
		},
		printData() {
			let data = this.$store.state.dataIds;
			if (data.length > 0) {
				this.isMultiplePrintBusy = true;
				ArchivedDocument.multiPagePrint(this).then(result => {
					if (result) {
						this.showPopupPrint(result.id);
					}
					this.isMultiplePrintBusy = false;
				}).catch(error => { this.isMultiplePrintBusy = false; });
			}
		},
		printSingleData(id) {
			ArchivedDocument.singleDataPagePrint(this, id).then(result => {
				if (result) {
					this.showPopupPDF(result.download);
				}
			});
		},
		downloadData() {
			if (this.sortBy != null) {
					this.sortingBy[this.sortBy] = this.sortDesc;
			}
			this.isDownloadDataBusy = true;
			ArchivedDocument.downloadData(this, this.filter, this.sortingBy).then(result => {
				if (result) {
					this.showPopupDownloadData(result.id);
				}
				this.isDownloadDataBusy = false;
			}).catch(error => { this.isDownloadDataBusy = false; })
		},
		createData() {
			this.isCreateBusy = true;
			ArchivedDocument.createData(this).then(result => {
				if (result != null) {
					if (result.id != null && result.id != "") {
						this.$router.push({ name: 'archiveddocumentedit', params : { id: result.id } });
					}
				}
				this.isCreateBusy = false;
			}).catch(error => {
				this.isCreateBusy = false;
			})
		},
		editData(index, id) {
			this.btnEditData[index] = true;
			this.dataId = id;
			ArchivedDocument.editData(this, id).then(result => {
				if (result != null) {
					if (result.id != null && result.id != "") {
						this.$router.push({ name: 'archiveddocumentedit', params : { id: result.id } });
					}
				}
				this.btnEditData[index] = false;
			}).catch(error => { this.btnEditData[index] = false; })
		},
		deleteData(id) {
			ArchivedDocument.deleteData(this, id).then(result => {
				if (result)
				{
					this.getArchivedDocuments();
				}
			})
		},
		showTableDetail(id) {
			this.showAllTableDetail();
			this.modelId = id;
			this.getInvestigatorDetails()
		},
		rowClass(item, type) {
			if (!item || type !== 'row') return;
			if (item.id === this.modelId) {
			if (this.isShowTableInvestigatorDetail) {
				return 'table-row-selected';
			}
			}
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768) {
				this.isShowFilter = true;
			} else {
				this.isShowFilter = this.showFilter;
			}
		},
		resetChildFilter() {
			this.filterInvestigatorDetail = new InvestigatorDetailFilter();
		},
		showAllTableDetail() {
			this.isShowTableInvestigatorDetail = true;
		},
		hideAllTableDetail() {
			this.isShowTableInvestigatorDetail = false;
		},
	},
	beforeMount() {
		this.$store.dispatch("removeCheckAllData");
		this.filter = new ArchivedDocumentFilter();
		this.filterInvestigatorDetail = new InvestigatorDetailFilter();
		this.getUserProfiles();
	},
	mounted(){
		let breadcrumb =[
			"Master Data", 
			"Archived Document"
		];
		this.getArchivedDocuments();
		this.$store.dispatch('setBreadCrumb', breadcrumb);
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
		});
	},
	watch: {
		sortBy: {
			handler: function() {
				this.getArchivedDocuments();
			}
		},
		sortDesc: {
			handler: function() {
				this.getArchivedDocuments();
			}
		},
		currentPage: {
			handler: function() {
				this.getArchivedDocuments();
			}
		},
		pageSizeOptions: {
			handler: function() {
				this.getArchivedDocuments();
			}
		},
		selectAll(newValue) {
			if (newValue) {
				ArchivedDocument.checkAllData(this, this.filter, this.sortingBy);
			} else {
				this.$store.dispatch("removeCheckAllData");
			}
		},
		'$route'(to, from) {
			if (to != null) {
				if (to.name == "archiveddocument") {
					this.getArchivedDocumentsWithoutLoading();
				}
			}
		},
		modelId: {
			handler: function() {
				this.resetChildFilter();
			}
		},
		/*APP_DETAIL_WATCH_INVESTIGATORDETAIL*/
		sortByInvestigatorDetail : {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		sortDescInvestigatorDetail: {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		currentInvestigatorDetailPage: {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		investigatorDetailPageSize : {
			handler: function() {
				this.getInvestigatorDetails();
			}
		},
		computedFilterInvestigatorDetail: {
			handler: function(newValue, oldValue) {
				if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
					this.getInvestigatorDetails();
				}
			},
			deep: true,
		},
		/*END_APP_DETAIL_WATCH_INVESTIGATORDETAIL*/
	},
	computed: {
		/*APP_DETAIL_COMPUTED_INVESTIGATORDETAIL*/
		computedFilterInvestigatorDetail(){
			return Object.assign({}, this.filterInvestigatorDetail);
		},
		/*END_APP_DETAIL_COMPUTED_INVESTIGATORDETAIL*/
		checkAllData() {
			return this.$store.state.dataIds;
		},
		userRole() {
			return RoleDetail.query().where("functionInfoId", "archived_document").first();
		}
	},
	beforeDestroy() {
		this.$store.dispatch("removeCheckAllData");
	},
}
</script>
