<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true @ok="saveButtonAction" title="Save Confirmation - Document Attachment">
<!-- APP_DETAIL_HEADER -->
		<b-card>
			<b-col cols="12" lg="6">
					<b-form-group id="fieldset-name" label-cols-sm="4" label-cols-lg="3" label="Name" label-for="filter-name">
						{{ model != null ? model.name : "" }}
					</b-form-group>
					<b-form-group id="fieldset-created-by" label-cols-sm="4" label-cols-lg="3" label="Created By" label-for="filter-created-by">
						{{ model != null ? model.createdBy : "" }}
					</b-form-group>
					<b-form-group id="fieldset-updated-by" label-cols-sm="4" label-cols-lg="3" label="Updated By" label-for="filter-updated-by">
						{{ model != null ? model.updatedBy : "" }}
					</b-form-group>
			</b-col>
		</b-card>
<!-- END_APP_DETAIL_HEADER -->
<!--APP_DETAIL_TABLE-->
	<b-card class="mt-3" header-bg-variant="transparent">
		<b-row class="mb-2">
			<b-col cols="9"><h5 class="card-title font-weight-bolder text-dark">Document Attachment Details</h5></b-col>
			<b-col>
				<span class="float-right">
					<b-form-select v-model="documentAttachmentDetailPageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
				</span>
			</b-col>
		</b-row>
		<b-row>
		<b-col>
			<div class="table-corner">
			<b-table id="my-table" stacked="md" head-variant="light" :no-local-sorting="true" :items="documentAttachmentDetails" :fields="fieldsDocumentAttachmentDetail" :busy="isDataLoading" :sort-by.sync="sortByDocumentAttachmentDetail" :sort-desc.sync="sortDescDocumentAttachmentDetail" small responsive>
			<!--APP_TABLE_FILTER-->
			<template v-slot:head(actions)>
				<b-button @click="isShowDocumentAttachmentDetailFilter = !isShowDocumentAttachmentDetailFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
			</template>
			<template #top-row v-if="isShowDocumentAttachmentDetailFilter">
				<b-th></b-th>
				<b-th stacked-heading="File Name">
					<b-form-input id="input-file-name-table" v-model="filterDocumentAttachmentDetail.fileName" size="sm"></b-form-input>
				</b-th>
				<b-th></b-th>
			</template>
			<!--END_APP_TABLE_FILTER-->
			<!--APP_TABLE_DATA-->
			<template v-slot:cell(no)="row">
				{{ ((row.index + 1) + ( documentAttachmentDetailPageSize * (currentDocumentAttachmentDetailPage - 1))) }}
			</template>
			<template v-slot:cell(documentAttachment)="row">
				{{ row.item.documentAttachment != null ? row.item.documentAttachment.name : "" }}
			</template>
			<template v-slot:cell(attachment)="row">
				{{ row.item.attachment != null ? row.item.attachment.originalFileName : "" }}
			</template>
			<!--END_APP_TABLE_DATA-->
			</b-table>
			</div>
		</b-col>
		<b-col cols="12">
			<!--APP_TABLE_PAGINATION-->
			<b-pagination
			class="float-right btn-paging"
			v-model="currentDocumentAttachmentDetailPage"
			:total-rows="totalDocumentAttachmentDetailRow"
			:per-page="documentAttachmentDetailPageSize"
			aria-controls="my-table"
			></b-pagination>
			<!--END_APP_TABLE_PAGINATION-->
		</b-col>
		</b-row>
	</b-card>
<!--END_APP_DETAIL_TABLE-->
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import DocumentAttachment from '@/models/DocumentAttachment/DocumentAttachment';
import DocumentAttachmentList from '@/models/DocumentAttachment/DocumentAttachmentList';
import DocumentAttachmentFilter from '@/filters/DocumentAttachment/DocumentAttachmentFilter';

import DocumentAttachmentDetail from '@/models/DocumentAttachment/DocumentAttachmentDetail';
import DocumentAttachmentDetailFilter from '@/filters/DocumentAttachment/DocumentAttachmentDetailFilter';
import Attachment from '@/models/Core/Attachment';
import AttachmentFilter from '@/filters/Core/AttachmentFilter';
import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
/*END_APP_DETAIL_IMPORT*/
export default {
	/*APP_DETAIL_PROP_DEFINITION*/
	props : ["openPopup", "modelId", "saveActionMethod", "resetMethod"],
	/*END_APP_DETAIL_PROP_DEFINITION*/
	components : {
	/*APP_DETAIL_PROP_DEFINITION*/
	PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,
	/*APP_DETAIL_COMPONENTS_DEFINITION*/
},
	data() {
		return {
			fileupload: null,
			model: {},
			datas: Array(),
			fieldsDocumentAttachmentDetail: [
				{"no" : {  "class" : "th-number" }},
				{"fileName": {},  "class" : "th-file-name", "label" : "File Name", key: 'fileName', sortable: true}, 
				{"attachment": {},  "class" : "th-attachment", "label" : "Attachment", key: 'attachment', sortable: true}, 
				{"createdBy": {},  "class" : "th-created-by", "label" : "Created By", key: 'createdBy', sortable: true}, 
				{"updatedBy": {},  "class" : "th-updated-by", "label" : "Updated By", key: 'updatedBy', sortable: true}, 
				{"actions": { "class" : "th-actions", "label" : "" }}
			],
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			/*APP_DETAIL_PAGINATION*/
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterDocumentAttachment : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentDocumentAttachmentPage : 1,
			documentAttachmentPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByDocumentAttachment: '',
			sortDescDocumentAttachment: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			filterDocumentAttachmentDetail : null,
			currentDocumentAttachmentDetailPage : 1,
			documentAttachmentDetailPageSize: 10,
			sortByDocumentAttachmentDetail: '',
			sortDescDocumentAttachmentDetail: '',
			sortingByDocumentAttachmentDetail: [],
			documentAttachmentDetails : [],
			documentAttachmentDetailId : '',
			totalDocumentAttachmentDetailRow : 0,
			isShowDocumentAttachmentDetailFilter: false,
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			/*END_APP_DETAIL_OBJ_VARIABLE*/
		}
	},
	methods : {
		/*APP_DETAIL_GETDocumentAttachment*/
		getDocumentAttachment : async function(id) {
			this.isBusy = true;
			DocumentAttachment.getData(this, id).then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETDocumentAttachment*/
		/*APP_DETAIL_GETDocumentAttachmentDetail*/
		getDocumentAttachmentDetails : async function() {
			let filter = {};
			Object.assign(filter, this.filterDocumentAttachmentDetail);
			if (this.modelId == null) return;
			filter.documentAttachmentId = this.modelId;
			this.sortingByDocumentAttachmentDetail = Array();
			if (this.sortByDocumentAttachmentDetail != null) {
				this.sortingByDocumentAttachmentDetail[this.sortByDocumentAttachmentDetail] = this.sortDescDocumentAttachmentDetail;
			}
			let currentPage = (this.currentDocumentAttachmentDetailPage - 1);
			DocumentAttachmentDetail.getList(this, filter, this.sortingByDocumentAttachmentDetail, currentPage , this.documentAttachmentDetailPageSize).then(result => {
				this.documentAttachmentDetails = result.data;
				this.totalDocumentAttachmentDetailRow = result.dataCount;
			}).catch(error => {});
		},
		/*END_APP_DETAIL_GETDocumentAttachmentDetail*/
		/*APP_DETAIL_GETATTACHMENT*/
		getAttachments : async function(input) {
			let filter = new AttachmentFilter();
			if (input != undefined) {
				filter.originalFileName = input;
			}
			Attachment.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.attachments = result.data
				}
			})
			.catch(error => {});
		},
		/*END_APP_DETAIL_GETATTACHMENT*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'documentattachmentcopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_SAVEBUTTONACTION*/
		saveButtonAction(modalEvent) {
			this.$emit("saveActionMethod", modalEvent);
		},
		/*END_APP_DETAIL_SAVEBUTTONACTION*/
		/*APP_DETAIL_RESETMODAL*/
		resetModal() {
			this.$emit('resetMethod'); 
		},
		/*END_APP_DETAIL_RESETMODAL*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
				this.isShowDocumentAttachmentDetailFilter = true;
			}
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterDocumentAttachment = new DocumentAttachmentFilter();
		this.filterDocumentAttachmentDetail = new DocumentAttachmentDetailFilter();
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	watch : {
		/*APP_DETAIL_OPENPOPUP*/
		openPopup(newValue, oldValue) {
			if (newValue) {
				this.getDocumentAttachment(this.modelId);
				this.getDocumentAttachmentDetails();
			}
		},
		/*END_APP_DETAIL_OPENPOPUP*/
		/*APP_DETAIL_WATCH_DOCUMENTATTACHMENTDETAIL*/
		sortByDocumentAttachmentDetail : {
			handler: function() {
				this.getDocumentAttachmentDetails();
			}
		},
		sortDescDocumentAttachmentDetail: {
			handler: function() {
				this.getDocumentAttachmentDetails();
			}
		},
		currentDocumentAttachmentDetailPage: {
			handler: function() {
				this.getDocumentAttachmentDetails();
			}
		},
		documentAttachmentDetailPageSize : {
			handler: function() {
				this.getDocumentAttachmentDetails();
			}
		},
		filterDocumentAttachmentDetail: {
			handler: function() {
				this.getDocumentAttachmentDetails();
			},
			deep: true,
		},
		/*END_APP_DETAIL_WATCH_DOCUMENTATTACHMENTDETAIL*/
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
		isShowPopup: {
			get: function(){
				if (this.openPopup) return true; 
				else return false; 
			},
			set: function(newValue){ }
		},
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
