import BaseModelAPI from '../BaseModelAPI';

export default class EmployeeStatusList extends BaseModelAPI {

	static entity = 'employeestatuslist'
	static entityName = 'employeestatus';

	constructor() {
		this.pageIndex = 0;
		this.pageSize = 0;
		this.pageCount = 0;
		this.dataCount = 0;
	}
}
