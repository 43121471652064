<template>
    <b-modal id="my-modal" scrollable 
        :no-close-on-backdrop=true 
        :title="label" 
        :no-close-on-esc=true 
        v-model="isShowPopup" 
        ok-title="Ok" 
        ok-variant="success" 
        size="sm"
        @hidden="resetModal"
    >

    <div>
      <b-card no-body class="mt-3" v-for="item in model" v-bind:key="item.id">
        <b-card-header header-tag="header" role="tab" class="p-2 pl-3" style="border-left: solid 4px #7d8492">
            <b-row>
              <b-col cols="12">
                  <div class="text-dark pt-lg-1">
                      {{ item.createdAt | moment("DD-MMM-YYYY HH:mm") }}
                  </div>
              </b-col>
              <b-col cols="12">
                  <div>
                      <h5 class="font-weight-bold mb-0 mb-lg-1">
                          {{ item.userProfile ? item.userProfile.fullName : "" }}
                      </h5>
                  </div>
              </b-col>
              <b-col cols="12">
                  <div>
                      <h6 class="font-weight mb-0 mb-lg-1">
                          {{ item.approvalStatusId }}
                      </h6>
                  </div>
              </b-col>
              <b-col cols="12">
                  <div class="text-secondary">
                    <b-form-group id="fieldset-survey-main-customer" label-cols-sm="12" label-cols-lg="12" label="Notes" label-for="filter-survey-main-customer">
                      {{ item.approvalRemarks != null && item.approvalRemarks != '' ? item.approvalRemarks : "-" }}
                    </b-form-group>
                  </div>
              </b-col>
              <b-col cols="12" v-if="item.attachment">
                  <div class="text-secondary">
                    <b-form-group id="fieldset-survey-main-customer" label-cols-sm="12" label-cols-lg="12" label="Attachment" label-for="filter-survey-main-customer">
                      {{ item.attachment != null  ? item.attachment.originalFilename : "-" }}
                      <b-button size="sm" @click="downloadData(item.attachment)" variant ="primary"  class="mr-2 btn-xs">
                        <b-icon-download></b-icon-download>
                      </b-button>
                    </b-form-group>
                  </div>
              </b-col>
          </b-row>
        </b-card-header>
      </b-card>
    </div>

    
    <template #modal-footer="{ ok }">
      <b-button size="sm" variant="secondary" class="float-right mr-2" @click="ok()">Close</b-button>
    </template>
    </b-modal>
</template>
<style lang="scss" scoped>
	.p-value {
        padding-top: calc(0.375rem + 1px);
        padding-bottom: calc(0.375rem + 1px);
    }
    .btn.not-collapsed .chevron-down {
        display: inline;
    }

    .btn.not-collapsed .chevron-right {
        display: none;
    }

    .btn.collapsed .chevron-right {
        display: inline;
    }

    .btn.collapsed .chevron-down {
        display: none;
    }
</style>
<script>
export default {
    props: ["openPopup","label","resetModalMethod", "contentLabel","approvalInstanceId"],
    data() {
      return {
        progressInterval : null,
        isBusy : false,
        isSuccess : false,
        isFailed : false,
        dataUrl : "",
        fileName : "",
        model: [],
        modelFlow: [],
      };
    },
    computed: {
        isShowPopup : {
            get: function () {
              if (this.openPopup) {
                this.getApprovalHistory();
                return true;
              }
              else return false;
            },
            set: function (newValue) {}
          }
    },
    async mounted() {
    },
    watch : {
    },
    methods: {
        resetModal() {
          this.isShowPopup = false;
          this.$emit('resetModalMethod');
        },
        getApprovalHistory() {
          if (this.approvalInstanceId == null || this.approvalInstanceId == undefined) return;
          this.isBusy = true;
          this.$http.get(process.env.VUE_APP_API_URL + 'approvallogs/?filter[documentId][eq]=' + this.approvalInstanceId + '&filter[documentType][eq]=SURAT KELUAR&sort=-createdDate&include=userProfile,attachment')
          .then(response => {
            if (response.data != null) {
              this.model = response.data.data;
            } else {
              this.isBusy = false;
            }
          }).catch(error => {
            this.isBusy = false;
          })
        },
        downloadData(currentModel) {
          if (currentModel == null || currentModel == undefined) return; 
          let url = process.env.VUE_APP_API_URL + 'attachment/download/' + currentModel.id;
          let fileName = currentModel.originalFilename;
          this.$http.get(url, {responseType: 'blob'}).then(response => {
            if (response != null) {
              saveAs(response.data, fileName);
            }
          }).catch(error => {
            this.$store.dispatch('addErrorMessageGlobal', error);
            throw error;
          })
        },
    }
}
</script>