import BaseModelAPI from '../BaseModelAPI';

export default class Directorate extends BaseModelAPI {
	static entity = 'directorate';
	static entityName = 'directorate';

	constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.name = '';
			this.createdBy = '';
			this.updatedBy = '';
			this.uploadValidationMessage = '';
			this.uploadValidationStatus = '';
	/*END_APP_MODEL_DEFINITION*/
	}

}