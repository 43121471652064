<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true @ok="saveButtonAction" title="Approval Remarks">
<!-- APP_DETAIL_HEADER -->
		<b-card>
			<span v-if="mode != 'revise'">
				<b-col cols="12" lg="6">
					<app-input-textarea id="fieldset-approval-notes" label-cols-sm="4" label-cols-lg="3" label="Catatan Pemeriksa Surat" label-for="input-approval-notes" size="sm" :model.sync="approval.approvalRemarks" :state="validateState('approvalRemarks')" :errorMessage="getErrorMessage(errorMessage.approvalRemarks, 'approvalRemarks')"   />
				</b-col>
			</span>
			<span v-else>
				<b-col cols="12" lg="6">
					<app-input-textarea id="fieldset-approval-notes" label-cols-sm="4" label-cols-lg="3" label="Catatan Pemeriksa Surat" label-for="input-approval-notes" size="sm" :model.sync="revise.approvalRemarks" :state="validateState('approvalRemarks')" :errorMessage="getErrorMessage(errorMessage.approvalRemarks, 'approvalRemarks')"   />
				</b-col>
				<b-col cols="12" lg="6">
					<app-input-fileupload label-cols-sm="4" label-cols-lg="3" label="File" size="sm" :model.sync="documentAttachment" />
				</b-col>
			</span>
		</b-card>
<!-- END_APP_DETAIL_HEADER -->
		<b-overlay :show="isModalBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import OutgoingDocumentList from '@/models/OutgoingDocument/OutgoingDocumentList';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';

import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputTextbox from '@/components/AppInputTextbox';
import AppInputTextarea from '@/components/AppInputTextarea';
import AppInputFileupload from '@/components/AppInputFileupload';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';

import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

/*END_APP_DETAIL_IMPORT*/
export default {
	/*APP_DETAIL_PROP_DEFINITION*/
	props : ["openPopup", "model", "saveActionMethod", "resetMethod", "mode", "isModalBusy"],
	/*END_APP_DETAIL_PROP_DEFINITION*/
	components : {PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,AppInputTextbox,AppInputTextarea,AppInputFileupload},
	mixins : [validationMixin],
	validations: {
		approval: {
			//approvalRemarks: {required},
		},
		revise: {
			//approvalRemarks: {required},
		}
	},
	data() {
		return {
			errorMessage : {
				approvalRemarks: { required : "Catatan Pemeriksa Surat is Required."},
			},
			approval : {
				approvalRemarks: ""
			},
			revise : {
				revisionNum: "",
				approvalRemarks: "",
				fileupload: null
			},
			fileupload: null,
			documentAttachment: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			/*APP_DETAIL_PAGINATION*/
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterOutgoingDocument : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentOutgoingDocumentPage : 1,
			outgoingDocumentPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByOutgoingDocument: '',
			sortDescOutgoingDocument: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			/*END_APP_DETAIL_OBJ_VARIABLE*/
		}
	},
	methods : {
		/*APP_DETAIL_GETOutgoingDocument*/
		getOutgoingDocument : async function(id) {
			this.isBusy = true;
			OutgoingDocument.getData(this, id).then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETOutgoingDocument*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'outgoingdocumentcopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_SAVEBUTTONACTION*/
		saveButtonAction(modalEvent) {
			modalEvent.preventDefault();
			if (this.mode != "revise") {
				this.$v.approval.$touch(); 
				if (this.$v.approval.$anyError) {
					return;
				} else {
					this.model.approvalRemarks = this.approval.approvalRemarks;
					this.$emit("saveActionMethod", modalEvent, this.model, this.mode);
					//this.approval.approvalRemarks = "";
				}
			} else {
				this.$v.revise.$touch(); 
				if (this.$v.revise.$anyError) {
					return;
				} else {
					if (this.documentAttachment != null) {
						let data = new FormData();
						data.append('file', this.documentAttachment);
						let _this = this;
						this.$http.post(process.env.VUE_APP_API_URL + 'attachment/upload',
							data,
							{ headers: { 'Content-Type': 'multipart/form-data' } }
							).then(async function(response){
								if (response != null) {
									if (response.data != null) {
										_this.model.approvalRemarks = _this.revise.approvalRemarks;
										_this.model.attachmentId = response.data.data.id;
										_this.$emit("saveActionMethod", modalEvent, _this.model, _this.mode);
									}
								}
								return true;
							})
							.catch(error => {
								this.isBusy = false;
								this.$store.dispatch('addErrorMessageGlobal', error);
								throw error;
							});
					} else {
						this.model.approvalRemarks = this.revise.approvalRemarks;
						this.$emit("saveActionMethod", modalEvent, this.model, this.mode);
					}
					
					//this.approval.approvalRemarks = "";
				}
			}
		},
		/*END_APP_DETAIL_SAVEBUTTONACTION*/
		/*APP_DETAIL_RESETMODAL*/
		resetModal() {
			this.$emit('resetMethod'); 
		},
		/*END_APP_DETAIL_RESETMODAL*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
		validateState(name) {
			if (this.mode != "revise") {
				if (!this.$v.approval[name]) return null;
				const { $dirty, $error } = this.$v.approval[name];
				return $dirty ? !$error : null;
			} else {
				if (!this.$v.revise[name]) return null;
				const { $dirty, $error } = this.$v.revise[name];
				return $dirty ? !$error : null;
			}
			
		},
		getErrorMessage(message, name) {
			if (this.mode != "revise") {
				if (!this.$v.approval[name]) return "";
				if (this.$v.approval[name].required != undefined && !this.$v.approval[name].required) {
					if (message.required) return message.required;
				}
			} else {
				if (!this.$v.revise[name]) return "";
				if (this.$v.revise[name].required != undefined && !this.$v.revise[name].required) {
					if (message.required) return message.required;
				}
			}
			return "";
		},
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterOutgoingDocument = new OutgoingDocumentFilter();
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	watch : {
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
		isShowPopup: {
			get: function(){
				if (this.openPopup) {
					return true; 
				}
				else {
					this.approval.approvalRemarks = "";
					return false; 
				}
			},
			set: function(newValue){ }
		},
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
