<template>
	<PopupRouterViewDetail label="Disposition Detail" @copyDataMethod="copyDataAction">
<!-- APP_DETAIL_HEADER -->
		<b-card>
			<b-col cols="12" lg="6">
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" label-for="filter-document-code">
						{{ model != null ? model.incomingDocument != null ? model.incomingDocument.documentCode : "" : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Tanggal & Waktu" label-for="filter-document-code">
						{{ model != null ? model.incomingDocument != null ? model.incomingDocument.createdAt : "" : "" | moment('DD-MMM-YYYY HH:mm')}}
					</b-form-group>
					<b-form-group id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Lokasi Surat" label-for="filter-document-location">
						{{ model != null ? model.incomingDocument != null ? model.incomingDocument.documentLocationName : "" : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Pengirim Disposisi" label-for="filter-document-location">
						{{ model != null ? model.createdByName : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Jenis Surat" label-for="filter-document-location">
						{{ model != null ? model.incomingDocument != null ? model.incomingDocument.documentTypeName : "" : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Perihal / Ringkasan Surat" label-for="filter-document-location">
						{{ model != null ? model.incomingDocument != null ? model.incomingDocument.remarks : "" : "" }}
					</b-form-group>
					<b-form-group id="fieldset-user-receiver" label-cols-sm="4" label-cols-lg="3" label="Penerima Disposisi" label-for="filter-user-receiver">
						{{ model != null ? ( model.userReceiver != null ? model.userReceiver.fullName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Jabatan Pegawai" label-for="filter-document-code">
						{{ model != null ? (model.userReceiver != null ? model.userReceiver.jobDescription : '-') : "-" }}
					</b-form-group>
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Email" label-for="filter-document-code">
						{{ model != null ? (model.userReceiver != null ? model.userReceiver.email : '-') : "-" }}
					</b-form-group>
					<b-form-group id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Pesan Disposisi" label-for="filter-remarks">
						{{ model != null ? model.remarks : "" }}
					</b-form-group>
			</b-col>
		</b-card>
<!-- END_APP_DETAIL_HEADER -->
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</PopupRouterViewDetail>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import Disposition from '@/models/Disposition/Disposition';
import DispositionList from '@/models/Disposition/DispositionList';
import DispositionFilter from '@/filters/Disposition/DispositionFilter';

import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
/*END_APP_DETAIL_IMPORT*/
export default {
	components : {
	/*APP_DETAIL_PROP_DEFINITION*/
	PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,
	/*APP_DETAIL_COMPONENTS_DEFINITION*/
},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			model: {},
			/*APP_DETAIL_PAGINATION*/
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterDisposition : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentDispositionPage : 1,
			dispositionPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByDisposition: '',
			sortDescDisposition: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			/*END_APP_DETAIL_OBJ_VARIABLE*/
		}
	},
	methods : {
		/*APP_DETAIL_GETDisposition*/
		getDisposition : async function(id) {
			this.isBusy = true;
			Disposition.getData(this, id, 'incomingDocument,userReceiver').then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETDisposition*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'dispositioncopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterDisposition = new DispositionFilter();
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	/*APP_DETAIL_MOUNTED*/
	mounted(){
		let id = this.$route.params.id;
		this.modelId = id;
		this.getDisposition(id);
		this.$nextTick(() => {
			window.addEventListener('resize', this.displayFilter);
			this.displayFilter();
		});
	},
	/*END_APP_DETAIL_MOUNTED*/
	watch : {
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
