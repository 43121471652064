import BaseModelAPI from '../BaseModelAPI';

export default class EmployeeLevel extends BaseModelAPI {
	static entity = 'employeelevel';
	static entityName = 'employeelevel';

	constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.name = '';
			this.description = '';
			this.parentId = '';
			this.parent = {};
			this.createdBy = '';
			this.updatedBy = '';
			this.uploadValidationMessage = '';
			this.uploadValidationStatus = '';
	/*END_APP_MODEL_DEFINITION*/
	}

}