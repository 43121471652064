<template>
		<b-card>
			<b-table-simple
			:bordered="true"
			
			:small="true"
			:no-border-collapse="true"
			>
			<b-tbody>
				<b-tr>
					<b-td rowspan="3">
						<img src="/img/logo.png" style="width: 200px" class="menu-logo" id="menu-logo" alt="menu logo" />
					</b-td>
					<b-td colspan="3" class="text-center"><b>INISIATIF ZAKAT INDONESIA</b></b-td>
				</b-tr>
				<b-tr>
					<b-td colspan="3" class="text-center"><b>RESI TANDA TERIMA {{ model.incomingDocument != null ? "SURAT MASUK" : "" }}{{ model.outgoingDocument != null ? "SURAT KELUAR" : "" }}</b></b-td>
				</b-tr>
				<b-tr>
					<b-td>No. Dokumen :</b-td>
					<b-td>No. Revisi: </b-td>
					<b-td>Tgl. Berlaku: </b-td>
				</b-tr>
				<b-tr>
					<b-td>No. Tanda Terima Surat</b-td>
					<b-td>{{ model != null ? model.receiptDocumentNo : "" }}</b-td>
					<b-td>Nama Penyerah Surat</b-td>
					<b-td>{{ model != null ? ( model.deliveryUser != null ? model.deliveryUser.fullName : "") : "" }}</b-td>
				</b-tr>
				<b-tr>
					<b-td>Tanggal Resi</b-td>
					<b-td>{{ (model != null ? model.documentCreatedDate : "") | moment("DD-MMM-YYYY") }}</b-td>
					<b-td>Jabatan Pegawai</b-td>
					<b-td>{{ model != null ? ( model.deliveryUser != null ? model.deliveryUser.jobDescription : "") : "" }}</b-td>
				</b-tr>
				
				<b-tr>
					<b-td>Pengirim Surat</b-td>
					<b-td>{{ model != null ? model.senderName : "" }}</b-td>
					<b-td>Nama Penerima Surat</b-td>
					<b-td>{{ model != null ? ( model.deliveryUser != null ? model.deliveryUser.fullName : "") : "" }}</b-td>
				</b-tr>
				<b-tr>
					<b-td>Ditujukan Kepada</b-td>
					<b-td style="white-space: pre-wrap; width: 35% !important">
						<span v-if="model.incomingDocument != null && model.incomingDocument.isSendToAll">
							Semua Karyawan
						</span>
						<span v-else>
							<div v-if="model.incomingDocumentDestinationUser != null">
								<span v-for="item, index in model.incomingDocumentDestinationUser" :key="item.id">
									{{ item.destinationUser ? item.destinationUser.fullName : "-" }}<span v-if="index < (model.incomingDocumentDestinationUser.length - 1)">, </span>
								</span>
							</div>
						</span>
						<span v-if="model.outgoingDocument != null && model.outgoingDocument.isSendToAll">
							Semua Karyawan
						</span>
						<span v-else>
							<div v-if="model.outgoingDocumentDestinationUser != null">
								<span v-for="item, index in model.outgoingDocumentDestinationUser" :key="item.id">
									{{ item.destinationUser ? item.destinationUser.fullName : "-" }}<span v-if="index < (model.outgoingDocumentDestinationUser.length - 1)">, </span>
								</span>
							</div>
						</span>
					</b-td>
					<b-td>Jabatan</b-td>
					<b-td>{{ model != null ? ( model.deliveryUser != null ? model.deliveryUser.jobDescription : "") : "" }}</b-td>
				</b-tr>
				<b-tr>
					<b-td>Ditembuskan Kepada</b-td>
					<b-td style="white-space: pre-wrap; width: 35% !important">
						<div v-if="model.incomingDocumentCopyUser != null">
							<span v-for="item, index in model.incomingDocumentCopyUser" :key="item.id">
								{{ item.copyUser ? item.copyUser.fullName : "-" }}<span v-if="index < (model.incomingDocumentCopyUser.length - 1)">, </span>
							</span>
						</div>
						<div v-if="model.outgoingDocumentCopyUser != null">
							<span v-for="item, index in model.outgoingDocumentCopyUser" :key="item.id">
								{{ item.copyUser ? item.copyUser.fullName : "-" }}<span v-if="index < (model.outgoingDocumentCopyUser.length - 1)">, </span>
							</span>
						</div>
					</b-td>
					<b-td colspan="2" rowspan="3">Catatan :<br />{{ model != null ? model.remarks : "" }}</b-td>
				</b-tr>
				<b-tr>
					<b-td>Jenis Surat / Barang</b-td>
					<b-td>{{ model != null ? ( model.documentType != null ? model.documentType.name : "-") : "-" }}</b-td>
					
				</b-tr>
				<b-tr>
					<b-td>Nomor Surat</b-td>
					<b-td>{{ model != null ? model.documentNo : "-" }}</b-td>
				</b-tr>
				<b-tr>
					<b-td>Tanggal Surat</b-td>
					<b-td>{{ (model != null ? model.documentDate : "") | moment("DD-MMM-YYYY") }}</b-td>
					<b-td>Tgl :<span class="ml-3">/</span><span class="ml-3">/</span></b-td>
					<b-td>Tgl :<span class="ml-3">/</span><span class="ml-3">/</span></b-td>
				</b-tr>
				<b-tr>
					<b-td colspan="2" style="height: 100px">Perihal / Ringkasan Surat:<br />{{ model != null ? (model.documentDescription != null && model.documentDescription != "" ? model.documentDescription : "-") : "-" }}</b-td>
					<b-td>Paraf Penyerah :</b-td>
					<b-td>Paraf Penerima :</b-td>
				</b-tr>
			</b-tbody>
			</b-table-simple>
			<div class="mt-3" id="btn-action">
				<b-button class="ml-2" variant="success" size="sm" @click="printPage"><b-icon-printer /> Print</b-button>
				<b-button class="ml-2" variant="danger" size="sm" @click="closePage"><b-icon-x /> Close</b-button>
			</div>
			<b-col cols="6" v-if="false">
					<b-form-group id="fieldset-receipt-document-no" label-cols-sm="4" label-cols-lg="3" label="No. Tanda Terima Surat" label-for="filter-receipt-document-no">
						{{ model != null ? model.receiptDocumentNo : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" label-for="filter-document-code">
						{{ model != null ? model.documentCode : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-created-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal & Waktu" label-for="filter-document-created-date">
						{{ (model != null ? model.documentCreatedDate : "") | moment("DD-MMM-YYYY") }}
					</b-form-group>
					<b-form-group id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Lokasi Surat" label-for="filter-document-location">
						{{ model != null ? ( model.documentLocation != null ? model.documentLocation.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-sender-name" label-cols-sm="4" label-cols-lg="3" label="Nama Pengirim" label-for="filter-sender-name">
						{{ model != null ? model.senderName : "" }}
					</b-form-group>
					<b-form-group id="fieldset-destination-user" label-cols-sm="4" label-cols-lg="3" label="Ditujukan Kepada" label-for="filter-destination-user">
						<div v-if="model.incomingDocumentDestinationUser != null">
							<span v-for="item, index in model.incomingDocumentDestinationUser" :key="item.id">
								{{ item.destinationUser ? item.destinationUser.fullName : "-" }}<span v-if="index < (model.incomingDocumentDestinationUser.length - 1)">, </span>
							</span>
						</div>
						<div v-if="model.outgoingDocumentDestinationUser != null">
							<span v-for="item, index in model.outgoingDocumentDestinationUser" :key="item.id">
								{{ item.destinationUser ? item.destinationUser.fullName : "-" }}<span v-if="index < (model.outgoingDocumentDestinationUser.length - 1)">, </span>
							</span>
						</div>
					</b-form-group>
					<b-form-group id="fieldset-document-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal Surat" label-for="filter-document-date">
						{{ (model != null ? model.documentDate : "") | moment("DD-MMM-YYYY") }}
					</b-form-group>
					<b-form-group id="fieldset-document-type" label-cols-sm="4" label-cols-lg="3" label="Jenis Surat/Barang" label-for="filter-document-type">
						{{ model != null ? ( model.documentType != null ? model.documentType.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-no" label-cols-sm="4" label-cols-lg="3" label="Nomor Surat" label-for="filter-document-no">
						{{ model != null ? model.documentNo : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-description" label-cols-sm="4" label-cols-lg="3" label="Ringkasan Surat" label-for="filter-document-description">
						{{ model != null ? model.documentDescription : "" }}
					</b-form-group>
					<b-form-group id="fieldset-delivery-user" label-cols-sm="4" label-cols-lg="3" label="Nama Penyerah Surat" label-for="filter-delivery-user">
						{{ model != null ? ( model.deliveryUser != null ? model.deliveryUser.fullName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-receipt-user" label-cols-sm="4" label-cols-lg="3" label="Nama Penerima Surat" label-for="filter-receipt-user">
						{{ model != null ? ( model.receiptUser != null ? model.receiptUser.fullName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Catatan" label-for="filter-remarks">
						{{ model != null ? model.remarks : "" }}
					</b-form-group>
					<b-form-group id="fieldset-incoming-document" label-cols-sm="4" label-cols-lg="3" label="Incoming Document" label-for="filter-incoming-document">
						{{ model != null ? ( model.incomingDocument != null ? model.incomingDocument.documentCode : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-outgoing-document" label-cols-sm="4" label-cols-lg="3" label="OutgoingDocument" label-for="filter-outgoing-document">
						{{ model != null ? model.outgoingDocument : "" }}
					</b-form-group>
			</b-col>

		</b-card>
</template>
<style>
body {
	background: #fff !important;
}
@media print {
	#btn-action {
		display: none;
	}
}
</style>
<script>
/*APP_DETAIL_IMPORT*/
import ReceiptDocument from '@/models/ReceiptDocument/ReceiptDocument';
import ReceiptDocumentList from '@/models/ReceiptDocument/ReceiptDocumentList';
import ReceiptDocumentFilter from '@/filters/ReceiptDocument/ReceiptDocumentFilter';

import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
/*END_APP_DETAIL_IMPORT*/
export default {
	components : {
	/*APP_DETAIL_PROP_DEFINITION*/
	PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,
	/*APP_DETAIL_COMPONENTS_DEFINITION*/
},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			model: null,
			/*APP_DETAIL_PAGINATION*/
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterReceiptDocument : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentReceiptDocumentPage : 1,
			receiptDocumentPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByReceiptDocument: '',
			sortDescReceiptDocument: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			/*END_APP_DETAIL_OBJ_VARIABLE*/
		}
	},
	methods : {
		printPage() { window.print() },
		closePage() { window.close() },
		/*APP_DETAIL_GETReceiptDocument*/
		getReceiptDocument : async function(id) {
			this.isBusy = true;
			let promises = Array();
			ReceiptDocument.getData(this, id, 'documentLocation,deliveryUser,documentType,destinationUser,receiptUser,incomingDocument,outgoingocument,incomingDocumentDestinationUser,outgoingDocumentDestinationUser')
			.then(result => 
			{ 
				this.model = result; 
				this.isBusy = false;
				return true;
			}).catch(error => { this.isBusy = false; });
				
		},
		/*END_APP_DETAIL_GETReceiptDocument*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'receiptdocumentcopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			window.print();
			let result = window.confirm("Close Page ?");
			if (result)
				window.close();
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterReceiptDocument = new ReceiptDocumentFilter();
		let id = this.$route.params.id;
		this.modelId = id;
		this.getReceiptDocument(id);
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	/*APP_DETAIL_MOUNTED*/
	mounted(){
		this.$nextTick(() => {
			//this.displayFilter();
		});
	},
	updated: function () {
		this.$nextTick(function () {
			//window.print();
		})
	},

	/*END_APP_DETAIL_MOUNTED*/
	watch : {
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
