<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true id="popup-upload" @ok="uploadButtonAction" :okTitle="okTitle" title="Upload Data">
		<b-card>
			<b-row>
				<b-col cols="12">
					<app-input-fileupload label="File" size="sm" :model.sync="fileupload" />
				</b-col>
			</b-row>
			<b-row v-if="isUploadSuccess">
				<b-col cols="12">
					<b-card no-body>
						<b-tabs card>
							<b-tab title="Function Info" active>
								<b-row class="mb-2">
									<b-col>
										<span class="float-right">
											<b-form-select v-model="functionInfoPageSize" size="sm" class="float-right" :options="pagingOptions"></b-form-select>
										</span>
									</b-col>
								</b-row>
								<b-row>
								<b-col>
								<div class="table-corner">
									<b-table id="my-table" stacked="md" head-variant="light" :no-local-sorting="true" :items="functionInfos" :fields="fieldsFunctionInfo" :busy="isDataLoading" :sort-by.sync="sortByFunctionInfo" :sort-desc.sync="sortDescFunctionInfo" small responsive>
									<template v-slot:head(actions)>
										<b-button @click="isShowFunctionInfoFilter = !isShowFunctionInfoFilter" class="float-right" size="sm"><b-icon-funnel-fill /></b-button>
									</template>
									<template #top-row v-if="isShowFunctionInfoFilter">
										<b-th></b-th>
										<b-th stacked-heading="Name">
											<b-form-input id="input-name-table" v-model="filterFunctionInfo.name" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="URL">
											<b-form-input id="input-uri-table" v-model="filterFunctionInfo.uri" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Icon Name">
											<b-form-input id="input-icon-name-table" v-model="filterFunctionInfo.iconName" size="sm"></b-form-input>
										</b-th>
										<b-th stacked-heading="Is Enabled">
											<b-form-checkbox id="input-is-enabled-table" @change="getFunctionInfos" class="float-right" v-model="filterFunctionInfo.isEnabled"></b-form-checkbox>
										</b-th>
										<b-th stacked-heading="Module Info">
											<v-select append-to-body label="name" :options="moduleInfos" :value="filterFunctionInfo.moduleInfoId"  :reduce="item => item.id" v-model="filterFunctionInfo.moduleInfoId" @search="getModuleInfos" :filterable="true"></v-select>
										</b-th>
										<b-th>
										</b-th>
									</template>
									<template v-slot:table-busys>
										<div class="text-center my-2">
									<b-spinner class="align-middle text-primary"></b-spinner>
										<strong class="ml-2">Loading..</strong>
										</div>
									</template>
									<template v-slot:cell(no)="row">
										{{ ((row.index + 1) + ( functionInfoPageSize * (currentFunctionInfoPage - 1))) }}
									</template>
									<template v-slot:cell(moduleInfo)="row">
										{{ row.item.moduleInfo != null ? row.item.moduleInfo.name : "" }}
									</template>
									</b-table>
									</div>
								</b-col>
								<b-col cols="12">
									<b-pagination
									class="float-right btn-paging"
									v-model="currentFunctionInfoPage"
									:total-rows="totalFunctionInfoRow"
									:per-page="functionInfoPageSize"
									aria-controls="my-table"
									></b-pagination>
								</b-col>
								</b-row>
							</b-tab>
						</b-tabs>
					</b-card>
				</b-col>
			</b-row>
		</b-card>
		<template #modal-footer="{ ok, cancel }">
			<b-button v-if="isUploadSuccess" size="sm" variant="primary" @click="downloadLog()">
				Download Error Message
			</b-button>
			<b-button size="sm" variant="success" class="float-right" @click="ok()">
				{{ okTitle }}
			</b-button>
			<b-button size="sm" variant="secondary" class="float-right" @click="cancel()">
				Cancel
			</b-button>
		</template>
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<style lang="scss" scoped>
.card {
	border: 1px solid rgba(0, 0, 0, 0.125) !important;
}
::v-deep .th-message {
	color: red;
}
::v-deep .modal-footer {
	display: block;
}
</style>
<script>
import axios from 'axios';
import { validationMixin } from "vuelidate"; 
import { required, minLength } from "vuelidate/lib/validators"; 
import { saveAs } from 'file-saver';

import FunctionInfo from '@/models/FunctionInfo/FunctionInfo';
import FunctionInfoList from '@/models/FunctionInfo/FunctionInfoList';
import FunctionInfoFilter from '@/filters/FunctionInfo/FunctionInfoFilter';

import ModuleInfo from '@/models/ModuleInfo/ModuleInfo';
import ModuleInfoFilter from '@/filters/ModuleInfo/ModuleInfoFilter';
import AppInputFileupload from '@/components/AppInputFileupload';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
export default {
	props: ['model', 'openPopup','uploadButtonMethod', 'parentId'],
	components : {AppInputFileupload,AppInputDatetimepicker,AppInputDatetimepickerRange,},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			selectAll:false,
			sortBy: '',
			sortDesc: '',
			pagingOptions:[
				{ value : '10', text : '10', selected:true },
				{ value : '25', text : '25' },
				{ value : '50', text : '50' },
				{ value : '100', text : '100' },
			],
			perPage: 10,
			currentPage: 1,
			totalData:0,
			fieldsFunctionInfo: [
				{"no" : { "class" : "th-number"}}, 
				{"name": { "class" : "th-name", "label" : "Name"}, key: 'name', sortable: true}, 
				{"uri": { "class" : "th-uri", "label" : "URL"}, key: 'uri', sortable: true}, 
				{"iconName": { "class" : "th-icon-name", "label" : "Icon Name"}, key: 'iconName', sortable: true}, 
				{"isEnabled": { "class" : "th-is-enabled", "label" : "Is Enabled"}, key: 'isEnabled', sortable: true}, 
				{"moduleInfo": { "class" : "th-module-info", "label" : "Module Info"}, key: 'moduleInfo', sortable: true}, 
				{"class" : "th-status", "label" : "Status", key: 'uploadValidationStatus', sortable: true},
				{"class" : "th-message", "label" : "Message", key: 'uploadValidationMessage', sortable: true},
				{"actions": { "class" : "th-actions", "label" : "" }}
			],
			busy:false,
			isDataLoading: false,
			isBusy : false,
			selected:'',
			pageSizeOptions: 10,
			pageIndex:0,
			sortingBy: Array(),
			functionInfos : [],
			functionInfoId : '',
			totalFunctionInfoRow : 0,
			isShowFunctionInfoFilter: false,
			filterFunctionInfo : null,
			currentFunctionInfoPage : 1,
			functionInfoPageSize: 10,
			sortByFunctionInfo: '',
			sortDescFunctionInfo: '',
			moduleInfos: [],
			processId: "",
			isUploadSuccess: false,
		}
	},
	methods : {
		resetModal() {
			this.datas = Array();
			this.$emit('resetMethod');
		},
		uploadButtonAction(modalEvent) {
			modalEvent.preventDefault();
			this.isBusy = true;
			if (this.isUploadSuccess) {
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'functioninfo/confirmUpload',
					[],
					).then(async function(response){
						if (response != null) {
						if (response.data != null) {
							_this.resetModal();
							_this.datas = Array();
							_this.fileupload = null;
							_this.isUploadSuccess = false;
						}
						}
						_this.isBusy = false;
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
			} else {
				if (this.fileupload != null) {
				let data = new FormData();
				data.append('file', this.fileupload);
				let _this = this;
				this.$http.post(process.env.VUE_APP_API_URL + 'functioninfo/upload',
					data,
					{ headers: { 'Content-Type': 'multipart/form-data' } }
					).then(async function(response){
						_this.isBusy = false;
						_this.isUploadSuccess = true;
						_this.getFunctionInfos();
					})
					.catch(error => {
						_this.$store.dispatch('addErrorMessageGlobal', error);
						_this.isBusy = false;
					});
				}
			}
		},
		getFunctionInfos : async function() {
			let filter = {};
			Object.assign(filter, this.filterFunctionInfo);
			filter.draftFromUpload = "1";
			filter.draftMode = "1";
			this.sortingByFunctionInfo = Array();
			if (this.sortByFunctionInfo != null) {
				this.sortingByFunctionInfo[this.sortByFunctionInfo] = this.sortDescFunctionInfo;
			}
			let currentPage = (this.currentFunctionInfoPage - 1);
			FunctionInfo.getList(this, filter, this.sortingByFunctionInfo, currentPage , this.functionInfoPageSize).then(result => {
				this.functionInfos = result.data;
				this.totalFunctionInfoRow = result.dataCount;
			}).catch(error => {});
		},
		getModuleInfos : async function(input) {
			let filter = new ModuleInfoFilter();
			if (input != undefined) {
				filter.name = input;
			}
			ModuleInfo.getList(this, filter, null, 0, 10)
			.then(result => {
				if (result != null) {
					this.moduleInfos = result.data
				}
			})
			.catch(error => {});
		},
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		downloadLog() {
			let _this = this;
			this.$http.post(process.env.VUE_APP_API_URL + 'functioninfo/downloadlog', [],
			{ headers: { 'Content-Type': 'application/json' }}
			).then(async function(response){
				await saveAs(process.env.VUE_APP_API_URL + 'functioninfo/downloadlog', "FunctionInfo.xslx");
				_this.isBusy = false;
			})
			.catch(error => {
				_this.$store.dispatch('addErrorMessageGlobal', error);
				_this.isBusy = false;
			});
		}
	},
	beforeMount() {
	},
	mounted(){
	},
	watch: {
		sortByFunctionInfo : {
			handler: function() {
				this.getFunctionInfos();
			}
		},
		sortDescFunctionInfo: {
			handler: function() {
				this.getFunctionInfos();
			}
		},
		currentFunctionInfoPage: {
			handler: function() {
				this.getFunctionInfos();
			}
		},
		functionInfoPageSize : {
			handler: function() {
				this.getFunctionInfos();
			}
		},
		filterFunctionInfo: {
			handler: function() {
				this.getFunctionInfos();
			},
			deep: true,
		},
		fileupload(newValue, oldValue) {
			this.datas = Array();
			this.isUploadSuccess = false;
		}
	},
	computed: {
		okTitle: {
			get: function() {
				if (this.isUploadSuccess) {
					return "Proses";
				}
				return "Simpan";
				}
		},
		isShowPopup : {
			get: function () {
				if (this.openPopup) {
					this.filterFunctionInfo = new FunctionInfoFilter();
					return true;
				}
				else return false;
			},
			set: function (newValue) {}
		},
	}
}
</script>
