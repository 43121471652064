import UserProfile from '@/models/UserProfile/UserProfile';

export default class UserProfileFilter {

	constructor() {
	/*APP_FILTER_DEFINITION*/
		this.id = '';
		this.fullName = '';
		this.jobDescription = '';
		this.email = '';
		this.coreUserId  = '';
		this.coreUserFirstName = '';
		this.branchId  = '';
		this.branchName = '';
		this.directorateId  = '';
		this.directorateName = '';
		this.employeeLevelId  = '';
		this.employeeLevelName = '';
		this.employeeStatusId  = '';
		this.employeeStatusName = '';
		this.educationLevelId  = '';
		this.educationLevelName = '';
		this.bloodTypeId  = '';
		this.bloodTypeName = '';
		this.maritalStatusId  = '';
		this.maritalStatusName = '';
		this.joinDateFrom = '';
		this.joinDateTo = '';
		this.birthDateFrom = '';
		this.birthDateTo = '';
		this.kidsCount = '';
		this.addressMultiple = '';
		this.phoneNumber = '';
		this.npwpNumber = '';
		this.nip = '';
		this.realEmail = '';
		this.draftFromUpload = '';
		this.draftMode = '';
	/*END_APP_FILTER_DEFINITION*/
		this.exactType = {
	/*APP_APP_FILTER_CRITERIA*/
			id : '0',
			fullName : '0',
			jobDescription : '0',
			email : '0',
			coreUserId : '0',
			coreUserFirstName : '0',
			branchId : '0',
			branchName : '0',
			directorateId : '0',
			directorateName : '0',
			employeeLevelId : '0',
			employeeLevelName : '0',
			employeeStatusId : '0',
			employeeStatusName : '0',
			educationLevelId : '0',
			educationLevelName : '0',
			bloodTypeId : '0',
			bloodTypeName : '0',
			maritalStatusId : '0',
			maritalStatusName : '0',
			joinDateFrom : '0',
			joinDateTo : '0',
			birthDateFrom : '0',
			birthDateTo : '0',
			kidsCount : '0',
			addressMultiple : '0',
			phoneNumber : '0',
			npwpNumber : '0',
			nip : '0',
			realEmail : '0',
	/*END_APP_FILTER_CRITERIA*/
		};
	}

}