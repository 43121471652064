import EmployeeStatus from '@/models/EmployeeStatus/EmployeeStatus';

export default class EmployeeStatusFilter {

	constructor() {
	/*APP_FILTER_DEFINITION*/
		this.id = '';
		this.name = '';
		this.createdBy = '';
		this.updatedBy = '';
		this.draftFromUpload = '';
		this.draftMode = '';
	/*END_APP_FILTER_DEFINITION*/
		this.exactType = {
	/*APP_APP_FILTER_CRITERIA*/
			id : '0',
			name : '0',
			createdBy : '0',
			updatedBy : '0',
	/*END_APP_FILTER_CRITERIA*/
		};
	}

}