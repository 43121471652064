import BaseModelAPI from '../BaseModelAPI';

export default class EducationLevel extends BaseModelAPI {
	static entity = 'educationlevel';
	static entityName = 'educationlevel';

	constructor(){

	/*APP_MODEL_DEFINITION*/
			this.id = '';
			this.name = '';
			this.createdBy = '';
			this.updatedBy = '';
			this.uploadValidationMessage = '';
			this.uploadValidationStatus = '';
	/*END_APP_MODEL_DEFINITION*/
	}

}