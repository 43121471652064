<template>
	<b-modal v-model="isShowPopup" dialog-class="modal-detail" @hidden="resetModal" scrollable :no-close-on-backdrop=true @ok="saveButtonAction" title="Save Confirmation - Outgoing Document">
<!-- APP_DETAIL_HEADER -->
		<b-card>
			<b-col cols="12" lg="6">
					<b-form-group id="fieldset-document-code" label-cols-sm="4" label-cols-lg="3" label="Kode Risalah" label-for="filter-document-code">
						{{ model != null ? model.documentCode : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-location" label-cols-sm="4" label-cols-lg="3" label="Lokasi Dokumen" label-for="filter-document-location">
						{{ model != null ? ( model.documentLocation != null ? model.documentLocation.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-applicant" label-cols-sm="4" label-cols-lg="3" label="Applicant" label-for="filter-applicant">
						{{ model != null ? ( model.applicant != null ? model.applicant.fullName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-destination-user" label-cols-sm="4" label-cols-lg="3" label="Ditujukan Kepada" label-for="filter-destination-user">
						{{ model != null ? model.destinationUser : "" }}
					</b-form-group>
					<b-form-group id="fieldset-destination-status" label-cols-sm="4" label-cols-lg="3" label="Status Tujuan" label-for="filter-destination-status">
						{{ model != null ? ( model.destinationStatus != null ? model.destinationStatus.name : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-destination-contact-person" label-cols-sm="4" label-cols-lg="3" label="Contact Person (CP)" label-for="filter-destination-contact-person">
						{{ model != null ? model.destinationContactPerson : "" }}
					</b-form-group>
					<b-form-group id="fieldset-destination-address" label-cols-sm="4" label-cols-lg="3" label="Alamat Kirim" label-for="filter-destination-address">
						{{ model != null ? model.destinationAddress : "" }}
					</b-form-group>
					<b-form-group id="fieldset-document-date" label-cols-sm="4" label-cols-lg="3" label="Tanggal Surat" label-for="filter-document-date">
						{{ (model != null ? model.documentDate : "") | moment("DD-MMM-YYYY") }}
					</b-form-group>
					<b-form-group id="fieldset-description" label-cols-sm="4" label-cols-lg="3" label="Perihal/Ringkasan Surat" label-for="filter-description">
						{{ model != null ? model.description : "" }}
					</b-form-group>
					<b-form-group id="fieldset-contract-date" label-cols-sm="4" label-cols-lg="3" label="Masa Berlaku Kontrak" label-for="filter-contract-date">
						{{ (model != null ? model.contractDate : "") | moment("DD-MMM-YYYY") }}
					</b-form-group>
					<b-form-group id="fieldset-contract-description" label-cols-sm="4" label-cols-lg="3" label="Catatan Atas Kontrak" label-for="filter-contract-description">
						{{ model != null ? model.contractDescription : "" }}
					</b-form-group>
					<b-form-group id="fieldset-is-draft" label-cols-sm="4" label-cols-lg="3" label="Draft" label-for="filter-is-draft">
						{{ model != null ? model.isDraft : "" }}
					</b-form-group>
					<b-form-group id="fieldset-is-lampiran" label-cols-sm="4" label-cols-lg="3" label="Lampiran" label-for="filter-is-lampiran">
						{{ model != null ? model.isLampiran : "" }}
					</b-form-group>
					<b-form-group id="fieldset-draft-attachment" label-cols-sm="4" label-cols-lg="3" label="Draft" label-for="filter-draft-attachment">
						{{ model != null ? ( model.draftAttachment != null ? model.draftAttachment.originalFileName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-lampiran-attachment" label-cols-sm="4" label-cols-lg="3" label="Lampiran" label-for="filter-lampiran-attachment">
						{{ model != null ? ( model.lampiranAttachment != null ? model.lampiranAttachment.originalFileName : "") : "" }}
					</b-form-group>
					<b-form-group id="fieldset-remarks" label-cols-sm="4" label-cols-lg="3" label="Catatan" label-for="filter-remarks">
						{{ model != null ? model.remarks : "" }}
					</b-form-group>
					<b-form-group id="fieldset-outgoing-document-status" label-cols-sm="4" label-cols-lg="3" label="Status" label-for="filter-outgoing-document-status">
						{{ model != null ? ( model.outgoingDocumentStatus != null ? model.outgoingDocumentStatus.name : "") : "" }}
					</b-form-group>
			</b-col>
		</b-card>
<!-- END_APP_DETAIL_HEADER -->
		<b-overlay :show="isBusy" no-wrap></b-overlay>
	</b-modal>
</template>
<script>
/*APP_DETAIL_IMPORT*/
import OutgoingDocument from '@/models/OutgoingDocument/OutgoingDocument';
import OutgoingDocumentList from '@/models/OutgoingDocument/OutgoingDocumentList';
import OutgoingDocumentFilter from '@/filters/OutgoingDocument/OutgoingDocumentFilter';

import PopupRouterViewDetail from '@/components/PopupRouterViewDetail';
import AppInputDatetimepicker from '@/components/AppInputDatetimepicker';
import AppInputDatetimepickerRange from '@/components/AppInputDatetimepickerRange';
/*END_APP_DETAIL_IMPORT*/
export default {
	/*APP_DETAIL_PROP_DEFINITION*/
	props : ["openPopup", "model", "saveActionMethod", "resetMethod"],
	/*END_APP_DETAIL_PROP_DEFINITION*/
	components : {
	/*APP_DETAIL_PROP_DEFINITION*/
	PopupRouterViewDetail,AppInputDatetimepicker,AppInputDatetimepickerRange,
	/*APP_DETAIL_COMPONENTS_DEFINITION*/
},
	data() {
		return {
			fileupload: null,
			datas: Array(),
			busy:false,
			isBusy: false,
			isDataLoading: false,
			selected:'',
			/*APP_DETAIL_PAGINATION*/
			/*END_APP_DETAIL_PAGINATION*/
			/*APP_DETAIL_FILTER*/
			filterOutgoingDocument : null,
			/*END_APP_DETAIL_FILTER*/
			/*APP_DETAIL_PAGING_DEFINITION*/
			currentOutgoingDocumentPage : 1,
			outgoingDocumentPageSize: 10,
			/*END_APP_DETAIL_PAGING_DEFINITION*/
			/*APP_DETAIL_SORT_DEFINITION*/
			sortByOutgoingDocument: '',
			sortDescOutgoingDocument: '',
			/*END_APP_DETAIL_SORT_DEFINITION*/
			/*APP_DETAIL_VARIABLE_DEFINITION*/
			/*END_APP_DETAIL_VARIABLE_DEFINITION*/
			/*APP_DETAIL_OBJ_VARIABLE*/
			/*END_APP_DETAIL_OBJ_VARIABLE*/
		}
	},
	methods : {
		/*APP_DETAIL_GETOutgoingDocument*/
		getOutgoingDocument : async function(id) {
			this.isBusy = true;
			OutgoingDocument.getData(this, id).then(result => { this.model = result; this.isBusy = false; }).catch(error => { this.isBusy = false; });
		},
		/*END_APP_DETAIL_GETOutgoingDocument*/
		/*APP_DETAIL_COPYDATA*/
		copyDataAction(modelEvent) {
			this.$router.push({ name: 'outgoingdocumentcopydata', params: { id: this.modelId, copydata : 1 } })
		},
		/*END_APP_DETAIL_COPYDATA*/
		/*APP_DETAIL_SAVEBUTTONACTION*/
		saveButtonAction(modalEvent) {
			this.$emit("saveActionMethod", modalEvent);
		},
		/*END_APP_DETAIL_SAVEBUTTONACTION*/
		/*APP_DETAIL_RESETMODAL*/
		resetModal() {
			this.$emit('resetMethod'); 
		},
		/*END_APP_DETAIL_RESETMODAL*/
		/*APP_DETAIL_DISPLAYFILTER*/
		displayFilter() {
			this.windowWidth = window.innerWidth;
			if (this.windowWidth <= 768 ) {
			}
		},
		/*END_APP_DETAIL_DISPLAYFILTER*/
	},
	/*APP_DETAIL_BEFORE_MOUNT*/
	beforeMount() {
		this.filterOutgoingDocument = new OutgoingDocumentFilter();
	},
	/*END_APP_DETAIL_BEFORE_MOUNT*/
	watch : {
	},
	/*APP_DETAIL_COMPUTED*/
	computed: {
		isShowPopup: {
			get: function(){
				if (this.openPopup) return true; 
				else return false; 
			},
			set: function(newValue){ }
		},
	}
	/*END_APP_DETAIL_COMPUTED*/
}
</script>
