import FunctionInfo from '@/models/FunctionInfo/FunctionInfo';

export default class FunctionInfoFilter {

	constructor() {
	/*APP_FILTER_DEFINITION*/
		this.id = '';
		this.name = '';
		this.uri = '';
		this.iconName = '';
		this.isEnabled = null;
		this.moduleInfoId  = '';
		this.moduleInfoName = '';
		this.isFromUpload = false;
		this.isSavedToBackend = false;
		this.draftFromUpload = '';
		this.draftMode = '';
	/*END_APP_FILTER_DEFINITION*/
		this.exactType = {
	/*APP_APP_FILTER_CRITERIA*/
			id : '0',
			name : '0',
			uri : '0',
			iconName : '0',
			isEnabled : '1',
			moduleInfoId : '0',
			moduleInfoName : '0',
			isFromUpload : '0',
			isSavedToBackend : '0',
	/*END_APP_FILTER_CRITERIA*/
		};
	}

}