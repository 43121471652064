import BaseModel from '../BaseModel';
import RoleDetail from './RoleDetail';

export default class Role extends BaseModel {
  static entity = 'role'
  static entityName = "role";
  
  static fields () {
    return {
      id : this.uid(),
      name : this.string(null).nullable(),
      description : this.string(null).nullable(),
      roleDetails : this.hasMany(RoleDetail, "roleId")
    }
  }

  static async getMe(this_) {
    const accessToken = window.localStorage.getItem("access_token")
    return this.api().get(process.env.VUE_APP_API_URL + `role/me?include=roleDetails`, {
        persistBy: "create",
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        dataTransformer: (response) => {
          if (response.data != undefined && response.data != null) {
            if (response.data.data != undefined && response.data.data != null) {
              return response.data.data;
            }
            return response.data;
          }
          return {};
        }
    }).catch(function(error){
        //this_.$store.dispatch('addErrorMessageGlobal', error);
        throw error;
    });
  }
  
  static async getMyUserGroup(this_) {
    return this_.$http.get(process.env.VUE_APP_API_URL + `${this.entityName}/myusergroup`)
    .then(response => {
        let responseData = response.data;
        
        return responseData;
    })
    .catch(function(error){
        this_.$store.dispatch('addErrorMessageGlobal', error);
        throw error;
    });;
}
}